import { getActionName } from './util';
import {
  GET_PRODUCTS, GET_INDUSTRY_STORES, SEARCH_STORES, GET_STORE_PRODUCTS_CATEGORIES,
  GET_STORE_DETAIL_INFO, SET_SELECTED_STORE_PRODUCT_CATEGORY,
  RESET_SELECTED_STORE_PRODUCT_CATEGORY, GET_CART_PRODUCTS,
  DELETE_PRODUCT_FROM_CART, ADD_UPDATE_PRODUCT_TO_CART,
  CHECKOUT_INVOICE, RESET_CHECKOUT_INVOICE, PLACE_ORDER,
  RESET_INDUSTRY_STORES, RESET_STORE_PRODUCT_CATEGORIES,
  RESET_PRODUCTS, VALIDATE_VOUCHER, RESET_VALIDATE_VOUCHER,
  SEARCH_APP, GET_SINGLE_PRODUCT, RESET_GET_SINGLE_PRODUCT,
  GET_PRODUCTS_BY_ORDER_ID, RESET_GET_PRODUCTS_BY_ORDER_ID,
  SET_STORE_LISTING_SELECTED_PAGE_INDEX, RESET_SEARCH_STORES,
  UPDATE_CHECKOUT_INVOICE, GET_ORDER_INVOICE_STATUS,
  INITIATE_NETA_XEPT_PAYMENT,
  RESET_INITIATE_NETAXEPT_PAYMENT, GET_BANK_ALFALAH_PAYMENT_FORM,
  RESET_BANK_ALFALAH_PAYMENT_FORM
} from 'view.updater/actions/products.actions';
import { fromJS } from 'immutable';
import { DOWNLOAD_INVOICE_RECEIPT, UPDATE_NETA_XEPT_PAYMENT } from '../actions/products.actions';
import { format24HrDateTime, downloadDocument } from 'components/common/util/util';

const initialState = fromJS({
  subCategoryProducts: [],
  industryStores: [],
  activeIndustry: '',
  storeDetailInfo: '',
  voucherInfo: '',
  checkOutInvoice: '',
  cartProducts: '',
  singleProduct: '',
  orderedProducts: '',// for reorder work
  storeProductCategories: [],
  selectedStoreProductCategory: '',
  storeListingSelectedPageIndex: 1,
  netsPaymentInitData: "",
  bankAlfalahPaymentFormDetails: ""
});

function productsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STORE_LISTING_SELECTED_PAGE_INDEX:
      return state.set('storeListingSelectedPageIndex', action.payload.storeListingSelectedPageIndex)
    case getActionName(INITIATE_NETA_XEPT_PAYMENT):
      if (action.response && action.response.data)
        return state.set("netsPaymentInitData", fromJS({ ...action.payload, ...action.response.data }));
      else
        return state;
    case RESET_INITIATE_NETAXEPT_PAYMENT:
      return state.set('netsPaymentInitData', '')
    case getActionName(GET_PRODUCTS_BY_ORDER_ID):
      return state.set("orderedProducts", fromJS(action.response));
    case RESET_GET_PRODUCTS_BY_ORDER_ID:
      return state.set('orderedProducts', '')
    case getActionName(GET_ORDER_INVOICE_STATUS):
      return state.set('checkOutInvoice', fromJS(action.response))
    case getActionName(SEARCH_APP):
      return state;
    case getActionName(GET_PRODUCTS):
      if (action.payload.name && action.payload.categoryId) {// categories wise search
        //start for search handling
        //if (action.response.products.length > 0) {
        const subCategoryPrductIndex = state.get('subCategoryProducts').findIndex((product) =>
          product.get('subCategoryId') == "SEARCH_BY_CATEGORY_ID" && action.payload.page == product.get('page'));
        if (subCategoryPrductIndex === -1) {
          state = state.updateIn(['subCategoryProducts'], (subCategoryProducts) => {
            return subCategoryProducts.push(fromJS({
              subCategoryId: "SEARCH_BY_CATEGORY_ID",
              storeQueryId: action.payload.storeQueryId,
              categoryId: action.response && action.response.products && action.response.products[0] ? action.response.products[0].category_id : action.payload.categoryId,
              page: action.payload.page,
              products: fromJS(action.response)
            }))
          })
        }
        else {
          state = state.updateIn(['subCategoryProducts', subCategoryPrductIndex, 'products'], (list) => {
            return fromJS(action.response);
          })
        }
      }
      else if (action.payload.name) {// store wise search
        const subCategoryPrductIndex = state.get('subCategoryProducts').findIndex((product) =>
          product.get('subCategoryId') == "SEARCH_BY_STORE_QUERY_ID" && action.payload.page == product.get('page'));
        if (subCategoryPrductIndex === -1) {
          state = state.updateIn(['subCategoryProducts'], (subCategoryProducts) => {
            return subCategoryProducts.push(fromJS({
              subCategoryId: "SEARCH_BY_STORE_QUERY_ID",
              storeQueryId: action.payload.storeQueryId,
              categoryId: "ALL_STORE_CATEGORY",
              page: action.payload.page,
              products: fromJS(action.response)
            }))
          })
        }
        else {
          state = state.updateIn(['subCategoryProducts', subCategoryPrductIndex, 'products'], (list) => {
            return fromJS(action.response);
          })
        }
      }
      // end for search handling
      else {
        const subCategoryPrductIndex = state.get('subCategoryProducts').findIndex((product) => product.get('subCategoryId') == action.payload.subCategoryId && action.payload.page == product.get('page'));
        if (subCategoryPrductIndex === -1) {
          state = state.updateIn(['subCategoryProducts'], (subCategoryProducts) => {
            return subCategoryProducts.push(fromJS({
              subCategoryId: action.payload.subCategoryId,
              storeQueryId: action.payload.storeQueryId,
              categoryId: action.payload.categoryId,
              page: action.payload.page,
              products: fromJS(action.response)
            }))
          })
        }
        else {
          state = state.updateIn(['subCategoryProducts', subCategoryPrductIndex, 'products'], (list) => {
            return fromJS(action.response);
          })
        }
      }
      return state;
    case RESET_PRODUCTS:
      if (action?.payload?.resetState === true)
        return state.set('subCategoryProducts', fromJS([]))
      else if (action?.payload?.resetSearchedProducts === true) {
        state = state.update('subCategoryProducts', (products) => products.filterNot((product) => product.get('subCategoryId') === "SEARCH_BY_CATEGORY_ID"))
        state = state.update('subCategoryProducts', (products) => products.filterNot((product) => product.get('subCategoryId') === "SEARCH_BY_STORE_QUERY_ID"))
        return state;
      }
      else
        return state;
    case GET_STORE_DETAIL_INFO:
      return state.set('storeDetailInfo', '')
    case SET_SELECTED_STORE_PRODUCT_CATEGORY:
      return state.set('selectedStoreProductCategory', fromJS({ ...action.payload }))
    case RESET_SELECTED_STORE_PRODUCT_CATEGORY:
      return state.set('selectedStoreProductCategory', '')
    case getActionName(GET_STORE_DETAIL_INFO):
      return state.set('storeDetailInfo', fromJS(action.response))
    case GET_INDUSTRY_STORES:
      state = state.set('activeIndustry', action.payload.selectedIndustry)
      return state;
    case RESET_INDUSTRY_STORES:
      if (action?.payload?.resetState === true)
        return state.set('industryStores', fromJS([]))
      else return state;
    case RESET_STORE_PRODUCT_CATEGORIES:
      if (action?.payload?.resetState === true)
        return state.set('storeProductCategories', fromJS([]))
      else return state;
    case getActionName(GET_INDUSTRY_STORES):
      const industryIndex = state.get('industryStores').findIndex((industry) => industry.get('industryId') == action.payload.industryId && industry.get('page') == action.payload.page);
      if (industryIndex === -1) {
        state = state.updateIn(['industryStores'], (industryStores) => {
          return industryStores.push(fromJS({
            industryId: action.payload.industryId,
            industries: fromJS(action.response),
            page: action.payload.page
          }))
        })
      }
      else {
        state = state.updateIn(['industryStores', industryIndex, 'industries'], (list) => {
          return fromJS(action.response);
        })
      }
      state = state.set('activeIndustry', action.payload.selectedIndustry)
      return state;
    case getActionName(SEARCH_STORES):
      const industryIdx = state.get('industryStores').findIndex((industry) => industry.get('industryId') == "-1");
      let companies = [];
      if (action.response && action.response.merchants) {
        for (let merchant of action.response.merchants) {
          companies.push({
            name: merchant.name,
            banner_url: merchant.picture && merchant.picture.medium,
            query_id: merchant.query_id,
            //picture_medium_url: merchant.picture && merchant.picture.medium
          })
        }
      }
      if (action.response && action.response.products) {
        for (let product of action.response.products) {
          if (product && product.product && product.product.merchant) {
            if (companies && companies.findIndex((cmp) => cmp.query_id == product.product.merchant.query_id) == -1) {
              companies.push({
                name: product.product.merchant.name,
                banner_url: "",
                query_id: product.product.merchant.query_id,
                //picture_medium_url: merchant.picture && merchant.picture.medium
              })
            }
          }
        }
      }
      if (industryIdx === -1) {
        state = state.updateIn(['industryStores'], (industryStores) => {
          return industryStores.push(fromJS({
            industryId: "-1",
            industries: fromJS({
              total_companies: 1,
              companies: companies
            }),
            page: action.payload.page
          }))
        })
      }
      else {
        state = state.updateIn(['industryStores', industryIdx, 'industries'], (list) => {
          return fromJS({
            total_companies: 1,
            companies: companies
          });
        })
      }
      return state;
    case GET_STORE_PRODUCTS_CATEGORIES:
      const ctegry = state.get('storeProductCategories').find((category) => category.get('storeQueryId') == action.payload.storeQueryId);
      if (ctegry) {
        if (action.payload.categoryId && action.payload.categoryId !== "ALL") {
          let ctIndex = ctegry?.get('listCategory')?.get('categories').findIndex((cat) => cat.get('id') == action.payload.categoryId);
          if (ctIndex > -1) {
            return state.set('selectedStoreProductCategory', ctegry?.get('listCategory')?.get('categories')?.get(ctIndex))
          }
          else {
            return state.set('selectedStoreProductCategory', ctegry?.get('listCategory')?.get('categories')?.get(0))
          }
        }
        else {
          if (action.payload.categoryId && action.payload.categoryId === "ALL") {
            return state;
          }
          else
            return state.set('selectedStoreProductCategory', ctegry?.get('listCategory')?.get('categories')?.get(0))
        }
      }
      return state;
    case getActionName(GET_STORE_PRODUCTS_CATEGORIES):
      const categoryIndex = state.get('storeProductCategories').findIndex((category) => category.get('storeQueryId') === action.payload.storeQueryId);
      if (categoryIndex === -1) {
        state = state.updateIn(['storeProductCategories'], (industryStores) => {
          return industryStores.push(fromJS({
            storeQueryId: action.payload.storeQueryId,
            listCategory: fromJS(action.response)
          }))
        })
      }
      else {
        state = state.updateIn(['storeProductCategories', categoryIndex, 'listCategory'], (list) => {
          return fromJS(action.response);
        })
      }
      return state;
    case CHECKOUT_INVOICE:
      return state.set('checkOutInvoice', '')
    case RESET_CHECKOUT_INVOICE:
      state = state.set('voucherInfo', '');
      state = state.set('checkOutInvoice', '');
      return state;
    case getActionName(CHECKOUT_INVOICE):
      return state.set('checkOutInvoice', fromJS(action.response))
    case getActionName(DOWNLOAD_INVOICE_RECEIPT):
      const date = format24HrDateTime(new Date());
      downloadDocument({
        blob: action.response,
        fileName: `invoice-receipt-${date}.pdf`
      })
    case getActionName(PLACE_ORDER):
      return state.updateIn(['checkOutInvoice'], (checkOutInvoice) => {
        return checkOutInvoice.merge({ ...action.response, isOrderSuccessfullyPlaced: true })
      })
    case UPDATE_CHECKOUT_INVOICE:
      if (state.get('checkOutInvoice')) {
        return state.updateIn(['checkOutInvoice', 'invoice'], (checkOutInvoice) => {
          if (checkOutInvoice) {
            if (checkOutInvoice.get('query_id') == action.payload.query_id) {
              return checkOutInvoice.merge({ ...action.payload })
            }
            else {
              return checkOutInvoice;
            }
          }
        })
      }
      else {
        return state;
      }
    case UPDATE_NETA_XEPT_PAYMENT:
      if (state.get('netsPaymentInitData')) {
        return state.updateIn(['netsPaymentInitData'], (netsPaymentInitData) => {
          if (netsPaymentInitData) {
            if (netsPaymentInitData.get('invoiceQueryId') == action.payload.invoiceQueryId) {
              return netsPaymentInitData.merge({ ...action.payload })
            }
            else {
              return netsPaymentInitData;
            }
          }
        })
      }
      else {
        return state;
      }
    case GET_CART_PRODUCTS:
      if (action.useIndexedDb === false)
        return state.set('cartProducts', fromJS(action.response))
    case DELETE_PRODUCT_FROM_CART:
      return state;
    case ADD_UPDATE_PRODUCT_TO_CART:
      return state;
    case getActionName(VALIDATE_VOUCHER):
      return state.set('voucherInfo', fromJS(action.response))
    case RESET_VALIDATE_VOUCHER:
      return state.set('voucherInfo', '')
    case getActionName(GET_SINGLE_PRODUCT):
      return state.set("singleProduct", fromJS(action.response));
    case RESET_GET_SINGLE_PRODUCT:
      return state.set('singleProduct', '')
    case RESET_SEARCH_STORES:
      const industryIdex = state.get('industryStores').findIndex((industry) => industry.get('industryId') == "-1");
      if (industryIdex === -1) {

      }
      else {
        return state.update('industryStores', (stores) => stores.filterNot((store) => store.get('industryId') == "-1"));
      }
      return state;
    case getActionName(GET_BANK_ALFALAH_PAYMENT_FORM):
      state = state.set('bankAlfalahPaymentFormDetails', fromJS({ ...action.response }));
      return state;
    case RESET_BANK_ALFALAH_PAYMENT_FORM:
      state = state.set('bankAlfalahPaymentFormDetails', "");
      return state;
    default:
      return state;
  }
}
export default productsReducer;
