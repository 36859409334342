import './merchant.detail.box.scss';
import React from 'react'
import { Box, Typography, IconButton, CardContent, Button, Paper, Tooltip } from '@mui/material';
import { useStyle } from './merchant.detail.box.style';
import { getImageUrl, getTheApplicationTheme } from 'components/common/util/util';
import FavouriteIconButton from 'components/common/favourite.icon.button/favourite.icon.button';
import { Skeleton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

const MerchantDetailBox = (props) => {
    const classes = useStyle()
    const { tag, index, image, description, footerMessage, headerMessage,
        execStatusGetStoreProductCategories, isCategoryExists, label,
        tagImage, footerDescription, buttonText, width, height } = props
    const onClick = (e) => {
        // if (isCategoryExists) {
        props.onClick()
        // }
    }

    const { t } = useTranslation("global");
    const styling = window.INDEX_NOKIA_ARENA ? { border:"1px solid #D6D6D6", borderRadius:"20px !important", background:"#F8F8F8" } : {};

    return (
        <div key={`merchant-detail-${index}`} id="merchant-box-detail">
            {getTheApplicationTheme().includes('STANDARD') ?
                (<Tooltip title={label ? label : ''} placement="top-start">
                    <div className="w-100 h-100 card-style cursor-pointer position-relative"
                        onClick={onClick}
                    >
                        {/* <div>
                    <FavouriteIconButton />
                </div> */}
                        <Box
                            // style={{
                            //     backgroundImage: `url(${getImageUrl(image)})`,
                            //     backgroundSize: width && height ? `${width} ${height}` : width ? `${width} 100%` : height ? `100% ${height}` : "100% 100%",
                            //     minHeight: '100px'
                            // }}
                            className="w-100 rounded box position-relative d-flex justify-content-center align-items-center flex-column p-3" style={styling}>
                            {/* {
                        headerMessage ?
                            <div className="w-100 position-absolute top-0 start-0 delivery-area">
                                <Typography color="white">{headerMessage}</Typography>
                            </div> : null
                    } */}
                            {/* <div className="d-flex flex-column position-relative align-items-between justify-content-between h-100 w-100 ">
                        {
                            tag ? <Paper className='d-flex tag-style mb-2 position-absolute width-max-content align-items-center pl-1 pr-1 mt-4' classes={{ root: classes.tagColorDetail }}>
                                <Typography classes={{ root: classes.textColor }}
                                    variant="caption" className='text-lowercase font-weight-normal d-flex align-items-center pt-1'>
                                    {tag ? tag : ""}
                                </Typography>
                                <div className='border-radius-100 pl-1 pb-1'>
                                    <img className='border-radius-100' src={getImageUrl(tagImage, true)} width="20px" height='20px' />
                                </div>
                            </Paper> : null
                        }
                    </div> */}
                            {/* <Typography color='greyColor.dark' variant='subtitle2' className="text-ellipsis-clamp-1 font-weight-bold text-capitalize w-100 mt-1 mb-1">
                        {label ? label : ""}
                    </Typography> */}
                            <img src={getImageUrl(image)}
                                // className={clsx(!isCategoryExists && execStatusGetStoreProductCategories.status === "PENDING" ? 'img-style-blur' : 'img-style')} 
                                className={clsx('img-style')}
                            />

                            <img src={process.env.PUBLIC_URL + '/assets/images/store-category.png'}
                                // className={clsx(!isCategoryExists && execStatusGetStoreProductCategories.status === "PENDING" ? 'img-style-blur' : 'img-style')} 
                                
                            />

                        </Box>
                        {description ?
                            <Box className='w-50 position-absolute' style={{top:"25%", right:"15%"}}>
                                <div className={clsx(classes.addressStyle, 'd-flex justify-content-center align-items-center')}>
                                    {/* <img
                                        src={`${process.env.PUBLIC_URL}/assets/icons/location_marker.svg`}
                                        width="20px"
                                        height="20px"
                                    /> */}
                                    <Typography className='pl-3 pr-4 text-center text-ellipsis-clamp-1' variant='body3' style={{color:"#1558CE"}}>{window.INDEX_NOKIA_ARENA === true ? t("messages.location") :'Location:'} {description} </Typography>
                                </div>
                            </Box> : null
                        }
                        {/* {
                        !isCategoryExists && execStatusGetStoreProductCategories.status === "PENDING" ?
                            <Skeleton className='w-100 mt-2' variant="rectangular" width="" height="20px" /> :
                            null
                    } */}
                        {/* <CardContent className="p-0 pt-2 px-1">
                    <div className="h-100">
                        <div className='content-style'>
                            <Tooltip title={label}>
                            <Typography variant='subtitle2' className="text-ellipsis-clamp-1 font-weight-bold text-capitalize">
                                {label ? label : ""}
                            </Typography>
                            </Tooltip>
                            <Tooltip title={description}>
                            <Typography gutterBottom variant="body3" className="text-ellipsis-clamp-2 font-weight-light text-capitalize">
                                {description ? description : ""}
                            </Typography>
                            </Tooltip>
                        </div>
                        <div className='d-flex justify-content-between align-items-center pt-2'>
                            {
                                buttonText ?
                                    <Typography variant='caption' component='div' classes={{ root: classes.btn }} className="pt-1 p-0 px-2 text-capitalize rounded width-max-content">
                                        {buttonText ? buttonText : ""}
                                    </Typography> : null
                            }
                            <Typography classes={{ root: classes.shipmentTag }} variant="caption" className="text-ellipsis-clamp-1 h-auto font-weight-bold text-capitalize pr-1">
                                {footerDescription ? footerDescription : ""}
                            </Typography>
                        </div>
                        <Typography variant='caption' className="text-danger">
                            {footerMessage}
                        </Typography>
                    </div>
                </CardContent> */}
                    </div>
                </Tooltip>) : null}
            {getTheApplicationTheme() === "PROMINENT" ? (
                <div className={clsx(classes.productFooter, 'pb-2')}>
                    <Box>
                        <div className="w-100 h-100 card-style cursor-pointer"
                            onClick={onClick}
                        >
                            {/* <div>
                    <FavouriteIconButton />
                </div> */}
                            <Box
                                // style={{
                                //     backgroundImage: `url(${getImageUrl(image)})`,
                                //     backgroundSize: width && height ? `${width} ${height}` : width ? `${width} 100%` : height ? `100% ${height}` : "100% 100%",
                                //     minHeight: '100px'
                                // }}
                                className={`w-100 box position-relative d-flex justify-content-center align-items-center ${classes.merchantBox}`}>
                                {/* {
                        headerMessage ?
                            <div className="w-100 position-absolute top-0 start-0 delivery-area">
                                <Typography color="white">{headerMessage}</Typography>
                            </div> : null
                    } */}
                                {/* <div className="d-flex flex-column position-relative align-items-between justify-content-between h-100 w-100 ">
                        {
                            tag ? <Paper className='d-flex tag-style mb-2 position-absolute width-max-content align-items-center pl-1 pr-1 mt-4' classes={{ root: classes.tagColorDetail }}>
                                <Typography classes={{ root: classes.textColor }}
                                    variant="caption" className='text-lowercase font-weight-normal d-flex align-items-center pt-1'>
                                    {tag ? tag : ""}
                                </Typography>
                                <div className='border-radius-100 pl-1 pb-1'>
                                    <img className='border-radius-100' src={getImageUrl(tagImage, true)} width="20px" height='20px' />
                                </div>
                            </Paper> : null
                        }
                    </div> */}
                                {/* <Typography color='greyColor.dark' variant='subtitle2' className="text-ellipsis-clamp-1 font-weight-bold text-capitalize w-100 mt-1 mb-1">
                        {label ? label : ""}
                    </Typography> */}
                                <Typography className={`pl-1 text-white text-center h-auto text-ellipsis-clamp-1 ${classes.merchantName}`} variant='h6'>{label}</Typography>

                            </Box>
                            {description ?
                                <Box className='w-100'>
                                    <div className={clsx(classes.addressLevel, 'pt-2 pb-2 justify-content-center align-items-center')}>
                                        <Typography className={`text-white text-center text-ellipsis-clamp-2 ${classes.address}`} variant='body2'>{description}</Typography>
                                    </div>
                                </Box> : null
                            }
                            {/* {
                        !isCategoryExists && execStatusGetStoreProductCategories.status === "PENDING" ?
                            <Skeleton className='w-100 mt-2' variant="rectangular" width="" height="20px" /> :
                            null
                    } */}
                            {/* <CardContent className="p-0 pt-2 px-1">
                    <div className="h-100">
                        <div className='content-style'>
                            <Tooltip title={label}>
                            <Typography variant='subtitle2' className="text-ellipsis-clamp-1 font-weight-bold text-capitalize">
                                {label ? label : ""}
                            </Typography>
                            </Tooltip>
                            <Tooltip title={description}>
                            <Typography gutterBottom variant="body3" className="text-ellipsis-clamp-2 font-weight-light text-capitalize">
                                {description ? description : ""}
                            </Typography>
                            </Tooltip>
                        </div>
                        <div className='d-flex justify-content-between align-items-center pt-2'>
                            {
                                buttonText ?
                                    <Typography variant='caption' component='div' classes={{ root: classes.btn }} className="pt-1 p-0 px-2 text-capitalize rounded width-max-content">
                                        {buttonText ? buttonText : ""}
                                    </Typography> : null
                            }
                            <Typography classes={{ root: classes.shipmentTag }} variant="caption" className="text-ellipsis-clamp-1 h-auto font-weight-bold text-capitalize pr-1">
                                {footerDescription ? footerDescription : ""}
                            </Typography>
                        </div>
                        <Typography variant='caption' className="text-danger">
                            {footerMessage}
                        </Typography>
                    </div>
                </CardContent> */}
                        </div>
                    </Box>
                </div>) : null}
        </div>
    )
}

export default MerchantDetailBox