import React from 'react';
import TextField from '@mui/material/TextField'

export default function ProductName(props) {
  return (
    <TextField
      label="Enter Name"
      className = "textbox-width"

    />
  )
}
