import React, { useState } from 'react'
import { IconButton } from '@mui/material'
//FAVOURITE_CHANGES
const FavouriteIconButton = () => {
    const [state, setState] = useState({
        isFavouriteItemSelected: false,
    });
    const selectFavouriteIconButton = (e) => {
        e.preventDefault()
        setState({ ...state, isFavouriteItemSelected: !state.isFavouriteItemSelected });
    };
    return (
        <div className="d-flex position-relative justify-content-end">
            {/* <IconButton
                className='cursor-pointer position-absolute'
                onClick={selectFavouriteIconButton}
                color="default"
                size="small"
                style={{ zIndex: 1 }}
            >
                {(
                    !state.isFavouriteItemSelected ?
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/icons/heart-icon.svg`}
                            width={30}
                            height={30}
                        />
                        : state.isFavouriteItemSelected ?
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/heart-icon-selected.svg`}
                                width={30}
                                height={30}
                            />
                            : null
                )}
            </IconButton> */}
        </div>
    )
}

export default FavouriteIconButton