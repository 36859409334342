import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
// Import root app
import App from './containers/app';
import 'sanitize.css/sanitize.css';
import configureStore from './configureStore';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
// import * as serviceWorker from './service.worker';
import TagManager from 'react-gtm-module';
// Create redux store with history
import { initialState } from 'view.updater/reducers/init';
import { ENV } from './view.updater/middlewares/util/api.config';
import i18next from 'i18next'
import global_en from './components/common/localization/localization.en.json'
import global_fi from './components/common/localization/localization.fi.json'
import { escape } from 'lodash';
import { I18nextProvider } from 'react-i18next';

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
}, () => {
  document.body.classList.remove('fontLoaded');
});
const tagManagerArgs = {
  gtmId: ENV === "PROD" ? "GTM-KW7NTHN" : "GTM-KK3PMVG"
}
TagManager.initialize(tagManagerArgs)

export const history = createBrowserHistory();
export const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');


i18next.init({
  interpolation: { escapeValue: false },
  lng: "fi",
  resources: {
    en: {
      global: global_en
    },
    fi: {
      global: global_fi
    },
  }

})

const render = () => {
  ReactDOM.render(
    <><I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App store={store} history={history} />
        </ConnectedRouter>
      </Provider>
    </I18nextProvider>
    </>,
    MOUNT_NODE
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/app'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        if (registration?.active?.scriptURL.includes('service-worker.js')) {
          registration.unregister()
        }
      })
    })
  }
}
unregister();
//serviceWorker.register();

if ('serviceWorker' in navigator) {
  if (window.TRANSPORT_SERVICE === true) {

  }
  else {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/custom-serv-woker.js')
        .then(registration => {
          console.log('Service Worker registered:', registration);
          navigator.serviceWorker.ready.then(() => {
            console.log('service worker is active and controlling the page')
            // Ensure the service worker is active and controlling the page
            if (navigator.serviceWorker.controller) {
              console.log('service worker controller is available');
              // Send a message to the service worker
              navigator.serviceWorker.controller.postMessage({ type: 'SERVICE_WORKER_REGISTERED', data: 'Service worker registered successfully' });
            }
          });
        })
        .catch(error => {
          console.log('Service Worker registration failed:', error);
        });
    });
  }
}

