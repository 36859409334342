import React from 'react'
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { getTheApplicationTheme } from 'components/common/util/util';
import { useStyle } from './loader.button.style';
import { IconButton } from '@mui/material';
import SwipeUnlock from 'components/common/swipe.unlock/swipe.unlock';

export default function LoaderButton(props) {
    const { variant, color, loading, loaderPosition, size, classNames, disabled, type, showBorderRadius, startAdornment, endAdornment, showBackArrow, showRightArrow, showSwipeButton } = props;
    let showTheSwipeButton = false;
    const buttonPositionCLassName = clsx("d-flex", {
        "flex-column flex-column-reverse": loaderPosition === "top",
        "flex-column": loaderPosition === "bottom",
        "": loaderPosition === "right",
        "flex-row-reverse": loaderPosition === "left"
    })
    const classes = useStyle()

    const isTypographyStyle = window.INDEX_NOKIA_ARENA === true;


    return (
        <>
            {getTheApplicationTheme().includes('STANDARD') ?
                <Button
                    classes={{ root: disabled && window.TRANSPORT_SERVICE === true ? classes.disabledStyle : window.TRANSPORT_SERVICE === true && loading ? classes.disabledStyle : ""  }}
                    sx={{ boxShadow: window.TRANSPORT_SERVICE === true ? 0 : 1 }}
                    variant={variant ? variant : "contained"}
                    color={color ? color : "primary"}
                    className={clsx(classNames, window.TRANSPORT_SERVICE === true ? "rounded-pill" : showBorderRadius ? 'rounded' : '')}
                    disabled={loading == true ? true : disabled == true ? true : false}
                    onClick={props.onClick}
                    size={size ? size : "medium"}
                    fullWidth={true}
                    type={type}
                    style={isTypographyStyle
                        ? {background:"#1558CE",borderRadius:"10px", color:"#fff" }
                        : {}
                    }
                >
                    <div className={buttonPositionCLassName}>
                        <div className="d-flex justify-content-center align-items-center">{props.children}</div>
                        {loading && <div className="p-1 pl-4 d-flex justify-content-center align-items-center">
                            <CircularProgress
                                color={window.INDEX_NOKIA_ARENA === true || window.TRANSPORT_SERVICE === true ? "secondary" : "primary"} size={20}  />
                        </div>}
                    </div>
                    {
                        endAdornment ?
                            <div>
                                {endAdornment}
                            </div> : null
                    }
                </Button>
                : (getTheApplicationTheme() === 'PROMINENT' && !showTheSwipeButton) ?
                    <>
                        <Button
                            classes={{ root: !color && classes.btnColorProminent }}
                            variant={variant ? variant : "contained"}
                            color={color ? color : "primary"}
                            className={clsx(classNames, showBorderRadius ? 'rounded' : '')}
                            disabled={loading == true ? true : disabled == true ? true : false}
                            onClick={props.onClick}
                            size={size ? size : "medium"}
                            fullWidth={true}
                            type={type}
                        >
                            <div className={clsx(buttonPositionCLassName, 'w-100 position-relative d-flex justify-content-center', endAdornment && 'justify-content-between')}>
                                {
                                    startAdornment ?
                                        <div>
                                            {startAdornment}
                                        </div>
                                        :
                                        showBackArrow ?
                                            <div className='h-100 w-100 position-absolute d-flex justify-content-start align-items-center'>
                                                <IconButton>
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/icons/arrow-back-black.svg`}
                                                        width="24px"
                                                        height="24px"
                                                    />
                                                </IconButton>
                                            </div>
                                            : null
                                }
                                <div className={clsx((endAdornment || startAdornment) ? 'width-max-content pl-2' : 'w-100',
                                    "d-flex justify-content-center align-items-center")}>
                                    {props.children}
                                </div>
                                {loading && <div className="p-1 pr-2 h-100 w-100 position-absolute d-flex justify-content-end align-items-center"><CircularProgress size={20} /></div>}
                                {
                                    endAdornment ?
                                        <div>
                                            {endAdornment}
                                        </div> :
                                        showRightArrow && !loading ?
                                            <div className='h-100 w-100 position-absolute d-flex justify-content-end align-items-center ml-1'>
                                                <IconButton className='bg-success'>
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right-white.svg`}
                                                        width="24px"
                                                        height="24px"
                                                    />
                                                </IconButton>
                                            </div>
                                            : null
                                }
                            </div>
                        </Button>
                    </> :
                    (getTheApplicationTheme() === 'PROMINENT' && showTheSwipeButton) ?
                        <div className={classNames}>
                            <SwipeUnlock
                                //classNames={classNames}
                                loading={loading}
                                buttonText={props.children}
                                onSwipeComplete={props.onClick}
                                disabled={loading == true ? true : disabled == true ? true : false}
                            />
                        </div>
                        : null
            }
        </>
    )
}
{/* <LoaderButton
fullWidth
onClick={login}
variant="contained"
color="primary"
classNames="mt-3"
loading={execStatus.status === "PENDING" ? true : false}
// loaderPosition="top"
>
Login
</LoaderButton> */}
//props
//variant                       //default contained                     //contained , outlined , default
//color                         //default primary                       //primary , secondary
//onclick
//children
//loaderPosition                //default center                        //left right center
//onClick                                                               //callBack
//loading                      //default false                          //true / false
//success                      //default false                          //true / false
//loaderSize                   //default 30                             number

