import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none",
        height: '100px'
    },
    oldPriceStyle: {
        color: theme.palette.greyColor.main,
        textDecoration: "line-through"
    },
    tagColor: {
        background: theme.palette.ternary.main,
        color: theme.palette.background.paper,
        borderTopLeftRadius: "50px",
        borderBottomLeftRadius: "50px",
        // zIndex: 1,
        marginRight: '2px',
        minWidth: '60px'
    },
    likeBtnDetail: {
        background: theme.palette.background.paper,
        padding: "2px",
        '&:hover': {
            background: theme.palette.lightBackgroundColor.main,
        }
    },
    lightBorder: {
        border: `0.7px solid ${theme.palette.lightBackgroundColor.main}`
    },
    productBoxStyle: {
        boxShadow: '0px 4px 20px -2px rgba(50, 50, 71, 0.04)',
        borderRadius: '17px',
        background: theme.palette.background.paper
    },
    productBoxSelected: {
        background: '#c5d0ec',
        boxShadow: '0px 4px 20px -2px rgba(50, 50, 71, 0.04)',
        borderRadius: '17px',
    },
    productBoxSelectedSPlus: {
        background: theme.palette.ternary.light,
        boxShadow: '0px 4px 20px -2px rgba(50, 50, 71, 0.04)',
        borderRadius: '17px'
    },
    borderStyle: {
        border: `2.5px solid ${theme.palette.ternary.main}`
    }
}))
