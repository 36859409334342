import React from 'react'

import { Dialog } from '@mui/material'
import { useTranslation} from "react-i18next";


export default function AppSplash() {

    const { t} = useTranslation("global");
    return (
        <Dialog
            open={true}
            // onClose={props.onClose}
            // PaperProps={{
            //     className: 'dialog-paper',
            // }}
            // disableBackdropClick={disableBackdropClick}
            // maxWidth={maxWidth ? maxWidth : "sm"}
            fullWidth
            PaperProps={{
                className: 'spalsh-dialog-paper m-0 overflow-hidden mh-100 w-100 position-relative',
            }}
        >
            <div>
                <img
                    src={process.env.PUBLIC_URL + '/assets/images/nokia-arean-splash.jpg'}
                    className="img-fluid vh-100 object-fit-cover"
                />


                <div style={{ background: "#1558CE",padding:"20px", paddingTop:"80px", paddingBottom:"60px", borderBottomRightRadius:"50%" }} className='position-absolute top-0 w-100'>
                    <img 
                    src={process.env.PUBLIC_URL + '/assets/images/nokia-arena-primary-logo-v1.png'}
                    className="img-fluid object-fit-cover d-flex m-auto"
                    />
                    <h2 className='text-center text-light pt-4 h4'>{t("messages.nokiaArenaPitchMessage")}</h2>
                </div>
            </div>



        </Dialog>
    )
}
