import { NODE_ANALYTICS_SERVICE, encrypt } from "../../components/common/util/util"
export const INIT_STRIPE_PAYMENT = "INIT_STRIPE_PAYMENT"
export const RESET_INIT_STRIPE_PAYMENT = "RESET_INIT_STRIPE_PAYMENT"
var CryptoJS = require("crypto-js");

export function initStripePayment(data) {
    const dataToEncrypt = {
        ...data,
        module: "stripeInitDto",
        subModule: "stripeInitDto",
        merchantQueryId: window.TRANSPORT_MERCHANT_QUERY_ID
    }
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), window.SITE_ID).toString();
    return {
        method: 'POST',
        url: `user/stripe/payment/init?random=${Math.random()}`,
        useService: NODE_ANALYTICS_SERVICE,
        type: INIT_STRIPE_PAYMENT,
        data: {
            stripeInitDetails: ciphertext
        },
        payload: data
        //method: 'POST'
    }
}

export function resetInitStripePayment() {
    return {
        type: RESET_INIT_STRIPE_PAYMENT,
        payload: {
            resetActionNames: ["INIT_STRIPE_PAYMENT"]
        }
    }
}

