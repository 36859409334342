import React from 'react'
import { Typography, Paper } from '@mui/material';
import { IsMobileWidth } from 'components/common/util/util';
import { useStyle } from './category.chip.style'
import clsx from 'clsx';

const CategoryChip = (props) => {
    const { title, image, selected, useDefaultStyle = true } = props
    const mobileWidth = IsMobileWidth()
    const classes = useStyle()
    return (
        <div style={{
            width: '100%'
        }} id="category-chip">
            <Paper classes={{
                root: (selected && useDefaultStyle) ? classes.selectedChip : (useDefaultStyle && !selected) ? classes.chip
                    : (!useDefaultStyle && selected) ? classes.selectedCustomChipStyle
                        : !(useDefaultStyle && selected) ? classes.customChipStyle : classes.chip
            }}
                className="p-1 pl-4 pr-4 d-flex justify-content-start align-items-center w-100 rounded-pill pt-2 pb-2">
                {image && !mobileWidth ?
                    <Typography className="width-max-content h-100">
                        <div className="h-100 w-100 p-1">
                            <img className='rounded' src={image} style={{ width: "40px", height: "30px" }} alt='category_image' />
                        </div>
                    </Typography> : null
                }
                <Typography classes={{ root: selected ? classes.selectedTitle : classes.title }} variant='caption' className={clsx((image && !mobileWidth) && 'pl-2', "text-ellipsis-clamp-1 text-capitalize font-weight-bold")}>
                    {title ? title : ""}
                </Typography>
            </Paper>
        </div>
    )
}
export default CategoryChip