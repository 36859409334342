import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    tagColorDetail: {
        background: theme.palette.ternary.main,
        color: theme.palette.background.paper,
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px"
    },
    likeBtnDetail: {
        // border:"2px solid #fff",
        padding: "2px",
        '&:hover': {
            background: theme.palette.background.paper,
        }
    },
    pricingStyle: {
        color: theme.palette.error.main,
        textDecoration: "line-through"
    },
    shipmentTag: {
        color: theme.palette.primary.main,
    },
    btn: {
        background: theme.palette.secondary.main,
        color: theme.palette.background.paper,
        fontSize: '12px',
        minWidth: '40px !important',
        height: '27px'
    },
    textColor: {
        color: theme.palette.background.paper,

    },
    addressStyle: {
        borderRadius: '10px',
        color: theme.palette.background.paper,
        background: "#fff",
        height:'25px'
    },
    merchantBox : {
        height:"100px",
        background: "linear-gradient(180deg, #1145A2 0%, #1558CE 100%)",
        borderRadius: "10px 10px 0px 0px !important"
    },
    addressLevel:{
        borderRadius: '0px 0px 10px 10px',
        color: theme.palette.background.paper,
        background: theme.palette.primary.dark,
        // height:'40px',
    },
    address:{
        // fontSize:"18px",
        textAlign: 'center',
        // height:"auto",
        // paddingBottom:"4px"
    },
    merchantName : {
        fontWeight:100
    },
    productFooter:{
        // backgroundColor:"#a6a6a6",
        // marginTop:"-8px",
        // borderRadius:"0px 0px 10px 10px",
        // height:"9px"
        background:'#a6a6a6',
        borderRadius: "10px 10px 10px 10px",
    }
}))
