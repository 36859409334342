import "./order.history.detail.dialog.scss";
import React, { useState } from "react";
import { Button, CircularProgress, Paper, Typography, Box, FormControlLabel, Radio, } from "@mui/material";
import { useStyle } from "./order.history.detail.dialog.style";
import clsx from "clsx";
import { IsMobileWidth, formatAmountV1, formatDate, getDeliveryTime } from "components/common/util/util";
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog'
import { getImageUrl, getTotalDeliveryTime, format12HrTime } from 'components/common/util/util';
import CustomizedStepper from "components/common/customized.stepper/customized.stepper";
import { getAddressDescription } from 'components/common/util/util';
import LoaderButton from 'components/common/material.components/loader.button/loader.button';

const OrderHistoryDetailDialog = (props) => {
  const classes = useStyle();
  const mobileWidth = IsMobileWidth();
  const { selectedOrder, openOrderHistoryDetailDialog, isActiveOrder } = props;
  let invoice = selectedOrder && selectedOrder.get('invoice')
  const getOrderStatusName = () => {
    if (invoice) {
      if (invoice.get('status') === "order_pending") {
        return "Order Pending"
      }
      else if (invoice.get('status') === "order_confirmed") {
        return "Order Confirmed"
      }
      else if (invoice.get('status') === "order_dispatched") {
        return "Order Dispatched"
      }
      else if (invoice.get('status') === "order_delivered") {
        return "Order Delivered"
      }
      else if (invoice.get('status') === "order_cancelled") {
        return "Order Cancelled"
      }
      else {
        return ''
      }
    }
    else {
      return ''
    }
  }
  const getOrderActiveStep = () => {
    if (invoice.get('status') === "order_pending") {
      return 1
    }
    else if (invoice.get('status') === "order_confirmed") {
      return 2
    }
    else if (invoice.get('status') === "order_dispatched") {
      return 3
    }
    else if (invoice.get('status') === "order_delivered") {
      return 4
    }
    else {
      return 0
    }
  }
  const reOrder = (e) => {

  }
  return (
    <div id="order-history-detail-dialog" className="w-100">
      {
        <ContentDialog
          open={openOrderHistoryDetailDialog}
          maxWidth='sm'
          handleClose={props.closeOrderHistoryDetailDialog}
          actions={<div className="w-100">
            <Paper classes={{ root: classes.lightBorder }} elevation={0} >
              <div className="d-flex justify-content-between p-2">
                <Typography classes={{ root: classes.textColor }} variant="body1" className="text-capitalize font-weight-bold">
                  Total
                </Typography>
                <Typography classes={{ root: classes.textColor }} variant="body1">
                  {invoice && invoice.get('payble_amount') ? formatAmountV1(invoice.get('payble_amount')) : ''}
                </Typography>
              </div>
            </Paper>
            {/* {!isActiveOrder ?
              <div className="pt-2 pb-1">
                <LoaderButton
                  onClick={reOrder}
                  color="primary"
                  //classes={{ root: classes.btnColor }}
                  classNames="w-100 text-capitalize">
                  Reorder
                </LoaderButton>
              </div> : null} */}
          </div>}
          title={
            <div className="w-100">
              <div className="w-100">
                <div className="position-relative w-100">
                  <Typography className="position-absolute d-flex justify-content-center align-items-center w-100 h-100 font-weight-bold"
                    variant="h6" classes={{ root: classes.textColor2 }}>
                    {invoice && invoice.get('merchant') && invoice.get('merchant').get('name') ? invoice.get('merchant').get('name') : ''}
                  </Typography>
                  <Typography className="position-absolute d-flex justify-content-end align-items-end h-100 w-100">
                    <Box className="d-flex justify-content-end align-items-end rounded-circle position-relative mr-2">
                      <CircularProgress
                        variant="determinate"
                        className="circle-style rounded-circle"
                        classes={{ root: classes.circleStyle }}
                        size={70}
                        value={100}
                        {...props}
                        thickness='2.5'
                        color="secondary" />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="rounded-circle timer-box"
                      >
                        <div>
                          <Typography
                            classes={{ root: classes.timer }}
                            variant="caption"
                            className="text-ellipsis-clamp-3 font-weight-bold p-0 text-center w-100 m-auto rounded-circle pt-2 pb-1 pl-1 pr-1"
                            color="secondary">
                            {getTotalDeliveryTime(invoice && invoice.get('merchant'))}
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                  </Typography>
                  <img className="cover-image"
                    src={invoice && invoice.get('merchant') ? getImageUrl(invoice.get('merchant').get('merchant_banner')) : ''} />
                </div>
                {/* {
                  isActiveOrder === true ?
                    <div className="d-flex justify-content-between pl-3 pr-3 pt-2 pb-2">
                      <div>
                        <Typography classes={{ root: classes.textColor }} variant="body1" className="font-weight-bold">
                          Order is being prepared
                        </Typography>
                        <div className='d-flex'>
                          <Typography classes={{ root: classes.textGray }} className='pl-1' variant="body2">
                            Arrives at 9:05 PM
                          </Typography>
                        </div>
                      </div>
                    </div> : null
                } */}
                {
                  isActiveOrder === true ?
                    <div className="d-flex justify-content-between pl-3 pr-3 pt-2 pb-2">
                      <div>
                        <Typography classes={{ root: classes.textColor }} variant="body1" className="font-weight-bold">
                          Status
                        </Typography>
                        <div className='d-flex'>
                          {/* <i className="material-icons text-muted">done</i> */}
                          <Typography classes={{ root: classes.textGray }} className='pt-1' variant="body2">
                            {getOrderStatusName()}
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <div>
                          <Typography classes={{ root: classes.textColor }} variant="body1" className="font-weight-bold text-right">
                            {getOrderStatusName()}
                          </Typography>
                          <Typography classes={{ root: classes.textGray }} variant="body2" className="text-right">
                            {`${invoice && invoice.get('created_date') ? formatDate(invoice.get('created_date')) : ''}
                         ${invoice && invoice.get('created_time') ? format12HrTime(invoice.get('created_time')) : ''}`}
                          </Typography>
                        </div>
                      </div>
                    </div> : null
                }
                {
                  isActiveOrder === true ?
                    <Paper
                      className="pl-2 pr-2 pt-2 pb-2"
                      elevation={0}
                      classes={{ root: classes.lightBorder }}>
                      <CustomizedStepper
                        activeStep={getOrderActiveStep()}
                        steps={[
                          {
                            label: "Order Received",
                            iconPath: `${process.env.PUBLIC_URL}/assets/images/confirming.gif`,
                          },
                          {
                            label: "In Progress",
                            iconPath: `${process.env.PUBLIC_URL}/assets/images/preparing.gif`,
                          },
                          {
                            label: "On The Way",
                            iconPath: `${process.env.PUBLIC_URL}/assets/images/zappmanrocket.gif`,
                          },
                          {
                            label: "Order Delivered",
                            iconPath: `${process.env.PUBLIC_URL}/assets/images/zappmanarrived.gif`,
                          },
                        ]}
                      //onStepClick={handleNext} 
                      />
                    </Paper> : null
                }
              </div>
            </div>
          }>
          <div>
            {
              invoice && invoice.get('rider_assigned') === true ?
                <Paper classes={{ root: classes.lightBorder }} elevation={0}
                  className={clsx(!mobileWidth && "mb-2 w-100 d-flex justify-content-evenly", mobileWidth && "w-100 mb-2 d-block")}>
                  <div className={clsx(!mobileWidth && "w-50 p-2", mobileWidth && "w-100 p-2")}>
                    <Typography variant="body2" className="font-weight-bold" classes={{ root: classes.textColor }}>
                      Zapp Man
                    </Typography>
                    <Typography variant="body2" classes={{ root: classes.textGray }}>
                      {invoice && invoice.get('zapp_man') && invoice.get('zapp_man').get('full_name') ? invoice.get('zapp_man').get('full_name') : ''}
                    </Typography>
                  </div>
                  <div className={clsx(!mobileWidth && "w-50 p-2 border-left", mobileWidth && "w-100 p-2 border-top")}>
                    <Typography variant="body2" className="font-weight-bold" classes={{ root: classes.textColor }}>
                      Call Support
                    </Typography>
                    <div className="d-flex justify-content-between align-items-center">
                      <Typography variant="body2" classes={{ root: classes.textGray }}>
                        +9221 3713-9277
                      </Typography>
                    </div>
                  </div>
                </Paper> : null
            }
            <Paper classes={{ root: classes.lightBorder }} elevation={0} className="mb-2 p-2 pt-2 pb-2" >
              <Typography classes={{ root: classes.textColor }} variant="subtitle1" className="d-flex align-items-center font-weight-bold pb-1" >
                Your Order Placed with
              </Typography>
              <Typography color="secondary" variant="body2" className="d-flex align-items-center font-weight-bold pb-2">
                {invoice && invoice.get('merchant') && invoice.get('merchant').get('name') ? invoice.get('merchant').get('name') : ''}
              </Typography>
              <div className="d-flex justify-content-between">
                <Typography variant="body2" classes={{ root: classes.textGray }} className="text-capitalize">
                  Order Time
                </Typography>
                <Typography classes={{ root: classes.textGray }} className="text-capitalize" variant="body2">
                  {invoice && invoice.get('created_time') ? format12HrTime(invoice.get('created_time')) : ''}
                </Typography>
              </div>
              <div className="d-flex justify-content-between">
                <Typography classes={{ root: classes.textGray }} className="text-capitalize" variant="body2">
                  Order Date
                </Typography>
                <Typography classes={{ root: classes.textGray }} className="text-capitalize" variant="body2" >
                  {formatDate(invoice && invoice.get('created_date') ? invoice.get('created_date') : '')}
                </Typography>
              </div>
              <div className="d-flex justify-content-between">
                <Typography classes={{ root: classes.textGray }} className="text-capitalize" variant="body2">
                  Order Id
                </Typography>
                <Typography classes={{ root: classes.textGray }} className="text-capitalize" variant="body2">
                  {invoice && invoice.get('short_order_id') ? invoice.get('short_order_id') : ''}
                </Typography>
              </div>
            </Paper>
            <Paper classes={{ root: classes.lightBorder }} elevation={0} className="mb-2 p-2 pt-2 pb-2" >
              <Typography classes={{ root: classes.textColor }} variant="subtitle1" className="text-capitalize font-weight-bold">
                Delivery Address
              </Typography>
              <Typography classes={{ root: classes.textGray }} className="text-capitalize" variant="body2" >
                {getAddressDescription(invoice.get('delivery_address'))}
              </Typography>
            </Paper>

            <Paper
              classes={{ root: classes.lightBorder }}
              elevation={0}
              className="mb-2 p-2 pt-2 pb-2" >
              <div className="w-100">
                <Typography
                  classes={{ root: classes.textColor }}
                  variant="subtitle1"
                  className="text-capitalize font-weight-bold">
                  Order Details
                </Typography>
              </div>
              <Typography
                classes={{ root: classes.textGray }}
                variant="body2">
                <div className="d-flex">
                  <div className="pr-1">
                    {invoice && invoice.get('invoice_items') && invoice.get('invoice_items').size > 1 ? `${invoice.get('invoice_items').size} items` : '1 item'}
                  </div>
                </div>
              </Typography>
              <div>
                {invoice && invoice.get('invoice_items') ? invoice.get('invoice_items').map((item, ind) => {
                  return <div key={ind} className="d-flex justify-content-between pt-1">
                    <Typography className="d-flex" variant="body2" classes={{ root: classes.textGray }}>
                      {`${item?.get('quantity')}x `}{item?.get('product_name') ? item?.get('product_name') : ''}
                    </Typography>
                    <Typography classes={{ root: classes.textGray }} variant="body2">
                      {item?.get("amount") ? formatAmountV1(item?.get("amount")) : ""}
                    </Typography>
                  </div>
                }) : ''}
              </div>
            </Paper>
            {
              invoice && invoice.get('payments') && invoice.get('payments').size > 0 ?
                <Paper
                  classes={{ root: classes.lightBorder }}
                  elevation={0}
                  className="mb-2 pl-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <Typography
                      classes={{ root: classes.textColor }}
                      variant="subtitle1"
                      className="text-capitalize font-weight-bold">
                      Payment By
                    </Typography>
                    <div className="d-flex flex-wrap">
                      {
                        invoice.get('payments') && invoice.get('payments').map((payment, index) => {
                          return <FormControlLabel
                            sx={{ color: 'blue' }}
                            value={payment.get('payment_type')}
                            checked={true}
                            control={
                              <Radio
                                classes={{ root: classes.label }} />
                            }
                            label={payment.get('payment_type')}
                          />
                        })
                      }
                    </div>
                  </div>
                </Paper> : null
            }
            <Paper
              classes={{ root: classes.lightBorder }}
              elevation={0}
              className="mb-2 p-2 pt-2 pb-2">
              <Typography
                classes={{ root: classes.textColor }}
                variant="subtitle1"
                className="d-flex align-items-center font-weight-bold pb-1">
                Your Bill
              </Typography>

              <div className="d-flex justify-content-between">
                <Typography
                  classes={{ root: classes.textGray }}
                  className="text-capitalize"
                  variant="body2">
                  Sub Total
                </Typography>
                <Typography
                  classes={{ root: classes.textGray }}
                  className="text-capitalize"
                  variant="body2">
                  {invoice && invoice.get('sub_total_amount') ? formatAmountV1(invoice.get('sub_total_amount')) : '0.00'}
                </Typography>
              </div>
              <div className="d-flex justify-content-between">
                <Typography
                  classes={{ root: classes.textGray }}
                  variant="body2"
                  className="text-capitalize">
                  Zapp Man Tip
                </Typography>
                <Typography
                  classes={{ root: classes.textGray }}
                  variant="body2"
                  className="text-capitalize">
                  {invoice && invoice.get('driver_tip') ? formatAmountV1(invoice.get('driver_tip')) : '0.00'}
                </Typography>
              </div>
              <div className="d-flex justify-content-between">
                <Typography
                  classes={{ root: classes.textGray }}
                  variant="body2"
                  className="text-capitalize">
                  Delivery Fee
                </Typography>
                <Typography
                  classes={{ root: classes.textGray }}
                  variant="body2"
                  className="text-capitalize">
                  {invoice && invoice.get('delivery_fee') ? formatAmountV1(invoice.get('delivery_fee')) : '0.00'}
                </Typography>
              </div>
            </Paper>
          </div >
        </ContentDialog >
      }
    </div >
  );
};

export default OrderHistoryDetailDialog;
