import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "none"
    },
    likeBtnDetail: {
        background: theme.palette.background.paper,
        padding: "2px",
        '&:hover': {
            background: theme.palette.lightBackgroundColor.main,
        }
    },
    cardMedia: {
        width: "120px",
        height: "120px",
        objectFit: "contain",
    }
}))
