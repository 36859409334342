import './shopping.cart.item.scss'
import React, { useState } from 'react'
import { Card, CardContent, CardMedia, Typography, IconButton, Button, Grid, Tooltip } from '@mui/material';
import QuantityButtonCounter from 'components/common/quantity.button.counter/quantity.button.counter';
import { IsMobileWidth, IsTabletWidth, getTheApplicationTheme } from 'components/common/util/util';
import clsx from 'clsx';
import { useStyle } from './shopping.cart.item.style'
import ContentDialog from "components/common/material.components/content.dialog/content.dialog";
import { formatAmountV1 } from 'components/common/util/util';

const ShoppingCartItem = (props) => {
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const { image, productName, description,
        price, totalPrice, product, unitPrice, discountAmount, quantity, addProductToCartId, execStatusAddUpdateProductToCart, execStatusGetCartProducts } = props;
    let isToShowCheckoutSummaryCounterButton = window.CHECKOUT_SUMMARY_COUNTER_BUTTON_TO_SHOW;
    const [state, setState] = useState({
        isOpenImageZoomDialog: false
    })
    const showImageZoomDialog = () => {
        setState({ ...state, isOpenImageZoomDialog: true });
    };
    const hideImageZoomDialog = () => {
        setState({ ...state, isOpenImageZoomDialog: false });
    };
    const isAddingToCartInProgress = () => {
        if (execStatusAddUpdateProductToCart.status === "PENDING" &&
            addProductToCartId === product.get('query_id')) {
            return true;
        }
        else if (execStatusGetCartProducts.status === "PENDING" &&
            addProductToCartId === product.get('query_id')) {
            return true
        }
        else
            return false
    }
    const showProductDetail = () => {
        props.redirectTo(`/products/details/${product.get('merchant_query_id')}/${product.get('category_id')}/${product.get('sub_category_id')}/1/${product.get('query_id')}/1`)
    }
    const classes = useStyle()


    const isTypographyStyle = window.INDEX_NOKIA_ARENA === true;

    return (
        <div id="shopping-cart-item">
            {getTheApplicationTheme().includes('STANDARD') ?
                <Grid container className={`d-flex ${isTypographyStyle ? 'align-items-start' : 'align-items-center'}`}
                    style={isTypographyStyle
                        ? { border: "1px solid #D6D6D6", borderRadius: "15px", padding: "10px" }
                        : {}
                    }
                >
                    <Grid items xs={isTypographyStyle ? 3 : 2} sm={2} md={2} lg={2}>
                        <img onClick={showImageZoomDialog} className={clsx(!(mobileWidth || tabletWidth) && 'img-size', (mobileWidth || tabletWidth) && 'responsive-img-size', 'cursor-pointer')} src={image ? image : ''} />
                    </Grid>
                    <Grid items xs={2} sm={2} md={2} lg={2} className={clsx(mobileWidth && 'text-center')}>
                        {quantity ?
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} color='dark.main' className='pt-2 font-weight-bold text-ellipsis-clamp-1 h-auto'
                                style={isTypographyStyle
                                    ? { fontSize: "14px" }
                                    : {}
                                }
                            >
                                {`${quantity}X`}
                            </Typography>
                            : ""}
                    </Grid>
                    <Grid items xs={isTypographyStyle ? 7 : 5} sm={5} md={5} lg={5}>
                        <Typography variant={clsx(!(mobileWidth || tabletWidth) && "body3", (mobileWidth || tabletWidth) && "caption")} color='dark.main' className='pt-2 text-ellipsis-clamp-3 first-letter-capital h-auto' style={isTypographyStyle
                            ? { fontSize: "14px" }
                            : {}
                        }
                        >
                            {productName ? productName : ""}
                        </Typography>
                        {description ?
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "body3", (mobileWidth || tabletWidth) && "caption")}
                                className={clsx(classes.description, "text-ellipsis-clamp-2 font-weight-normal text-capitalize")}>
                                {description}
                            </Typography> : null
                        }
                        {/* : ''
                        } */}

                        {/* <div className="pb-2 cursor-pointer" onClick={showProductDetail}>
                                <div className="text-primary cursor-pointer">
                                    View Details
                                </div>
                            </div> */}
                        {/* <div className='d-flex align-items-end mt-4 ml-n2'>
                            <Typography variant="subtitle2" className=' text-ellipsis-clamp-1 h-auto'>
                                <IconButton size="small" className='material-icons'>
                                    favorite_border
                                </IconButton>
                                Save for later .
                            </Typography>

                        </div> */}
                        {/* <div className={clsx("pl-2", !mobileWidth && 'd-flex justify-content-between mt-5 w-65', mobileWidth && 'd-flex w-100 p-2 flex-column')}> */}
                        {price ? <Typography color='dark.main' component="div"
                            classes={{ root: discountAmount ? classes.oldPriceStyle : '' }}
                            variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} style={isTypographyStyle
                                ? { fontSize: "14px", fontWeight: "700" }
                                : {}
                            }>
                            {price}
                        </Typography> : ''}
                        {discountAmount ?
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} component="div" className={clsx(!mobileWidth && 'font-weight-bold text-style', mobileWidth && 'font-weight-bold pb-1')}>
                                {discountAmount}
                            </Typography>
                            : null}
                        {/* <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} component="div" className={clsx(!mobileWidth && 'font-weight-light', mobileWidth && 'pb-1')}>
                        <QuantityButtonCounter
                            maxQuantity={product.get('product_available_quantity') > 0 ? product.get('product_available_quantity') : 99}
                            isAddingToCartInProgress={isAddingToCartInProgress}
                            onQuantityChange={props.onQuantityChange}
                            value={quantity} />
                    </Typography> */}
                    </Grid>
                    {!window.INDEX_NOKIA_ARENA === true &&
                        <Grid items xs={3} sm={3} md={3} lg={3} className='position-relative'>
                            {/* <div className='position-absolute w-100 justify-content-end'>
                        <IconButton
                            onClick={props.onItemDelete}
                            style={{
                                width: "30px",
                                height: "30px",
                                display: "flex"
                            }} size="small" className='material-icons'>
                            <img src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`} width='24px' />
                        </IconButton>
                    </div> */}
                            <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} component="div"
                                className={clsx('font-weight-bold text-right w-100 pr-2')} >
                                {totalPrice ? totalPrice : ""}
                            </Typography>
                            {isToShowCheckoutSummaryCounterButton ?
                                <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} component="div" className={clsx(!mobileWidth && 'font-weight-light', mobileWidth && 'pb-1', 'pt-2 pb-2')}>
                                    <QuantityButtonCounter
                                        maxQuantity={product && product.get('product_available_quantity') > 0 ? product.get('product_available_quantity') : 99}
                                        isAddingToCartInProgress={isAddingToCartInProgress}
                                        onQuantityChange={props.onQuantityChange}
                                        value={quantity} />
                                </Typography> : null
                            }
                            {/* <Typography variant={clsx(!(mobileWidth||tabletWidth)&&"subtitle1",(mobileWidth||tabletWidth)&&'caption')} component="div"
                            className='font-weight-light material-icons pr-2 cursor-pointer' onClick={() => props.openDialog()}>
                            close
                        </Typography> */}
                            {/* </div> */}
                        </Grid>
                    }
                </Grid>
                : getTheApplicationTheme() === 'PROMINENT' ?
                    <Grid container className='d-flex align-items-center'>
                        <Grid items xs={6} sm={6} md={6} lg={6}>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "body3", (mobileWidth || tabletWidth) && "caption")} color='dark.main' className='pt-2 font-weight-bold text-ellipsis-clamp-3 first-letter-capital h-auto'>
                                {productName ? productName : ""}
                            </Typography>
                            {description ?
                                <Typography variant={clsx(!(mobileWidth || tabletWidth) && "body3", (mobileWidth || tabletWidth) && "caption")}
                                    className={clsx(classes.description, "text-ellipsis-clamp-2 font-weight-normal text-capitalize")}>
                                    {description}
                                </Typography> : null
                            }
                            {price ? <Typography color='dark.main' component="div"
                                classes={{ root: discountAmount ? classes.oldPriceStyle : '' }}
                                variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}>
                                {price}
                            </Typography> : ''}
                            {discountAmount ?
                                <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} component="div" className={clsx(!mobileWidth && 'font-weight-bold text-style', mobileWidth && 'font-weight-bold pb-1')}>
                                    {discountAmount}
                                </Typography>
                                : null}
                            {/* {isToShowCheckoutSummaryCounterButton ?
                                <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} component="div" className={clsx(!mobileWidth && 'font-weight-light', mobileWidth && 'pb-1', 'w-100 d-flex justify-content-start pt-2 pb-2')}>
                                    <QuantityButtonCounter
                                        maxQuantity={product.get('product_available_quantity') > 0 ? product.get('product_available_quantity') : 99}
                                        isAddingToCartInProgress={isAddingToCartInProgress}
                                        onQuantityChange={props.onQuantityChange}
                                        value={quantity} />
                                </Typography> : null
                            } */}
                        </Grid>
                        {isToShowCheckoutSummaryCounterButton ?
                            <Grid items xs={3} sm={3} md={3} lg={3} className='d-flex w-100 justify-content-end'>
                                <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} component="div" className={clsx(!mobileWidth && 'font-weight-light', mobileWidth && 'pb-1')}>
                                    <QuantityButtonCounter
                                        maxQuantity={product && product.get('product_available_quantity') > 0 ? product.get('product_available_quantity') : 99}
                                        isAddingToCartInProgress={isAddingToCartInProgress}
                                        onQuantityChange={props.onQuantityChange}
                                        value={quantity}
                                        size='sm'
                                    />
                                </Typography>
                            </Grid>
                            : null
                        }
                        <Grid items
                            xs={isToShowCheckoutSummaryCounterButton ? 3 : 6}
                            sm={isToShowCheckoutSummaryCounterButton ? 3 : 6}
                            md={isToShowCheckoutSummaryCounterButton ? 3 : 6}
                            lg={isToShowCheckoutSummaryCounterButton ? 3 : 6}
                            className='position-relative d-flex w-100 justify-content-end'>
                            {quantity ? ""
                                /* <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} color='dark.main' className='font-weight-normal text-ellipsis-clamp-1 h-auto0'>
                                     {`${quantity} x`}
                                 </Typography> */
                                : ""}
                            {totalPrice ?
                                <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} component="div"
                                    className={clsx('font-weight-normal text-right pl-1')} >
                                    {totalPrice}
                                </Typography>
                                : unitPrice ? <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')} component="div"
                                    className={clsx('font-weight-normal text-right pl-1')} >
                                    {unitPrice}
                                </Typography> : ""}
                        </Grid>
                    </Grid> : null
            }

            {
                state.isOpenImageZoomDialog == true ?
                    <ContentDialog
                        handleClose={hideImageZoomDialog}
                        open={state.isOpenImageZoomDialog}
                        dividers={false}
                    // title={productName ? productName : ""}
                    >
                        <div className='w-100 d-flex justify-content-center align-items-center overflow-auto image-zoom-dialog-shopping-cart-item' style={{ minHeight: '350px' }}>
                            {/* <CardMedia
                                component="img"
                                sx={{ margin: '0 auto' }}
                                height="100%"
                                image={image ? image : ''}
                            /> */}
                            <img className='image-zoom-dialog-shopping-cart-item' src={image ? image : ''} width='100%' />
                        </div>
                    </ContentDialog>
                    : null}
        </div>
    )
}
export default ShoppingCartItem

