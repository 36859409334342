import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    cart_header: {
        backgroundColor: theme.palette.bannerColor.main,
    },
    imgSize: {
        boxShadow: "none !important",
        width: "50px",
        minHeight: "40px"
    },
    grid: {
        // maxHeight: '70vh !important',
        // overflow: 'auto'
    },
    iconColor: {
        backgroundColor: theme.palette.bannerColor.main,
    },
    btnStyle: {
        textTransform: 'none'
    },
    orderDispatched: {
        width: '100%',
        backgroundColor: theme.palette.ternary.main,
        // paddingTop: '10px'
    }
}));