import {
    ADD_UPDATE_PRODUCT_TO_CART,
    getCartProducts, DELETE_PRODUCT_FROM_CART,
    CLEAR_CART_AND_ADD_NEW_ITEM, CLEAR_CART_AND_ADD_NEW_ITEMS,
    DELETE_ALL_CART_PRODUCTS, addUpdateProductToCart
} from '../actions/products.actions';
import { put } from 'redux-saga/effects';
export default function* allAfterLocalMiddleware(action) {
    switch (action.type) {
        case ADD_UPDATE_PRODUCT_TO_CART:
            yield put(getCartProducts());
            break;
        case DELETE_ALL_CART_PRODUCTS:
            yield put(getCartProducts());
            break;
        case DELETE_PRODUCT_FROM_CART:
            yield put(getCartProducts());
            break;
        case CLEAR_CART_AND_ADD_NEW_ITEM:
            yield put(addUpdateProductToCart(action.payload));
            break;
        case CLEAR_CART_AND_ADD_NEW_ITEMS:
            if (action.payload && action.payload.items && action.payload.items.length > 0) {
                for (let item of action.payload.items) {
                    yield put(addUpdateProductToCart(item));
                }
            }
            break;
        default:
            return;
    }
}