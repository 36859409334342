import React, { useContext, useEffect, useRef, useState } from 'react'
import { CircularProgress, Grid, Skeleton, Typography, Box, Container, Paper } from '@mui/material'
import MerchantDetailBox from 'components/common/merchant.detail.box/merchant.detail.box';
import { SERVER_BASE_URL } from "view.updater/middlewares/util/api.config";
import { formatAmountV1, getDeliveryTime, getSelectedAddressLatLng, getDeliveryFee } from 'components/common/util/util';
import { AppContext } from 'containers/main/container';
import { GET_INDUSTRY_STORES, SEARCH_STORES } from 'view.updater/actions/products.actions';
import { checkExecutionStatus, scrollToRefElement } from 'components/common/util/util';
import BoxLoadingSkeleton from 'components/common/skeletons/box.loading.skeleton/box.loading.skeleton';
import { Pagination } from '@mui/material';
import { INDUSTRY_STORE_PAGE_SIZE } from 'view.updater/actions/products.actions';
import { storeIsInRadius } from 'components/common/util/util';
import { IsMobileWidth, IsTabletWidth, scrollToTop, getQueryStringValue } from 'components/common/util/util';
import clsx from 'clsx';
import SiteContentWrapper from 'components/common/site.content.wrapper/site.content.wrapper';
import { useStyle } from './store.list.style';
import { getTheApplicationTheme, getMessageColor } from 'components/common/util/util';
import packageJson from "../../../../../package.json";
import TopNavigationPanel from 'components/common/top.navigation.panel/top.navigation.panel';
import { renderHtml } from "components/common/util/util";
import { useTranslation } from "react-i18next";
import AlertDialogBox from '../../../../components/common/alert.dialog.box/alert.dialog.box';

export default function StoreList(props) {
    const { activeIndustry, industryStores, selectedStorePage, auth, storeProductCategories, execStatusGetStoreProductCategories } = props;
    const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const classes = useStyle()
    const [siteContentWrapperHeight, setSiteContentWrapperHeight] = useState('auto');
    const containerHeightRef = useRef(null);
    const [alertOpen, setAlertOpen] = useState(true);
    let timeoutId;
    useEffect(() => {
        const containerHeight = containerHeightRef?.current?.offsetHeight;
        timeoutId = setTimeout(() => {
            setSiteContentWrapperHeight(containerHeight);
        }, 200)
    }, [industryStores]);


    const { t } = useTranslation("global");

    const handleClose = () => {
        setAlertOpen(false); // Function to close the alert dialog
    };


    useEffect(() => {
        document.getElementById('store-content') &&
            document.getElementById('store-content').addEventListener('touchmove', function (event) {
                event.stopPropagation();
            });
        scrollToTop()
        // Cleanup function to clear the timeout when the component unmounts
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [])
    let selectedIndustryStores;
    const ref = useRef(null)
    const onStoreClick = () => {

    }
    if (activeIndustry) {
        selectedIndustryStores = industryStores && industryStores.find((industry) => industry.get('industryId') == "-1")
        if (!selectedIndustryStores)
            selectedIndustryStores = industryStores && industryStores.find((industry) => industry.get('industryId') == activeIndustry.get('id') && industry.get('page') == selectedStorePage)
    }

    const context = useContext(AppContext);
    const execStatusGetIndustryStores = checkExecutionStatus(context, GET_INDUSTRY_STORES);
    const execStatusSearchStores = checkExecutionStatus(context, SEARCH_STORES);
    const redirect = (location) => {
        props.resetSearchStores();
        props.resetSelectedStoreProductCategory();
        props.history.push(location);
    };
    const onMerchantClick = (store) => {
        // const defaultCatSubCategory = getDefaultCategoryIdAndSubCategoryId(store.get('query_id'))
        // if (defaultCatSubCategory)
        //     redirect(`/products/product-list/${store.get('query_id')}/${defaultCatSubCategory.categoryId}/${defaultCatSubCategory.subCategoryId}/3?trade_type_id=${tradeTypeId}`)
        //redirect(`/products/product-list/${store.get('query_id')}/-1/-1/3?trade_type_id=${tradeTypeId}`)
        redirect(`/products/product-list/${store.get('query_id')}/ALL/ALL/3?trade_type_id=${tradeTypeId}`)

        //redirect(`/products/store/${store.get('query_id')}/${activeIndustry.get('id')}`)
    }
    const getDefaultCategoryIdAndSubCategoryId = (storeQueryId) => {
        const categories = storeProductCategories && storeProductCategories.find((category) => category.get('storeQueryId') === storeQueryId);
        if (categories && categories.get('listCategory') && categories.get('listCategory').get('categories') && categories.get('listCategory').get('categories').get(0)) {
            return {
                categoryId: categories.get('listCategory').get('categories').get(0).get('id'),
                subCategoryId: categories.get('listCategory').get('categories').get(0) && categories.get('listCategory').get('categories').get(0).get('sub_categories') && categories.get('listCategory').get('categories').get(0).get('sub_categories').get(0) && categories.get('listCategory').get('categories').get(0).get('sub_categories').get(0).get('id')
            }
        }
        else {
            return ""
        }
    }
    const getDelvryTime = (store) => {
        let days = 0;
        let hours = 0;
        let minutes = 0;
        if (store.get('delivery_time') && store.get('preparation_time')) {

            if (store.get('delivery_time').get('day') >= 0 && store.get('preparation_time').get('day') >= 0)
                days = store.get('delivery_time').get('day') + store.get('preparation_time').get('day');

            if (store.get('delivery_time').get('hour') >= 0 && store.get('preparation_time').get('hour') >= 0)
                hours = store.get('delivery_time').get('hour') + store.get('preparation_time').get('hour');

            if (store.get('delivery_time').get('minute') >= 0 && store.get('preparation_time').get('minute') >= 0)
                minutes = store.get('delivery_time').get('minute') + store.get('preparation_time').get('minute');
        }
        const delyTime = getDeliveryTime(days, hours, minutes)
        return delyTime;
    }
    const onPageChange = (e, page) => {
        setTimeout(() => {
            // scrollToRefElement(ref)
            scrollToTop()
        }, 200)
        let location = getSelectedAddressLatLng(auth);
        props.getIndustryStores({
            industryId: activeIndustry?.get('id'),
            selectedIndustry: activeIndustry,
            page: page,
            lat: "61.4936273",
            long: "23.7738686",
            tradeTypeId: tradeTypeId
        })
        props.updateSelctedStorePage(page)
    }
    const checkStoreInRadius = (store) => {
        let location = getSelectedAddressLatLng(auth);
        if (location.lat && location.long && store && store.get('address') && store.get('address').get('lat') && store.get('address').get('long') && store.get('service_area_radius')) {
            return storeIsInRadius(location.lat, location.long, store.get('address').get('lat'), store.get('address').get('long'), store.get('service_area_radius'))
        }
        else {
            return true;
        }
    }
    const getTotalPageCount = () => {
        if (selectedIndustryStores) {
            const totalCompanies = selectedIndustryStores?.get('industries')?.get('total_companies')
            if (totalCompanies) {
                return Math.ceil(totalCompanies / INDUSTRY_STORE_PAGE_SIZE)
            }
        }
        else return 1
    }
    const checkCategoriesExist = (storeQueryId) => {
        if (storeProductCategories && storeProductCategories.findIndex((category) => category.get('storeQueryId') === storeQueryId) > -1) {
            return true
        }
        else {
            return false
        }
    }
    const color = window.INDEX_NOKIA_ARENA ? 'black' : 'primary.light';
    return (
        <div>
            {getTheApplicationTheme().includes('STANDARD') ?
                <SiteContentWrapper
                    showBottomNavigation={true}
                >
                    <Grid id='store-content' ref={ref} item xs={12} sm={12} md={12} lg={12} className={clsx(classes.grid, 'w-100 pl-0 pr-0')}>
                        <Grid container className='d-flex justify-content-center'>
                            {
                                execStatusSearchStores.status === "PENDING" ?
                                    <div className={clsx((!mobileWidth || tabletWidth) && 'pl-4 pr-4', mobileWidth && 'pl-1 pr-1', 'w-100 pt-4')}>
                                        <Grid container className='w-100 d-flex justify-content-center align-items-center'>
                                            {Array.from(new Array(4)).map((_, i) =>
                                                <Grid key={i} xs={12} sm={12} md={12} lg={9} item>
                                                    {/* <BoxLoadingSkeleton /> */}
                                                    <Typography variant='body1' color='dark.main' className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
                                                        Loading Shops....
                                                    </Typography>
                                                    <Skeleton variant="rectangular" width={'100%'} height={70} />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </div> :
                                    selectedIndustryStores && selectedIndustryStores.get('industries') && selectedIndustryStores.get('industries').get('companies') &&
                                        selectedIndustryStores.get('industries').get('companies') &&
                                        selectedIndustryStores.get('industries').get('companies').size > 0 ?
                                        <>

                                            {window.INDEX_NOKIA_ARENA === true &&
                                                <div className={clsx(classes.orderDetailInfo, 'w-100 p-3 pt-5 order-det')}  >
                                                    {renderHtml(t("messages.nokiaArenaOrder"))}
                                                </div>
                                            }

                                            <Typography variant='subtitle' style={{ color }} className="w-100 d-flex justify-content-center align-items-center pt-3 pb-3">
                                                {!window.INDEX_NOKIA_ARENA && (window.INDEX_CHOOSE_STORE_LABEL ? window.INDEX_CHOOSE_STORE_LABEL : 'Choose your restaurantsasasas')}
                                                {window.INDEX_NOKIA_ARENA && (window.INDEX_CHOOSE_STORE_LABEL ? window.INDEX_CHOOSE_STORE_LABEL : t("messages.nokiaArenaHeading"))}

                                            </Typography>
                                            {selectedIndustryStores.get('industries').get('companies').map((store, index) => {
                                                let isInRadius = checkStoreInRadius(store);
                                                const isCategoryExists = checkCategoriesExist(store.get('query_id'));
                                                return <Grid key={index} item xs={12} sm={12} md={12} lg={9} className='mb-2 mt-2'>
                                                    <MerchantDetailBox
                                                        index={index}
                                                        key={`merchant-${index}`}
                                                        onClick={() => onMerchantClick(store)}
                                                        image={`${store.get('banner_url')}`}
                                                        label={store.get('name')}
                                                        tag={getDelvryTime(store)}
                                                        execStatusGetStoreProductCategories={execStatusGetStoreProductCategories}
                                                        isCategoryExists={isCategoryExists}
                                                        // description={store.get('min_amount') ? `Minimum Amount Rs ${formatAmountV1(store.get('min_amount'))}` : ""}
                                                        buttonText={store.get('shop_status')}
                                                        tagImage={`assets/images/zapp.man.png`}
                                                        footerMessage={isInRadius === false ? "Not in your delivery area" : ""}
                                                        //tagImage={`${SERVER_BASE_URL}/${activeIndustry && activeIndustry.get('image')}`}
                                                        footerDescription={getDeliveryFee(store, auth)}
                                                        onButtonClick={onStoreClick}
                                                        description={store && store.get('address') && store.get('address')?.get('description')}
                                                    />
                                                </Grid>

                                            })}
                                            {
                                                getTotalPageCount() > 1 ?
                                                    <div className='pt-3 w-100 pt-5 d-flex justify-content-center pb-2'>
                                                        <Pagination
                                                            page={selectedStorePage}
                                                            onChange={onPageChange}
                                                            color="secondary"
                                                            count={getTotalPageCount()}
                                                            shape="rounded"
                                                        />
                                                    </div> : null
                                            }
                                        </>
                                        : execStatusGetIndustryStores.status === 'PENDING' ?
                                            <div className={clsx((!mobileWidth || tabletWidth) && 'pl-4 pr-4', mobileWidth && 'pl-1 pr-1', 'w-100 pt-4')}>
                                                <Grid container className='w-100 d-flex justify-content-center align-items-center'>
                                                    {Array.from(new Array(4)).map((_, i) =>
                                                        <Grid key={i} xs={12} sm={12} md={12} lg={9} item className='mt-2 mb-2 position-relative'>
                                                            {/* <BoxLoadingSkeleton /> */}
                                                            {i === 1 ?
                                                                <Typography variant='body1' color='dark.main' className="font-weight-bold position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
                                                                    Loading Shops....
                                                                </Typography> : null
                                                            }
                                                            <Skeleton variant="rectangular" width={'100%'} height={70} />
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </div>
                                            : execStatusGetIndustryStores.status === "ERROR" ?
                                                <div className="d-flex justify-content-center text-danger p-3 pt-5 w-100">
                                                    {execStatusGetIndustryStores?.errorDetail?.get('message')}
                                                </div> :
                                                execStatusSearchStores.status === "ERROR" ?
                                                    <div className="d-flex justify-content-center text-danger p-3 pt-5 w-100">
                                                        {execStatusSearchStores?.errorDetail?.get('message')}
                                                    </div> :
                                                    selectedIndustryStores && selectedIndustryStores.get('industries') && selectedIndustryStores.get('industries').get('companies') &&
                                                        selectedIndustryStores.get('industries').get('companies').size === 0 ?
                                                        <Typography color="secondary" variant='h6' className={`${getMessageColor()} d-flex justify-content-center align-items-center font-weight-bold min-h-25 w-100`}>
                                                            {window.INDEX_NOKIA_ARENA === true ?
                                                                <AlertDialogBox
                                                                    open={alertOpen}
                                                                    title={t('messages.alertMessageTitle')}
                                                                    description={t('messages.alertMessgae')}
                                                                    onClose={handleClose}
                                                                /> :
                                                                'No Stores Found'

                                                            }
                                                        </Typography> : null

                            }
                        </Grid>
                    </Grid>
                </SiteContentWrapper>
                : getTheApplicationTheme() === "PROMINENT" ?
                    (<Paper
                        elevation={0}
                        className={clsx(!(mobileWidth || tabletWidth) && classes.backgroundImage, (mobileWidth || tabletWidth) && classes.mobileBackgroundImage,
                            'overflow-hidden vw_width100 vh_height100 d-flex justify-content-center')}
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home-bg.png)`,
                        }}
                    >
                        <Paper variant='h5' className='h-100 w-100 position-absolute' classes={{ root: classes.bgColorStyle }} > </Paper>
                        <Container ref={containerHeightRef} maxWidth='sm' className={clsx(classes.linearBackground, 'position-absolute vw_width100 vh_height100 z-index-1000')}>
                            {window.HOME_PAGE_TOP_IMAGE_URL ?
                                <Box className="d-flex w-100 justify-content-center position-relative" style={{ height: '145px' }}>
                                    <div className='position-absolute w-100 z-index mt-n5'>
                                        <TopNavigationPanel
                                            transparent={true}
                                            hideCloseButton={true}
                                        />
                                    </div>
                                    {window.HOME_PAGE_TOP_IMAGE_URL ?
                                        <Box className="w-100">
                                            <img className={classes.logoBg} src={window.HOME_PAGE_TOP_IMAGE_URL} width="100%" height="200px" />
                                        </Box> : null
                                    }
                                    {window.HOME_PAGE_LOGO_URL ?
                                        <Box className={`position-absolute w-100 z-index-1200 ${classes.logo}`}>
                                            <img src={window.HOME_PAGE_LOGO_URL} width="190px" />
                                        </Box> : null
                                    }
                                    <Typography variant='subtitle' className={clsx(classes.scrollDiv, "text-white w-100 d-flex justify-content-center align-items-end pt-2 pb-2 bg-white position-absolute")}>
                                        Text
                                    </Typography>
                                </Box> : null
                            }
                            <Box className={clsx(classes.merchantsWrapper, 'overflow-auto')} style={{ height: window.HOME_PAGE_TOP_IMAGE_URL ? `calc(${siteContentWrapperHeight}px - 210px)` : `calc(${siteContentWrapperHeight}px - 60px)` }}>
                                <Grid id='store-content' ref={ref} xs={12} item sm={12} md={12} lg={12} className={clsx(classes.grid, 'w-100 pl-0 pr-0 overflow-auto')}>
                                    <Grid container className='d-flex justify-content-center pt-5'>
                                        {
                                            execStatusSearchStores.status === "PENDING" ?
                                                <div className={clsx((!mobileWidth || tabletWidth) && 'pl-4 pr-4', mobileWidth && 'pl-1 pr-1', 'w-100 pt-4')}>
                                                    <Grid container className='w-100 d-flex justify-content-center align-items-center'>
                                                        {Array.from(new Array(4)).map((_, i) =>
                                                            <Grid key={i} xs={12} sm={12} md={12} lg={9} item>
                                                                {/* <BoxLoadingSkeleton /> */}
                                                                <Typography variant='body1' color='dark.main' className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
                                                                    Loading Shops....
                                                                </Typography>
                                                                <Skeleton variant="rectangular" width={'100%'} height={70} />
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </div> :
                                                selectedIndustryStores && selectedIndustryStores.get('industries') && selectedIndustryStores.get('industries').get('companies') &&
                                                    selectedIndustryStores.get('industries').get('companies') &&
                                                    selectedIndustryStores.get('industries').get('companies').size > 0 ?
                                                    <>
                                                        <Typography variant='subtitle' color='primary.light' className="w-100 d-flex justify-content-center align-items-center pt-2 pb-2">
                                                            Choose pickup location
                                                        </Typography>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} className='d-flex flex-column align-items-center overflow-auto'>
                                                            <Grid container className={`d-flex justify-content-center ${classes.productsWrapper}`}>
                                                                {selectedIndustryStores.get('industries').get('companies') &&
                                                                    selectedIndustryStores.get('industries').get('companies').reverse().map((store, index) => {
                                                                        let isInRadius = checkStoreInRadius(store);
                                                                        const isCategoryExists = checkCategoriesExist(store.get('query_id'));
                                                                        // if (index > 1) {
                                                                        //     return null;
                                                                        // }
                                                                        return <Grid key={index} item xs={11} sm={11} md={11} lg={9} className='mb-2 mt-2'>
                                                                            <MerchantDetailBox
                                                                                index={index}
                                                                                key={`merchant-${index}`}
                                                                                onClick={() => onMerchantClick(store)}
                                                                                image={`${store.get('banner_url')}`}
                                                                                label={store.get('name')}
                                                                                tag={getDelvryTime(store)}
                                                                                execStatusGetStoreProductCategories={execStatusGetStoreProductCategories}
                                                                                isCategoryExists={isCategoryExists}
                                                                                // description={store.get('min_amount') ? `Minimum Amount Rs ${formatAmountV1(store.get('min_amount'))}` : ""}
                                                                                buttonText={store.get('shop_status')}
                                                                                tagImage={`assets/images/zapp.man.png`}
                                                                                footerMessage={isInRadius === false ? "Not in your delivery area" : ""}
                                                                                //tagImage={`${SERVER_BASE_URL}/${activeIndustry && activeIndustry.get('image')}`}
                                                                                footerDescription={getDeliveryFee(store, auth)}
                                                                                onButtonClick={onStoreClick}
                                                                                description={store && store.get('address') && store.get('address')?.get('description')}
                                                                            />
                                                                        </Grid>

                                                                    })}
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            getTotalPageCount() > 1 ?
                                                                <div className='pt-3 w-100 pt-5 d-flex justify-content-center pb-2'>
                                                                    <Pagination
                                                                        page={selectedStorePage}
                                                                        onChange={onPageChange}
                                                                        color="secondary"
                                                                        count={getTotalPageCount()}
                                                                        shape="rounded"
                                                                    />
                                                                </div> : null
                                                        }
                                                    </>
                                                    : execStatusGetIndustryStores.status === 'PENDING' ?
                                                        <div className={clsx((!mobileWidth || tabletWidth) && 'pl-4 pr-4', mobileWidth && 'pl-1 pr-1', 'w-100 pt-4')}>
                                                            <Grid container className='w-100 d-flex justify-content-center align-items-center'>
                                                                {Array.from(new Array(4)).map((_, i) =>
                                                                    <Grid key={i} xs={12} sm={12} md={12} lg={9} item className='mt-2 mb-2 position-relative'>
                                                                        {/* <BoxLoadingSkeleton /> */}
                                                                        {i === 1 ?
                                                                            <Typography variant='body1' color='dark.main' className="font-weight-bold position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
                                                                                Loading Shops....
                                                                            </Typography> : null
                                                                        }
                                                                        <Skeleton variant="rectangular" width={'100%'} height={70} />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </div>
                                                        : execStatusGetIndustryStores.status === "ERROR" ?
                                                            <div className="d-flex justify-content-center text-danger p-3 pt-5 w-100">
                                                                {execStatusGetIndustryStores?.errorDetail?.get('message')}
                                                            </div> :
                                                            execStatusSearchStores.status === "ERROR" ?
                                                                <div className="d-flex justify-content-center text-danger p-3 pt-5 w-100">
                                                                    {execStatusSearchStores?.errorDetail?.get('message')}
                                                                </div> :
                                                                selectedIndustryStores && selectedIndustryStores.get('industries') && selectedIndustryStores.get('industries').get('companies') &&
                                                                    selectedIndustryStores.get('industries').get('companies').size === 0 ?
                                                                    <Typography color="secondary" variant='h6' className={`${getMessageColor()} d-flex justify-content-center align-items-center font-weight-bold min-h-25 w-100`}>
                                                                        No Stores Found
                                                                    </Typography> : null

                                        }
                                        {/* <Grid xs={12} sm={12} md={12} lg={12} item>
                                            <Typography className='text-secondary text-center pt-2 pb-2' variant="body2">Version {packageJson.version}</Typography>
                                        </Grid> */}
                                    </Grid>
                                </Grid >
                            </Box>

                        </Container>
                    </Paper>) : null
            }
        </div >
    )
}