import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    btn: {
        background: theme.palette.greyColor.main,
        color: theme.palette.dark.main,
        fontSize: '20px',
        boxShadow: 'none',
        minWidth: '20px',
        width: '25px',
        height: '25px',
        padding: '0px',
        '&:hover': {
            background: theme.palette.greyColor.main,
            color: theme.palette.dark.main
        }
    },
    btnSPlus1: {
        background: theme.palette.counterButtonColor.main,
        color: theme.palette.dark.main,
        fontSize: '20px',
        boxShadow: 'none',
        minWidth: '20px',
        width: '65px',
        height: '35px',
        padding: '0px',
        borderRadius: '17px 0px 0px 0px',
        '&:hover': {
            background: theme.palette.counterButtonColor.dark,
            color: theme.palette.dark.main,
            boxShadow: 'none'
        }
    },
    btnSPlus2: {
        background: theme.palette.counterButtonColor.main,
        color: theme.palette.dark.main,
        fontSize: '20px',
        boxShadow: 'none',
        minWidth: '20px',
        width: '65px',
        height: '35px',
        padding: '0px',
        borderRadius: '0px 0px 17px 0px',
        '&:hover': {
            background: theme.palette.counterButtonColor.dark,
            color: theme.palette.dark.main,
            boxShadow: 'none'
        }
    },
    btnProminent: {
        background: theme.palette.primary.main,
        color: theme.palette.background.paper,
        fontSize: '20px',
        boxShadow: 'none',
        minWidth: '20px',
        width: '40px',
        height: '40px',
        padding: '0px',
        '&:hover': {
            background: theme.palette.primary.dark,
            color: theme.palette.background.paper,
        }
    },
    btnProminentSmall: {
        background: theme.palette.primary.main,
        color: theme.palette.background.paper,
        fontSize: '20px',
        boxShadow: 'none',
        minWidth: '20px',
        width: '25px',
        height: '25px',
        padding: '0px',
        '&:hover': {
            background: theme.palette.primary.dark,
            color: theme.palette.background.paper,
        }
    }
}));