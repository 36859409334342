import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { getTheApplicationTheme, isStandardPlusTheme } from 'components/common/util/util';

export default function CustomLinearProgressBar(props) {
    const { progressValue, classNames, color } = props
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: color ? color : getTheApplicationTheme() === 'PROMINENT' ? theme.palette.primary.main : theme.palette.background.paper,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            background: isStandardPlusTheme() && window.TERNARY_COLOR ? window.TERNARY_COLOR
                : getTheApplicationTheme() === 'PROMINENT' ? 'linear-gradient(180deg, #0FD26A 0%, #0E8B48 100%)'
                    : '#45DA66'
        },
    }));
    return (
        <>
            {getTheApplicationTheme().includes('STANDARD') ?
                <Box sx={{ flexGrow: 1 }}>
                    <BorderLinearProgress className={classNames} variant="determinate" value={progressValue} />
                </Box>
                : getTheApplicationTheme() === 'PROMINENT' ?
                    <Box sx={{ flexGrow: 1 }} className='w-100 d-flex justify-content-center align-items-center'>
                        <BorderLinearProgress className={classNames} variant="determinate" value={progressValue} />
                    </Box> : null
            }
        </>
    );
}
