import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles(theme=>({

  alertdialogbox: {
  marginTop: 10 ,
  paddingBottom: 7,
  //fontSize: 18, 
  fontWeight: 400,
  color: "#000000 !important",
  marginTop: 20,
  marginRight: 20,
  marginLeft: 20,
  marginBottom: 20 
},
thanks: {
  marginTop: 7,
  color: "#000000 !important",
  textAlign: "center" ,
  fontSize: 18, 
  fontWeight:400, 
},
titleColor:{
  color:"#fff !important"
},
desc: {
  marginTop: 10,
  marginRight: 10,
  marginLeft: 10,
  marginBottom: 10
},
wait:{
margin:0
}
}))