import React, { useState } from 'react'
import { Box, Grid, Typography, Divider } from '@mui/material';
import SummaryBox from '../summary.box/summary.box'
import ShoppingCartItem from 'components/common/shopping.cart.item/shopping.cart.item';
import { renderHtml } from 'components/common/util/util';
import DeleteDialog from 'components/common/delete.dialog/delete.dialog';
import { formatAmountV1, PAYMENT_OPTIONS } from 'components/common/util/util';
import PageHeading from 'components/common/page.heading/page.heading';
import { IsMobileWidth, IsTabletWidth, scrollToTop, getImageUrl, isStandardPlusTheme, getTaxAmount } from "components/common/util/util";
import clsx from "clsx";
import { useStyle } from './shopping.cart.style';
import ShoppingCartHeader from './components/shopping.cart.header/shopping.cart.header';
import TapableView from "components/common/tapable.view/tapable.view";
import SiteLabelTextField from 'components/common/site.label.textfield.v3/site.label.textfield.v3';
import TermsAndConditionSelectionDialog from "components/common/terms.and.conditions.selection.dialog/terms.and.conditions.selection.dialog";
import { isValidFields } from './validations';
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import SiteContentWrapper from 'components/common/site.content.wrapper/site.content.wrapper';
import { useEffect } from 'react';
import { detectBrowser, getQueryStringValue, getCurrency } from 'components/common/util/util';
import { getTaxPercentage, getTheApplicationTheme } from 'components/common/util/util';
import ErrorMessageDialog from './components/shopping.cart.header/error.message.dialog';
import { isPaymentMethodApplicable } from 'components/common/util/util';
import { useTranslation } from "react-i18next";

//TAX_CHANGES
const ShoppingCart = (props) => {
    const { auth, execStatusBankAlfalahGetPaymentForm } = props;
    const [state, setstate] = useState({
        deleteDialogOpen: false,
        selectedProduct: '',
        paymentMethod: '',
        email: '',
        isTermsAndConditionsAccepted: false,
        validationResult: {
            isValidEmail: true,
        }
    })
    const browser = detectBrowser()
    const mobileWidth = IsMobileWidth();
    const tabletWidth = IsTabletWidth();
    const classes = useStyle()
    const { cartProducts, execStatusCheckoutInvoice, execStatusCreateInvoice, execStatusPlaceOrder, execStatusNetaXeptPayment } = props;
    useEffect(() => {
        document.getElementById('cart-summary-content') &&
            document.getElementById('cart-summary-content').addEventListener('touchmove', function (event) {
                event.stopPropagation();
            });
        scrollToTop()
    }, []);

    const { t } = useTranslation("global");

    let totalPrice = 0;
    for (let cartProd of cartProducts) {
        if (cartProd && cartProd?.get('data')?.get('promotion') && cartProd?.get('data')?.get('promotion').get('promotion_amount')) {
            totalPrice = totalPrice + (cartProd.get('data')?.get('cost_after_discount') * cartProd.get('data')?.get('quantity'))
        }
        else
            totalPrice = totalPrice + (cartProd.get('data')?.get('cost') * cartProd.get('data')?.get('quantity'))
    }
    let totalTaxAmount = 0;
    if (cartProducts && cartProducts.size > 0) {
        totalTaxAmount = getTaxAmount(cartProducts, totalPrice)
    }
    const onClose = () => {
        setstate({ ...state, deleteDialogOpen: false, selectedProduct: "" })
    }
    const openDeleteDialog = (product) => {
        setstate({ ...state, deleteDialogOpen: true, selectedProduct: product })
    }
    const onDelete = () => {
        props.setAddProductToCartId(state.selectedProduct?.get("data")?.get('query_id'))
        props.deleteProductFromCart({
            uniqueId: state.selectedProduct && state.selectedProduct?.get("data")?.get('query_id')
        })
        onClose();
    }
    // const goBack = () => {
    //     props.history.goBack();
    //     //props.history.push(route)
    // }
    const redirectTo = (route) => {
        props.history.push(route)
    }
    const onQuantityChange = (product, quantity) => {
        props.resetCheckOutInvoice();
        props.resetPlaceOrder();
        props.resetCreateInvoice();
        if (quantity === 0) {
            setstate({ ...state, deleteDialogOpen: true, selectedProduct: product })
        }
        else {
            props.setAddProductToCartId(product?.get("data").get('query_id'))
            props.addUpdateProductToCart({
                ...product?.get('data')?.toJS(),
                quantity: quantity,
                //merchantAddressId: "",
                storeQueryId: product?.get("data").get('storeQueryId'),
                uniqueId: product?.get("data").get('query_id')
            })
        }
    }
    const getTotalPrice = (prod) => {
        if (prod?.get('data')?.get('promotion') && prod?.get('data')?.get('promotion').get('promotion_amount')) {
            return formatAmountV1(prod?.get('data')?.get('quantity') * prod?.get('data')?.get('cost_after_discount'))
        }
        else if (prod?.get('data')?.get('quantity') && prod?.get('data')?.get('cost')) {
            return formatAmountV1(prod?.get('data')?.get('quantity') * prod?.get('data')?.get('cost'))
        }
        else
            return ''

    }

    const onChange = (name, value) => {
        setstate({ ...state, [name]: value })
    }
    // props.history.push('payment-success');
    const onCheckOut = (e, paymentMethod) => {
        props.resetCheckOutInvoice();
        props.resetPlaceOrder();
        props.resetCreateInvoice();
        e.preventDefault()
        setstate({ ...state, paymentMethod: paymentMethod })
        let invoiceItemAttributes = [];
        let variantOptionsIds = []
        // const validationResult = isValidFields(state);
        // setstate({ ...state, validationResult: validationResult })
        // if (validationResult.isValidEmail === true) {
        let merchantAddressId = 0;
        if (cartProducts) {
            for (let cartProd of cartProducts) {
                //COLOR_SIZE_CHANGES
                let selectedvariants = cartProd.get('data') && cartProd?.get('data')?.get('selectedVariants')
                if (selectedvariants) {
                    selectedvariants.forEach(variant => {
                        variantOptionsIds.push(variant?.get('optionId'))
                    });
                }
                merchantAddressId = cartProd?.get('data')?.get('merchantAddressId')
                invoiceItemAttributes.push({
                    instruction: "",
                    product_query_id: cartProd?.get('data')?.get('query_id'),
                    quantity: cartProd?.get('data')?.get('quantity'),
                    tax_percent: getTaxPercentage(cartProd),
                    item_description: "",
                    color: cartProd?.get('data')?.get('selectedColor') ? cartProd?.get('data')?.get('selectedColor') : {},
                    size: cartProd?.get('data')?.get('selectedSize') ? cartProd?.get('data')?.get('selectedSize') : {},
                    variant_option_ids: variantOptionsIds
                })
            }
            props.createInvoice({
                invoice: {
                    invoice_items_attributes: invoiceItemAttributes,
                    organization_query_id: cartProducts?.get(0)?.get('data')?.get('storeQueryId'),
                    //address_id: 234
                }
            }, {
                email: state.email,
                merchantAddressId: merchantAddressId,
                paymentMethod: paymentMethod
            })
        }
        // }
    }
    const openBankAlfalahPaymentForm = (e, paymentMethod) => {
        setstate({ ...state, paymentMethod: paymentMethod })
        props.getBankAlfalahPaymentForm({ userId: auth && auth.get('user').get('id') });
    };
    const processPaymentBySoneri = (e, paymentMethod) => {
        props.resetCheckOutInvoice();
        props.resetPlaceOrder();
        props.resetCreateInvoice();
        e.preventDefault()
        setstate({ ...state, paymentMethod: paymentMethod })
        let invoiceItemAttributes = [];
        let variantOptionsIds = []
        // const validationResult = isValidFields(state);
        // setstate({ ...state, validationResult: validationResult })
        // if (validationResult.isValidEmail === true) {
        let merchantAddressId = 0;
        if (cartProducts) {
            for (let cartProd of cartProducts) {
                //COLOR_SIZE_CHANGES
                let selectedvariants = cartProd.get('data') && cartProd?.get('data')?.get('selectedVariants')
                if (selectedvariants) {
                    selectedvariants.forEach(variant => {
                        variantOptionsIds.push(variant?.get('optionId'))
                    });
                }
                merchantAddressId = cartProd?.get('data')?.get('merchantAddressId')
                invoiceItemAttributes.push({
                    instruction: "",
                    product_query_id: cartProd?.get('data')?.get('query_id'),
                    quantity: cartProd?.get('data')?.get('quantity'),
                    tax_percent: getTaxPercentage(cartProd),
                    item_description: "",
                    color: cartProd?.get('data')?.get('selectedColor') ? cartProd?.get('data')?.get('selectedColor') : {},
                    size: cartProd?.get('data')?.get('selectedSize') ? cartProd?.get('data')?.get('selectedSize') : {},
                    variant_option_ids: variantOptionsIds
                })
            }
            props.createInvoice({
                invoice: {
                    invoice_items_attributes: invoiceItemAttributes,
                    organization_query_id: cartProducts?.get(0)?.get('data')?.get('storeQueryId'),
                    //address_id: 234
                }
            }, {
                email: state.email,
                merchantAddressId: merchantAddressId,
                paymentMethod: paymentMethod
            })
        }
    };
    const goBack = () => {
        let merchantQueryId = cartProducts && cartProducts.get(0) && cartProducts.get(0).get('data') && cartProducts.get(0).get('data').get('merchant_query_id')

        let categoryId = cartProducts && cartProducts.get(0) && cartProducts.get(0).get('data') && cartProducts.get(0).get('data').get('category_id')
        let subCategoryId = cartProducts && cartProducts.get(0) && cartProducts.get(0).get('data') && cartProducts.get(0).get('data').get('sub_category_id')

        if (!categoryId)
            categoryId = cartProducts && cartProducts.get(0) && cartProducts.get(0).get('data') && cartProducts.get(0).get('data').get('sub_category') && cartProducts.get(0).get('data').get('sub_category').get('category') && cartProducts.get(0).get('data').get('sub_category').get('category').get('id')
        if (!subCategoryId)
            subCategoryId = cartProducts && cartProducts.get(0) && cartProducts.get(0).get('data') && cartProducts.get(0).get('data').get('sub_category') && cartProducts.get(0).get('data').get('sub_category').get('id')

        let tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
        // props.resetCheckOutInvoice();
        // props.resetPlaceOrder();
        // props.resetCreateInvoice();
        if (cartProducts && cartProducts?.size > 0 && merchantQueryId && categoryId && subCategoryId) {
            props.history.push(`/products/product-list/${merchantQueryId}/${categoryId}/${subCategoryId}/3?trade_type_id=${tradeTypeId}`);
        }
        else {
            props.history.push(`/products`);
        }
    }
    const isTypographyStyle = window.INDEX_NOKIA_ARENA === true;
    return (
        <SiteContentWrapper
            {...props}
            //IOS_TESTING
            // contentMaxHeight={browser === 'Safari' ? '450px' : undefined}
            topNavigationName='Payment Method'
            bottomHeight='203px'
            bottomContent={
                <Grid item xs={12} sm={12} lg={12} md={12} className='w-100' style={isTypographyStyle
                    ? { background: "#F8F8F8", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }
                    : {}
                }>
                    <div className="w-100 d-flex justify-content-center pt-2 pl-4 pr-4 text-center">
                        {!window.INDEX_NOKIA_ARENA && window.TERM_AND_CONDITIONS_SUMMARY ?
                            renderHtml(window.TERM_AND_CONDITIONS_SUMMARY)
                            : null
                        }
                        {window.INDEX_NOKIA_ARENA === true && (
                            <div style={{ paddingTop: "15px" }}>
                                <img style={{ width: "auto" }}
                                    src={`${process.env.PUBLIC_URL}/assets/icons/alert-icon.png`} />
                                <p style={{ padding: "15px 0", fontSize: "16px", fontWeight: "700" }}>
                                    {t("messages.nokiaArenaOrderPlacement")}
                                </p>
                            </div>
                        )}
                    </div>
                    <Box className="d-flex justify-content-center">
                        <TermsAndConditionSelectionDialog onChange={onChange} goBack={goBack} {...props} />
                    </Box>
                    <div className='w-100'>
                        {
                            execStatusPlaceOrder.status === "ERROR" ?
                                <div className="w-100">
                                    <Typography variant='caption' className="d-flex font-weight-bold text-danger justify-content-center w-100">
                                        {execStatusPlaceOrder?.errorDetail?.get('message')}
                                    </Typography>
                                </div> : null
                        }
                        {
                            execStatusCreateInvoice.status === "ERROR" && typeof execStatusCreateInvoice?.errorDetail?.get('message') !== 'object' ?
                                <div className="w-100">
                                    <Typography variant='caption' className="d-flex font-weight-bold text-danger justify-content-center w-100">
                                        {execStatusCreateInvoice?.errorDetail?.get('message')}
                                    </Typography>
                                </div> : null
                        }
                        {
                            execStatusCheckoutInvoice.status === "ERROR" ?
                                <div className="w-100">
                                    <Typography variant='caption' className="d-flex font-weight-bold text-danger justify-content-center w-100">
                                        {execStatusCheckoutInvoice?.errorDetail?.get('message')}
                                    </Typography>
                                </div> : null
                        }
                        {
                            execStatusNetaXeptPayment.status === "ERROR" ?
                                <div className="w-100">
                                    <Typography variant='caption' className="d-flex font-weight-bold text-danger justify-content-center w-100">
                                        {execStatusNetaXeptPayment?.errorDetail?.get('message')}
                                    </Typography>
                                </div> : null
                        }
                    </div>
                    {/* <Box m={2}>
                    {PAYMENT_OPTIONS && PAYMENT_OPTIONS.map((data, index) => {
                        return <TapableView onClick={() => onPaymentSelect(data)} key={index}
                            name={data.name}
                            icon={data.icon}
                            disabled={!state.isTermsAndConditionsAccepted ? true : false}
                            color={data.color}
                        />
                    })}
                </Box> */}
                    <div className='w-100 d-flex flex-column pl-4 pr-4 align-items-center'>
                        {
                            isPaymentMethodApplicable("NEST_MOBILE_PAY") ?
                                <LoaderButton
                                    // fullWidth
                                    type="submit"
                                    variant={getTheApplicationTheme() === 'PROMINENT' ? 'outlined' : "contained"}
                                    showSwipeButton={getTheApplicationTheme() === 'PROMINENT' ? true : false}
                                    color={isStandardPlusTheme() ? "secondary" : getTheApplicationTheme() === 'PROMINENT' ? '' : "primary"}
                                    classNames={clsx(!(mobileWidth || tabletWidth) && 'w-40', (mobileWidth || tabletWidth) && 'w-100', "mt-2 mb-2", classes.btnStyle1)}
                                    disabled={!state.isTermsAndConditionsAccepted ? true : false}
                                    onClick={(e) => onCheckOut(e, 'Mobilepay')}
                                    loading={(execStatusCheckoutInvoice.status === "PENDING" ||
                                        execStatusCreateInvoice.status === "PENDING" ||
                                        execStatusPlaceOrder.status === "PENDING" ||
                                        execStatusNetaXeptPayment.status === "PENDING") && state.paymentMethod == 'Mobilepay' ? true : false}
                                    {...props}
                                >
                                    <img className='mr-1' src={`${process.env.PUBLIC_URL}/assets/icons/pay-icon.svg`} width='18px' />
                                    {window.INDEX_NOKIA_ARENA === true ? t('messages.mobilePayButtonText'): 'Pay with Mobilepay'}  
                                </LoaderButton> : null
                        }
                        {/* <LoaderButton
                            // fullWidth
                            type="submit"
                            variant="contained"
                            classNames={clsx(!(mobileWidth || tabletWidth) && 'w-30', (mobileWidth || tabletWidth) && 'w-100', "mt-2 mb-2", classes.btnStyle)}
                            disabled={true}
                        // onClick={onCheckOut}
                        >
                            Pay with apple
                            <img style={{ marginBottom: '2px' }} className='ml-1 mr-1' src={`${process.env.PUBLIC_URL}/assets/icons/apple-icon.svg`} width='14px' />
                            pay
                        </LoaderButton> */}
                        {
                            isPaymentMethodApplicable("NEST_CARD_PAY") ?
                                <LoaderButton
                                    // fullWidth
                                    type="submit"
                                    variant={isStandardPlusTheme() ? 'none' : "outlined"}
                                    showSwipeButton={getTheApplicationTheme() === 'PROMINENT' ? true : false}
                                    color={getTheApplicationTheme() === 'PROMINENT' ? "" : "primary"}
                                    classNames={clsx(!(mobileWidth || tabletWidth) && 'w-40', (mobileWidth || tabletWidth) && 'w-100', "mt-2 mb-2", getTheApplicationTheme() !== 'PROMINENT' ? classes.linkStyle : classes.btnStyle1)}
                                    disabled={!state.isTermsAndConditionsAccepted ? true : false}
                                    onClick={(e) => onCheckOut(e, 'Visa')}
                                    loading={(execStatusCheckoutInvoice.status === "PENDING" ||
                                        execStatusCreateInvoice.status === "PENDING" ||
                                        execStatusPlaceOrder.status === "PENDING" ||
                                        execStatusNetaXeptPayment.status === "PENDING") && state.paymentMethod == 'Visa' ? true : false}
                                    {...props}
                                >
                                    {window.INDEX_NOKIA_ARENA === true ? t('messages.payByCardButtonText'): ' Pay by Card'}  
                                </LoaderButton> : null
                        }
                        {
                            isPaymentMethodApplicable("PAYMENT_BY_CARD_BANK_ALFALAH") ?
                                <LoaderButton
                                    // fullWidth
                                    type="submit"
                                    variant={isStandardPlusTheme() ? 'none' : "outlined"}
                                    showSwipeButton={getTheApplicationTheme() === 'PROMINENT' ? true : false}
                                    color={getTheApplicationTheme() === 'PROMINENT' ? "" : "primary"}
                                    classNames={clsx(!(mobileWidth || tabletWidth) && 'w-40', (mobileWidth || tabletWidth) && 'w-100', "mt-2 mb-2", getTheApplicationTheme() !== 'PROMINENT' ? classes.linkStyle : classes.btnStyle1)}
                                    disabled={!state.isTermsAndConditionsAccepted ? true : false}
                                    onClick={(e) => openBankAlfalahPaymentForm(e, "PAYMENT_BY_CARD_BANK_ALFALAH")}
                                    loading={(execStatusCheckoutInvoice.status === "PENDING" ||
                                        execStatusCreateInvoice.status === "PENDING" ||
                                        execStatusBankAlfalahGetPaymentForm.status === "PENDING" ||
                                        execStatusPlaceOrder.status === "PENDING") && state.paymentMethod == 'PAYMENT_BY_CARD_BANK_ALFALAH' ? true : false}
                                    {...props}
                                >
                                    Pay with Card
                                </LoaderButton> : null
                        }
                        {
                            isPaymentMethodApplicable("PAYMENT_BY_SONERI") ?
                                <LoaderButton
                                    // fullWidth
                                    type="submit"
                                    variant={isStandardPlusTheme() ? 'none' : "outlined"}
                                    showSwipeButton={getTheApplicationTheme() === 'PROMINENT' ? true : false}
                                    color={getTheApplicationTheme() === 'PROMINENT' ? "" : "primary"}
                                    classNames={clsx(!(mobileWidth || tabletWidth) && 'w-40', (mobileWidth || tabletWidth) && 'w-100', "mt-2 mb-2", getTheApplicationTheme() !== 'PROMINENT' ? classes.linkStyle : classes.btnStyle1)}
                                    disabled={!state.isTermsAndConditionsAccepted ? true : false}
                                    onClick={(e) => processPaymentBySoneri(e, "PAYMENT_BY_SONERI")}
                                    loading={(execStatusCheckoutInvoice.status === "PENDING" ||
                                        execStatusCreateInvoice.status === "PENDING" ||
                                        execStatusPlaceOrder.status === "PENDING") && state.paymentMethod == 'PAYMENT_BY_SONERI' ? true : false}
                                    {...props}>
                                    Pay with Soneri
                                </LoaderButton> : null
                        }
                        {getTheApplicationTheme() === 'PROMINENT' ?
                            <LoaderButton
                                // fullWidth
                                showBackArrow={true}
                                type="submit"
                                variant='outlined'
                                classNames={clsx(!(mobileWidth || tabletWidth) && 'w-40', (mobileWidth || tabletWidth) && 'w-100', "mt-2 mb-2", classes.btnStyle1)}
                                onClick={goBack}
                                {...props}
                            >
                                Change order
                            </LoaderButton> : null}
                    </div>
                </Grid>
            }
        >
            {
                state.deleteDialogOpen ?
                    <DeleteDialog open={state.deleteDialogOpen}
                        alertMessage="Are you sure you want to delete?"
                        buttonText='Delete'
                        onDelete={onDelete}
                        title={`Delete ${state.selectedProduct && state.selectedProduct?.get('data')?.get('name')} from cart`}
                        onClose={onClose}>
                        If you choose to delete this item,it will no longer part of your cart.
                    </DeleteDialog>
                    : null
            }
            < Box className={clsx(classes.iconDiv, 'd-flex justify-content-start width-max-content align-items-center cursor-pointer mt-1 mb-1 position-sticky top-5 z-index-1200')} >
                {/* <PageHeading redirect={() => props.history.goBack()} name="Shopping Cart" /> */}
                {
                    getTheApplicationTheme().includes('STANDARD') ?
                        <Typography onClick={goBack}
                            classes={{ root: classes.iconColor }}
                            className={`${isTypographyStyle ? 'rounded-circle' : ''}`}
                            style={isTypographyStyle
                                ? { background: "#1558CE" }
                                : {}
                            }>
                            {
                                window.INDEX_NOKIA_ARENA === true ?
                                    <img src={`${process.env.PUBLIC_URL}/assets/icons/close-alert-dialog-box-v1.png`} /> : (

                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`} width='24px' />
                                    )

                            }

                        </Typography>
                        : null
                }
            </Box >
            <Grid id='cart-summary-content' container className={clsx(classes.gridStyle, 'pl-3 pr-3 pt-2')}>
                {/* {
                    cartProducts && cartProducts?.size === 0 ?
                        <div className={clsx(mobileWidth && 'mt-5', "w-100")}>
                            <Typography variant='h6' className="d-flex pt-4 justify-content-center font-weight-bold  h-auto">
                                Shopping cart is Empty!
                            </Typography>
                            <div className="d-flex justify-content-center pt-5">
                                <div>
                                    <Button onClick={() => goBack('/products')} variant="outlined" className="text-capitalize mr-2" >Continue Shopping</Button>
                                </div>
                                <div>
                                    <Button onClick={() => redirectTo('/order/history')} variant="contained" className="text-capitalize ml-2" >Go to Order History</Button>
                                </div>
                            </div>
                        </div> : null
                } */}
                {getTheApplicationTheme().includes('STANDARD') ?
                    <div className='w-100'>
                        <Grid item xs={12} sm={12} lg={12} md={12} className={classes.grid}>
                            {/* {
                    cartProducts 
                } */}
                            {/* {cartProducts && cartProducts.size && cartProducts?.size > 0 ?
                        < div >
                            <ShoppingCartHeader {...props} />
                        </div> : null
                    } */}
                            {
                                cartProducts && cartProducts?.map((prod, index) => {
                                    return <div key={index} className="mb-2 mb-2">
                                        <ShoppingCartItem
                                            onItemDelete={() => openDeleteDialog(prod)}
                                            image={getImageUrl(prod?.get('data')?.get('pictures')?.get(0)?.get('image_original_url'))}
                                            productName={prod?.get('data')?.get('name')}
                                            discountAmount={prod?.get('data')?.get('promotion') && prod?.get('data')?.get('promotion').get('promotion_amount') ? prod?.get('data').get('cost_after_discount') : ''}
                                            price={prod?.get('data')?.get('cost') && formatAmountV1(prod?.get('data')?.get('cost'))}
                                            // description={renderHtml(prod?.get('data')?.get('description'))}
                                            quantity={prod?.get('data')?.get('quantity')}
                                            onQuantityChange={(qty) => onQuantityChange(prod, qty)}
                                            {...props}
                                            redirectTo={redirectTo}
                                            product={prod?.get('data')}
                                            totalPrice={`${getTotalPrice(prod)} ${getCurrency()}`} />
                                    </div>
                                })
                            }

                            {
                                window.INDEX_NOKIA_ARENA === true &&
                                <button
                                    onClick={() => {
                                        window.history.back();
                                    }}
                                    style={{ border: "none", marginTop: "15px", display: "flex", alignItems: "center", margin: "10px 0 10px auto", borderRadius: "10px", padding: "5px 10px" }}>+ {t("messages.addMore")}</button>
                            }
                        </Grid>
                        <div style={isTypographyStyle
                            ? { background: "#F8F8F8", border: "1px solid #F7F7F7", padding: "10px", borderRadius: "10px" }
                            : {}
                        }>
                            {totalTaxAmount > 0 ?
                                <Grid item xs={12} sm={12} lg={12} md={12}
                                    className={`d-flex  ${isTypographyStyle ? 'justify-content-between w-100' : 'justify-content-end'}`}
                                >
                                    <div className={`d-flex justify-content-between pt-3 pb-3 w-67 ${isTypographyStyle ? 'w-100' : ''}`} >
                                        <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                                            component="div" color='dark.main' className={` pr-2  ${isTypographyStyle ? 'font-weight-normal' : 'font-weight-bold'}`} style={isTypographyStyle
                                                ? { fontSize: "14px" }
                                                : {}}>
                                            Tax
                                        </Typography>
                                        <Typography color='primary' variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                                            component="div" className={` pr-2  ${isTypographyStyle ? 'font-weight-normal' : 'font-weight-bold'}`} style={isTypographyStyle
                                                ? { color: "#000", fontSize: "14px" }
                                                : {}
                                            }>
                                            {`${formatAmountV1(totalTaxAmount)} ${getCurrency()}`}
                                        </Typography>
                                    </div>
                                </Grid> : null
                            }
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Divider variant="middle" orientation='horizontal' />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} md={12} className={`d-flex  ${isTypographyStyle ? 'justify-content-between w-100' : 'justify-content-end'}`}>
                                <div className={`w-67 d-flex justify-content-between pt-3 pb-3 ${isTypographyStyle ? 'w-100' : ''}`}>
                                    <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                                        component="div" color='dark.main' className='font-weight-bold' style={isTypographyStyle
                                            ? { fontSize: "14px" }
                                            : {}}>
                                        Total
                                    </Typography>
                                    <Typography color='primary' variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                                        component="div" className='font-weight-bold pr-2' style={isTypographyStyle
                                            ? { fontSize: "14px", color: "#000" }
                                            : {}}>
                                        {`${formatAmountV1(totalPrice)} ${getCurrency()}`}
                                    </Typography>
                                </div>
                            </Grid>
                        </div>
                    </div>
                    : getTheApplicationTheme() === 'PROMINENT' ?
                        <div className='w-100 mt-3 mb-3'>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                {cartProducts && cartProducts?.get('0') && cartProducts?.get('0').get('data') && cartProducts?.get('0').get('data').get('merchant_name') ?
                                    <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && "h6", (mobileWidth || tabletWidth) && 'body1')} component="div"
                                        className={clsx('font-weight-bold text-center')} >
                                        {`Order ${cartProducts?.get('0').get('data').get('merchant_name')}`}
                                    </Typography>
                                    : null}
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} md={12} className={clsx(classes.grid, 'pt-2')}>
                                {/* {
                    cartProducts 
                } */}
                                {/* {cartProducts && cartProducts.size && cartProducts?.size > 0 ?
                        < div >
                            <ShoppingCartHeader {...props} />
                        </div> : null
                    } */}
                                {
                                    cartProducts && cartProducts?.map((prod, index) => {
                                        return <div key={index} className="mb-2 mb-2">
                                            <ShoppingCartItem
                                                onItemDelete={() => openDeleteDialog(prod)}
                                                image={getImageUrl(prod?.get('data')?.get('pictures')?.get(0)?.get('image_original_url'))}
                                                productName={`${prod?.get('data')?.get('name')} (${prod?.get('data')?.get('quantity')} x ${prod?.get('data')?.get('cost') && formatAmountV1(prod?.get('data')?.get('cost'))})`}
                                                discountAmount={prod?.get('data')?.get('promotion') && prod?.get('data')?.get('promotion').get('promotion_amount') ? prod?.get('data').get('cost_after_discount') : ''}
                                                // price={prod?.get('data')?.get('cost') && formatAmountV1(prod?.get('data')?.get('cost'))}
                                                // description={renderHtml(prod?.get('data')?.get('description'))}
                                                quantity={prod?.get('data')?.get('quantity')}
                                                onQuantityChange={(qty) => onQuantityChange(prod, qty)}
                                                {...props}
                                                redirectTo={redirectTo}
                                                product={prod?.get('data')}
                                                totalPrice={`${getTotalPrice(prod)} ${getCurrency()}`} />
                                        </div>
                                    })
                                }
                            </Grid>
                            {totalTaxAmount > 0 ?
                                <Grid item xs={12} sm={12} lg={12} md={12} className='d-flex justify-content-end'>
                                    <div className='w-67 d-flex justify-content-between pt-3 pb-3'>
                                        <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                                            component="div" color='dark.main' className='font-weight-bold' >
                                            Tax
                                        </Typography>
                                        <Typography color='primary' variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                                            component="div" className='font-weight-bold pr-2' >
                                            {`${formatAmountV1(totalTaxAmount)} ${getCurrency()}`}
                                        </Typography>
                                    </div>
                                </Grid> : null
                            }
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <Divider variant="middle" orientation='horizontal' />
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12} md={12} className='d-flex justify-content-end'>
                                <div className='w-100 d-flex justify-content-between pt-3 pb-3'>
                                    <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                                        component="div" color='dark.main' className='font-weight-bold' >
                                        Total
                                    </Typography>
                                    <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                                        component="div" className='font-weight-bold pr-2' >
                                        {`${formatAmountV1(totalPrice)} ${getCurrency()}`}
                                    </Typography>
                                </div>
                            </Grid>
                        </div>
                        : null
                }
                {
                    execStatusCreateInvoice && execStatusCreateInvoice.status === "ERROR" &&
                        execStatusCreateInvoice?.errorDetail?.get('message')
                        && typeof execStatusCreateInvoice?.errorDetail?.get('message') === 'object' &&
                        cartProducts ?
                        <ErrorMessageDialog
                            {...props}
                            open={true} /> : null
                }
                {/* {
                <Grid item xs={12} sm={12} lg={3} md={3.3} >
                    <div className="pt-4 mt-3">
                        <SummaryBox redirecto={redirectTo}
                            totalItems={cartProducts?.size}
                            orderTotalPrice={totalPrice}
                            buttonTitle="Continue Shopping"
                            buttonTitle2="Proceed to Checkout"
                            {...props} />
                    </div>
                </Grid>
            } */}
            </Grid>
        </SiteContentWrapper >
    )
}
export default ShoppingCart