import './order.card.scss';
import React from 'react'
import { Typography, CardContent, CardMedia, Paper, Button } from '@mui/material';
import { formatAmountV1 } from 'components/common/util/util';
import { useStyle } from './order.card.style';
const OrderCard = (props) => {
    const classes = useStyle()
    const { image, description, noOfItems, cost, deliveredDate ,orderId } = props;

    return (
        <div id="product-box" className='w-100 h-100'>
            <div className="w-100 border p-1 card-style h-100" >
                <Paper
                    elevation={0}
                    classes={{ root: classes.paper }}
                    className="w-100 paper-root2 h-60 rounded"
                >
                    <div className='d-flex justify-content-center'>
                        <CardMedia
                            component="img"
                            image={image}
                            alt="zapp-app-logo"
                            classes={{root: classes.cardMedia}}
                            className="rounded-md"
                        />
                        {/* <div>
                            <Paper className='rounded-md orderID bg-white border px-1 position-absolute' elevation={0}>
                                <Typography variant='subtitle2' className="  text-capitalize">
                                    Order ID:<b>{orderId}</b>
                                </Typography>

                            </Paper>
                        </div> */}

                    </div>
                </Paper>
                <CardContent className="d-flex align-items-end h-40 pb-1 px-2 pt-3 ">
                    <div className='w-100'>
                        <div>
                            <Typography variant="body1" className="text-ellipsis-clamp-2  font-weight-bold text-capitalize">
                                {description ? description : ""}
                            </Typography>

                            <div className=''>
                                <Typography variant='subtitle2' className=" text-muted  text-capitalize">
                                    <span>
                                        {
                                            noOfItems ? noOfItems + ' item(s)' : ''
                                        }
                                    </span>
                                    <span>
                                        {cost ? ' Rs ' + formatAmountV1(cost) : ""}
                                    </span>
                                </Typography>
                            </div>
                            <div className='w-100'>
                                <div className='d-flex  align-items-center justify-content-between w-100'>
                                    <Typography variant="body1" className="  font-weight-bold text-capitalize">
                                        {deliveredDate}
                                    </Typography>
                                    {/* <Button className='px-3 orderBtn width-max-content text-capitalize' size="small">
                                        Re order
                                    </Button> */}

                                </div>

                            </div>

                        </div>
                    </div>
                </CardContent>
            </div>
        </div>
    )
}

export default OrderCard