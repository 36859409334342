//import { pink } from '@mui/material/colors'
// export const primaryColor = "#622F8C";#380E7F
// export const primaryColor = "#380E7F";
// export const secondaryColor = "#D64795";
export const primaryColor = window.PRIMARY_COLOR ? window.PRIMARY_COLOR : '#1558ce'
export const secondaryColor = window.SECONDARY_COLOR ? window.SECONDARY_COLOR : "#002368"
export const ternaryColor = window.TERNARY_COLOR ? window.TERNARY_COLOR : "#002368"; //#BF1F94 
export const counterButtonColor = window.INDEX_COUNTER_BUTTON_COLOR ? window.INDEX_COUNTER_BUTTON_COLOR : "#DDF6E5"; //#BF1F94 
export const filterButtonColor = window.INDEX_FILTER_BUTTON_COLOR ? window.INDEX_FILTER_BUTTON_COLOR : "#d8e9f9"; //#BF1F94 
export const counterButtonHover = window.INDEX_COUNTER_BUTTON_HOVER_COLOR ? window.INDEX_COUNTER_BUTTON_HOVER_COLOR : "#d7efde"; //#BF1F94 
export const tagColor = "#e1180e";
export const darkColor = "#000";
export const bannerColor = "#d9d9d9";
export const lightBackgroundColor = "#f7f7f7";
export const gradientColor = "linear-gradient(-180deg,#CB1C91,#622F8C)";
export const purpleColor = "#741464";
export const lightPinkColor = "#F9DEEF";
export const darkPinkColor = "#ffd5f0";
export const greyColor = '#B9B9B9';
export const darkBlueColor = '#6F6DC3';
export const lightBlueColor = '#EDF2FE';
export const gradientColor2 = "linear-gradient(-180deg,#D12194,#270843)";
export const gradientColor3 = "linear-gradient(111.84deg, #1558ce 7.29%, #002368 94.24%)";
export const secondaryColor2 = '#002368';

//THEME_COLOR_CHANGES #82156f
export const materialThemeBase = {
  typography: {
    fontFamily: ['Roboto'].join(','),
    h3: {
      fontFamily: 'Montserrat-SemiBold',
      fontWeight: 600,
      fontSize: 40,
      lineHeight: '48px'
    },
    h4: {
      fontFamily: 'Montserrat-SemiBold',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '40px'
    },
    h5: {
      fontFamily: 'Montserrat-Bold',
      fontWeight: 600,
      fontSize: 28,
      lineHeight: '32px'
    },
    h6: {
      fontFamily: 'Montserrat-SemiBold',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '32px'
    },
    subtitle24M5: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: '32px',
      letterSpacing: '-0.0075em'
    },
    subtitle18M5: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: '-0.0075em'
    },
    subtitle16N5: {
      fontFamily: 'Nunito',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
    },
    subtitle20M7: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '24px'
    },
    subtitle20M6: {
      fontFamily: 'Montserrat-SemiBold',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '24px'
    },
    subtitle: {
      fontFamily: 'Montserrat-SemiBold',
      fontSize: 18,
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '24px'
    },
    subtitle11: {
      fontFamily: 'Montserrat-Bold',
      fontSize: 16,
      fontWeight: 600,
      fontStyle: 'normal',
    },
    subtitle16SB: {
      fontFamily: 'Montserrat-SemiBold',
      fontSize: 16,
      fontWeight: 600,
      fontStyle: 'normal',
    },
    subtitle14M6: {
      fontFamily: 'Montserrat-Light',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px'
    },
    subtitle12: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 400,
      fontStyle: 'normal',
    },
    subtitle14M4: {
      fontFamily: 'Montserrat',
      fontSize: 14,
      fontWeight: 400,
      fontStyle: 'normal',
    },
    subtitle28M4: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 28,
      lineHeight: '32px'
    },
    subtitle20M4: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '24px'
    },
    subtitle18M4: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 18,
      lineHeight: '24px'
    },
    subtitle23M4: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 23,
      lineHeight: '28px',
    },
    subtitle16M3: {
      fontFamily: 'Montserrat',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '24px'
    },
    subtitle35N6: {
      fontFamily: 'Nunito',
      fontWeight: 600,
      fontSize: 35,
      lineHeight: '24px'
    },
    subtitle28N4: {
      fontFamily: 'Nunito',
      fontWeight: 400,
      fontSize: 28,
      lineHeight: '24px'
    },
    subtitle25N4: {
      fontFamily: 'Nunito',
      fontWeight: 400,
      fontSize: 25,
      lineHeight: '24px'
    },
    body18NL: {
      fontFamily: 'Nunito-Light',
      fontWeight: 300,
      fontSize: 18,
      lineHeight: '24px'
    },
    body16NL: {
      fontFamily: 'Nunito-Light',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '24px'
    },
    body1: {
      fontFamily: 'Nunito',
      fontWeight: 300,
      fontSize: 18,
      lineHeight: '24px'
    },
    body2: {
      fontFamily: 'Nunito',
      fontWeight: 300,
      fontSize: 16,
      lineHeight: '24px'
    },
    body3: {
      fontFamily: 'Nunito',
      fontWeight: 300,
      fontSize: 14,
      lineHeight: '20px'
    },
    caption: {
      fontFamily: 'Nunito',
      fontWeight: 300,
      fontSize: 12,
      lineHeight: '20px'
    }
  },
  palette: {
    primary: {
      main: primaryColor,
      extraDark: '#290846'
      //extraLight: pink[200],
      //darkLight : "#701367"
    },
    secondary: {
      main: secondaryColor,
      // dark:"",
      // light:""
    },
    secondaryColor2: {
      main: secondaryColor2
    },
    dark: {
      main: darkColor,
      light: '#212121'
    },
    danger: {
      main: tagColor
    },
    bannerColor: {
      main: bannerColor,
      dark: '#B9B9B9',
      light: '#f3f3f3'
    },
    ternary: {
      main: ternaryColor,
      light: window.TERNARY_COLOR_LIGHT ? window.TERNARY_COLOR_LIGHT : '#c5d0ec'
    },
    lightBackgroundColor: {
      main: lightBackgroundColor,
      dark: '#A5A5A5',
      light: '#EEEEEE',
      light1: '#E5E5E5'
    },
    gradientColor2: {
      main: gradientColor2
    },
    gradientColor: {
      main: gradientColor
    },
    purpleColor: {
      main: purpleColor,
      normal: '#1558ce'
    },
    lightPinkColor: {
      main: lightPinkColor
    },
    darkPinkColor: {
      main: darkPinkColor
    },
    greyColor: {
      main: greyColor,
      dark: '#666666'
    },
    lightBlueColor: {
      main: lightBlueColor
    },
    darkBlueColor: {
      main: darkBlueColor,
      light: '#8488D9'
    },
    gradientColor3: {
      main: gradientColor3
    },
    counterButtonColor: {
      main: counterButtonColor,
      dark: counterButtonHover
    },
    filterButtonColor:{
      main: filterButtonColor,
      dark:filterButtonColor,
    }
  }
  // shadows: Array(25).fill("none")
}
