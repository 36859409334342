// Async Indexed DB functions 

export function insertDataIntoIndexedDB(key, data) {
    if (window.siteIndexedDB) {
        return new Promise((resolve, _reject) => {
            let objStore = window.siteIndexedDB.transaction(['siteIndexedDBStore'], 'readwrite')
                .objectStore('siteIndexedDBStore')
            let request = objStore && objStore.add({ id: key, data });
            request.onsuccess = function (_event) {
                resolve({
                    insertedSuccessfully: true
                })
                if (window.TRANSPORT_SERVICE === true) {
                    objStore.count().onsuccess = (event) => {
                        const totalRecords = event.target.result;
                        // If the total exceeds 70, delete the older record
                        if (totalRecords > 71) {
                            const deleteRequest = objStore.openCursor();

                            deleteRequest.onsuccess = (e) => {
                                const cursor = e.target.result;

                                if (cursor) {
                                    // Delete the older record
                                    objStore.delete(cursor.primaryKey);
                                }
                            };
                        }
                    };
                }
            };
            request.onerror = function (event) {
                resolve({
                    error: {
                        message: "IndexedDb Operation failed"
                    }
                })
            }
        })
    }
}

export function readDataFromIndexedDB(key) {
    if (window.siteIndexedDB) {
        let objectStore = window.siteIndexedDB.transaction(['siteIndexedDBStore']).objectStore('siteIndexedDBStore');
        return new Promise((resolve, _reject) => {
            let request = objectStore.get(key);
            request.onsuccess = function (_event) {
                if (request.result) {
                    resolve(request.result);
                    // console.log(request.result);
                } else {
                    // console.log('No data record');
                }
            };
            request.onerror = function (event) {
                resolve({
                    error: {
                        message: "IndexedDb Operation failed"
                    }
                })
            }
        })
    }
}
export function readAllDataFromIndexedDB() {
    if (window.siteIndexedDB) {
        let objectStore = window.siteIndexedDB.transaction(['siteIndexedDBStore']).objectStore('siteIndexedDBStore');
        return new Promise((resolve, _reject) => {
            objectStore.getAll().onsuccess = function (event) {
                resolve({
                    response: event.target.result
                })
            };
            objectStore.getAll().onerror = function (event) {
                resolve({
                    error: {
                        message: "IndexedDb Operation failed"
                    }
                })
            };
        })
    }
}
export function deleteAllDataFromIndexedDb() {
    if (window.siteIndexedDB) {
        let indexedDbName = ''
        if (window.TRANSPORT_SERVICE === true) {
            indexedDbName = "transport-service-indexed-db"
        }
        else {
            indexedDbName = "zapp-indexed-db"
        }
        var DBOpenRequest = window.indexedDB.open(indexedDbName, 1);
        return new Promise((resolve, _reject) => {
            DBOpenRequest.onsuccess = function (event) {
                let db = DBOpenRequest.result;
                var transaction = db.transaction(["siteIndexedDBStore"], "readwrite");
                let objectStore = transaction.objectStore('siteIndexedDBStore');
                transaction.oncomplete = function (_event) {

                }
                var objectStoreRequest = objectStore.clear();
                objectStoreRequest.onsuccess = function (_event) {
                    resolve({
                        allDeletedSuccessfully: true
                    })
                };
                objectStoreRequest.onerror = function (_event) {
                    resolve({
                        error: {
                            message: "IndexedDb Operation failed"
                        }
                    })
                };
            };
            DBOpenRequest.onerror = function (event) {
                resolve({
                    error: {
                        message: "IndexedDb Operation failed"
                    }
                })
            }
        })
    }
}


export function deleteDataFromIndexedDB(key) {
    if (window.siteIndexedDB) {
        return new Promise((resolve, _reject) => {
            let request = window.siteIndexedDB.transaction(['siteIndexedDBStore'], 'readwrite')
                .objectStore('siteIndexedDBStore')
                .delete(key);
            request.onsuccess = function (_event) {
                resolve({
                    deletedSuccessfully: true
                })
                // console.log(key);
                // console.log('The data has been deleted successfully');
            };
            request.onerror = function (_event) {
                resolve({
                    error: {
                        message: "IndexedDb Operation failed"
                    }
                })
            };
            // request.onerror = function (event) {
            //     error();
            //     // console.log('The data has been updated failed');
            // }
        })
    }
}

export function updateDataFromIndexedDB(key, data) {
    if (window.siteIndexedDB) {
        return new Promise((resolve, _reject) => {
            let request = window.siteIndexedDB.transaction(['siteIndexedDBStore'], 'readwrite')
                .objectStore('siteIndexedDBStore')
                .put({ id: key, data });
            request.onsuccess = function () {
                resolve({
                    updatedSuccessfully: true
                })
            };
            request.onerror = function (event) {
                resolve({
                    error: {
                        message: "IndexedDb Operation failed"
                    }
                })
            }
        })
    }
}

export function checkExistingIndexDBKey(key, _resolve, _reject) {
    if (window.siteIndexedDB) {
        return new Promise((resolve, _reject) => {
            let request = window.siteIndexedDB.transaction(['siteIndexedDBStore']).objectStore('siteIndexedDBStore').getKey(key);
            request.onsuccess = function (_event) {
                // console.log('Data Found');
                if (request.result) {
                    // console.log(request.result);
                    resolve({
                        isExist: true
                    });
                } else {
                    // console.log('Does not exist');
                    resolve({
                        isExist: false
                    });
                }
            };
            request.onerror = function (_event) {
                resolve({
                    error: {
                        message: "IndexedDb Operation failed"
                    }
                })
            };
        })
    }
}

export function initializeIndexedDb() {
    let indexedDbName = ''
    if (window.TRANSPORT_SERVICE === true) {
        indexedDbName = "transport-service-indexed-db"
    }
    else {
        indexedDbName = "zapp-indexed-db"
    }
    let request = window.indexedDB.open(indexedDbName, 1);  // new database created with version 1 
    return new Promise((resolve, _reject) => {
        request.onsuccess = function (event) {
            // console.log('onsuccess');
            window.siteIndexedDB = event.target.result; // === request.result
            resolve();
        };
        request.onerror = function (_event) {
            console.log('onerror indexeddb');
            window.siteIndexedDB = null;
            resolve();
        };
        request.onupgradeneeded = function (event) {
            // console.log('onupgradeneeded');
            window.siteIndexedDB = event.target.result;
            let objectStore = window.siteIndexedDB.createObjectStore('siteIndexedDBStore', { keyPath: 'id' });
            objectStore.createIndex('id', '', { unique: false });
            //resolve();
        };
    })

}