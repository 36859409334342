import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    cart_header: {
        backgroundColor: theme.palette.bannerColor.main,
    },
    imgSize: {
        boxShadow: "none !important",
        width: "50px",
        minHeight: "40px"
    },
    grid: {
        // maxHeight: '200px !important',
        // overflow: 'auto'
    },
    iconColor: {
        backgroundColor: theme.palette.bannerColor.main,
    },
    btnStyle: {
        textTransform: 'none'
    },
    iconColor: {
        backgroundColor: theme.palette.background.paper,
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '12px !important',
        boxShadow: '0px 4px 20px -2px rgba(50, 50, 71, 0.02) !important',
        "&:hover": {
            backgroundColor: theme.palette.background.paper
        }
    },
    iconDiv: {
        backgroundColor: '#F8F8F8'
    },
    gridStyle: {
        background: '#FFFFFF',
        boxShadow: '0px 4px 20px -2px rgba(50, 50, 71, 0.04)',
        borderRadius: '17px'
    },
    linkStyle: {
        textDecoration: 'underline',
        textTransform: 'none !important'
    },
    btnStyle: {
        background: theme.palette.dark.main,
        color: theme.palette.background.paper,
        textTransform: 'none',
        '&:hover': {
            background: theme.palette.dark.light,
            color: theme.palette.background.paper
        }
    },
    btnStyle1: {
        textTransform: 'none !important',
    },
    // btnStyleBack:{
    //     height: '45px'
    // },
    disabledStyle: {
        color:'rgba(0, 0, 0, 0.26)'
    }
}));