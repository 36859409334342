// The initial state of the App
import { fromJS } from 'immutable';
import { GET_USER_VOUCHERS, RESET_USER_VOUCHERS, RE_SEND_OTP, RESET_RESEND_OTP } from '../actions/user.actions';
import { getActionName } from './util';

const initialState = fromJS({
    userVouchers: '',
    resendOtpData: ''
});
function userReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USER_VOUCHERS:
            return state.set('userVouchers', '')
        case RESET_USER_VOUCHERS:
            return state.set('userVouchers', '')
        case RESET_RESEND_OTP:
            return state.set('resendOtpData', '')
        case getActionName(GET_USER_VOUCHERS):
            return state.set('userVouchers', fromJS(action.response))
        case getActionName(RE_SEND_OTP):
            return state.set('resendOtpData', fromJS(action.response))
        default:
            return state;
    }
}
export default userReducer;
