import React, { useRef, useEffect, useState } from 'react';
// import Header from './components.main/header/header'
//import SideBar from './components.main/sidebar/sidebar'
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import AppNavbar from 'components/common/app.navbar/app.navbar'
import Footer from './components.main/footer/footer';
import Container from '@mui/material/Container';
import { onMessage } from "firebase/messaging";
import {
    NOTIFICATION_ORDER_RECEIVED, NOTIFICATION_PRODUCT_UPDATE, NOTIFICATION_ORDER_DISPATCHED,
    NOTIFICATION_ORDER_DELIVERED, NOTIFICATION_ORDER_CONFIRMD, NOTIFICATION_ORDER_CANCELLED,
    NOTIFICATION_ZAPPMAN_PICKEDUP_ORDER, NOTIFICATION_ZAPPMAN_ARRIVED_TO_PICKUP_ORDER,
    NOTIFICATION_WALLET_RIDER_STARTS_JOB, NOTIFICATION_LOGGED_OUT, NOTIFICATION_LOGGED_OUTT
} from "components/common/util/util";
import { messaging, onMessageListener } from "../../firebase";
import { SERVER_P20_URL } from '../../view.updater/middlewares/util/api.config';
import { fromJS } from 'immutable';
import TransportNavigationPanel from '../../components/common/transport.navigation.panel/transport.navigation.panel';
import AppSplash from '../../components/common/app.splash/app.splash';

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        // height: '100vh',
        // overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar
}));

const Main = props => {
    const { appProperties, location, auth } = props;
    const sseInterval = useRef(null)
    const [state, setState] = useState({
        showSite: window.INDEX_SHOW_SPLASH === true ? false : true
    })
    useEffect(() => {
        const timer = setTimeout(() => {
            setState({ ...state, showSite: true });
        }, 2000);

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timer);
    }, []); // Depend on `count` to reset the timer when `count` changes

    const classes = useStyles();
    useEffect(() => {
        if (props.auth && !props.auth.get('user')) {
            //props.push('/home');
        }
        else if (!props.auth) {
            // props.push('/home');
        }
        //FIREBASE_MESSAGING_CHANGES
        const fcmChannel = new BroadcastChannel("fcm-channel");
        fcmChannel.onmessage = (message) => {
            onFirebaseNotifcationReceived(message)
            //FIREBASE_MESSAGES_LISTEN
        };
        if (auth && auth.get('user')) {

            if (window.sse) {
                //window.sse.close();
                //opeSSEConnetion();
            }
            else {
                opeSSEConnetion();
                if (sseInterval.current === null) {
                    sseInterval.current = setInterval(opeSSEConnetion, 30000)
                }
            }
        }
    }, [props.auth]);
    useEffect(() => {
        if (window.TRANSPORT_SERVICE === true) {
            if (window.location.href.includes("confirm-booking")) {

            }
            else if (window.location.href.includes("stripe/payment-success")) {

            }
            else if (window.location.href.includes("transport/about-us")) {

            }
            else if (window.location.href.includes("transport/privacy-policy")) {

            }
            else if (window.location.href.includes("terms")) {

            }
            else {
                props.push('/transport/transport-start-journey');
            }
        }
        document.getElementById('main-content-children') &&
            document.getElementById('main-content-children').addEventListener('touchmove', function (event) {
                if (event.target.className && event.target.className.includes('Slider')) {
                    return
                }
                event.stopPropagation();
            });
        return () => {
            if (window.sse) {
                window.sse.close();
                window.sse = undefined;
                clearInterval(sseInterval && sseInterval.current);
            }
        }
    }, [])
    const opeSSEConnetion = () => {
        if (window.sse) {
            window.sse.close();
            window.sse = undefined;
        }
        if (auth && auth.get('user') && auth.get('user').get('session_ktb_token')) {
            if (window.TRANSPORT_SERVICE === true) {

            }
            else {
                window.sse = new EventSource(`${SERVER_P20_URL}/user/sse?x-p3-mwallet-session-token=${auth && auth.get('user') && auth.get('user').get('session_ktb_token')}&x-udid=${window.localStorage.getItem("visitorId")}_1`);
            }
        }
        // window.sse.addEventListener(window.localStorage.getItem("visitorId"), function(e) {
        //     debugger
        // })
        // function getRealtimeData(data) {
        //     debugger
        //     // process the data here,
        //     // then pass it to state to be rendered
        // }
        window.sse && window.sse.addEventListener('message', function (e) {
            const dt = JSON.parse(e.data);
            if (dt) {
                if (dt.type === "SSE_NETS_PAYMENT_SUCCESSFULL" && dt.invoiceQueryId) {
                    props.updateNetaXeptPayment({
                        status: dt.type,
                        invoiceQueryId: dt.invoiceQueryId,
                        merchantQueryId: dt.merchantQueryId
                    })
                }
                else if (dt.type === "SSE_SONERI_PAYMENT_SUCCESSFULL" && dt.invoiceQueryId) {

                }
                else {
                    props.updateCheckoutInvoice({
                        status: dt.status,
                        query_id: dt.invoiceQueryId
                    })
                }
            }
            // receives: 'simple event'
        }, false);
        window.sse && window.sse.addEventListener(`${window.localStorage.getItem("visitorId")}_1`, function (e) {
            //receives "10" and "30" 
        }, false);
        //sse.onmessage = e => getRealtimeData(JSON.parse(e.data));
        if (window.sse) {
            window.sse.onerror = (error) => {
                // error log here
                window.sse.close();
                window.sse = undefined;
                opeSSEConnetion();
            }
        }

    }
    const handleServiceWorkerMessage = (event) => {
        // Handle the message received from the service worker
        const message = event.data;
        if (message.type === "SERVICE_WORKER_REGISTERED") {
            opeSSEConnetion();
        }
        else {
            const dt = JSON.parse(message.data)
            if (dt) {
                if (dt.type === "SSE_NETS_PAYMENT_SUCCESSFULL" && dt.invoiceQueryId) {
                    props.updateNetaXeptPayment({
                        status: dt.type,
                        invoiceQueryId: dt.invoiceQueryId,
                        merchantQueryId: dt.merchantQueryId
                    })
                }
                else if (dt.type === "SSE_SONERI_PAYMENT_SUCCESSFULL" && dt.invoiceQueryId) {

                }
                else {
                    props.updateCheckoutInvoice({
                        status: dt.status,
                        query_id: dt.invoiceQueryId
                    })
                }
            }
            console.log('Received message from service worker:', message);

        }

    }
    const showNotification = (data) => {
        if (data) {
            if (data.module_code == NOTIFICATION_ORDER_RECEIVED) {
                props.updateCheckoutInvoice({
                    status: data.module_type,
                    query_id: data.query_id
                })
            }
            else if (data.module_code == NOTIFICATION_ORDER_DISPATCHED) {
                props.updateCheckoutInvoice({
                    status: data.module_type,
                    query_id: data.query_id
                })
            }
            else if (data.module_code == NOTIFICATION_ORDER_DELIVERED) {
                props.updateCheckoutInvoice({
                    status: data.module_type,
                    query_id: data.query_id
                })
            }
            else if (data.module_code == NOTIFICATION_ORDER_CONFIRMD) {
                props.updateCheckoutInvoice({
                    status: data.module_type,
                    query_id: data.query_id
                })

            }
            else if (data.module_code == NOTIFICATION_ORDER_CANCELLED) {
                props.updateCheckoutInvoice({
                    status: data.module_type,
                    query_id: data.query_id
                })

            }
            else if (data.module_code == NOTIFICATION_LOGGED_OUT) {

            }
            else if (data.module_code == NOTIFICATION_LOGGED_OUTT) {

            }
            else if (data.module_code == NOTIFICATION_ZAPPMAN_PICKEDUP_ORDER) {

            }
            else if (data.module_code == NOTIFICATION_ZAPPMAN_ARRIVED_TO_PICKUP_ORDER) {

            }
            else if (data.module_code == NOTIFICATION_WALLET_RIDER_STARTS_JOB) {

            }
            else if (data.module_code == NOTIFICATION_PRODUCT_UPDATE) {

            }
            else {

            }
        }
    }
    const onCheckOut = () => {
        props.push('/checkout/cart-summary');
    }
    const onFirebaseNotifcationReceived = (message) => {
        //FIREBASE_MESSAGING_CHANGES
        if (message && message.data && message.data.data) {
            showNotification(message.data.data)
        }
        else if (message && message.data) {
            showNotification(message.data)
        }
    }
    onMessage(messaging, (payload) => {
        onFirebaseNotifcationReceived(payload)
    });
    //FIREBASE_MESSAGING_CHANGES
    // onMessageListener()
    //     .then((payload) => {
    //         onFirebaseNotifcationReceived(payload)
    //         //FIREBASE_MESSAGES_LISTEN
    //     })
    //     .catch((err) => {
    //         console.log('failed: ', err)
    //     });
    if ('serviceWorker' in navigator) {
        // window.navigator.serviceWorker.addEventListener('message', event => {
        //   console.log("message listner is called") // NOT_IN_USE AND not work as expected.

        //   //console.log(event.data.msg, event.data.url);
        // });
    }
    if ('serviceWorker' in navigator && navigator.serviceWorker.addEventListener) {
        console.log('service worker add event listener is called')
        const serviceWorkerMessageHandler = handleServiceWorkerMessage;
        navigator.serviceWorker.removeEventListener('message', serviceWorkerMessageHandler);
        navigator.serviceWorker.addEventListener('message', serviceWorkerMessageHandler);
    }

    return (
        <div className="app">
            {/* <AppNavbar {...props} onCheckOut={onCheckOut} /> */}
            <div id="main-content-children" className="app-body d-flex flex-column">

                {window.INDEX_SHOW_SPLASH === true && !state.showSite &&
                    <AppSplash />
                }
                {
                    window.SITE_TEMPORARY_DISABLE === true ?
                        <div className='d-flex justify-content-center pt-5 pb-5 mt-5 mb-5'>
                            <h1> {window.SITE_TEMPORARY_DISABLE_MESSAGE ? window.SITE_TEMPORARY_DISABLE_MESSAGE : "We are currently unavailable"} </h1>
                        </div> :
                        <div>
                            {/* <SideBar location={location} setAppProperties={props.setAppProperties} appProperties={appProperties} /> */}
                            {/* <Header setAppProperties={props.setAppProperties} appProperties={appProperties} /> */}
                            <Container maxWidth={window.TRANSPORT_SERVICE === true ? 'xxl' : 'xl'} className="app pl-0 pr-0">
                                <main className={clsx(classes.content, '')}>
                                    {/* <div className={classes.appBarSpacer} /> */}
                                    {
                                        auth && auth.get('user') && state.showSite === true ?
                                            props.children : window.TRANSPORT_SERVICE === true ? props.children :
                                                null
                                    }
                                    {
                                        window.TRANSPORT_SERVICE === true ?
                                            <TransportNavigationPanel /> : null
                                    }
                                </main>
                            </Container>
                            {/* <div className='pt-5'>
                        <Footer {...props} />
                    </div> */}
                        </div>
                }
            </div>
        </div>
    )
}
export default connect(null, { push })(Main);