import React, { useEffect, useState, useRef } from 'react'
import { Grid, CircularProgress, Typography } from '@mui/material'
//import OrderCard from 'components/common/order.card/order.card'
import { Pagination } from '@mui/material';
import { ORDER_HISTORY_PAGE_SIZE } from 'view.updater/actions/order.actions';
import OrderHistoryCard from 'components/common/order.history.card/order.history.card';
import { getImageUrl, formatDate, scrollToRefElement, getOrderHistoryTime } from 'components/common/util/util';
import OrderHistoryDetailDialog from './order.history.detail.dialog/order.history.detail.dialog';
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import { isOutOfStock, getMessageColor } from 'components/common/util/util';
import ReOrderErrorDialog from './reorder.error.dialog/reorder.error.dialog';
import AddProductWarningDialog from "components/common/product.box/add.product.warning.dialog";
import { getQueryStringValue } from 'components/common/util/util';

export default function CancelledOrders(props) {
    const { execStatus, cancelledOrders, cartProducts, execStatusGetProductsByOrderId, execStatusAddUpdateProductToCart, orderedProducts } = props;
    const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
    const [state, setState] = useState({
        selectedPage: 1,
        openOrderHistoryDetailDialog: false,
        showReOrderErrorDialog: false,
        selectedOrder: '',
        showAddProductWarningDialog: false,
        warningMessage: ''

    })
    let cancleOrdrs = cancelledOrders && cancelledOrders.find((order) => order.get('page') == state.selectedPage);
    const ref = useRef(null)
    const onPageChange = (e, page) => {
        setTimeout(() => {
            scrollToRefElement(ref)
        }, 200)
        props.getCancelledOrders({
            page: page
        });
        setState({ ...state, selectedPage: page })
    }
    const getTotalPageCount = () => {
        if (cancleOrdrs) {
            const totalOrders = cancleOrdrs.get('orders')?.get('pagination')?.get('total_items')
            if (totalOrders) {
                return Math.ceil(totalOrders / ORDER_HISTORY_PAGE_SIZE)
            }
        }
        else return 1
    }
    const openOrderHistoryDetailDialog = (order) => {
        props.history.push(`/checkout/payment-success/${order && order.get('invoice') && order.get('invoice').get('query_id')}?showOrderDetail=true&trade_type_id=${tradeTypeId}`)
        // setState({
        //     ...state, openOrderHistoryDetailDialog: true, selectedOrder: order
        // })
    }
    const closeOrderHistoryDetailDialog = () => {
        setState({ ...state, openOrderHistoryDetailDialog: false, selectedOrder: '' })
    }
    const showReOrderErrorDialog = (order) => {
        setState({
            ...state, showReOrderErrorDialog: true,
            selectedOrder: order
        })
    }
    const hideReOrderErrorDialog = (unClear = true) => {
        if (unClear === true) {
            setState({ ...state, openOrderHistoryDetailDialog: false, selectedOrder: '' })
        }
        else {
            setState({ ...state, showReOrderErrorDialog: false })
        }
    }
    const reOrder = (order, e, forceUpdate = false) => {
        e.preventDefault();
        e.stopPropagation();
        if (order && order.get('invoice') && order.get('invoice').get('merchant') && order.get('invoice').get('merchant').get('query_id')) {
            if (order.get('invoice').get('merchant').get('shop_status') != "open") {
                showAddProductWarningDialog("Merchant is closed, please try again later")
            }
            else {
                if (forceUpdate === true) {
                    setState({
                        ...state, selectedOrder: order
                    })
                    props.resetGetProductsByOrderId();
                    if (order && order.get('invoice') && order.get('invoice').get('order_id')) {
                        props.getProductsByOrderId({
                            orderId: order.get('invoice').get('order_id')
                        })
                    }
                }
                else if (!cartProducts) {
                    setState({
                        ...state, selectedOrder: order
                    })
                    props.resetGetProductsByOrderId();
                    if (order && order.get('invoice') && order.get('invoice').get('order_id')) {
                        props.getProductsByOrderId({
                            orderId: order.get('invoice').get('order_id')
                        })
                    }
                }
                else if (cartProducts && cartProducts.size == 0) {
                    setState({
                        ...state, selectedOrder: order
                    })
                    props.resetGetProductsByOrderId();
                    if (order && order.get('invoice') && order.get('invoice').get('order_id')) {
                        props.getProductsByOrderId({
                            orderId: order.get('invoice').get('order_id')
                        })
                    }
                }
                else {
                    showReOrderErrorDialog(order)
                }

            }

        }
    }
    useEffect(() => {
        return () => {
            props.resetGetProductsByOrderId()
        }
    }, [])
    const getQuantity = (product, lastOrderQuantity) => {
        if (product && product.get('product_available_quantity') > 0) {
            if (product.get('product_available_quantity') < lastOrderQuantity) {
                return product.get('product_available_quantity')
            }
            else {
                return lastOrderQuantity
            }
        }
        else {
            return lastOrderQuantity
        }
    }
    useEffect(() => {
        if (orderedProducts && orderedProducts.size > 0 && orderedProducts.get('products') && orderedProducts.get('products').size > 0) {
            let reOrderedProducts = [];
            for (var prd of orderedProducts.get('products')) {
                if (!isOutOfStock(prd)) {
                    let selectedItem = state.selectedOrder.get('invoice').get('invoice_items').find((itm) => itm.get('product_query_id') === prd?.get("query_id"));
                    reOrderedProducts.push({
                        ...prd.toJS(),
                        quantity: getQuantity(prd, selectedItem && selectedItem.get('quantity')),
                        storeQueryId: state.selectedOrder && state.selectedOrder.get('invoice') && state.selectedOrder.get('invoice').get('merchant') ? state.selectedOrder.get('invoice').get('merchant').get('query_id') : '',
                        uniqueId: prd?.get("query_id")
                    })
                }
            }
            if (reOrderedProducts && reOrderedProducts.length > 0) {
                props.clearCartAndAddNewItems({
                    items: reOrderedProducts
                })
                props.history.push(`/checkout/cart-summary`);
            }
        }
    }, [orderedProducts])
    const getLoadingStatus = (data) => {
        if (execStatusGetProductsByOrderId.status === "PENDING" || execStatusAddUpdateProductToCart.status === "PENDING") {
            if (state.selectedOrder && state.selectedOrder.get('invoice').get('order_id') === data.get("invoice").get('order_id')) {
                return true;
            }
            else return false;
        }
        else {
            return false
        }
    }
    const showAddProductWarningDialog = (message) => {
        setState({ ...state, showAddProductWarningDialog: true, warningMessage: message })
    }
    const handleCloseAddProductWarningDialog = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setState({ ...state, showAddProductWarningDialog: false, warningMessage: '' })
    }

    return (
        <div ref={ref}>
            <Grid container spacing={2} className='pt-2'>
                {
                    cancleOrdrs && cancleOrdrs.get('orders') && cancleOrdrs.get('orders').get("invoices") && cancleOrdrs.get('orders').get("invoices").size !== 0 ?
                        cancleOrdrs.get('orders').get("invoices").map((data, i) =>
                            <Grid spacing={3} item xs={12} sm={6} md={4} lg={4} key={i}>
                                <OrderHistoryCard
                                    onClick={() => openOrderHistoryDetailDialog(data)}
                                    image={getImageUrl(`${data.get("invoice") && data.get("invoice").get("merchant") && data.get("invoice").get("merchant").get("merchant_banner") && data.get("invoice").get("merchant").get("merchant_banner")}`)}
                                    heading={data.get("invoice") && data.get("invoice").get("merchant") && data.get("invoice").get("merchant").get("name") && data.get("invoice").get("merchant").get("name")}
                                    price={data.get("invoice") && data.get("invoice").get("payble_amount")}
                                    //subText={`Cancelled ${data.get("invoice") && getOrderHistoryTime(data.get("invoice").get("status_and_time"), data.get("invoice").get("status"))}`}
                                    imageText='Cancelled'
                                // footer={
                                //     <LoaderButton
                                //         color="primary"
                                //         loading={getLoadingStatus(data)}
                                //         onClick={(e) => reOrder(data, e)}
                                //         variant="outlined"
                                //         //classes={{ root: classes.btnColor }}
                                //         classNames="w-100 text-capitalize">
                                //         Reorder
                                //     </LoaderButton>}
                                >
                                    {
                                        data?.get("invoice")?.get("invoice_items")?.map((item, index) => {
                                            return <div key={index}>
                                                {`${item?.get('quantity')}*`} {item?.get('product_name') ? item?.get('product_name') : ''}
                                            </div>
                                        })
                                    }
                                </OrderHistoryCard>
                            </Grid>
                        ) :
                        execStatus.status === "PENDING" ?
                            <div className='d-flex w-100 flex-column justify-content-center align-items-center h-60-vh'>
                                <Typography variant='body1' className="text-dark pt-2 pb-2">
                                    Loading Orders....
                                </Typography>
                                <CircularProgress />
                            </div> :
                            cancleOrdrs && cancleOrdrs.get('orders').size === 0 ?
                                <div className={`${getMessageColor()} w-100 font-weight-bold d-flex justify-content-center align-items-center h-60-vh`}>
                                    No Orders Found!
                                </div> :
                                execStatus.status === "ERROR" ?
                                    <div className="w-100">
                                        <p>{execStatus.status === "ERROR" ?
                                            <div className="d-flex pt-3 text-danger justify-content-center w-100">
                                                {execStatus?.errorDetail?.get('message')}
                                            </div> : null}</p>
                                    </div> : null
                }
            </Grid>
            {
                state.openOrderHistoryDetailDialog == true ?
                    <OrderHistoryDetailDialog
                        closeOrderHistoryDetailDialog={closeOrderHistoryDetailDialog}
                        openOrderHistoryDetailDialog={state.openOrderHistoryDetailDialog}
                        {...state}
                    /> : null
            }
            {
                state.showReOrderErrorDialog === true ?
                    <ReOrderErrorDialog
                        reOrder={reOrder}
                        selectedOrder={state.selectedOrder}
                        hideReOrderErrorDialog={hideReOrderErrorDialog}
                        isOpen={state.showReOrderErrorDialog}
                        {...props} /> : null
            }
            {
                getTotalPageCount() > 1 ?
                    <div className='pt-3 w-100 pt-5 d-flex justify-content-center pb-2'>
                        <Pagination
                            page={state.selectedPage}
                            onChange={onPageChange}
                            color="secondary"
                            count={getTotalPageCount()}
                            shape="rounded"
                        />
                    </div> : null
            }
            {
                state.showAddProductWarningDialog === true ?
                    <AddProductWarningDialog
                        open={state.showAddProductWarningDialog}
                        handleClose={handleCloseAddProductWarningDialog}>
                        <div className="pb-3 font-weight-bold text-center">
                            <div>
                                <div className='pt-3'>
                                    {state.warningMessage}
                                </div>
                            </div>
                        </div>
                    </AddProductWarningDialog> : null
            }
        </div>
    )
}
