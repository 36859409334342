import React from 'react'
import { Grid, Typography, Divider } from '@mui/material';
import { formatAmountV1, getCurrency } from 'components/common/util/util';
import ShoppingCartItem from 'components/common/shopping.cart.item/shopping.cart.item';
import clsx from "clsx";
import { useStyle } from './status.box.v3.style';
import { IsMobileWidth, IsTabletWidth, getImageUrl } from "components/common/util/util";

const StatusBoxV3 = (props) => {
    const { checkOutInvoiceState, description } = props;
    const mobileWidth = IsMobileWidth();
    const tabletWidth = IsTabletWidth();
    const classes = useStyle()
    let totalPrice = 0;
    let invoiceItems = checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && checkOutInvoiceState?.get('invoice')?.get('invoice_items')
    for (let invoiceItemProduct of invoiceItems) {
        if (invoiceItemProduct && invoiceItemProduct.get('promotion') && invoiceItemProduct.get('promotion').get('promotion_amount')) {
            totalPrice = totalPrice + invoiceItemProduct.get('cost_after_discount')// * invoiceItemProduct.get('quantity'))
        }
        else
            totalPrice = totalPrice + invoiceItemProduct.get('amount')// * invoiceItemProduct.get('quantity'))
    }

    const getTotalPrice = (prod) => {
        if (prod?.get('promotion') && prod?.get('promotion').get('promotion_amount')) {
            return formatAmountV1(prod?.get('cost_after_discount')) //prod?.get('quantity') * 
        }
        else if (prod?.get('quantity') && prod?.get('amount')) {
            return formatAmountV1(prod?.get('amount')) //prod?.get('quantity') *
        }
        else
            return ''

    }
    return (
        <Grid container className={clsx(classes.gridProminent, 'pt-3 pb-3 pl-3 pr-3 ml-0 mr-0')}>
            <Grid item xs={12} sm={12} lg={12} md={12} className='w-100 d-flex justify-content-center'>
                <img src={getImageUrl(checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && checkOutInvoiceState?.get('invoice')?.get('qr_data_url'))}
                    width={mobileWidth ? '300px' : '200px'} />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12}>
                <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && "h6", (mobileWidth || tabletWidth) && 'body1')} component="div"
                    className={clsx('font-weight-bold text-center pt-2 pb-2')} >
                    {`Order ${checkOutInvoiceState.get('invoice') && checkOutInvoiceState.get('invoice').get('merchant') &&
                        checkOutInvoiceState.get('invoice').get('merchant')?.get('name')}`}
                </Typography>
                <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && "h6", (mobileWidth || tabletWidth) && 'body1')} component="div"
                    className={clsx('font-weight-bold text-center pt-2 pb-2')} >
                    {`Location ${checkOutInvoiceState.get('invoice') && checkOutInvoiceState.get('invoice').get('merchant') &&
                        checkOutInvoiceState?.get('invoice').get('merchant').get('address') &&
                        checkOutInvoiceState.get('invoice').get('merchant').get('address')?.get('description')}`}
                </Typography>
                {
                    description ? <Typography variant={clsx(!(mobileWidth || tabletWidth) && "body3", (mobileWidth || tabletWidth) && 'body3')} className={clsx(classes.subLabel, "text-center")}>
                        {description}
                    </Typography> : null
                }
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12} className={clsx('pt-2')}>
                {
                    checkOutInvoiceState && checkOutInvoiceState?.get('invoice')?.get('invoice_items')?.map((prod, index) => {
                        return <div key={index} className="mb-2 mb-2">
                            <ShoppingCartItem
                                // onItemDelete={() => openDeleteDialog(prod)}
                                unitPrice={`${prod?.get('amount') && formatAmountV1(prod?.get('amount'))}`}
                                // image={getImageUrl(prod?.get('data')?.get('pictures')?.get(0)?.get('image_original_url'))}
                                productName={`${prod?.get('product_name')} (${prod?.get('quantity')})`}
                                discountAmount={prod?.get('data')?.get('promotion') && prod?.get('data')?.get('promotion').get('promotion_amount') ? prod?.get('data').get('cost_after_discount') : ''}
                                // price={prod?.get('amount')&& formatAmountV1(prod?.get('amount'))}
                                // description={renderHtml(prod?.get('data')?.get('description'))}
                                quantity={prod?.get('quantity')}
                                // onQuantityChange={(qty) => onQuantityChange(prod, qty)}
                                {...props}
                                // redirectTo={redirectTo}
                                // product={prod?.get('data')}
                                totalPrice={`${getTotalPrice(prod)} ${getCurrency()}`} />
                        </div>
                    })
                }
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12}>
                <Divider variant="middle" orientation='horizontal' />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} md={12} className='d-flex justify-content-end'>
                <div className='w-100 d-flex justify-content-between pt-3 pb-3'>
                    <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                        component="div" color='dark.main' className='font-weight-bold' >
                        Total
                    </Typography>
                    <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                        component="div" className='font-weight-bold pr-2' >
                        {`${formatAmountV1(totalPrice)} ${getCurrency()}`}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default StatusBoxV3
