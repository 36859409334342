import { createSelector } from 'reselect';

const selectProducts = (state) => {
    return state.products;
}
const makeSelectProductsItems = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('productItems')
);
const makeSelectNetsPaymentInitData = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('netsPaymentInitData')
);
const makeStoreListingSelectedPageIndex = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('storeListingSelectedPageIndex')
);
const makeSelectSubCategoryProducts = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('subCategoryProducts')
);
const makeSelectIndustryStores = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('industryStores')
);
const makeSelectActiveIndustry = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('activeIndustry')
);

const makeSelectProductCategories = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('storeProductCategories')
);
const makeSelectStoreDetailInfo = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('storeDetailInfo')
);

const makeSelectSelectedStoreProductCategory = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('selectedStoreProductCategory')
);

const makeSelectCartProducts = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('cartProducts')
);

const makeSelectCheckOutInvoice = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('checkOutInvoice')
);

const makeSelectVoucherInfo = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('voucherInfo')
);

const makeSelectSingleProduct = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('singleProduct'));

const makeSelectOrderedProducts = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('orderedProducts'));

const makeSelectBankAlfalahPaymentFormDetails = () => createSelector(
    selectProducts,
    (productsState) => productsState.get('bankAlfalahPaymentFormDetails'));

export {
    selectProducts,
    makeSelectProductsItems,
    makeSelectIndustryStores,
    makeSelectActiveIndustry,
    makeSelectProductCategories,
    makeSelectStoreDetailInfo,
    makeSelectSelectedStoreProductCategory,
    makeSelectSubCategoryProducts,
    makeSelectCartProducts,
    makeSelectCheckOutInvoice,
    makeSelectVoucherInfo,
    makeSelectSingleProduct,
    makeSelectOrderedProducts,
    makeStoreListingSelectedPageIndex,
    makeSelectNetsPaymentInitData,
    makeSelectBankAlfalahPaymentFormDetails
};
