import "./site.label.textfield.v3.scss";
import React from "react";
import ReactInputMask from "react-input-mask";
import { TextField, Typography } from "@mui/material";
import { useStyles } from "./site.label.textfield.v3.style";
import { Paper, InputAdornment, CircularProgress } from "@mui/material";
import clsx from "clsx";
import { regex } from "components/common/util/regex";
import { IsMobileWidth, IsTabletWidth } from "components/common/util/util";

export default function SiteLabelTextFieldV3(props) {
    const {
        classNames,
        maxLength,
        pattern,
        startAdornment,
        responsiveStartAdornment,
        endAdornment,
        label,
        topAdornment,
        execStatus,
        error,
        maskChar,
        value,
        mask,
        placeholder,
        validationType,
        responsiveEndAdornment,
        type,
        autoFocus
    } = props;
    const mobileWidth = IsMobileWidth();
    const tabletWidth = IsTabletWidth();
    const classes = useStyles();
    const onChange = (event) => {
        if (validationType === "ALPHANUMERIC") {
            if (
                regex.alphanumericWithOutSpace.test(event.target.value) ||
                event.target.value === ""
            ) {
                props.onChange(event);
            }
        }
        else if (validationType === "ALPHA_WITH_SPACE") {
            if (
                regex.alphaWithSpace.test(event.target.value) ||
                event.target.value === ""
            ) {
                props.onChange(event);
            }
        }
        else if (validationType === "ALPHANUMERIC_WITH_SPACE") {
            if (
                regex.alphanumericWithSpace.test(event.target.value) ||
                event.target.value === ""
            ) {
                props.onChange(event);
            }
        } else if (validationType === "NUMERIC") {
            if (
                regex.wholeNumber.test(event.target.value) ||
                event.target.value === ""
            ) {
                props.onChange(event);
            }
        }
        else if (validationType === "FLOAT") {
            if (
                regex.wholeNumberAndDecimalNumber.test(Number(event.target.value)) ||
                event.target.value === ""
            ) {
                props.onChange(event);
            }
        }
         else {
            props.onChange(event);
        }
    };
    return (
        <React.Fragment>
            {mask && !props.disabled ? (
                <ReactInputMask
                    mask={mask}
                    maskChar={maskChar}
                    placeholder={placeholder}
                    onChange={onChange}
                    alwaysShowMask={false}
                    value={value}
                >
                    {(inputProps) => (
                        <div className="w-100">
                            <div className="d-flex w-100 justify-content-between">
                                {topAdornment ? (
                                    <Typography classes={{ root: classes.textColor }} variant="caption" className="mt-3 max-width-content">
                                        <label htmlFor="text-field" className="form-label">
                                            {topAdornment}
                                        </label>
                                    </Typography>
                                ) : (
                                    ""
                                )}
                                {error ? <Typography variant="caption" className="text-danger mt-3">{error}</Typography> : null}
                            </div>
                            <Paper
                                id="site-label-text-field"
                                classes={{ root: classes.paperRoot }}
                                elevation={0}
                                className={clsx(
                                    classes.paperRoot,
                                    "w-100 border",
                                    mobileWidth && responsiveEndAdornment && "d-flex flex-column"
                                )}
                            >
                                {execStatus && execStatus.status === "PENDING" ? (
                                    <div>
                                        <CircularProgress size={50} className="p-3" />
                                    </div>
                                ) : (
                                    <TextField
                                        {...inputProps}
                                        label={null}
                                        disabled={props.disabled ? props.disabled : null}
                                        variant="outlined"
                                        autoFocus={autoFocus ? autoFocus : false}
                                        inputProps={{
                                            maxLength: maxLength,
                                            pattern: pattern,
                                        }}
                                        InputProps={{
                                            classes: {
                                                inputAdornedEnd: classes.endAdn,
                                                notchedOutline: classes.noBorder,
                                                input: clsx(!(mobileWidth || tabletWidth) && classes.input, (mobileWidth || tabletWidth) && classes.mobileInput),
                                                disabled: classes.disabled,
                                            },
                                            startAdornment: startAdornment || responsiveStartAdornment ? (
                                                <InputAdornment
                                                    color="secondary"
                                                    className="h-100 pr-1 pl-1"
                                                    position="start"
                                                    onClick={props.onClick}
                                                >
                                                    {startAdornment && !mobileWidth
                                                        ? startAdornment
                                                        : responsiveStartAdornment
                                                            ? null
                                                            : startAdornment}
                                                </InputAdornment>
                                            ) : null,
                                            endAdornment: (
                                                <InputAdornment className="h-100" position="end">
                                                    {endAdornment && !mobileWidth
                                                        ? endAdornment
                                                        : responsiveEndAdornment
                                                            ? ""
                                                            : endAdornment}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                {mobileWidth ? responsiveEndAdornment : null}
                            </Paper>
                        </div>
                    )}
                </ReactInputMask>
            ) : (
                <div className="w-100">
                    <div className="d-flex w-100 justify-content-between">
                        {topAdornment ? (
                            <Typography classes={{ root: window.TRANSPORT_SERVICE === true ? classes.textLabelColorTransport : classes.textColor }} variant="caption" className="mt-3 max-width-content">
                                <label htmlFor="text-field" className="form-label">
                                    {topAdornment}
                                </label>
                            </Typography>
                        ) : (
                            ""
                        )}
                        {error ? <Typography variant="caption" className="text-danger mt-3">{error}</Typography> : null}
                    </div>
                    <Paper
                        id="site-label-text-field"
                        elevation={0}
                        classes={{ root: classes.paperRoot &&  window.window.PAYMENT_DARK_THEME === true ? classes.paperRootDarkThem :''}}
                        className={clsx(window.window.PAYMENT_DARK_THEME === true? 'w-100': 'w-100 border',
                            mobileWidth && responsiveEndAdornment && "d-flex flex-column"
                        )}
                    >
                        {
                            <div className="d-flex align-items-center w-100">
                                {execStatus && execStatus.status === "PENDING" ? (
                                    <div className="pr-4">
                                        <CircularProgress size={45} className="p-3" />
                                    </div>
                                ) : null}
                                <div className="flex-grow-1">
                                    {props.disabled === true ? (
                                        <div
                                            className={clsx(
                                                "d-flex align-items-center  pl-1 pt-1 pb-2",
                                                classNames,
                                                endAdornment && "justify-content-between"
                                            )}
                                            style={{
                                                minHeight: "45px",
                                            }}
                                        >
                                            <div>{value}</div>
                                            <div>
                                                {endAdornment && !mobileWidth
                                                    ? endAdornment
                                                    : responsiveEndAdornment
                                                        ? null
                                                        : endAdornment
                                                            ? endAdornment
                                                            : null}
                                            </div>
                                        </div>
                                    ) : (
                                        <TextField
                                            onBlur={props.onBlur}
                                            value={value}
                                            placeholder={placeholder}
                                            label={null}
                                            disabled={props.disabled ? props.disabled : null}
                                            variant="outlined"
                                            autoFocus={autoFocus ? autoFocus : false}
                                            inputProps={{
                                                maxLength: maxLength,
                                                pattern: pattern,
                                            }}
                                            onChange={onChange}
                                            InputProps={{
                                                classes: {
                                                    //inputAdornedStart : classes.startAdn,
                                                    inputAdornedEnd: classes.endAdn,
                                                    //inputAdornedStart: classes.paddingLeft,
                                                    notchedOutline: classes.noBorder,
                                                    input: classes.input,
                                                    disabled: classes.disabled,
                                                },
                                                startAdornment:
                                                    startAdornment || responsiveStartAdornment ? (
                                                        <InputAdornment
                                                            color="secondary"
                                                            className="h-100 pr-1 pl-1"
                                                            position="start"
                                                        >
                                                            {startAdornment && !mobileWidth
                                                                ? startAdornment
                                                                : responsiveStartAdornment
                                                                    ? null
                                                                    : startAdornment}
                                                        </InputAdornment>
                                                    ) : null,
                                                endAdornment: (
                                                    <InputAdornment className="h-100" position="end">
                                                        {endAdornment && !mobileWidth
                                                            ? endAdornment
                                                            : responsiveEndAdornment
                                                                ? null
                                                                : endAdornment}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                    {mobileWidth ? responsiveEndAdornment : null}
                                </div>
                            </div>
                        }
                    </Paper>
                </div>
            )}
        </React.Fragment>
    );
}