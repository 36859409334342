import React, { useState } from 'react'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import TermsAndConditions from 'containers/terms.and.conditions/terms.and.conditions';
import { IsMobileWidth, IsTabletWidth, getTheApplicationTheme } from 'components/common/util/util'
import clsx from 'clsx';
import { useStyle } from './terms.and.conditions.selection.dialog.style';
import { useEffect } from 'react';
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import TopNavigationPanel from 'components/common/top.navigation.panel/top.navigation.panel';
import { useTranslation} from "react-i18next";


const TermsAndConditionSelectionDialog = (props) => {
    const { isTermsAndConditionsAccepted } = props
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth();
    const [state, setState] = useState({
        isTermsAndConditionSelectionDialogOpen: getTheApplicationTheme() === 'PROMINENT' ? true : false
    })
    const { t} = useTranslation("global");

    
    const hideTermsAndConditionSelectionDialog = () => {
        setState({ ...state, isTermsAndConditionSelectionDialogOpen: false })
        if (getTheApplicationTheme() === 'PROMINENT') {
            props.onChange('isTermsAndConditionsAccepted', true)
        }
    }
    const showTermsAndConditionSelectionDialog = () => {
        setState({ ...state, isTermsAndConditionSelectionDialogOpen: true })
    }
    useEffect(() => {
        document.getElementById('term-and-condition-content') &&
            document.getElementById('term-and-condition-content').addEventListener('touchmove', function (event) {
                event.stopPropagation();
            });
    }, [])

    return (
        <div id='term-and-condition-content'>
            {getTheApplicationTheme() !== 'PROMINENT' ?
                <div className='w-100 d-flex align-items-center'>
                    <FormControlLabel
                        control={<Checkbox
                            checked={isTermsAndConditionsAccepted}
                            onChange={(e) => props.onChange('isTermsAndConditionsAccepted', e.target.checked)}
                            disableRipple={true}
                            classes={{ root: classes.radioBtn }}
                        />}
                        label={
                            <Typography color='dark.main' variant='caption' className={clsx(!mobileWidth && 'pl-1', mobileWidth && 'text-padding', 'font-weight-bold')}>
                               {window.INDEX_NOKIA_ARENA === true ? t("messages.nokiaArenaTermsAndCondition"): ' I accept the'} 
                            </Typography>
                        }
                        className='mb-0 mr-1'
                    />
                    <Typography onClick={showTermsAndConditionSelectionDialog} color='dark.main' variant='caption' className={clsx(mobileWidth && 'text-padding', 'font-weight-bold cursor-pointer', classes.linkStyle)}>
                        
                        {window.INDEX_NOKIA_ARENA === true ? t("messages.nokiaArenaTermsAndConditionV1"): ' Terms & Conditions'} 

                    </Typography>
                </div> : null}
            <ContentDialog
                open={state.isTermsAndConditionSelectionDialogOpen}
                maxWidth='md'
                handleClose={getTheApplicationTheme() !== 'PROMINENT' ? hideTermsAndConditionSelectionDialog : ''}
                dividers={false}
                fullScreen={getTheApplicationTheme() === 'PROMINENT' ? true : false}
                title={getTheApplicationTheme() === 'PROMINENT' ?
                    <div className='w-100'>
                        <TopNavigationPanel
                            hideNavigationButtons={true}
                            name='Terms & Conditions'
                        />
                    </div> : null}
                hideActionsPadding={true}
                hideTitlePadding={true}
                actions={<div className={clsx(classes.actions, 'w-100')}>
                    {getTheApplicationTheme() === 'PROMINENT' ?
                        <div className='w-100 d-flex flex-column pl-4 pr-4 align-items-center'>
                            <LoaderButton
                                // fullWidth
                                type="submit"
                                variant="outlined"
                                classNames={clsx(!(mobileWidth || tabletWidth) && 'w-30', (mobileWidth || tabletWidth) && 'w-100', "mt-2 mb-2", classes.btnStyle1)}
                                // disabled={!state.isTermsAndConditionsAccepted ? true : false}
                                showSwipeButton={getTheApplicationTheme() === 'PROMINENT' ? true : false}
                                onClick={hideTermsAndConditionSelectionDialog}
                                {...props}
                            >
                                I agree
                            </LoaderButton>
                            <LoaderButton
                                // fullWidth
                                showBackArrow={true}
                                type="submit"
                                variant="outlined"
                                classNames={clsx(!(mobileWidth || tabletWidth) && 'w-30', (mobileWidth || tabletWidth) && 'w-100', "mt-2 mb-2", classes.btnStyleBack)}
                                onClick={props.goBack}
                                {...props}
                            >
                                Change order
                            </LoaderButton>
                        </div> : null
                    }
                </div>}
            >
                <TermsAndConditions />
            </ContentDialog>
        </div>
    )
}

export default TermsAndConditionSelectionDialog