import { makeStyles } from '@mui/styles';
import { secondaryColor, primaryColor } from '../../../containers/main/material.theme/app.theme/app.material.theme.base';

export const useStyle = makeStyles((theme) => ({
    selectedChip: {
        width: "160px !important",
        boxShadow: "none !important",
        height: "auto",
        cursor: 'pointer',
        backgroundColor: theme.palette.filterButtonColor.main,
        color: theme.palette.background.paper,
        border: "none",
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'Inter',
        borderRadius: '5px'
    },
    chip: {
        width: "160px !important",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.dark.light,
        boxShadow: "none !important",
        height: "auto",
        cursor: 'pointer',
        fontSize: '18px',
        fontWeight: 400,
        fontFamily: 'Inter',
        borderRadius: '5px',
        "&:hover": {
            backgroundColor: theme.palette.filterButtonColor.main,
            color: theme.palette.dark.main,
            border: "none",
        },
    },
    title: {
        color: theme.palette.dark.light,
    },
    selectedTitle: {
        color: theme.palette.dark.light,
    },
    selectedCustomChipStyle: {
        width: "160px !important",
        boxShadow: "none !important",
        height: "auto",
        cursor: 'pointer',
        backgroundColor: '#f2f7f3',
        color: theme.palette.background.paper,
        fontSize: '15px',
        fontWeight: 400,
        fontFamily: 'Inter',
        borderRadius: '3px',
        border: `2px solid ${theme.palette.ternary.main} !important`
    },
    customChipStyle: {
        width: "160px !important",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.dark.light,
        boxShadow: "none !important",
        height: "auto",
        cursor: 'pointer',
        fontSize: '15px',
        fontWeight: 400,
        fontFamily: 'Inter',
        borderRadius: '3px',
        "&:hover": {
            backgroundColor: '#f2f7f3',
            color: theme.palette.background.paper,
            border: "none",
        }
    }
    // title: {
    //     '&:hover': {
    //         //color: theme.palette.secondary.light,
    //         color: '#ffffff'
    //     }
    // },
    // selectedTitle: {
    //     color: 'white'
    // }
}))