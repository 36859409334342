import React, { useEffect } from 'react'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog';
import { Skeleton, Typography } from '@mui/material';
import { cacheClear } from 'components/common/util/cache.clear';

//VERSION_CHANGES
const NewVersionUpdateDialog = (props) => {
    const { open, newVersion } = props;
    useEffect(() => {
        setTimeout(() => {
            cacheClear();
        }, 3000)
    }, [])
    return (
        <div>
            <ContentDialog
                handleClose={props.handleClose}
                open={open}
                title={<div><Typography><b>Dear Valued Customer,</b></Typography></div>}>
                <div>
                    <Typography className="pe-4 ps-4 pt-2 pb-2">
                        We have launched a new version of the app.
                        Your update will begin now
                    </Typography>
                    <Typography className="pe-4 ps-4 pt-2 pb-2">
                        {`New version # is: ${newVersion}`}
                    </Typography>
                    <Typography className="pe-4 ps-4 pt-2 pb-2">
                        Thank you
                    </Typography>
                    <Typography className="d-flex justify-content-center pe-4 ps-4 pt-2 pb-2">
                        <div>
                            <Skeleton varianat="text" width={250} height={50} />
                        </div>
                    </Typography>
                </div>
            </ContentDialog>
        </div>
    )
}
export default NewVersionUpdateDialog