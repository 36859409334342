import React from 'react'
import { Typography } from '@mui/material'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog';
import { formatAmountV1, getTotalDeliveryTime, renderHtml, getMerchantTimeSlots, getDeliveryFee } from '../util/util';
import { useStyle } from './merchant.detail.info.dialog.style';
import ExpandableViewV1 from 'components/common/expandable.view.v1/expandable.view.v1';
import { IsMobileWidth } from 'components/common/util/util';
import clsx from 'clsx';
import MerchantAddressMap from './components/merchant.address.map/merchant.address.map';

const MerchantDetailnfoDialog = (props) => {
    const { isMerchantDetailInfoDialogOpen, storeDetailInfo, auth } = props
    const classes = useStyle();
    const mobileWidth = IsMobileWidth()

    return (
        <div>
            <ContentDialog
                open={isMerchantDetailInfoDialogOpen}
                maxWidth='sm'
                handleClose={props.hideMerchantDetailInfoDialog}
                dividers={true}
                setHeaderBackgroundAsPrimary={true}
                title={<div className='w-100 d-flex justify-content-center'>
                    <Typography variant='h6' className='font-weight-normal text-white'>
                        {storeDetailInfo && storeDetailInfo.get('name') && storeDetailInfo.get('name')}
                    </Typography>
                </div>}
                actions={
                    <div className='w-100'>
                        <MerchantAddressMap {...props} />
                    </div>
                }
            >
                <div className={clsx(!mobileWidth && 'd-flex', mobileWidth && 'd-block')}>
                    <div className={clsx(!mobileWidth && 'pl-2 pr-2 w-50', mobileWidth && 'w-100 pl-2 pr-2')}>
                        <Typography classes={{ root: classes.darkColor }} variant='body2' className='font-weight-normal pt-2'>
                            {storeDetailInfo && renderHtml(storeDetailInfo.get('description'))}
                        </Typography>
                        <Typography classes={{ root: classes.darkColor }} variant="body2" className="text-capitalize mt-2">
                            <img
                                className='mr-2'
                                src={`${process.env.PUBLIC_URL}/assets/icons/shopping_basket.svg`}
                                width="25px"
                            />
                            {`RS ${storeDetailInfo && storeDetailInfo?.get('min_amount') && formatAmountV1(storeDetailInfo?.get('min_amount'))} Minimum`}
                        </Typography>
                        <Typography classes={{ root: classes.darkColor }} variant="body2" className="text-capitalize mt-2">
                            <img
                                className='mr-2'
                                src={`${process.env.PUBLIC_URL}/assets/icons/clock-icon.svg`}
                                width="25px"
                            />
                            {`Delivery in ${getTotalDeliveryTime(storeDetailInfo)}`}
                        </Typography>
                        <Typography classes={{ root: classes.darkColor }} variant="body2" className="text-capitalize mt-2">
                            <img
                                className='mr-2'
                                src={`${process.env.PUBLIC_URL}/assets/icons/store_icon.svg`}
                                width="25px"
                            />
                            {storeDetailInfo && getDeliveryFee(storeDetailInfo, auth)}
                        </Typography>
                        {/* <Typography classes={{ root: classes.darkColor }} variant="body2" className="text-capitalize mt-2">
                            <img
                                className='mr-2'
                                src={`${process.env.PUBLIC_URL}/assets/icons/dollar-icon.svg`}
                                width="25px"
                            />
                            $
                        </Typography> */}
                        <div className='d-flex w-100'>
                            <Typography classes={{ root: classes.darkColor }} variant="body2" className="d-flex text-capitalize w-100 mt-2 h-25 pr-3">
                                <img
                                    className='mr-2'
                                    src={`${process.env.PUBLIC_URL}/assets/icons/calendar_month_icon.svg`}
                                    width="25px"
                                />
                                <Typography className={storeDetailInfo?.get('shop_status') === 'open' ? 'text-success' : 'text-danger'}>
                                    {storeDetailInfo && storeDetailInfo?.get('shop_status') && storeDetailInfo?.get('shop_status')}
                                </Typography>
                            </Typography>
                        </div>

                    </div>
                    <div className={clsx(!mobileWidth && 'w-50 pl-2 pr-2', mobileWidth && 'w-100 pl-2 pr-2')}>
                        <ExpandableViewV1 label="Available Days">
                            <div className="d-flex justify-content-between w-100">
                                <Typography classes={{ root: classes.darkColor }} variant="body2">
                                    {getMerchantTimeSlots(storeDetailInfo)}
                                </Typography>
                            </div>
                        </ExpandableViewV1>
                    </div>
                </div>
            </ContentDialog >
        </div >
    )
}

export default MerchantDetailnfoDialog