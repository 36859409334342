import React from 'react'
import HistoryList from './components/history.list'
import SiteContentWrapper from 'components/common/site.content.wrapper/site.content.wrapper';

export default function OrderHistory(props) {
    return (
        <div>
            <SiteContentWrapper
                {...props}
                topNavigationName='Order history'
                showBottomNavigation={true}>
                <HistoryList {...props} />
            </SiteContentWrapper>
        </div>
    )
}
