import { makeStyles } from '@mui/styles';
//import { lightBackgroundColor } from 'components/containers/main/material.theme/app.theme/app.material.theme.base';
export const useStyle = makeStyles(theme => ({
    paper: {
        border: `1px solid ${theme.palette.lightBackgroundColor}`
    },
    container: {
        // maxHeight: '60vh !important',
        // overflow:'auto'
    },
    tabs: {
        backgroundColor: '#F8F8F8'
    }
}))