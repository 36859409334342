import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

export default function MaterialExpansionPanel(props) {
    const { expanded, title } = props
    return (
            <Accordion elevation={0.2} expanded={expanded} onChange={props.onExpandChange} >
                <AccordionSummary
                    expandIcon={<i className="material-icons">
                        expand_more
                    </i>}
                >
                    <b>{title}</b>
                </AccordionSummary>
                <AccordionDetails style={{
                    background : "#ffffff"
                }}>
                    {props.children}
                </AccordionDetails>
            </Accordion>
    )
}
