import './alert.dialog.box.style.scss'
import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import { Typography } from "@mui/material";
import { useStyle } from "./alert.dialog.box.style";
import clsx from 'clsx';
import LoaderButton from "../material.components/loader.button/loader.button";
import { useTranslation } from "react-i18next";

const AlertDialogBox = (props) => {
  const { open, title, closeButtonText, description, img, bottomText, showCloseButton = true, useDarkTheme = false } = props;
  const classes = useStyle()
  const { t } = useTranslation("global");


  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  let darkThemeStyles = {}
  if (useDarkTheme === true)
    darkThemeStyles = {
      backgroundColor: "#2a2e30",
      width: "340px",
      borderRadius: "20px",
    };

  const isTypographyStyle = window.INDEX_NOKIA_ARENA === true;


  return (
    <Dialog open={open} onClose={handleClose} className={clsx("dialogBackgroundColor", useDarkTheme === true && "dialog-box-container")} PaperProps={{
      style: {
        width: "285px",
        mariginBottom: 20,
        marginRight: 20,
        marginLeft: 20,
        borderRadius: 50,
        ...darkThemeStyles,
        ...(isTypographyStyle ? {
          borderRadius: "10px",
        } : {})
      }
    }}>
      <div className={`${classes.thanks} title ${isTypographyStyle ? classes.titleColor : ''}`} style={isTypographyStyle
        ? { background: "#1558CE", padding: "10px 0px", margin:"0",}
        : {}
      } >{title}</div>
      <div>
        <div style={{ textAlign: "center" }} classNames={classes.desc}>
          {img ? img : null}
          <Typography variant="p" color="dark.main" className={`${classes.alertdialogbox}`}>{description}
          </Typography>
        </div>
        {bottomText &&
          <div style={{ textAlign: "center" }} className="wait">
            <Typography variant="p" color="dark.main" className={`${classes.alertdialogbox}`}>{bottomText}
            </Typography>
          </div>
        }
        {
          showCloseButton === true ?
            <div className="w-100 d-flex justify-content-center">
              <LoaderButton onClick={handleClose} classNames="text-capitalize w-50 mt-3 mb-3">
                {closeButtonText ? closeButtonText : (window.INDEX_NOKIA_ARENA === true ? t("messages.closeButton") : 'Close')}
              </LoaderButton>
            </div>
            : null
        }
      </div>
    </Dialog>
  );
};

export default AlertDialogBox;
