// The initial state of the App
import { fromJS } from 'immutable';
import { getActionName } from './util';
import { INIT_STRIPE_PAYMENT, RESET_INIT_STRIPE_PAYMENT } from '../actions/payment.actions';
var CryptoJS = require("crypto-js");

const initialState = fromJS({
    stripePaymentData: {

    }
});
function paymentReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_INIT_STRIPE_PAYMENT:
            return state.set('stripePaymentData', fromJS({}))
        case getActionName(INIT_STRIPE_PAYMENT):
            window.localStorage.setItem('pickupAddressDescription', action.payload.pickupAddressDescription);
            window.localStorage.setItem('deliveryAddressDescription', action.payload.deliveryAddressDescription);
            window.localStorage.setItem('pickupAddressShortDescription', action.payload.pickupAddressShortDescription);
            window.localStorage.setItem('deliveryAddressShortDescription', action.payload.deliveryAddressShortDescription);
            return state.update('stripePaymentData', (stripePaymentData) => {
                if(action.response && action.response.data) {
                    const bytes = CryptoJS.AES.decrypt(action.response.data.clientSecret, window.SITE_ID);
                    const decryptedClientSecret = bytes.toString(CryptoJS.enc.Utf8);
                    return stripePaymentData.merge(fromJS({ ...action.payload, clientSecret: decryptedClientSecret }));
                }
                else
                    return stripePaymentData
            })
        default:
            return state;
    }
}
export default paymentReducer;
