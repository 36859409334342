import React, { useContext, useEffect, useState } from 'react'
import { IsMobileWidth, IsTabletWidth, isToShowSemiNavbar } from 'components/common/util/util';
import AppbarDesktop from './app.navbar.desktop';
import AppbarMobile from './app.navbar.mobile';
import Collapse from '@mui/material/Collapse';
import AddressDialog from "containers/checkout/components/cart.summary/components/address.dialog/address.dialog";
import AppSidebar from './components/app.sidebar/app.sidebar';
import AddressChip from "./components/address.chip/address.chip";
import SemiAppNavbar from './components/semi.app.navbar/semi.app.navbar';
import { useStyle } from './app.navbar.style';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { SEARCH_STORES } from 'view.updater/actions/products.actions';
import { checkExecutionStatus } from 'components/common/util/util';
import { AppContext } from 'containers/main/container';

const Appbar = (props) => { //POST_LOGIN
    const { auth, cartProducts, storeListingSelectedPageIndex } = props;
    const context = useContext(AppContext);
    const execStatusSearchStores = checkExecutionStatus(context, SEARCH_STORES);
    const [state, setState] = useState({
        addressDialogIsOpen: false,
        isDrawerOpen: false
    })
    const [resetSearchPanel, setResetSearchPanel] = useState(false)
    let location = useLocation();
    useEffect(() => {
        if (location) {
            if (location.pathname !== "/products") {
                setResetSearchPanel(true)
            }
        }
    }, [location])
    const classes = useStyle()
    let totalPrice = 0;
    for (let cartProd of cartProducts) {
        if (cartProd && cartProd?.get('data')?.get('promotion') && cartProd?.get('data')?.get('promotion').get('promotion_amount')) {
            totalPrice = totalPrice + (cartProd.get('data')?.get('cost_after_discount') * cartProd.get('data')?.get('quantity'))
        }
        else
            totalPrice = totalPrice + (cartProd.get('data')?.get('cost') * cartProd.get('data')?.get('quantity'))
    }
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const handleAddressDialog = () => {
        setState({ ...state, addressDialogIsOpen: true })
    }
    const closeDialog = () => {
        setState({ ...state, addressDialogIsOpen: false })
    }

    const showDrawer = () => {
        setState({ ...state, isDrawerOpen: true });
    };

    const hideDrawer = () => {
        setState({ ...state, isDrawerOpen: false });
    };

    const updateSelctedStorePage = (page) => {
        props.setStoreListingSelectedPageIndex({
            storeListingSelectedPageIndex: page
        })
    }
    const getSelectedAddress = () => {
        if (auth && auth.get("selectedAddressId") && auth?.get('user')?.get('home_address')?.get('id') == auth.get("selectedAddressId")) {
            return <div>
                <AddressChip
                    iconPath={`${process.env.PUBLIC_URL}/assets/icons/home-nav-icon.svg`}
                    addressTitle='Home' />
            </div>

        }
        else if (auth && auth.get("selectedAddressId") && auth?.get('user')?.get('work_address')?.get('id') == auth.get("selectedAddressId")) {
            return <div>
                <AddressChip
                    iconPath={`${process.env.PUBLIC_URL}/assets/icons/suitcase.svg`}
                    addressTitle='work'
                    iconWidth='20px' />
            </div>
        }
        else if (auth && auth.get("selectedAddressId") && auth?.get('user')?.get('other_addresses')) {
            let add = auth?.get('user')?.get('other_addresses').find((address) => address.get('id') == auth.get("selectedAddressId"));
            if (add) {
                return <div>
                    <AddressChip
                        iconPath={`${process.env.PUBLIC_URL}/assets/icons/pin-icon.svg`}
                        addressTitle={add.get('name')}
                        iconWidth='20px' />
                </div>
            }
            else {
                return <div>
                    <AddressChip
                        iconPath={`${process.env.PUBLIC_URL}/assets/icons/pin-icon.svg`}
                        addressTitle='Select Address'
                        iconWidth='20px' />
                </div>
            }
        }
        else {
            return <div>
                <AddressChip
                    iconPath={`${process.env.PUBLIC_URL}/assets/icons/pin-icon.svg`}
                    addressTitle='Select Address'
                    iconWidth='20px' />
            </div>
        }
    }
    const resetTheSearchPannel = () => {
        setResetSearchPanel(true)
    }
    const resetSearchPannelToDefault = () => {
        setResetSearchPanel(false)
    }
    return (
        <div>
            {
                state.addressDialogIsOpen == true ?
                    <AddressDialog
                        isAddressDialogOpen={state.addressDialogIsOpen}
                        closeAddressDialog={closeDialog}
                        {...props} /> : null
            }
            {
                !(mobileWidth || tabletWidth) ? <Collapse in={true}>
                    <AppbarDesktop resetTheSearchPannel={resetTheSearchPannel} resetSearchPanel={resetSearchPanel} resetSearchPannelToDefault={resetSearchPannelToDefault} execStatusSearchStores={execStatusSearchStores} totalPrice={totalPrice} {...props} handleAddressDialog={handleAddressDialog}
                        showDrawer={showDrawer} hideDrawer={hideDrawer} getSelectedAddress={getSelectedAddress}
                    />
                </Collapse> : <AppbarMobile resetTheSearchPannel={resetTheSearchPannel} resetSearchPanel={resetSearchPanel} resetSearchPannelToDefault={resetSearchPannelToDefault} execStatusSearchStores={execStatusSearchStores} totalPrice={totalPrice} {...props} handleAddressDialog={handleAddressDialog}
                    showDrawer={showDrawer} hideDrawer={hideDrawer} getSelectedAddress={getSelectedAddress}
                />
            }
            {/* {isToShowSemiNavbar(location) ?
                <div className={clsx(!(mobileWidth || tabletWidth) && '', tabletWidth && 'mb-3', mobileWidth && 'mb-2')}>
                    <SemiAppNavbar resetTheSearchPannel={resetTheSearchPannel} resetSearchPanel={resetSearchPanel} resetSearchPannelToDefault={resetSearchPannelToDefault} selectedStorePage={storeListingSelectedPageIndex} updateSelctedStorePage={updateSelctedStorePage}
                        handleAddressDialog={handleAddressDialog} getSelectedAddress={getSelectedAddress}  {...props} />
                </div> : null
            } */}
            {/* <div className={classes.appBarSpacer} />
            {mobileWidth && auth && auth.get('industry_types') && auth.get('industry_types') && auth.get('industry_types')?.size > 10 ?
                <div className={classes.appBarSpacer} /> : null} */}
            <AppSidebar isDrawerOpen={state.isDrawerOpen} {...state} {...props}
                showDrawer={showDrawer} hideDrawer={hideDrawer} />
        </div>
    )
}
export default Appbar
