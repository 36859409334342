/**
 * The global state selectors
 */

import { createSelector } from 'reselect';

const selectApp = (state) => state.app;

const languageSelector = () => createSelector(
  selectApp,
  (selectApp) => selectApp.get('language')
)

const executionsSelector = () => createSelector(
  selectApp,
  (selectApp) => selectApp.get('appLoadersStatus')
)

const appPropertiesSelector = () => createSelector(
  selectApp,
  (selectApp) => selectApp.get('appProperties')
)

const appAnimationSelector = () => createSelector(
  selectApp,
  (selectApp) => {
    return selectApp.get('animations')
  })

const merchantDetailSuccessfullySavedSelector = () => createSelector(
  selectApp,
  (selectApp) => {
    return selectApp.get('merchantDetailSuccessfullySaved')
  })

const riderDetailSuccessfullySavedSelector = () => createSelector(
  selectApp,
  (selectApp) => {
    return selectApp.get('riderDetailSuccessfullySaved')
  })

const appForceUpdateSelector = () => createSelector(
  selectApp,
  (selectApp) => {
    return selectApp.get('forceUpdateAppVersion')
  })

const appNewVersionSelect = () => createSelector(
  selectApp,
  (selectApp) => {
    return selectApp.get('newVersion')
  })



export {
  languageSelector,
  executionsSelector,
  appForceUpdateSelector,
  appNewVersionSelect,
  appPropertiesSelector,
  appAnimationSelector,
  merchantDetailSuccessfullySavedSelector,
  riderDetailSuccessfullySavedSelector
};
