import React from 'react'
import ContentDialog from "components/common/material.components/content.dialog/content.dialog";
import { Typography, Button } from '@mui/material';

const ErrorMessageDialog = (props) => {
    const { open, execStatusCreateInvoice, cartProducts } = props
    const hideDialog = () => {
        if (typeof execStatusCreateInvoice?.errorDetail?.get('message') === 'object' &&
            execStatusCreateInvoice?.errorDetail?.get('message') &&
            cartProducts?.size > 0 && Array.isArray(execStatusCreateInvoice?.errorDetail?.get('message').toJS())) {
            let productIds = execStatusCreateInvoice?.errorDetail?.get('message').toJS();
            for (let i = 0; i < productIds.length; i++) {
                props.deleteProductFromCart({
                    uniqueId: productIds[i]
                })
            }
        }
        props.resetCreateInvoice();
    }
    return (
        <>
            <ContentDialog maxWidth="sm"
                open={open}
                handleClose={hideDialog}
                setHeaderBackgroundAsPrimary={true}
                dividers={false}
                actions={<div className='w-100 d-flex justify-content-center'>
                    <Button onClick={hideDialog} variant='outlined' color="primary" className='text-capitalize w-75 ml-1 pl-3 mb-3 pr-3'>
                        Close
                    </Button>
                </div>}
            >
                <div style={{ minHeight: '100px' }} className='d-flex flex-column justify-content-start align-items-start'>
                    <Typography variant='body1' className='text-danger pt-3 pb-3'>
                        Following products no longer available, and will be removed from your shopping cart.
                    </Typography>
                    <ul>
                        {
                            typeof execStatusCreateInvoice?.errorDetail?.get('message') === 'object' &&
                            execStatusCreateInvoice?.errorDetail?.get('message') &&
                            cartProducts?.size > 0 &&
                            Array.isArray(execStatusCreateInvoice?.errorDetail?.get('message').toJS()) &&
                            execStatusCreateInvoice?.errorDetail?.get('message').toJS().map((id) => {
                                let prod = cartProducts.find((product) => product.get('data').get('query_id') === id);
                                return <li>
                                    <Typography variant='body1' className='text-dark'>
                                        {prod && prod.get('data') && prod.get('data').get('name')}
                                    </Typography>
                                </li>
                            })
                        }
                    </ul>
                </div>
            </ContentDialog>
        </>
    )
}

export default ErrorMessageDialog
