import './sub.category.products.list.scss';
import React, { useEffect, useState, useContext, useRef } from 'react'
import ProductBox from 'components/common/product.box/product.box'
import { Paper, IconButton, Typography, Box, Grid, Pagination, Skeleton, Button } from '@mui/material';
import { useStyle } from './sub.category.products.list.style';
import { renderHtml } from 'components/common/util/util';
import { IsMobileWidth, IsTabletWidth, checkExecutionStatus, scrollToRefElement, scrollToTop, getTheApplicationTheme } from 'components/common/util/util';
import clsx from 'clsx';
import { formatAmountV1, getImageUrl } from 'components/common/util/util';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PageHeading from 'components/common/page.heading/page.heading';
import { Swiper, SwiperSlide } from "swiper/react";
import CategoryChip from 'components/common/category.chip/category.chip'
import { useParams } from 'react-router';
import AddProductErrorDialog from '../products.store.detail/components/products.list/add.product.error.dialog';
import { PRODUCT_PAGE_SIZE, GET_PRODUCTS, GET_STORE_PRODUCTS_CATEGORIES, ADD_UPDATE_PRODUCT_TO_CART, GET_CART_PRODUCTS, GET_STORE_DETAIL_INFO } from 'view.updater/actions/products.actions';
import { AppContext } from '../main/container';
import BoxLoadingSkeleton from 'components/common/skeletons/box.loading.skeleton/box.loading.skeleton';
import CategoryListSkeleton from 'components/common/skeletons/category.list.skeleton/category.list.skeleton';
import { storeIsInRadius, getSelectedAddressLatLng } from 'components/common/util/util';
import SearchPanel from 'components/common/search.panel/search.panel';
import MerchantDetail from 'components/common/merchant.detail/merchant.detail';
import { getQueryStringValue, detectBrowser, getMessageColor } from 'components/common/util/util';
import CheckoutBasketV2 from 'components/common/checkout.basket.v2/checkout.basket.v2';
import { Container } from '@mui/system';
import OrderCheckoutSkeleton from 'components/common/order.checkout.skeleton/order.checkout.skeleton';
import SiteContentWrapper from 'components/common/site.content.wrapper/site.content.wrapper';
import { useTranslation} from "react-i18next";

const SubCategoryProductsList = (props) => {

    const { cartProducts, selectedStoreProductCategory, auth, subCategoryProducts, storeDetailInfo, storeProductCategories } = props;
    const { storeQueryId, categoryId, subCategoryId, industryId } = useParams();
    const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
    const classes = useStyle()
    const tabletWidth = IsTabletWidth()
    const mobileWidth = IsMobileWidth()
    const browser = detectBrowser()
    const context = useContext(AppContext);
    const execStatusGetProducts = checkExecutionStatus(context, GET_PRODUCTS);
    const execStatusStoreProductCategory = checkExecutionStatus(context, GET_STORE_PRODUCTS_CATEGORIES);
    const execStatusAddUpdateProductToCart = checkExecutionStatus(context, ADD_UPDATE_PRODUCT_TO_CART);
    const execStatusGetCartProducts = checkExecutionStatus(context, GET_CART_PRODUCTS);
    const execStatusGetStoreDetailInfo = checkExecutionStatus(context, GET_STORE_DETAIL_INFO);
    const [state, setState] = useState({
        showProductErrorDialogOpen: false,
        selectedProduct: "",
        selectedPage: 1,
        resetSearchPanel: false
    })
    const name = getQueryStringValue("name");
    const [addProductToCartId, setAddProductToCartId] = useState("")

    const { t} = useTranslation("global");

    const productRef = useRef(null);
    const productsElements = [];
    // Once the scrollToElement function is run, the scroll will show the element
    useEffect(() => {
        document.getElementById('sub-category-content') &&
            document.getElementById('sub-category-content').addEventListener('touchmove', function (event) {
                event.stopPropagation();
            });
        setTimeout(() => {
            // scrollToRefElement(productRef)
            scrollToTop()
        }, 100)


        // props.getProducts({
        //     storeQueryId: storeQueryId,
        //     categoryId: categoryId,
        //     subCategoryId: subCategoryId,
        //     page: state.selectedPage
        // })
        //props.resetSelectedStoreProductCategory();
        props.getCartProducts();
        // showAllProducts();
        return () => {
            props.resetProducts(false, {
                resetSearchedProducts: true
            })
            //props.resetStoreProductCategories();
            props.resetStoreDetailInfo();
        }
    }, [])

    useEffect(() => {
        if (auth && auth.get('user')) {
            if (categoryId === "ALL") {
                props.setSelectedStoreProductCategory({
                    categoryId: "ALL",
                    sub_categories: [],
                    subCategoryId: "ALL", storeQueryId: storeQueryId
                })
            }
            props.getStoreProductCategories({
                storeQueryId,
                categoryId,
                name,
                dontSetSelectedProduct: categoryId === "ALL" ? true : false
            });
            props.getStoreDetailInfo({
                storeQueryId
            })
        }
        //showAllProducts();
    }, [auth])

    let selectedProductCategories;
    if (storeQueryId)
        selectedProductCategories = storeProductCategories && storeProductCategories.find((category) => category.get('storeQueryId') === storeQueryId)

    const showAllProducts = () => {
        props.history.push(`/products/product-list/${storeQueryId}/ALL/ALL/${industryId}`);
        //props.resetTheSearchPanel();
        props.resetProducts(false, {
            resetSearchedProducts: true
        })
        props.setSelectedStoreProductCategory({
            categoryId: "ALL",
            sub_categories: [],
            subCategoryId: "ALL", storeQueryId: storeQueryId
        })

        window.scrollTo({
            top: 350,
            left: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        if (storeProductCategories && selectedProductCategories) {
            if (categoryId == "-1" || subCategoryId == "-1") {
                let defaultCategory = selectedProductCategories && selectedProductCategories?.get('listCategory') && selectedProductCategories?.get('listCategory')?.get('categories')?.size > 0 && selectedProductCategories?.get('listCategory')?.get('categories').get(0);
                let subCategoryId = defaultCategory && defaultCategory.get('sub_categories') && defaultCategory.get('sub_categories').get(0) && defaultCategory.get('sub_categories').get(0).get('id');
                props.history.push(`/products/product-list/${storeQueryId}/${defaultCategory.get('id')}/${subCategoryId}/${industryId}`);
                //props.resetTheSearchPanel();
                props.resetProducts(false, {
                    resetSearchedProducts: true
                })
                if (defaultCategory)
                    props.setSelectedStoreProductCategory({ ...defaultCategory?.toJS(), storeQueryId: storeQueryId })
            }
        }
    }, [storeProductCategories])

    const redirectTo = (route) => {
        props.history.push(route)
    }
    const getSwiperTopClass = () => {
        if (getTheApplicationTheme().includes('STANDARD') && window.TRADE_TYPE_IMAGE_URL) {
            return 'top-50'
        }
        else if (getTheApplicationTheme() === 'PROMINENT' && !mobileWidth && !tabletWidth) {
            return 'top-128'
        }
        else if (getTheApplicationTheme() === 'PROMINENT' && (mobileWidth || tabletWidth)) {
            return 'top-120'
        }
        else {
            return 'top-0'
        }
    }

    const pushProductRef = (element, id) => {
        if (element) {
            productsElements.push({ element, id });
            if (Number(subCategoryId) === id) {
                element.scrollIntoView({
                    block: "start",
                    behavior: "smooth"
                });
            }
        }
    };

    const [expandedProductIndex, setExpandedProductIndex] = useState(null);

    const handleProductBoxClick = (index) => {
        if (expandedProductIndex === index) {
            setExpandedProductIndex(null);
        } else {
            setExpandedProductIndex(index);
        }
    };
    const onPageChange = (e, page) => {
        setState({ ...state, selectedPage: page })
        props.getProducts({
            storeQueryId: storeQueryId,
            categoryId: categoryId,
            subCategoryId: subCategoryId,
            page: page
        })
        scrollToRefElement(productRef)
    }
    const showProductErrorDialog = (selectedProduct) => {
        setState({ ...state, showProductErrorDialogOpen: true, selectedProduct: selectedProduct })
    }
    const hideProductErrorDialog = () => {
        setState({ ...state, showProductErrorDialogOpen: false, selectedProduct: "" })
    }
    const changeSubCategory = (category) => {
        props.resetProducts(false, { resetSearchedProducts: true })
        setState({ ...state, selectedPage: 1, resetSearchPanel: true })
        props.getProducts({
            storeQueryId: storeQueryId,
            categoryId: categoryId,
            subCategoryId: category.get('id'),
            page: state.selectedPage
        })
        props.history.push(`/products/product-list/${storeQueryId}/${categoryId}/${category.get('id')}/${industryId}`);
        const targetElement = productsElements.find((ele) => ele.id === category.get("id"));
        if (targetElement && targetElement.element) {
            targetElement.element.scrollIntoView({
                block: "start",
                behavior: "smooth"
            });

        }
    };

    const onLoadMoreSubCategories = (category, index) => {
        let subProducts;
        if (category.get('id'))
            subProducts = subCategoryProducts && subCategoryProducts.find((pro) => pro.get('subCategoryId') == category.get('id'))
        if (subProducts && subProducts.get('products')?.get('products')?.size <= subProducts?.get('products')?.get('total_products')) {
            setState({ ...state, selectedPage: state.selectedPage + 1 })
            props.getProducts({
                storeQueryId: storeQueryId,
                categoryId: categoryId,
                subCategoryId: subCategoryId,
                page: state.selectedPage + 1
            })
        }
    };

    const goBack = () => {
        props.push(`/products/store/${storeQueryId}/${industryId}?categoryId=${categoryId}`);
    }
    const addToCart = (product, quantity) => {
        if (!cartProducts) {
            props.addUpdateProductToCart({
                ...product?.toJS(),
                merchantAddressId: storeDetailInfo && storeDetailInfo.get('address') && storeDetailInfo.get('address').get('id'),
                quantity: quantity + 1,
                storeQueryId: storeQueryId,
                uniqueId: product?.get("query_id")
            })
        }
        else if (cartProducts && cartProducts.size == 0) {
            props.addUpdateProductToCart({
                ...product?.toJS(),
                quantity: quantity + 1,
                merchantAddressId: storeDetailInfo && storeDetailInfo.get('address') && storeDetailInfo.get('address').get('id'),
                storeQueryId: storeQueryId,
                uniqueId: product?.get("query_id")
            })
        }
        else {
            let cartProduct = cartProducts && cartProducts?.find((cartProd) => cartProd.get('data').get('storeQueryId') === storeQueryId)
            if (cartProduct) {
                //MERCHANT_DIFFERENT CASE HANDLE
                props.addUpdateProductToCart({
                    ...product?.toJS(),
                    quantity: quantity + 1,
                    merchantAddressId: storeDetailInfo && storeDetailInfo.get('address') && storeDetailInfo.get('address').get('id'),
                    storeQueryId: storeQueryId,
                    uniqueId: product?.get("query_id")
                })
            }
            else {
                showProductErrorDialog({
                    ...product?.toJS(),
                    quantity: quantity + 1,
                    storeQueryId: storeQueryId,
                    uniqueId: product?.get("query_id")
                });
                //SHOW MERCHANT_DIFFERENT DIALOG
            }
        }
    }
    const onQtyChange = (product, quantity) => {
        if (quantity === 0) {
            props.deleteProductFromCart({
                uniqueId: product?.get("query_id")
            })
        }
        else {
            if (product) {
                const cartProduct = cartProducts && cartProducts?.find((cartProd) => cartProd.get('id') === product.get('query_id'))
                if (cartProduct && cartProduct.get('data')) {
                    props.addUpdateProductToCart({
                        ...cartProduct.get('data').toJS(),
                        quantity: quantity,
                        storeQueryId: storeQueryId,
                        merchantAddressId: storeDetailInfo && storeDetailInfo.get('address') && storeDetailInfo.get('address').get('id'),
                        uniqueId: product?.get("query_id")
                    })
                }
                else {
                    props.addUpdateProductToCart({
                        ...product?.toJS(),
                        quantity: quantity,
                        merchantAddressId: storeDetailInfo && storeDetailInfo.get('address') && storeDetailInfo.get('address').get('id'),
                        storeQueryId: storeQueryId,
                        uniqueId: product?.get("query_id")
                    })
                }
            }
        }
    }
    const getSubCategoryProducts = (subCategoryId) => {
        let subProducts;
        if (subCategoryId)
            subProducts = subCategoryProducts && subCategoryProducts.find((pro) => pro.get('subCategoryId') == subCategoryId && pro.get('page') == state.selectedPage)
        if (execStatusGetStoreDetailInfo.status === "PENDING") {
            return <div className='w-100 pt-1'>
                <Grid container>
                    {Array.from(new Array(6)).map((_, i) =>
                        <Grid key={i} xs={12} sm={12} md={12} lg={12}>
                            <OrderCheckoutSkeleton index={i} />
                        </Grid>
                    )}
                </Grid>
            </div>
        }
        else if (subProducts && subProducts.size == 0 && execStatusGetProducts.status === "PENDING") {
            return <div className='w-100 pt-1'>
                <Grid container>
                    {Array.from(new Array(6)).map((_, i) =>
                        <Grid key={i} xs={12} sm={12} md={12} lg={12}>
                            <OrderCheckoutSkeleton index={i} />
                        </Grid>
                    )}
                </Grid>
            </div>
        }
        else if (!subProducts && execStatusGetProducts.status === "PENDING") {
            return <div className='w-100 pt-1'>
                <Grid container>
                    {Array.from(new Array(6)).map((_, i) =>
                        <Grid key={i} xs={12} sm={12} md={12} lg={12}>
                            <OrderCheckoutSkeleton index={i} />
                        </Grid>
                    )}
                </Grid>
            </div>
        }
        else if (subProducts && subProducts.get('products')?.get('products')?.size > 0) {
            return subProducts.get('products').get('products').map((product, index) => {
                return <Grid key={index} xs={12} sm={12} md={12} lg={12} item>
                    <ProductBox
                        {...props}
                        collapsed={expandedProductIndex === index}
                        onClick={() => handleProductBoxClick(index)}
                        execStatusGetCartProducts={execStatusGetCartProducts}
                        auth={auth}
                        execStatusAddUpdateProductToCart={execStatusAddUpdateProductToCart}
                        cartProducts={cartProducts}
                        addProductToCartId={addProductToCartId}
                        onAdd={(quantity) => addToCart(product, quantity)}
                        image={getImageUrl(product.get('pictures')?.get(0)?.get('image_thumb_url'))}
                        secondaryImage={getImageUrl(product?.get('pictures')?.get(0)?.get('image_original_url'))}
                        label={product.get('name')}
                        setAddProductToCartId={setAddProductToCartId}
                        cost={product.get('cost') && formatAmountV1(product.get('cost'))}
                        product={product}
                        storeQueryId={storeQueryId}
                        industryId={industryId}
                        categoryId={categoryId}
                        page={state.selectedPage}
                        subCategoryId={subCategoryId}
                        onQtyChange={(quantity) => onQtyChange(product, quantity)}
                        description={renderHtml(product.get('description'))}
                    />
                </Grid>
            })
        }
        else if (!subProducts && execStatusStoreProductCategory.status === "PENDING") {
            return <div className='w-100 pt-1'>
                <Grid container>
                    {Array.from(new Array(6)).map((_, i) =>
                        <Grid key={i} xs={12} sm={12} md={12} lg={12}>
                            <OrderCheckoutSkeleton index={i} />
                        </Grid>
                    )}
                </Grid>
            </div>
        }
        else if (subProducts && subProducts.get('products')?.get('products')?.size == 0) {
            return <div className={`${getMessageColor()} w-100 d-flex justify-content-center paper-root p-4 m-3`}>
                No Data Found
            </div>
        }
        else {
            return <div className='w-100 pt-1'>
                <Grid container>
                    {Array.from(new Array(6)).map((_, i) =>
                        <Grid key={i} xs={12} sm={12} md={12} lg={12}>
                            <OrderCheckoutSkeleton index={i} />
                        </Grid>
                    )}
                </Grid>
            </div>
        }
    }
    const getTotalPageCount = () => {
        let subProducts;
        if (subCategoryId)
            subProducts = subCategoryProducts && subCategoryProducts.find((pro) => pro.get('subCategoryId') == subCategoryId)

        if (subProducts) {
            return subProducts && Math.ceil(subProducts?.get('products')?.get('total_products') / PRODUCT_PAGE_SIZE)
        }
        else return 1
    }
    const checkStoreInRadius = (storeLat, storeLng, serviceAreaRadius) => {
        let location = getSelectedAddressLatLng(auth);
        if (location.lat && location.long && storeLat && storeLng && serviceAreaRadius) {
            return storeIsInRadius(location.lat, location.long, storeLat, storeLng, serviceAreaRadius)
        }
        else {
            return true;
        }
    }
    let totalPrice = 0;
    for (let cartProd of cartProducts) {
        if (cartProd && cartProd?.get('data')?.get('promotion') && cartProd?.get('data')?.get('promotion').get('promotion_amount')) {
            totalPrice = totalPrice + (cartProd.get('data')?.get('cost_after_discount') * cartProd.get('data')?.get('quantity'))
        }
        else
            totalPrice = totalPrice + (cartProd.get('data')?.get('cost') * cartProd.get('data')?.get('quantity'))
    }
    const getStoreDetailInfoName = () => {
        if (storeDetailInfo) {
            if (storeDetailInfo.get('address') && storeDetailInfo.get('address').get('lat') && storeDetailInfo.get('address').get('long')) {
                if (!checkStoreInRadius(storeDetailInfo.get('address').get('lat'), storeDetailInfo.get('address').get('long'), storeDetailInfo.get('service_area_radius'))) {
                    return <div className={`pl-3 ${getMessageColor()}`} style={{
                        fontSize: 'initial'
                    }}>
                        Not in your delivery area
                    </div>
                }
                else {
                    return ``
                }
            }
            else
                return ``
        }
        else return ''
    }
    const onProductSearch = (value) => {
        props.resetProducts(false, {
            resetSearchedProducts: true
        })
        setState({ ...state, resetSearchPanel: false })
        if (value) {
            props.history.push(`/products/product-list/${storeQueryId}/${categoryId}/SEARCH_BY_CATEGORY_ID/${industryId}?name=${value}`);
            props.getProducts({
                storeQueryId: storeQueryId,
                categoryId: categoryId,
                //subCategoryId: subCategoryId,
                page: state.selectedPage,
                name: value
            })
        }
        else {
            let subCat = selectedStoreProductCategory && selectedStoreProductCategory?.get('sub_categories') && selectedStoreProductCategory?.get('sub_categories').get(0);
            if (subCat) {
                props.history.push(`/products/product-list/${storeQueryId}/${categoryId}/${subCat.get('id')}/${industryId}?name=${value}`);
                props.getProducts({
                    storeQueryId: storeQueryId,
                    categoryId: categoryId,
                    subCategoryId: subCat.get('id'),
                    page: state.selectedPage,
                    name: value
                })
            }
        }
    }
    const onCheckOut = () => {
        // props.push('/checkout/cart-summary');
        props.history.push(`/checkout/cart-summary-confirmation?trade_type_id=${tradeTypeId}`);
    }
    const onCategoryChange = (category) => {
        let subCategoryId = category && category.get('sub_categories') && category.get('sub_categories').get(0) && category.get('sub_categories').get(0).get('id');
        props.history.push(`/products/product-list/${storeQueryId}/${category.get('id')}/${subCategoryId}/${industryId}`);
        //props.resetTheSearchPanel();
        props.resetProducts(false, {
            resetSearchedProducts: true
        })
        props.setSelectedStoreProductCategory({ ...category?.toJS(), storeQueryId: storeQueryId })
        window.scrollTo({
            top: 350,
            left: 0,
            behavior: 'smooth'
        });
    }
    const isTypographyStyle = window.INDEX_NOKIA_ARENA === true;
    const bannerUrl = storeDetailInfo && storeDetailInfo.size > 0 ? storeDetailInfo.get('banner_url') : '' ;
    const addressDescription = storeDetailInfo && storeDetailInfo.size > 0 && storeDetailInfo.get('address') && storeDetailInfo.get('address')?.get('description');
    return (
        <SiteContentWrapper
            {...props}
            showBottomNavigation={true}
            isToShowAppScroll={false}
            topNavigationName={storeDetailInfo && storeDetailInfo?.get('name')}
            topNavigationLabel={storeDetailInfo && storeDetailInfo.get('address') && storeDetailInfo.get('address')?.get('description')}
            //IOS_TESTING
            // contentMaxHeight={browser === 'Safari' && cartProducts?.size > 0 ? '400px' : browser === 'Safari' ? '450px' : undefined}
            // bottomHeight={cartProducts?.size > 0 ? '170px' : '78px'}
            bottomContent={cartProducts?.size > 0 ?
                <div className={`${isTypographyStyle ? 'pt-2 pb-0 w-100 pl-2 pr-2' : 'pt-2 pb-2 w-100 pl-4 pr-4'}`}>
                    {
                        cartProducts?.size > 0 ?
                            <CheckoutBasketV2
                                label={totalPrice}
                                {...props}
                                onCheckOut={onCheckOut}
                                count={cartProducts?.size}
                            /> : null
                    }
                    {
                        execStatusGetProducts.status === "ERROR" &&
                        <div className="w-100">
                            <p>
                                <div className="d-flex pt-3 text-danger justify-content-center w-100">
                                    {execStatusGetProducts?.errorDetail?.get('message')}
                                </div></p>
                        </div>
                    }
                </div> : null
            }
        >

            <div id='sub-category-content' className={clsx(window.INDEX_NOKIA_ARENA ?'vh_height80' :'', window.INDEX_NOKIA_ARENA && classes.productItemsWrapper)}
                style={isTypographyStyle
                    ? { paddingBottom: "0",paddingRight:"0" }
                    : {}
                }

            >
                {/* <div className={clsx(!(mobileWidth || tabletWidth) && 'w-70 mb-2', tabletWidth && 'w-30', mobileWidth && 'w-100', 'bg-white position-absolute z-index-1000 d-flex')}>
                    <PageHeading
                        redirect={goBack}
                        name={selectedStoreProductCategory && selectedStoreProductCategory?.get('name')}
                        subText={selectedStoreProductCategory && selectedStoreProductCategory.get("merchant")}
                        endText={getStoreDetailInfoName()}
                        loading={execStatusStoreProductCategory.status === "PENDING" || execStatusGetStoreDetailInfo.status === "PENDING" ? true : false}
                    />
                </div> */}
                
                    <div className={clsx(!(mobileWidth || tabletWidth) && 'w-100 d-flex flex-row-reverse align-items-center h-100',
                        (mobileWidth || tabletWidth) && 'w-100 d-flex flex-column h-100 justify-content-center mt-4 mb-4', `sticky bg-style ${getSwiperTopClass()}`)}>
                        {/* <div className={clsx(!(mobileWidth || tabletWidth) && 'w-30', (mobileWidth || tabletWidth) && 'w-100', 'position-relative')}>
                        <MerchantDetail
                            execStatusGetStoreDetailInfo={execStatusGetStoreDetailInfo}
                            {...props}
                        />
                    </div> */}

                        <div className="w-100 h-100 rounded w-100 pr-2 pb-1">
                            <div className={clsx(!mobileWidth && "h-100 d-flex flex-column", mobileWidth && 'd-block')}>
                                {/* <div className={clsx(!(mobileWidth || tabletWidth) && "w-100", tabletWidth && 'w-100', mobileWidth && 'w-100 m-auto')}>
                            <div className={clsx(!(mobileWidth || tabletWidth) && 'pt-1', (mobileWidth || tabletWidth) && '')}>
                                <SearchPanel
                                    //defaultValue={name}
                                    reset={state.resetSearchPanel}
                                    loading={execStatusGetProducts.status === "PENDING" ? true : false}
                                    placeholder="Search"
                                    onSearch={onProductSearch}
                                />
                            </div>
                        </div> */}
                                {selectedProductCategories && selectedProductCategories?.get('listCategory')?.get('categories')?.size > 1 ?
                                    <div key="show-all-products-swiper-div" className={clsx(!(mobileWidth || tabletWidth) && "w-100", tabletWidth && 'w-100', mobileWidth && 'w-95 m-auto', 'pt-3 pb-3')}>
                                        <Swiper
                                            key="show-all-products-swiper"
                                            slidesPerView={'auto'}
                                            mousewheel={true}
                                            navigation={!mobileWidth && true}
                                            keyboard={true}
                                            freeMode={true}
                                            className={clsx('swiper', mobileWidth ? 'pl-2 pr-2' : 'pl-5 pr-5')}>

                                            {
                                                !selectedStoreProductCategory &&
                                                    execStatusStoreProductCategory.status === "PENDING" ? (
                                                    <div>
                                                        {/* <CategoryListSkeleton /> */}
                                                        <Skeleton variant="rectangular" width={'100%'} height={25} />
                                                    </div>
                                                ) : (
                                                    selectedProductCategories && (
                                                        <>
                                                            <SwiperSlide
                                                                key="show-all-products"
                                                                onClick={showAllProducts}
                                                                className={clsx(
                                                                    (!mobileWidth || tabletWidth) && "swiper-slide mr-2 ml-2",
                                                                    mobileWidth && "swiper-slide-responsive mr-1 ml-1"
                                                                )}
                                                            >
                                                                <CategoryChip
                                                                    title={window.INDEX_NOKIA_ARENA === true ? t("messages.nokiaArenaFilterButton"): 'ALL'}
                                                                    selected={selectedStoreProductCategory && selectedStoreProductCategory.get('categoryId') === 'ALL'}
                                                                />
                                                            </SwiperSlide>

                                                        </>
                                                    )
                                                )
                                            }
                                            {
                                                !selectedStoreProductCategory &&
                                                    execStatusStoreProductCategory.status === "PENDING" ?
                                                    <div>
                                                        {/* <CategoryListSkeleton /> */}
                                                        <Skeleton variant="rectangular" width={'100%'} height={25} />
                                                    </div> :
                                                    selectedProductCategories && selectedProductCategories?.get('listCategory')?.get('categories')?.size > 0 &&
                                                    selectedProductCategories.get('listCategory').get('categories').map((category, index) => {
                                                        return <>
                                                            <SwiperSlide key={index} onClick={() => onCategoryChange(category, index)} className={clsx((!mobileWidth || tabletWidth) && "swiper-slide mr-2 ml-2", mobileWidth && "swiper-slide-responsive mr-1 ml-1")} >
                                                                <CategoryChip
                                                                    selected={selectedStoreProductCategory && selectedStoreProductCategory?.get('id') === category.get('id')}
                                                                    title={category.get('name')}
                                                                    image={getImageUrl(category?.get('picture')?.get('image_thumb_url'))}
                                                                />
                                                            </SwiperSlide>
                                                        </>

                                                    })
                                            }
                                        </Swiper>
                                    </div> : null
                                }
                            </div>
                            <div>
                                {selectedStoreProductCategory && selectedStoreProductCategory?.get('sub_categories').size > 1 ?
                                    < div className={clsx(!(mobileWidth || tabletWidth) && "w-100", tabletWidth && 'w-100', mobileWidth && 'w-95 m-auto', 'pt-1 pb-1')}>
                                        <Swiper
                                            slidesPerView={'auto'}
                                            mousewheel={true}
                                            // navigation={true}
                                            keyboard={true}
                                            freeMode={true}
                                            className="swiper pl-2 pr-5">
                                            {
                                                !selectedStoreProductCategory &&
                                                    execStatusStoreProductCategory.status === "PENDING" ?
                                                    <div>
                                                        {/* <CategoryListSkeleton /> */}
                                                        <Skeleton variant="rectangular" width={'100%'} height={25} />
                                                    </div> :
                                                    selectedStoreProductCategory && selectedStoreProductCategory?.get('sub_categories') && selectedStoreProductCategory?.get('sub_categories').map((category, index) => {
                                                        return <>
                                                            <SwiperSlide key={index} onClick={() => changeSubCategory(category)} className={clsx((!mobileWidth || tabletWidth) && "swiper-slide-sub-category mr-1 ml-1", mobileWidth && "swiper-slide-responsive mr-1 ml-1")} >
                                                                <CategoryChip
                                                                    useDefaultStyle={false}
                                                                    selected={category.get("id") == subCategoryId ? true : false}
                                                                    title={category.get('name')}
                                                                // image={getImageUrl(category.get('picture')?.get('image_thumb_url'))}
                                                                />
                                                            </SwiperSlide>
                                                        </>
                                                    })
                                            }
                                        </Swiper>
                                    </div> : null
                                }
                            </div>
                        </div>

                    </div>
                
                {window.INDEX_NOKIA_ARENA === true &&
                    <>
                        <div className='ml-3 mr-3'>
                            <img src={process.env.PUBLIC_URL + '/assets/images/store-category.png'} style={{ width: "auto", display: "block", margin: "0 auto" }} />
                            <h2 style={{ textAlign: "center", fontSize: "24px",marginBottom:"30px" }}>The Kitchen</h2>
                            <div className='position-relative'>
                                <img src={getImageUrl(bannerUrl)} className={clsx(classes.imageStyleBox)} />
                                <Typography className='pl-3 pr-4 text-center text-ellipsis-clamp-1 w-60 position-absolute' variant='body3' style={{ color: "#1558CE", top: "25%", right: "15%", background: "#fff", borderRadius: "50px" }}>{t("messages.location")} {addressDescription}</Typography>
                                <p style={{ padding: "15px 0", fontSize: "16px" }}>{t("messages.nokiaArenaKitchenOperation")}</p>
                            </div>

                        </div>
                    </>
                }
                <div className={clsx(
                    !window.INDEX_NOKIA_ARENA && (
                        selectedStoreProductCategory && (selectedStoreProductCategory?.get('sub_categories').size > 1 || selectedProductCategories?.get('listCategory')?.get('categories')?.size > 1) ?
                            'vh_height67' :
                            'vh_height80'
                    ),
                    !window.INDEX_NOKIA_ARENA && classes.productItemsWrapper
                )} >
                    {execStatusStoreProductCategory.status === "PENDING" ?
                        <div className='w-100 pt-1'>
                            <Grid container>
                                {Array.from(new Array(6)).map((_, i) =>
                                    <Grid key={i} xs={12} sm={12} md={12} lg={12}>
                                        <OrderCheckoutSkeleton index={i} />
                                    </Grid>
                                )}
                            </Grid>
                        </div> :
                        selectedStoreProductCategory && selectedStoreProductCategory?.get('subCategoryId') == "ALL" ?
                            <Grid spacing={2} container className={clsx((!mobileWidth || tabletWidth) && 'pl-3 pr-3', 'mb-2 pl-3 pr-3', classes.container)}>
                                {getSubCategoryProducts("ALL")}
                            </Grid>
                            : selectedStoreProductCategory && (selectedStoreProductCategory?.get('sub_categories').size == 0) ?
                                < div className={`${getMessageColor()} w-100 d-flex justify-content-center paper-root p-4 m-3`}>
                                    No Data Found
                                </div>
                                :
                                selectedStoreProductCategory &&
                                selectedStoreProductCategory.get('sub_categories') &&
                                selectedStoreProductCategory.get('sub_categories').map((category, index) => {
                                    return (<div key={index} className="w-100">
                                        {selectedStoreProductCategory && selectedStoreProductCategory?.get('sub_categories').size > 1 ?
                                            <div className='pb-2 pt-2' ref={(ref) => pushProductRef(ref, category.get('id'))}>
                                                <Typography variant='body2' className='font-weight-bold pl-3 pr-3'>
                                                    {category.get('name')}
                                                </Typography>
                                            </div> : null
                                        }
                                        <Grid spacing={2} container className={clsx((!mobileWidth || tabletWidth) && 'pl-3 pr-3', 'mb-2', classes.container)}>
                                            {getSubCategoryProducts(category.get('id'))}
                                        </Grid>
                                        {/* <div className='w-100 d-flex flex-column align-items-center justify-content-center mt-2 mb-2'>
                                        <Button onClick={() => onLoadMoreSubCategories(category, index)} className='width-max-content text-capitalize' color='primary'>Load More</Button>
                                    </div> */}
                                    </div>)
                                })}
                </div>
                {
                    getTotalPageCount() > 1 ?
                        <div className='pt-3 pb-2'>
                            <Pagination
                                page={state.selectedPage}
                                onChange={onPageChange}
                                color="secondary"
                                count={getTotalPageCount()}
                                shape="rounded"
                            />
                        </div> : null
                }


                {
                    state.showProductErrorDialogOpen === true ?
                        <AddProductErrorDialog
                            redirectTo={redirectTo}
                            selectedProduct={state.selectedProduct}
                            hideProductErrorDialog={hideProductErrorDialog}
                            isOpen={state.showProductErrorDialogOpen}
                            {...props} /> : null
                }


                {window.INDEX_NOKIA_ARENA === true &&
                    <div style={{ background: "#000", marginTop: "60px" }}>
                        <h2 style={{ padding: "35px 20px", textAlign: "center", fontSize: "24px", color: "#fff" }}>All Food Pick-Up Locations</h2>
                        <img
                            style={{ width: "100%" }}
                            src={`${process.env.PUBLIC_URL}/assets/images/foods-pickup-location.png`}
                        />
                        <div style={{ color: "#fff", borderTop: "1px solid", textAlign: "center", width: "75%", margin: "0 auto", marginTop: "100px", padding: "15px" }}>
                            © Nokia Arena
                        </div>
                    </div>
                }
            </div>
        </SiteContentWrapper >
    )
}
export default connect(null, { push })(SubCategoryProductsList);