import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    btn: {
        background: theme.palette.greyColor.main,
        color: theme.palette.dark.main,
        fontSize: '20px',
        boxShadow: 'none',
        minWidth: '20px',
        width: '25px',
        height: '25px',
        padding: '0px',
        '&:hover': {
            background: theme.palette.greyColor.main,
            color: theme.palette.dark.main
        }
    },
    btnSPlus: {
        background: 'transparent',
        color: '#141423',
        minWidth: '20px',
        width: '35px',
        height: '35px',
        padding: '0px',
        '&:hover': {
            background: theme.palette.counterButtonColor.dark,
            color: theme.palette.dark.main,
        }
    },
    btnProminent: {
        background: theme.palette.primary.main,
        color: theme.palette.background.paper,
        fontSize: '20px',
        boxShadow: 'none',
        minWidth: '20px',
        width: '40px',
        height: '40px',
        padding: '0px',
        '&:hover': {
            background: theme.palette.primary.dark,
            color: theme.palette.background.paper,
        }
    }
}));