import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles(theme => ({
    container: {
        // height: '100vh',
        // backgroundColor: '#F8F8F8'
    },
    bottomNavigation: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        maxWidth: '900px',
        margin: 'auto',
        display: 'inline-block',
        // backgroundColor: theme.palette.background.paper,// TODO test
        // overflowX: 'hidden',
        // overflowY: 'auto'
    },
    bottomNavigationProminent: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        maxWidth: '960px',
        margin: 'auto',
        display: 'inline-block',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '10px 10px 0px 0px'
        // overflowX: 'hidden',
        // overflowY: 'auto'
    },
    logo: {
        backgroundColor: '#F8F8F8',
        height: '51px',
        marginTop: '10px'
    },
    wrapperContent: {
        overflowX: 'hidden',
        overflowY: 'auto !important',
        paddingBottom: '70px',
        // paddingTop: '25px'
    },
    imageStyle: {
        // objectFit: 'contain',
        maxWidth: '100%',
        height: '50px',
        ObjectFit: 'contain',
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        margin:"0 auto"
    },
    bottomBar: {
        position: "absolute",
        bottom: 0,
        background: "#2A2E30",
        width: "100%",
        padding: 50,
      },
}))