import { useStyle } from "./checkout.screen.style";
import "./checkout.screen.scss";
import React from "react";
import {
  Typography,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Radio,
} from "@mui/material";
import OrderPanel from "components/common/order.panel/order.panel";
import SiteLabelTextField from "components/common/site.label.textfield/site.label.textfield";
import clsx from "clsx";
import { IsMobileWidth } from "components/common/util/util";

const CheckoutScreen = () => {
  const classes = useStyle();
  const mobileWidth = IsMobileWidth();

  return (
    <Grid container id="checkout">
      <Grid item sm={12} lg={6} className="p-3">
        <Typography variant="h5" className="mb-3">
          Checkout
        </Typography>
        <div className="p-4 mb-2 border-outline">
          <div
            className={clsx(
              !mobileWidth && "d-flex w-100",
              mobileWidth && "d-block w-100"
            )}
          >
            <div
              className={clsx(
                !mobileWidth && "w-50 mr-4",
                mobileWidth && "w-100 mr-0"
              )}
            >
              <SiteLabelTextField topAdornment="First Name" />
            </div>

            <div
              className={clsx(!mobileWidth && "w-50", mobileWidth && "w-100")}
            >
              <SiteLabelTextField topAdornment="Last Name" />
            </div>
          </div>

          <div className="mt-3">
            <SiteLabelTextField topAdornment="Company (Optional)" />
          </div>
          <div className="mt-3">
            <SiteLabelTextField topAdornment="Address Line 1" />
          </div>
          <div className="mt-3">
            <SiteLabelTextField topAdornment="Address Line 2" />
          </div>

          <div
            className={clsx(
              !mobileWidth && "d-flex mt-3 w-100",
              mobileWidth && "d-block w-100"
            )}
          >
            <div
              className={clsx(
                !mobileWidth && "w-50 mr-4",
                mobileWidth && "w-100 mr-0"
              )}
            >
              <SiteLabelTextField topAdornment="State" />
            </div>

            <div
              className={clsx(!mobileWidth && "w-50", mobileWidth && "w-100")}
            >
              <SiteLabelTextField topAdornment="State/Province" />
            </div>
          </div>

          <div className="mt-3">
            <SiteLabelTextField topAdornment="Country" />
          </div>

          <div
            className={clsx(
              !mobileWidth && "d-flex mt-3 w-100",
              mobileWidth && "d-block w-100"
            )}
          >
            <div
              className={clsx(
                !mobileWidth && "w-50 mr-4",
                mobileWidth && "w-100 mr-0"
              )}
            >
              <SiteLabelTextField topAdornment="Mobile" />
            </div>

            <div
              className={clsx(!mobileWidth && "w-50", mobileWidth && "w-100")}
            >
              <SiteLabelTextField topAdornment="Phone (Optional)" />
            </div>
          </div>

          <div className="mt-3">
            <FormControlLabel
              control={<Checkbox />}
              label="save this information for the next time"
            />
          </div>
        </div>
        <div class="mt-3 unique">
          <FormControlLabel
            control={<Checkbox />}
            label="Skip to a different address?"
          />
        </div>
        <div className="p-4 mb-2 mt-4 border-outline">
          <Typography variant="h6" className="mb-3">
            Shipping Method
          </Typography>
          <div className="mb-2 unique">
            <SiteLabelTextField
              startAdornment={
                <FormControlLabel
                  control={<Radio />}
                  label="Free Delivery"
                  className="radio"
                />
              }
              endAdornment="Free"
            />
          </div>
        </div>
        <div className="p-4 mb-4 mt-4 border-outline">
          <Typography variant="h6" className="mb-3">
            Payment
          </Typography>
          <div
            className={clsx(
              !mobileWidth && "w-100 mb-2 d-flex",
              mobileWidth && "w-100 d-block"
            )}
          >
            <div
              className={clsx(
                !mobileWidth && "w-50 mr-4",
                mobileWidth && "w-100 mb-3 mr-0"
              )}
            >
              <div className="unique">
                <SiteLabelTextField
                  startAdornment={
                    <FormControlLabel
                      control={<Radio />}
                      label="Cash"
                      className="radio"
                    />
                  }
                />
              </div>
            </div>
            <div
              className={clsx(
                !mobileWidth && "w-50 mr-4",
                mobileWidth && "w-100 mr-0"
              )}
            >
              <div className="unique">
                <SiteLabelTextField
                  startAdornment={
                    <FormControlLabel
                      control={<Radio />}
                      label="Credit/Debit Card"
                      className="radio"
                    />
                  }
                  endAdornment=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-3">
          <FormControlLabel
            control={<Checkbox />}
            label="I have read and agree to the website terms & Conditions"
          />
        </div>
        <Typography>
          <Button
            className="w-100"
            classes={{
              root: classes.btn,
            }}
          >
            Continue
          </Button>
        </Typography>
      </Grid>
      <Grid item sm={12} lg={6} className="p-3">
        <OrderPanel />
        <div className="mt-5 p-4 border-outline">
          <Typography variant="h5">Need Help?</Typography>
          <Typography className="mt-3">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur
            pariatur natus asperiores recusandae voluptatem earum vero, non,
            unde, cupiditate similique quasi expedita dignissimos consequuntur
            quos dicta maxime incidunt sunt. Ipsum!
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default CheckoutScreen;
