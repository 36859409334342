import React from "react";
import ContentDialog from "components/common/material.components/content.dialog/content.dialog";
import { Box, IconButton, Typography } from "@mui/material";

const QrDialog = (props) => {
  const { isQrDialogOpen, heading } = props
  return (
    <div>
      <ContentDialog
        backDropColor='#353935'
        open={isQrDialogOpen}
        transparentDialog={true}
        dividers={false}
        maxWidth="xs"
        actions={
          <Box className="d-flex justify-content-center w-100">
            <Box sx={{ background: "#fff", borderRadius: "50%" }}>
              <IconButton onClick={props.hideQrDialog}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`}
                  width="24px"
                />
              </IconButton>
            </Box>
          </Box>
        }
      >
        {heading && (
          <Box className="d-flex justify-content-center" mb={2}>
            <Typography variant="h4" color="#fff">
              {heading}
            </Typography>
          </Box>
        )}
        {props.children}
      </ContentDialog>
    </div>
  );
};

export default QrDialog;
