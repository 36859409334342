import React, { useRef, useState, useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { useStyle } from './site.content.wrapper.style';
import './site.content.wrapper.scss'
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx';
import BottomNavigationPanel from 'components/common/bottom.navigation.panel/bottom.navigation.panel';
import { useLocation } from 'react-router';
import { detectBrowser, getTheApplicationTheme, isDemoQRMe, isStandardPlusTheme } from 'components/common/util/util';
import { connect } from "react-redux";
import { getAppVersion } from 'view.updater/actions/app.actions';
import TopNavigationPanel from 'components/common/top.navigation.panel/top.navigation.panel';
import AppTopMenu from '../app.top.menu/app.top.menu';
//import AddNewAddressBar from '../add.new.address.bar/index';

const SiteContentWrapper = (props) => {
    const { classNames, bottomContent, showTopPannel = true, childrenHeight, showBottomNavigation, bottomHeight, contentMaxHeight, cartProducts, topNavigationName, topNavigationLabel, status, isToShowAppScroll = true } = props
    const classes = useStyle()
    const tabletWidth = IsTabletWidth()
    const mobileWidth = IsMobileWidth()
    const browser = detectBrowser()
    let location = useLocation()
    const [siteContentWrapperHeight, setSiteContentWrapperHeight] = useState(500);
    const containerHeightRef = useRef(null);

    useEffect(() => {
        const containerHeight = containerHeightRef.current.offsetHeight;
        setSiteContentWrapperHeight(containerHeight);
    }, [cartProducts]);

    const bottomContentRef = useRef(null);
    const [bottomContentHeight, setBottomContentHeight] = useState('auto');
    const updateBottomContentHeight = () => {
        if (bottomContentRef.current) {
            setBottomContentHeight(bottomContentRef.current.offsetHeight);
        }
    };
    useEffect(() => {
        document.getElementById('site-content-children') &&
            document.getElementById('site-content-children').addEventListener('touchmove', function (event) {
                event.stopPropagation();
            });
    }, [])
    useEffect(() => {
        setTimeout(() => {
            updateBottomContentHeight();
        }, 200)
    }, [bottomContentRef, cartProducts]);
    const getChildrenStyle = () => {
        if (browser === 'Firefox') {
            return contentMaxHeight ? contentMaxHeight : `calc(${siteContentWrapperHeight}px - ${bottomContentHeight}px)`
        }
        else if (browser === 'Safari') {
            return contentMaxHeight ? contentMaxHeight : `calc(${siteContentWrapperHeight}px - ${bottomContentHeight}px)`
        }
        else {
            return contentMaxHeight ? contentMaxHeight : `calc(${siteContentWrapperHeight}px - ${bottomContentHeight}px)`
        }
    }

    const isTypographyStyle = window.INDEX_NOKIA_ARENA === true;


    return (
        <div className={clsx(classNames, window.TRANSPORT_SERVICE === true ? "wrapperElement" : "")}>
            <Container ref={containerHeightRef} maxWidth='md' className={clsx((mobileWidth || tabletWidth) && 'pl-0 pr-0', 'position-relative overflow-hidden vw_width100 vh_height100 pl-0 pr-0', classes.container)}>
                <Grid id="site-content-children" item xs={12} sm={12} md={12} lg={12} className={clsx(isToShowAppScroll && classes.wrapperContent, 'w-100 p-0')}
                    style={{
                        height: childrenHeight ? childrenHeight : window.TRANSPORT_SERVICE === true ? "auto" : getChildrenStyle()
                    }}
                >
                    {getTheApplicationTheme() === 'PROMINENT' && showTopPannel ?
                        <div className='w-100 z-index-1200 top-n1 sticky mb-2'>
                            <TopNavigationPanel
                                name={topNavigationName}
                                label={topNavigationLabel}
                                status={status}
                            />
                        </div>
                        : getTheApplicationTheme().includes('STANDARD') && window.TRADE_TYPE_IMAGE_URL && showTopPannel ?
                            <div className={clsx(
                                !window.INDEX_NOKIA_ARENA && classes.logo, 'd-flex justify-content-center w-100 top-n1 sticky' && window.TRANSPORT_SERVICE === true ? "transparentBackground" : "")}>
                                {window.INDEX_NOKIA_ARENA ? (
                                    <AppTopMenu />
                                ) : (
                                    isDemoQRMe ? (
                                        <img
                                            className={clsx(classes.imageStyle, "pt-1 pb-1")}
                                            src={`${process.env.PUBLIC_URL}/assets/images/qrme-logo-white-bg.png`}
                                        />
                                    ) : (
                                        <img
                                            className={classes.imageStyle}
                                            src={window.TRADE_TYPE_IMAGE_URL}
                                        />
                                    )
                                )}
                            </div>
                            :
                            null
                    }
                    {/* <div className='w-100'>
                        <AddNewAddressBar />
                    </div> */}
                    <div  className={`${isTypographyStyle ? 'w-100 pl-0 pr-0 no-padding' : 'w-100 pl-3 pr-3 no-padding'}`}>
                        {props.children}
                    </div>
                    {window.INDEX_NOKIA_ARENA  && window.location.pathname == '/products' &&
                        <div style={{ background: "#000" , marginTop:"60px"}}>
                            <h2 style={{ padding: "35px 20px", textAlign: "center", fontSize: "24px", color:"#fff" }}>All Food Pick-Up Locations</h2>
                            <img
                                style={{ width: "100%" }}
                                src={`${process.env.PUBLIC_URL}/assets/images/foods-pickup-location.png`}
                            />
                            <div style={{color:"#fff", borderTop:"1px solid", textAlign: "center",width:"75%", margin:"0 auto", marginTop:"100px", padding:"15px"}}>
                                © Nokia Arena
                            </div>
                        </div>
                    }
                </Grid>
                <div className={clsx(getTheApplicationTheme() === 'PROMINENT' ? classes.bottomNavigationProminent :
                    getTheApplicationTheme().includes('STANDARD') ? classes.bottomNavigation : classes.bottomNavigation,
                    'w-100 d-flex flex-column justify-content-end')} ref={bottomContentRef} style={{ height: 'auto' }}>
                    {bottomContent ? <div className={clsx(!window.TRANSPORT_SERVICE && 'pt-1 pb-0')}>  {bottomContent}</div> : null}
                    {(showBottomNavigation && getTheApplicationTheme().includes('STANDARD')) && !window.INDEX_NOKIA_ARENA ? <div> <BottomNavigationPanel {...props} /></div> : null}
                </div>
            </Container>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => ({
    getAppVersion: () => dispatch(getAppVersion())
});
export default connect(null, mapDispatchToProps)(SiteContentWrapper);
