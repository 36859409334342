import React from 'react'
import { useStyle } from './address.chip.style'
import { Typography } from '@mui/material'

const AddressChip = (props) => {
    const { iconPath, addressTitle, iconWidth } = props
    const classes = useStyle()
    return (
        <Typography className="d-flex justify-content-center align-items-center" classes={{ root: classes.addressSelector }}>
            <div className='mr-2'>
                <img
                    src={iconPath ? iconPath : ''}
                    width={iconWidth ? iconWidth : "15px"}
                    alt='address_icon'
                />
            </div>
            <div className="d-block">
                <Typography variant="subtitle2">
                    Deliver to
                </Typography>
                <div className="d-flex">
                    <Typography classes={{ root: classes.textStyle }} className='text-capitalize'>
                        {addressTitle}
                    </Typography>
                    <img
                        className='ml-2'
                        src={`${process.env.PUBLIC_URL}/assets/icons/expand-more-icon.svg`}
                        width="12px"
                        alt='expand_more'
                    />
                </div>
            </div>
        </Typography>
    )
}

export default AddressChip