import React, { useState } from 'react'
import { useStyle } from './merchant.detail.style';
import { Typography, Divider, Button } from '@mui/material'
import { formatAmountV1, getTotalDeliveryTime, format12HrTime, getDeliveryFee } from 'components/common/util/util';
import ProductPageHeader from 'components/common/product.page.header/product.page.header';
import ProductPageHeaderSkeleton from 'components/common/skeletons/product.page.header.skeleton/product.page.header.skeleton';
import MerchantDetailnfoDialog from 'components/common/merchant.detail.info.dialog/merchant.detail.info.dialog';

const MerchantDetail = (props) => {
    const { storeDetailInfo, execStatusGetStoreDetailInfo, auth } = props
    const classes = useStyle()
    const [state, setState] = useState({
        isMerchantDetailInfoDialogOpen: false
    })


    const getMerchantSubHeading = () => {
        let subHeading = '';
        if (storeDetailInfo && storeDetailInfo?.get('industries')) {
            for (let industry of storeDetailInfo.get('industries')) {
                if (!subHeading) {
                    subHeading = industry && industry.get('name')
                }
                else {
                    subHeading = `${subHeading}, ${industry && industry.get('name')}`
                }
            }
        }
        return subHeading;
    }

    const showMerchantDetailInfoDialog = () => {
        setState({ ...state, isMerchantDetailInfoDialogOpen: true })
    }

    const hideMerchantDetailInfoDialog = () => {
        setState({ ...state, isMerchantDetailInfoDialogOpen: false })
    }
    return (
        <div>
            {
                storeDetailInfo && storeDetailInfo.size > 0 ?
                    <div className='w-100'>
                        <ProductPageHeader
                            headerImage={storeDetailInfo?.get('banner_url')}
                            circularImage={storeDetailInfo?.get('picture_medium_url')}
                            tag={getTotalDeliveryTime(storeDetailInfo)}
                            heading={storeDetailInfo && storeDetailInfo?.get('name') && storeDetailInfo?.get('name')}
                            subHeading={<Typography classes={{ root: classes.darkColor }} variant="body2" className="text-ellipsis-clamp-1 text-capitalize h-auto">
                                {getMerchantSubHeading()}
                            </Typography>}
                            description={
                                <div className='d-flex'>
                                    <Typography classes={{ root: classes.darkColor }} variant="body2" className="text-ellipsis-clamp-1 text-capitalize h-auto">
                                        {`RS ${formatAmountV1(storeDetailInfo && storeDetailInfo?.get('min_amount') && storeDetailInfo?.get('min_amount'))} Minimum`}
                                    </Typography>
                                    <div className='d-flex'>
                                        <Divider classes={{ root: classes.divider }} className='pl-1' orientation="vertical" flexItem />
                                        <Typography classes={{ root: classes.darkColor }} variant="body2" className="text-ellipsis-clamp-1 text-capitalize h-auto pl-1">
                                            {getDeliveryFee(storeDetailInfo, auth)}
                                        </Typography>
                                    </div>
                                </div>
                            }
                            footer={
                                <div className='d-flex'><Typography variant='caption' component='div' className='pb-1'>
                                    <Typography variant='caption' component='div' classes={{ root: classes.btn }} className="pt-1 p-0 px-3 text-ellipsis-clamp-1 text-capitalize rounded-pill">
                                        {storeDetailInfo && storeDetailInfo?.get('shop_status') && storeDetailInfo?.get('shop_status')}
                                    </Typography>
                                </Typography>
                                    {/* {storeDetailInfo && storeDetailInfo?.get('is_open') && !storeDetailInfo?.get('is_open') ?
                                        < Typography classes={{ root: classes.darkColor }} variant="body2" className="text-ellipsis-clamp-1 pl-2">
                                            {`Opens at  ${storeDetailInfo && storeDetailInfo?.get('time_slots') && storeDetailInfo?.get('time_slots')?.get('0') &&
                                                storeDetailInfo?.get('time_slots')?.get('0')?.get('start_time') && format12HrTime(storeDetailInfo?.get('time_slots')?.get('0')?.get('start_time'))}`}
                                        </Typography> : null
                                    } */}
                                </div>
                            }
                            showMerchantDetailInfoDialog={showMerchantDetailInfoDialog}
                            {...props}
                        />
                    </div> :
                    execStatusGetStoreDetailInfo.status === "PENDING" ?
                        <div>
                            <ProductPageHeaderSkeleton />
                        </div> : null
            }
            {
                execStatusGetStoreDetailInfo.status === "ERROR" ?
                    <div className="d-flex justify-content-center w-100">
                        {execStatusGetStoreDetailInfo?.errorDetail?.get('message')}
                    </div> : null
            }

            {
                state.isMerchantDetailInfoDialogOpen ?
                    <MerchantDetailnfoDialog isMerchantDetailInfoDialogOpen={state.isMerchantDetailInfoDialogOpen}
                        hideMerchantDetailInfoDialog={hideMerchantDetailInfoDialog}
                        {...props} />
                    : null
            }
        </div >
    )
}

export default MerchantDetail