import { Typography } from '@mui/material'
import React from 'react'
import SiteContentWrapper from 'components/common/site.content.wrapper/site.content.wrapper'
import packageJson from "../../../package.json";
import { isDemoQRMe } from 'components/common/util/util';
import { renderHtml } from '../../components/common/util/util';
import { useTranslation } from "react-i18next";

const SiteDescription = () => {
    const { t } = useTranslation("global");
    const isTypographyStyle = window.INDEX_NOKIA_ARENA === true;
    return (
        <SiteContentWrapper
            topNavigationName='About us'
            showBottomNavigation={true}>
            <div className={`pt-3 pb-3 ${isTypographyStyle ? ' pl-0 pr-0 mt-2' : 'bg-white  pl-3 pr-3 mt-5'} `} >
                <Typography variant='body1' color='dark.main' className='pt-1 pb-1 font-weight-bold' style={isTypographyStyle
                    ? { width: "90%", margin: "0 auto", textAlign: "center", marginBottom: "20px", fontSize: "24px" }
                    : {}
                }>
                    {window.INDEX_NOKIA_ARENA === true ? t("messages.aboutUsHeading") : 'Provider of the service:'}
                </Typography>
                {
                    isDemoQRMe ?
                        <div style={isTypographyStyle
                            ? { background: "#F8F8F8", width: "95%", margin: "0 auto", textAlign: "center" }
                            : {}
                        }>
                            <Typography variant='body1' color='dark.main' className='pt-4' style={isTypographyStyle
                                ? { paddingBottom: "15px" }
                                : {}
                            }>

                                {window.INDEX_NOKIA_ARENA === true ?
                                    t("messages.aboutUsText")
                                    :
                                    renderHtml('KOy Tampereen Monitoimiareena concern (company ID: 2792951-4) <b>”qrme fi”</b>')
                                }
                            </Typography>
                            <Typography variant='body1' color='dark.main'>

                                {window.INDEX_NOKIA_ARENA === true ? t("messages.address") : 'Kansikatu 3, 33100 Tampere'}

                            </Typography>
                            <Typography variant='body1' color='dark.main'>
                                <a target='_blank' href="mailto:info@qrme.fi">

                                    {window.INDEX_NOKIA_ARENA === true ?
                                        renderHtml(t("messages.mailTo")) :
                                        renderHtml('<b>info@qrme.fi</b>')
                                    }
                                </a>
                            </Typography>
                            <Typography variant='body1' color='dark.main'>

                                {window.INDEX_NOKIA_ARENA === true ? t("messages.phoneNo") : '+358 50 511 7668'}

                            </Typography>
                            {/* <Typography variant='body1' color='dark.main' className='pt-4 font-weight-bold'>
                                Restaurants:
                            </Typography>
                            <Typography variant='body1' color='dark.main'>
                                Noho Partners Oyj (company ID1952494-7)
                            </Typography> */}
                        </div> :
                        <div style={isTypographyStyle
                            ? { background: "#F8F8F8", width: "95%", margin: "0 auto", textAlign: "center", padding: "0 10px 20px 10px", borderRadius: "10px" }
                            : {}
                        }>
                            <Typography variant='body1' color='dark.main' className='pt-4' style={isTypographyStyle
                                ? { paddingBottom: "15px" }
                                : {}
                            }>
                                {window.TRADE_TYPE_DESCRIPTION ? renderHtml(window.TRADE_TYPE_DESCRIPTION) : ""}
                            </Typography>
                            {/* <Typography variant='body1' color='dark.main'>
                                Kansikatu 3, 33100 Tampere
                            </Typography> */}
                            <Typography variant='body1' color='dark.main'>
                                <a target='_blank' href={`mailto:${window.TRADE_TYPE_COMPANY_EMAIL}`}> <b>{window.TRADE_TYPE_COMPANY_EMAIL}</b></a>
                            </Typography>
                            <Typography variant='body1' color='dark.main'>
                                {window.TRADE_TYPE_COMPANY_PHONE}
                            </Typography>
                            <Typography variant='body1' color='dark.main' className='pt-4 font-weight-bold'>
                                {!window.INDEX_NOKIA_ARENA && (window.INDEX_TRADE_TYPE_COMPANY_DETAILS_LABEL ? window.INDEX_TRADE_TYPE_COMPANY_DETAILS_LABEL : 'Choose your restaurant')}
                                {window.INDEX_NOKIA_ARENA === true &&
                                    t("messages.selectRestaurant")
                                }
                            </Typography>
                            <Typography variant='body1' color='dark.main'>
                                {
                                    window.INDEX_NOKIA_ARENA === true ?
                                        t("messages.restaurantDes") :
                                        `${window.TRADE_TYPE_COMPANY_DETAILS}`
                                }

                            </Typography>
                        </div>
                }
            </div>
            <div className='mt-3'>

                {window.INDEX_NOKIA_ARENA === true ?
                    <div style={{ color: "#000", borderTop: "1px solid", textAlign: "center", width: "75%", margin: "0 auto", padding: "15px" }}>
                        © Nokia Arena
                    </div> :
                    <Typography className='pt-2' variant='body18NL'>Version {packageJson.version}</Typography>
                }
            </div>
        </SiteContentWrapper>
    )
}

export default SiteDescription