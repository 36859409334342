import './app.scss';
import React from 'react';
import { Switch, Route } from 'react-router';
import { ConnectedRouter } from 'connected-react-router'
import Main from 'containers/main/main.index';
import Container from 'containers/main/container.index';
import DashBoardPage from 'containers/dashboard.page/loadable';
import InventoryMgmtPage from 'containers/inventory.mgmt.page/loadable';
import PromotionsPage from 'containers/promotions.page/loadable';
import OrdersMgmtPage from 'containers/orders.mgmt.page/loadable';
import TransactionsPage from 'containers/transactions.page/loadable';
import StaffMgmtPage from 'containers/staff.mgmt.page/loadable';
import PNLPage from 'containers/pnl.page/loadable';
import NotFoundPage from 'containers/not.found.page/loadable';
import TestPage from 'containers/test.page/test.page';
import ProductAddPage from 'containers/product.add.page/product.add';
//import LoginPage from 'containers/login.page/loadable'; // LOGIN_CHANGES
import ZappTestDesigns from 'containers/test.home.page/test.home.page';
import ZappTestDesignsCategory from 'containers/test.category.page/test.category.page';
import CheckoutScreen from 'containers/checkout.screen/checkout.screen';
import ReviewOrder from 'containers/review.order/review.order';
// import SignIn from 'containers/signin/loadable';
import ProductsPage from 'containers/products/index';
import ProductsStoreDetail from 'containers/products.store.detail/loadable';
import SubCategoryProductsList from 'containers/sub.category.products.list/index';
import ProductsDetails from 'containers/products.details/loadable';
//ROUTE_CHANGES
import packageJson from '../../../package.json';
import CartSummary from 'containers/checkout/components/cart.summary/index'
import OrderHistory from 'containers/order.history/index'
import Signup from 'containers/signup/loadable';
// import Login from 'containers/login/loadable';
import HomePage from 'containers/home.page/loadable';
import AboutUsPage from 'containers//aboutus.page/loadable';
import RidersPage from 'containers/riders.page/loadable';
import MerchantPartnersPage from 'containers/merchant.partners.page/loadable';
import CareersPage from 'containers/careers.page/loadable';
import NewsPage from 'containers/news.page/loadable';
import FaqsPage from 'containers/faqs.page/loadable';
import ContactPage from 'containers/contact.page/loadable';
import PrivacyPolicy from 'containers/privacy.policy/loadable';
import CookiesPolicy from 'containers/cookies.policy/loadable';
import TermsAndConditions from 'containers/terms.and.conditions/loadable';
//checkout screen template
// import CheckoutV2 from 'components/common/templates/checkout.v2/checkout.v2';
import ForgotPassword from 'containers/forget.password/loadable';
import CheckoutCartSummaryConfirmation from 'containers/checkout.cart.summary.confirmation/index';
import PaymentSuccess from 'containers/payment.success/index';
import TransportMainPage from 'containers/transport.main.page/loadable';
import SiteDescription from 'containers/site.description/site.description';
import NokiaArenaTermsAndConditions from 'containers/nokia.arena.terms.and.conditions/nokia.arena.terms.and.conditions';
import TransportLocationPickup from 'containers/transport.location.pickup/loadable';
import TransportConfirmBooking from 'containers/transport.confirm.booking/loadable';
import TransportConfirmPickupLocation from 'containers/transport.confirm.pickup.location/loadable';
import TransportBookingSuccess from 'containers/transport.booking.success/loadable';
import TransportStartJourney from 'containers/transport.start.journey/loadable';
import StripePaymentSuccess from 'containers/stripe.payment.success/loadable';
import TransportBookingHistory from 'containers/transport.booking.history/loadable';
import TransportAboutUsPage from 'containers/transport.about.us.page/loadable';
import TransportTerms from 'containers/terms/loadable';             
import TransportPrivacyPolicy from 'containers/transport.privacy.policy/loadable';             
// import ForgotPasswordTemplate from 'components/common/templates/forget.password/forget.password';
// import SignUpTemplate from 'components/common/templates/signup/signup';
global.appVersion = packageJson.version;

const App = (props) => (
  <Container>
    <ConnectedRouter history={props.history}>
      <Switch>
        <Switch>
          {/* <Route path="/signin" component={Login} /> */}
          {/* <Route path="/login" component={SignIn} /> */}
          {/* <Route path="/signup/:accountType" component={Signup} /> */}
          {/* <Route path="/signup-template" component={SignUpTemplate} />
          <Route path="/forgot-pin-template" component={ForgotPasswordTemplate} /> */}
          {/* <Route path="/login" component={LoginPage} /> */}
          <Route path="/signup/user" component={Signup} />
          <Route path="/forgot-pin" component={ForgotPassword} />
          <Route path='/home' component={HomePage} />
          <Route path='/about' component={AboutUsPage} />
          <Route path='/riders' component={RidersPage} />
          <Route path='/merchants' component={MerchantPartnersPage} />
          <Route path='/careers' component={CareersPage} />
          <Route path='/news' component={NewsPage} />
          <Route path='/faqs' component={FaqsPage} />
          <Route path='/contact' component={ContactPage} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route path='/cookies-policy' component={CookiesPolicy} />
          <Route path='/terms-and-conditions' component={TermsAndConditions} />
          <Route path='/nokia-arena-terms-and-conditions' component={NokiaArenaTermsAndConditions} />

          <Main>
            <Switch>
              <Route exact path="/" component={ProductsPage} />
              <Route exact path="/terms" component={TransportTerms} />
              <Route exact path="/transport/privacy-policy" component={TransportPrivacyPolicy} />
              <Route exact path="/transport/main-page" component={TransportMainPage} />
              <Route exact path="/transport/stripe/payment-success" component={StripePaymentSuccess} />
              <Route exact path="/transport/location-pickup" component={TransportLocationPickup} />
              <Route exact path="/transport/confirm-booking/:invoiceQueryId" component={TransportConfirmBooking} />
              <Route exact path="/transport/confirm-pickup-location" component={TransportConfirmPickupLocation} />
              <Route exact path="/transport/about-us" component={TransportAboutUsPage} />
              <Route exact path="/transport/booking-history" component={TransportBookingHistory} />
              <Route exact path="/transport/booking-success" component={TransportBookingSuccess} />
              <Route exact path="/transport/transport-start-journey" component={TransportStartJourney} />
              <Route path="/dashboard" component={DashBoardPage} />
              <Route path="/about-us" component={SiteDescription} />
              <Route path="/inventory-mgmt/add" component={ProductAddPage} />
              <Route path="/inventory-mgmt" component={InventoryMgmtPage} />
              <Route path="/promotions" component={PromotionsPage} />
              <Route path="/products/store/:storeQueryId/:industryId" component={ProductsStoreDetail} />
              <Route path="/products/product-list/:storeQueryId/:categoryId/:subCategoryId/:industryId" component={SubCategoryProductsList} />
              <Route path="/products/details/:storeQueryId/:categoryId/:subCategoryId/:industryId/:productQueryId/:page" component={ProductsDetails} />
              <Route exact path="/products" component={ProductsPage} />
              <Route path="/orders-mgmt" component={OrdersMgmtPage} />
              <Route path="/transactions" component={TransactionsPage} />
              <Route path="/staff-mgmt" component={StaffMgmtPage} />
              <Route path="/pnl" component={PNLPage} />
              <Route path="/test" component={TestPage} />
              <Route path="/checkout/cart-summary" component={CartSummary} />
              <Route path="/checkout/cart-summary-confirmation" component={CheckoutCartSummaryConfirmation} />
              <Route path="/checkout/payment-success/:invoiceQueryId" component={PaymentSuccess} />
              <Route path="/zapp-test-designs" component={ZappTestDesigns} />
              <Route path="/zapp-test-designs-category" component={ZappTestDesignsCategory} />
              <Route path="/checkout" component={CheckoutScreen} />
              <Route path="/review-order" component={ReviewOrder} />
              <Route path="/order/history" component={OrderHistory} />
              {/* <Route path="/checkoutv2" component={CheckoutV2} /> */}
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </Main>
        </Switch>
      </Switch>
    </ConnectedRouter>
  </Container>
);
export default App;