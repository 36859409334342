import React from 'react'
import SiteLabelTextField from 'components/common/site.label.textfield.v3/site.label.textfield.v3';
import TermsAndConditionSelectionDialog from "components/common/terms.and.conditions.selection.dialog/terms.and.conditions.selection.dialog";
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import SiteContentWrapper from 'components/common/site.content.wrapper/site.content.wrapper';
import { useStyle } from './email.subscription.style';
import clsx from 'clsx';
import StatusBox from 'components/common/status.box/status.box';
import { IsMobileWidth, IsTabletWidth, getTheApplicationTheme, isStandardPlusTheme } from "components/common/util/util";
import { getOrderStatusDetails } from 'components/common/util/util';
import { useTranslation } from "react-i18next";

const EmailSubscription = (props) => {
    const { isTermsAndConditionsAccepted, email, isValidEmail, checkOutInvoiceState } = props
    const classes = useStyle()
    const mobileWidth = IsMobileWidth();
    const tabletWidth = IsTabletWidth();
    const orderStatusDetail = getOrderStatusDetails(checkOutInvoiceState && checkOutInvoiceState?.get("invoice") && checkOutInvoiceState?.get("invoice")?.get("status"))
    const { t } = useTranslation("global");

    return (
        <SiteContentWrapper
            // bottomHeight='105px'
            status={checkOutInvoiceState && checkOutInvoiceState.get("invoice") && checkOutInvoiceState.get("invoice").get("status")}
            topNavigationName={orderStatusDetail.topNavigationName}
            bottomContent={
                getTheApplicationTheme().includes('STANDARD') ?
                    <Box className="w-100 d-flex flex-column align-items-center pt-2 pl-4 pr-4">
                        <LoaderButton
                            // fullWidth
                            type="submit"
                            showBorderRadius={isStandardPlusTheme() ? false : true}
                            variant="contained"
                            color="primary"
                            classNames={clsx(!(mobileWidth || tabletWidth) && 'w-40', (mobileWidth || tabletWidth) && 'w-100', "mt-2 mb-2", isStandardPlusTheme() ? classes.btnStyleRounded : classes.btnStyle)}
                            // disabled={!isTermsAndConditionsAccepted ? true : false}
                            onClick={props.emailTheReceipt}
                        >
                            Submit
                        </LoaderButton>
                        <Typography onClick={props.onSkip}
                            variant='body3'
                            className={clsx(classes.linkStyle, 'mt-2 mb-2 w-100 text-center cursor-pointer font-weight-bold')}>
                            Skip
                        </Typography>
                    </Box>
                    : null
            }
        >
            <Grid container spacing={0}>
                {getTheApplicationTheme().includes('STANDARD') ?
                    <Grid item xs={12} sm={12} lg={12} md={12} className=''>
                        <StatusBox
                            // heading={checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && `${checkOutInvoiceState?.get('invoice')?.get('short_order_id')}`}
                            title={<img src={isStandardPlusTheme() ? `${process.env.PUBLIC_URL}/assets/icons/succes_image_green.svg` : `${process.env.PUBLIC_URL}/assets/icons/succes_image.svg`} width='100px' />}
                            heading={window.INDEX_NOKIA_ARENA === true ? t("messages.orderCompleted") : 'Order completed!'}
                            titleColor='#fff'
                            // titleBackgroundColor={orderStatusDetail.titleBackgroundColor}
                            label={window.INDEX_NOKIA_ARENA === true ? t("messages.sendReceipt") : 'Send receipt'}
                            // labelBackgroundColor={orderStatusDetail.labelBackgroundColor}
                            subLabel={window.INDEX_NOKIA_ARENA === true ? t("messages.sendEmailReceipt") : 'Send a receipt to your email address'}

                        //subLabel={getOrderStatusDescription(checkOutInvoiceState?.get("invoice")?.get("status"))}
                        />
                        <div className='w-100 d-flex flex-column align-items-center'>
                            <div className={clsx(!(mobileWidth || tabletWidth) && 'w-40', (mobileWidth || tabletWidth) && 'w-100')}>
                                <SiteLabelTextField
                                    value={email}
                                    error={!isValidEmail ? "Please enter valid email" : ''}
                                    onChange={(e) => props.onChange('email', e.target.value)}
                                    // topAdornment={<Typography variant='body1' color='dark.main'>Email</Typography>}
                                    placeholder='Email'
                                // error={!state.validationResult.isValidEmail ? 'Please enter your email' : ''}
                                />
                                {isStandardPlusTheme() ? null :
                                    <div className='w-100 d-flex align-items-start pt-3 pb-3 pl-3 pr-3'>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={isTermsAndConditionsAccepted}
                                                onChange={(e) => props.onChange('isTermsAndConditionsAccepted', e.target.checked)}
                                                disableRipple={true}
                                                classes={{ root: classes.radioBtn }}
                                            />}
                                            label={
                                                <Typography color='greyColor.dark' variant='body2'>
                                                    I want to receive news, offers and promotions from Nokia Arena and partners.
                                                </Typography>
                                            }
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </Grid>
                    : getTheApplicationTheme() === 'PROMINENT' ?
                        <div className='w-100'>
                            <div className={clsx(classes.topNavigation, 'pb-1 mt-4 w-100')}>
                                <Grid spacing={0} item xs={12} sm={12} lg={12} md={12} className={clsx(classes.gridProminent, 'ml-0 mr-0')}>
                                    <div className='w-100 d-flex flex-column align-items-center'>
                                        <div className={clsx(!(mobileWidth || tabletWidth) && 'w-40', (mobileWidth || tabletWidth) && 'w-100')}>
                                            <div className='w-100 d-flex justify-content-center align-items-center pt-3 pb-2'>
                                                <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} className={clsx("font-weight-bold")}>
                                                    Email Receipt
                                                </Typography>
                                            </div>
                                            <div className='w-100 pl-3 pr-3'>
                                                <SiteLabelTextField
                                                    value={email}
                                                    error={!isValidEmail ? "Please enter valid email" : ''}
                                                    onChange={(e) => props.onChange('email', e.target.value)}
                                                    // topAdornment={<Typography variant='body1' color='dark.main'>Email</Typography>}
                                                    placeholder='Email'
                                                // error={!state.validationResult.isValidEmail ? 'Please enter your email' : ''}
                                                />
                                            </div>
                                            <div className='w-100 d-flex align-items-start pt-3 pb-3 pl-3 pr-3'>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        checked={isTermsAndConditionsAccepted}
                                                        onChange={(e) => props.onChange('isTermsAndConditionsAccepted', e.target.checked)}
                                                        disableRipple={true}
                                                        // classes={{ root: classes.radioBtnProminent }}
                                                        color='primary'
                                                        size='large'
                                                    />}
                                                    label={
                                                        <Typography color='greyColor.dark' variant='body2'>
                                                            I want to receive news, offers and promotions from Nokia Arena and partners.
                                                        </Typography>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                            <Box className="w-100 d-flex flex-column align-items-center pt-2 pl-4 pr-4">
                                <LoaderButton
                                    // fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    classNames={clsx(!(mobileWidth || tabletWidth) && 'w-30', (mobileWidth || tabletWidth) && 'w-100', "mt-2 mb-2", classes.btnStyle)}
                                    // disabled={!isTermsAndConditionsAccepted ? true : false}
                                    onClick={props.emailTheReceipt}
                                >
                                    
                                    {window.INDEX_NOKIA_ARENA === true ? t("messages.submitButton") : 'Submit'}

                                </LoaderButton>
                                <Typography onClick={props.onSkip}
                                    variant='body2'
                                    className={clsx(classes.linkStyle, 'mt-2 mb-2 w-100 text-center cursor-pointer font-weight-bold')}>
                                    {window.INDEX_NOKIA_ARENA === true ? t("messages.skipButton") : 'Skip'}
                                </Typography>
                            </Box>
                        </div>
                        : null
                }
            </Grid>
        </SiteContentWrapper >
    )
}

export default EmailSubscription