import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from '../main/container';
import { GET_INDUSTRY_STORES, GET_STORE_PRODUCTS_CATEGORIES } from 'view.updater/actions/products.actions';
import { checkExecutionStatus } from 'components/common/util/util';
import IndustryList from './components/industry.list/industry.list';
import StoreList from './components/store.list/store.list';
import { getImageUrl, scrollToRefElement } from 'components/common/util/util';
import { Typography } from '@mui/material';
import { IsMobileWidth, IsTabletWidth, scrollToTop } from 'components/common/util/util';
import clsx from 'clsx';
import { fromJS } from 'immutable';
import { redirectToHomePage } from 'components/common/util/util';

function Products(props) {
    const { activeIndustry, storeListingSelectedPageIndex } = props;
    const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
    const context = useContext(AppContext);
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const ref = useRef(null)
    useEffect(() => {
        setTimeout(() => {
            // scrollToRefElement(ref)
            scrollToTop()
        }, 100)
        // For dev
        // props.getIndustryStores({
        //     industryId: 21, //auth.get('industry_types')?.get(0)?.get('id'),
        //     selectedIndustry: fromJS({
        //         id: 21
        //     }), // auth && auth.get('industry_types') && auth.get('industry_types')?.get(0),
        //     page: 1,
        //     lat: "24.8505547922336",
        //     long: "67.0082287956653",
        //     tradeTypeId: tradeTypeId
        // })
        props.getIndustryStores({
            industryId: window.INDEX_INDUSTRY_ID ? window.INDEX_INDUSTRY_ID : 21, //auth.get('industry_types')?.get(0)?.get('id'),
            selectedIndustry: fromJS({
                id: window.INDEX_INDUSTRY_ID ? window.INDEX_INDUSTRY_ID : 21
            }), // auth && auth.get('industry_types') && auth.get('industry_types')?.get(0),
            page: 1,
            lat: "61.4936273",
            long: "23.7738686",
            tradeTypeId: tradeTypeId
        })
        props.getCartProducts();
        if(window.TRANSPORT_SERVICE === true) {
            props.history.push(`/transport/transport-start-journey`)
        }
        else if (window.IS_ONLY_SINGLE_MERCHANT === true) {
            redirectToHomePage(props);
        }
    }, [])
    const updateSelctedStorePage = (page) => {
        props.setStoreListingSelectedPageIndex({
            storeListingSelectedPageIndex: page
        })
        scrollToRefElement(ref)
    }
    const execStatusGetIndustryStores = checkExecutionStatus(context, GET_INDUSTRY_STORES);
    const execStatusGetStoreProductCategories = checkExecutionStatus(context, GET_STORE_PRODUCTS_CATEGORIES);
    return (
        <div>
            {/* <div className="sticky top-63 pt-3 pb-3">
                <IndustryList {...state} updateSelctedStorePage={updateSelctedStorePage}  {...props} />
            </div> */}
            {/* <div className='mt-3'>
                <Typography className="h-100" variant="subtitle">
                    <img src={getImageUrl(activeIndustry && activeIndustry?.get('image'))}
                        width={'50px'} />
                    {activeIndustry && activeIndustry?.get('name')}
                </Typography>
            </div> */}
            <div className='' ref={ref}>
                <StoreList selectedStorePage={storeListingSelectedPageIndex}
                    updateSelctedStorePage={updateSelctedStorePage}
                    execStatusGetStoreProductCategories={execStatusGetStoreProductCategories}
                    execStatusGetIndustryStores={execStatusGetIndustryStores}
                    {...props} />
            </div>
        </div>
    )
}
export default Products
