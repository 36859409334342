import Login from './login';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { appPropertiesSelector } from 'view.updater/selectors/app.selectors';
import { login, resetLogin } from 'view.updater/actions/auth.actions';
import { makeSelectAuth } from 'view.updater/selectors/auth.selectors';
// LOGIN_CHANGES
const mapDispatchToProps = (dispatch) => ({
    login: (data) => dispatch(login(data)),
    resetLogin: () => dispatch(resetLogin())
});

const mapStateToProps = createStructuredSelector({
    appProperties : appPropertiesSelector(),
    auth : makeSelectAuth()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Login);