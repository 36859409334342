/**
 * Homepage selectors
 */

import { createSelector } from 'reselect';

const selectLocalization = (state) => {
      return state.localization;
} 

const makeSelectLocalizationMessages = () => createSelector(
    selectLocalization,
    (localizationState) => localizationState.get('messages')
);

const makeSelectLocalizationLanguage = () => createSelector(
    selectLocalization,
    (localizationState) => localizationState.get('locale')
);

export {
    selectLocalization,
    makeSelectLocalizationMessages,
    makeSelectLocalizationLanguage
};
