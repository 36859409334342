import React from 'react'
import { Skeleton } from '@mui/lab';

export default function CategoryListSkeleton(props) {
    const { width, height } = props
    return (
        <div>
            <div className="w-100" >
                <div className='d-flex justify-content-between align-items-center'>
                    <Skeleton className="rounded-md ml-1 mr-1" width={'15%'} height={80} />
                    <Skeleton className="rounded-md ml-1 mr-1" width={'15%'} height={80} />
                    <Skeleton className="rounded-md ml-1 mr-1" width={'15%'} height={80} />
                    <Skeleton className="rounded-md ml-1 mr-1" width={'15%'} height={80} />
                    <Skeleton className="rounded-md ml-1 mr-1" width={'15%'} height={80} />
                    <Skeleton className="rounded-md ml-1 mr-1" width={'15%'} height={80} />
                </div>
            </div>
        </div>
    )
}
