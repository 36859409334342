import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    darkColor: {
        color: theme.palette.dark.main,
    },
    divider: {
        borderColor: theme.palette.dark.main,
        height: '17px'
    },
    btn: {
        background: theme.palette.primary.main,
        color: theme.palette.background.paper,
        fontSize: '12px',
        minWidth: '40px !important',
        height: '27px'
    },
}))
