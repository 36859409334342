import { Skeleton } from '@mui/lab';
import { Typography } from '@mui/material';
import React from 'react';

function PaymentLoadingSkeleton(props) {
    const { text } = props
    return (
        <div>
            <div className="mt-5 w-100 d-flex flex-column align-items-center">
                <div className='w-100 position-relative d-flex align-items-center justify-content-center'>
                    {text ?
                        <Typography variant='body1' color='dark.main' className="font-weight-bold h-100 position-absolute w-100 d-flex justify-content-center align-items-center">
                            {text}
                        </Typography> : null}
                    <Skeleton variant="rectangular" width={'70%'} height={150} />
                </div>
                <div className='mt-2 w-80'>
                    <div className='mt-3 d-flex align-items-center justify-content-center'>
                        <Skeleton width='80%' height={40} />
                    </div>
                    <div className='d-flex justify-content-between mt-3'>
                        <Skeleton width="30%" height={40} />
                        <Skeleton width="30%" height={40} />
                        <Skeleton width="30%" height={40} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentLoadingSkeleton;