import React from 'react'
import { Skeleton } from '@mui/lab';
import { IsMobileWidth } from 'components/common/util/util';
import clsx from 'clsx';

export default function ProductPageHeaderSkeleton() {
    const mobileWidth = IsMobileWidth()
    return (
        <div>
            <div>
                <Skeleton variant="rectangular" width='100%' height={100} />
            </div>
            {/* <div className={clsx(!mobileWidth && 'd-flex', mobileWidth && 'd-block')}>
                <div className='p-2 pl-3'>
                    <Skeleton variant="circular" width={65} height={65} />
                </div>
                <div className='w-100 p-2 pr-3'>
                    <div>
                        <Skeleton width='50%' height={30} />
                    </div>
                    <Skeleton width='40%' />
                    <Skeleton width='20%' />
                    <Skeleton width='10%' />
                </div>
            </div> */}
        </div>
    )
}
