import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  paymentBtn: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    color: "#000",
    textTransform: "none",
  },
}));
