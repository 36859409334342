import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    likeBtnDetail: {
        // background: theme.palette.background.paper,
        padding: "2px",
        '&:hover': {
            background: theme.palette.background.paper,
        }
    },
    tagColor: {
        background: theme.palette.darkBlueColor.main,
        color: theme.palette.background.paper,
        borderTopLeftRadius: "50px",
        borderBottomLeftRadius: "50px",
        zIndex: 1,
    },
    darkColor: {
        color: theme.palette.dark.main,
    },
    divider: {
        borderColor: theme.palette.dark.main,
        height: '17px'
    },
    shipmentTag: {
        color: theme.palette.ternary.main,
    },
    btn: {
        background: theme.palette.darkBlueColor.main,
        fontSize: '12px',
        minWidth: '35px !important',
        minHeight: '22px'
    },
    description: {
        background: theme.palette.lightBackgroundColor.main,
        opacity: '80%',
        border: '0px'
    },
    // paper:{
    //     // background: theme.palette.gradientColor2.main,
    // }
}))
