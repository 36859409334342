import './box.group.scss'
import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import { useStyles } from './box.group.style';
import clsx from 'clsx';

export default function BoxGroup(props) {
    const classes = useStyles();
    const { groupContent, height, width, boxHeight, classNames,
        enableDroppableStyle, enableHover, contentType, minHeight, imageRounded } = props;

    const [droppableContainerIndex, setDroppableContainerIndex] = useState(null)

    // const isSelected = (index) => {
    //     return selectedIndexes &&
    //         selectedIndexes.findIndex((selectedIndex) => selectedIndex === index) > -1 ?
    //         true : false
    // }

    const isDroppable = (index) => {
        return index === droppableContainerIndex ? true : false;
    }
    const dragEnterCapture = (e, index) => {
        if (e.target.nodeName === "IMG")
            setDroppableContainerIndex(index)
    }
    const dragEndCapture = () => {
        setDroppableContainerIndex(null)
    }

    return (
        <div className={clsx(classNames, classes.boxContainer)}>
            {
                groupContent && groupContent.map((data, index) => {
                    return <div id="box-group"
                        style={{
                            height: boxHeight ? boxHeight : 'auto'
                        }}>
                        {
                            enableDroppableStyle ?
                                <Paper
                                    onDragEnterCapture={(e) => dragEnterCapture(e, index)}
                                    onDragLeaveCapture={() => dragEndCapture()}
                                    onDragOverCapture={(e) => dragEnterCapture(e, index)}
                                    onDropCapture={() => dragEndCapture(index)}
                                    onClick={(e) => props.onContentClick(e, data.item, index)}
                                    key={index}
                                    style={{
                                        height: height ? height : 'auto',
                                        width: width ? width : '190px',
                                        minHeight: minHeight ? minHeight : 'auto'
                                    }}
                                    className={clsx('m-3 paper', imageRounded && 'image-rounded')}>
                                    {/* {
                                        isSelected(index) ?
                                            <Avatar style={{ width: "25px", height: "25px" }}
                                                className={`font-weight-bolder ${classes.checkIcon}`}>
                                                <i className="fa fa-check"></i>
                                            </Avatar> :
                                            null
                                    } */}
                                    <div
                                        className={`w-100 h-100
                                            ${isDroppable(index) ? 'droppable' : null}
                                            ${enableHover && contentType === "IMAGE" ?
                                                classes.hoverOpacity : enableHover ?
                                                    classes.hover : contentType === "IMAGE" ? "opacity" : null
                                            }`}>
                                        {/* {
                                            contentType === "IMAGE" ?
                                                <div className="title p-1">{data.title}</div> :
                                                null
                                        } */}
                                        {data.centerText ? <div className="center-text p-1">{data.centerText}</div> : null}
                                        <div className={`w-100 h-100`}>{data.content}</div>
                                    </div>
                                    <div className="text-muted p-1-4">
                                        {data.footer ? data.footer : null}
                                    </div>
                                    <div>
                                        {
                                            data.actions ?
                                                <div className={`${imageRounded ? "action-button-center" : "action-button"}`}>
                                                    {
                                                        data && data.actions.map((data, index) => {
                                                            return (
                                                                <span key={index}>{data}</span>
                                                            )
                                                        })
                                                    }
                                                </div> : null
                                        }
                                    </div>
                                </Paper> :
                                <Paper
                                    onClick={(e) => props.onContentClick(e, data.item, index)}
                                    key={index}
                                    style={{
                                        height: height ? height : 'auto',
                                        width: width ? width : '190px',
                                        minHeight: minHeight ? minHeight : 'auto'
                                    }}
                                    className={clsx('m-3 paper', imageRounded && 'image-rounded')}>
                                    {/* {
                                        isSelected(index) ?
                                            <Avatar style={{ width: "25px", height: "25px" }}
                                                className={`font-weight-bolder ${classes.checkIcon}`}>
                                                <i className="fa fa-check"></i>
                                            </Avatar> :
                                            null
                                    } */}
                                    <div className={`w-100 h-100
                                                ${enableHover && contentType === "IMAGE" ?
                                            classes.hoverOpacity : enableHover ?
                                                classes.hover : contentType === "IMAGE" ? "opacity" : null}
                                                ${classes.boxContent}`}>
                                        {/* {
                                            contentType === "IMAGE" ?
                                                <div className="title p-1">{data.title}</div>
                                                : null
                                        } */}
                                        {data.centerText ? <div className="center-text p-1">
                                            {data.centerText}</div> : null}
                                        <div className={`w-100 h-100`}>{data.content}</div>
                                    </div>
                                    <div className="text-muted p-1-4">
                                        {data.footer ? data.footer : null}
                                    </div>
                                    <div>
                                        {
                                            data.actions ?
                                                <div className={`${imageRounded ? "action-button-center" : "action-button"}`}>
                                                    {
                                                        data && data.actions.map((data, index) => {
                                                            return (
                                                                <span key={index}>{data}</span>
                                                            )
                                                        })
                                                    }
                                                </div> : null
                                        }
                                    </div>
                                </Paper>
                        }
                    </div>
                })
            }
        </div>
    )
}
