/**
 * Gets the repositories of the user from Github
 */
import { call, put, select, takeEvery } from 'redux-saga/effects';
import allAfterMiddleware from './all.after.middleware';
import request from './request';
import { addUpdateAppLoadersStatus } from 'view.updater/actions/app.actions';
import { selectAuthorization } from '../selectors/auth.selectors';
import { doMerchantLogin, resetAuth } from '../actions/auth.actions';
import { push } from 'connected-react-router';
import axios from 'axios';
import allAfterErrorMiddleware from './all.error.middleware';
import { CANCEL_REQUEST, CANCEL_ALL_REQUEST, cancelAllRequest } from '../actions/app.actions';
import { differenceBetweenDatesInMinutes } from 'components/common/util/util';
//import { makeSelectUsername } from 'view.updater/selectors/home.selectors'; // better not to use 
/**
 * Github repos request/response handler
 */
const PUBLIC_ACCESS_ACTIONS = [
  "GET_ITMS"
]
var cancelSources = [];
export function* getServerData(action) {
  if (action && ((action.type).indexOf("SUCCESS") > -1)) {
    yield allAfterMiddleware(action);
  }
  if (action && ((action.type).indexOf("ERROR") > -1)) {
    yield allAfterErrorMiddleware(action);
  }
  if (action.type === CANCEL_REQUEST) {
    //const cancelSrc = cancelSources && cancelSources.find((source)=> source.type === action.payload.type);
    //cancelSrc && cancelSrc.cancelFunction && cancelSrc.cancelFunction();
    const cancelSrces = cancelSources && cancelSources.filter((source) => source.type === action.payload.type);
    cancelSrces && cancelSrces.map((src) => {
      src && src.cancelFunction && src.cancelFunction();
    })
    cancelSources = cancelSources && cancelSources.filter((source) => source.type !== action.payload.type);
    //cancelSrc && cancelSrc.cancelFunction && cancelSrc.cancelFunction();
  }
  if (action.type === CANCEL_ALL_REQUEST) {
    const cancelSrcesAl = cancelSources && cancelSources.filter((source) => {
      if (PUBLIC_ACCESS_ACTIONS.indexOf(source.type) !== -1) {

      }
      else {
        return true;
      }
    });
    cancelSrcesAl && cancelSrcesAl.map((src) => {
      src && src.cancelFunction && src.cancelFunction();
    })
    cancelSources = cancelSources && cancelSources.filter((source) => {
      if (PUBLIC_ACCESS_ACTIONS.indexOf(source.type) !== -1) {
        return true;
      }
      else {

      }
    });
  }
  if (action.url) {
    const cancelSrcesSameUrl = cancelSources && cancelSources.filter((source) => source.url === action.url);
    cancelSrcesSameUrl && cancelSrcesSameUrl.map((src) => {
      src && src.cancelFunction && src.cancelFunction();
    })
    const CancelToken = axios.CancelToken;
    let tkn = new CancelToken(function executor(c) {
      cancelSources = cancelSources && cancelSources.filter((source) => differenceBetweenDatesInMinutes(source.executionDate, new Date()) <= 4);
      //cancelSources = cancelSources && cancelSources.filter((source)=> source.type !== action.type);
      cancelSources.push({
        type: action.type,
        cancelFunction: c,
        url: action.url,
        executionDate: new Date()
      })
    })

    //const username = yield select(makeSelectUsername()); // read from state if needed in future
    try {
      let response;
      const authorization = yield select(selectAuthorization);
      // Call our request helper (see 'utils/request')
      if (action.method === 'POST' || action.method === 'PUT' || action.method === 'DELETE') {
        //let data = {};
        if (action.encoded === "URL_ENCODED") {
          // data = new URLSearchParams(action.data);
          // let formBody = [];
          // Object.keys(action.data).map((key) => {
          //   const encodedKey = encodeURIComponent(key);
          //   const encodedValue = encodeURIComponent(action.data[key]);
          //   formBody.push(encodedKey + "=" + encodedValue);
          // });
          // formBody = formBody.join("&");
          // data = formBody;
        }
        else {
          // data = new FormData();
          // for (var item in action.data) {
          //   data.append(item, action.data[item])
          // }
        }
        response = yield call(request, action.url, {
          method: action.method || 'GET',
          body: action.data,
          encoded: action.encoded,
          cancelToken: tkn,
          contentType: action.contentType,
          isLocal: action.isLocal,
          apiVersion: action.apiVersion,
          actionType: action.type,
          useService: action.useService,
          useGridFormService: action.useGridFormService,
          userMerchantService: action.useMerchantService,
          actionUrl: action.url
        }, authorization.get('auth'));
      }
      else {
        response = yield call(request, action.url, {
          method: 'GET',
          actionType: action.type,
          useGridFormService: action.useGridFormService,
          userMerchantService: action.useMerchantService,
          useService: action.useService,
          actionUrl: action.url,
          isLocal: action.isLocal,
          apiVersion: action.apiVersion,
          cancelToken: tkn
        }, authorization.get('auth'));
      }
      if (response) {
        if (response.code && response.code === 401) {
          if (action.type === "LOGIN") {
            yield put(addUpdateAppLoadersStatus(action.type, 'ERROR', `${action.type}_ERROR_ID`, response.error));// remove in future
          }
          else {
            if (window.location.href.includes('transport') === true) {

            }
            else {
              yield put(cancelAllRequest());
              yield put(resetAuth());
              if (response.error) {
                yield put(addUpdateAppLoadersStatus(action.type, 'ERROR', `${action.type}_ERROR_ID`, response.error));
              }
              //yield put(push('/home'));
            }
          }
        }
        else if (response.code && response.code === 419) {
          if (window.TRANSPORT_SERVICE === true) {
            yield put(cancelAllRequest());
            yield put(resetAuth());
            yield put(doMerchantLogin({
              login_id: window.MERCHANT_LOGIN_USERNAME,
              password: window.MERCHANT_LOGIN_USER_PASSWORD
            }))
          }
        }
        else if (response.code === 0) {
          yield put(addUpdateAppLoadersStatus(action.type, 'ERROR', `${action.type}_ERROR_ID`, { description: "Request Timeout" }));
        }
        else if (response.code === 1) {
          yield put(addUpdateAppLoadersStatus(`${action.type}_REQUEST_CANCELLED`, 'REQUEST_CANCELLED', `${action.type}_ERROR_ID`, { description: "Connection Failed" }));
        }
        else if (response.error) {
          yield put({ type: `${action.type}_ERROR`, data: response && { ...response.error, ...action.payload } });
          yield put(addUpdateAppLoadersStatus(action.type, 'ERROR', `${action.type}_ERROR_ID`, response.error));

        }
        else {
          yield put({ type: `${action.type}_SUCCESS`, baseType: `${action.type}`, response, data: action.data, payload: action.payload });
        }
      }
      else {
        yield put({ type: `${action.type}_ERROR` });
        yield put(addUpdateAppLoadersStatus(action.type, 'ERROR', `${action.type}_ERROR_ID`));
      }
    } catch (err) {
      yield put({ type: `${action.type}_ERROR` });
      yield put(addUpdateAppLoadersStatus(action.type, 'ERROR', `${action.type}_ERROR_ID`, { description: "Connection Timeout" }));
    }
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export default function* getDataFromServer() {
  yield takeEvery('*', getServerData);
}
