import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  orderBox: {
    // height: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: "60px",
  },
  gridCOntainer: {
    background: '#45d466',
    borderRadius: '0px'
  },
  btnStyle: {
    textTransform: 'none'
  }
}));
