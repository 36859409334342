import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    topNavigation: {
        // background: theme.palette.greyColor.main,
        borderRadius: '20px'
    },
    borderRadius1: {
        borderRadius: '0px 0px 10px 10px'
    },
    borderRadius2: {
        borderRadius: '0px 0px 20px 20px'
    },
    name: {
        color: theme.palette.primary.main,
        fontFamily: 'Roboto !important'
    },
    label: {
        borderRadius: '0px 0px 20px 20px',
        color: theme.palette.background.paper,
        background: theme.palette.primary.main,
        height: '35px'
    },
    status: {
        borderRadius: '0px 0px 20px 20px',
        color: theme.palette.background.paper,
        background: theme.palette.primary.main,
        height: '40px'
    },
    linearProgress: {
        borderRadius: '50px',
        height: 24,
        width: 24,
        background: theme.palette.primary.main,
        border: `1px solid ${theme.palette.background.paper}`
    },
    transparentColor: {
        background: "transparent !important"
    }
}))
