import './sale.item.box.carousel.scss';
import React from 'react'
import Slider from "react-slick";
import SaleItemBox from 'components/common/sale.item.box/sale.item.box';
import IconButton from '@mui/material/IconButton'
import { IsMobileWidth } from 'components/common/util/util';
import clsx from 'clsx'

const SaleItemBoxCarousel = (props) => {
    const mobileWidth = IsMobileWidth()

    function NextArrow(props) {
        const { className, onClick } = props;

        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className={clsx(!mobileWidth && "bg-white ml-n4 carousel5", mobileWidth && "bg-white ml-n3 carousel5")}>
                    <i className="fa fa-arrow-right p-1" id="left_arrow_carousel5"></i>
                </IconButton>
            </div>
        );
    }

    function PrevArrow(props) {
        const { className, onClick } = props;
        return (
            <div
                className={className}
                onClick={onClick}>
                <IconButton className="bg-white ml-4 carousel5" >
                    <i className='fa fa-arrow-left p-1' id="left_arrow_carousel5"></i>
                </IconButton>
            </div>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: true
                }
            }
        ]
    }
    return (
        <div
            className="p-1 w-100 h-100"
            id="sale-item-box-carousel"
        >

            <Slider className="h-100 w-90 m-auto" {...settings}>
                <div>
                    <div className="p-4 carousel5-item" >
                        <SaleItemBox
                            tag="sale 20%" title="mangolia Bag" image="https://media.gettyimages.com/photos/blue-leather-womens-handbag-on-white-background-picture-id958437054?s=612x612" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale" width="100%" height="80%"
                        />
                    </div>
                </div>
                <div>
                    <div className="p-4 carousel5-item" >
                        <SaleItemBox
                            tag="new" title="mangolia Bag" image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale" width="100%" height="80%"
                        />
                    </div>

                </div>
                <div>
                    <div className="p-4 carousel5-item" >
                        <SaleItemBox
                            title="mangolia Bag" image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale" width="100%" height="80%"
                        />
                    </div>
                </div>
                <div>
                    <div className="p-4 carousel5-item" >
                        <SaleItemBox
                            title="mangolia Bag" image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale" width="100%" height="80%"
                        />
                    </div>
                </div>
                <div>
                    <div className="p-4 carousel5-item" >
                        <SaleItemBox
                            title="mangolia Bag" image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale" width="100%" height="80%"
                        />
                    </div>
                </div>
                <div>
                    <div className="p-4 carousel5-item" >
                        <SaleItemBox
                            title="mangolia Bag" image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale" width="100%" height="80%"
                        />
                    </div>
                </div>

                <div>
                    <div className="p-4 carousel5-item" >
                        <SaleItemBox
                            title="mangolia Bag" image="https://us.123rf.com/450wm/semilyon/semilyon2007/semilyon200700011/151133655-red-female-glossy-leather-bag-isolated-on-white-background.jpg?ver=6" description="The bag for Sale The bag for Sale The The bag for Sale The bag for Sale The bag for Sale The bag for Sale The bag for Sale" width="100%" height="80%"
                        />
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default SaleItemBoxCarousel