import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    drawer: {
        width: '60%',
        height:"100vh",
        background: "#141423",
        left:"0 !important",
        maxHeight: "100vh",
        top: "0 !important",
        [theme.breakpoints.up('lg')]: {
            width: '25%',
            left:"0% !important",
          },
    },
    contentOnTablet: {
        width: '100%',
        paddingTop: "0px !important",
        boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.15)',
    },
    contentOnMobile: {
        width: '100%',
        paddingTop: "0px !important",
        boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    listTextStyle: {
        margin: 0,
        fontFamily: 'Nunito',
        fontWeight: 300,
        fontSize: 16,
        color:'#fff'
        // '&:hover': {
        //     // backgroundColor: theme.palette.primary.light,
        //     color: theme.palette.background.paper
        // },
    },
    // listItemStyle: {
    //     backgroundColor: theme.palette.background.paper,
    //     color: theme.palette.primary.main,
    //     '&:hover': {
    //         backgroundColor: theme.palette.primary.light,
    //         color: theme.palette.background.paper
    //     },
    //     '&:selected': {
    //         backgroundColor: theme.palette.primary.light,
    //         color: theme.palette.background.paper
    //     }
    // }
    
}))