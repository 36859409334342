import "./customized.stepper.scss";
import React from "react";
import clsx from "clsx";
import { useStyles } from "./customized.stepper.style";
import {
  useQontoStepIconStyles,
  QontoConnector,
} from "./customized.stepper.style";
import { IsMobileWidth } from "components/common/util/util";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div
          className={`${classes.activeCircle} d-flex align-items-center justify-content-center`}
        >
          <i class="fas fa-check"></i>
        </div>
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

export default function CustomizedStepper(props) {
  const classes = useStyles();
  const { activeStep, steps, iconPath } = props;

  const mobileWidth = IsMobileWidth();
  return (
    <div className={`${classes.root} stepper w-100`}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
        classes={{
          horizontal: "w-100",
        }}
      >
        {steps &&
          steps.map((data, index) => (
            <Step
              onClick={() => props.onStepClick(index)}
              key={data}
              classes={{ alternativeLabel: classes.labelRoot }}>
              <StepLabel
                classes={{
                  root: classes.stepRoot,
                  alternativeLabel: `p-0 ${mobileWidth && "font-size-small"}`,
                }}
                StepIconComponent={QontoStepIcon}
              >
                <img width="63px" height="40" src={data.iconPath} />
              </StepLabel>
              <div className="d-flex justify-content-center">
                <Typography
                  classes={{ root: classes.textColor }}
                  variant="body2"
                  className="pt-1 text-center"
                >
                  {data.label}
                </Typography>
              </div>
            </Step>
          ))}
      </Stepper>
    </div>
  );
}
