import React, { useState } from 'react';
import { Collapse, Typography, IconButton } from '@mui/material';
import { useStyle } from './expandable.view.v1.style';

export default function ExpandableViewV1(props) {
    const [collapsed, setCollapsed] = useState(true)
    const { label } = props
    const classes = useStyle()

    return (
        <div id="expandable-view">
            <div className="d-block cursor-pointer">
                <div className='w-100 d-flex align-items-center' onClick={() => { setCollapsed(!collapsed) }}>
                    <div className='d-flex'>
                        <Typography color='dark.main' variant="body2" className='p-0 d-flex text-center'>
                            {label ? label : ""}
                        </Typography>
                    </div>
                    <div>
                        {
                            collapsed ?
                                <IconButton size='small' classes={{ root: classes.iconStyle }}>
                                    <i className="material-icons">expand_less</i>
                                </IconButton> :
                                <IconButton size='small' classes={{ root: classes.iconStyle }}>
                                    <i className="material-icons">expand_more</i>
                                </IconButton>
                        }
                    </div>
                </div>
                <Collapse in={collapsed}>
                    {props.children}
                </Collapse>
            </div>
        </div >
    )
}