import React, { useState } from 'react';
import { Popover, MenuItem } from '@mui/material';
import { NOKIA_ARENA_LANGUAGES } from '../util/util';
import './language.dropdown.scss'

import { useTranslation } from "react-i18next";

const LanguageDropdown = () => {
    const [state, setState] = useState({
        selectedLanguage: 'Suomi',
        showLanguageDropdown: false,
        anchorEl: null
    });

    const { i18n } = useTranslation("global");

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        const selectedLanguage = NOKIA_ARENA_LANGUAGES.find(language => language.code === lng).name;
        setState({ ...state, selectedLanguage, showLanguageDropdown: false });
    };

    const onLanguageSelect = (value) => {
        setState({ ...state, selectedLanguage: value, showLanguageDropdown: false });
    };

    const showLanguageDropdown = (e) => {
        setState({ ...state, anchorEl: e.currentTarget, showLanguageDropdown: true });
    };

    const hideLanguageDropdown = () => {
        setState({ ...state, showLanguageDropdown: false });
    };

    return (
        <div>
            <div className="p-1 pr-4 pl-4 d-flex align-items-center" onClick={showLanguageDropdown} style={{ background: "#1558ce", borderRadius: "50px", boxShadow: "#00000040 0px 14px 28px, #00000038 0px 10px 10px", color:"#fff" }}>
                <div>{state.selectedLanguage}</div>
                {state.showLanguageDropdown ?
                    <span className="material-icons">
                        keyboard_arrow_up
                    </span> :
                    <span className="material-icons">
                        keyboard_arrow_down
                    </span>}
            </div>
            <Popover
                onClose={hideLanguageDropdown}
                open={state.showLanguageDropdown}
                anchorEl={state.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

                PaperProps={{
                    sx: {
                      borderRadius: "15px !important",
                    }
                  }}
                

            >
                <div  id='language-dropdown' className="p-2" style={{

                    background: "#1558ce",
                    boxShadow: "#00000040 0px 14px 28px, #00000038 0px 10px 10px"
                }}>
                    {NOKIA_ARENA_LANGUAGES.map((language, index) => (
                        <MenuItem
                            selected={language.name === state.selectedLanguage}
                            onClick={() => changeLanguage(language.code)}
                            key={index}
                            value={language.name}
                           
                        >
                            <div style={{display:"flex", alignItems:"center", gap:"15px", color:"#fff"}}>
                                <img src={language.image} height="20px" width="40px" />
                                {language.name}
                            </div>
                        </MenuItem>
                    ))}
                </div>
            </Popover>
        </div>
    );
};

export default LanguageDropdown;
