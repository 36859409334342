import './terms.and.conditions.scss';
import React, { useEffect, useState } from 'react'
import { Container, Typography } from '@mui/material'
import clsx from 'clsx';
import { IsMobileWidth, IsTabletWidth, scrollToTop, renderHtml } from 'components/common/util/util';
import { useStyle } from './terms.and.conditions.style'

const TermsAndConditions = (props) => {
    const { showDynamicTermAndConditions } = props
    const classes = useStyle()
    const tabletWidth = IsTabletWidth()
    const mobileWidth = IsMobileWidth()
    // const onChange = (name, value) => {
    //     setState({ ...state, [name]: value })
    // }
    useEffect(() => {
        document.getElementById('terms-and-conditions-dialog') &&
            document.getElementById('terms-and-conditions-dialog').addEventListener('touchmove', function (event) {
                event.stopPropagation();
            });
        scrollToTop()
    }, [])

    return (
        <div id='terms-and-conditions' className={clsx(!(mobileWidth || tabletWidth) && '', 'pb-5', (tabletWidth || mobileWidth) && 'pt-2 pb-2')}>
            <Container id='terms-and-conditions-dialog' maxWidth='md' className='content-style-1 h-100'>
                {window.TERM_AND_CONDITIONS || showDynamicTermAndConditions ?
                    <div className='w-100 p-3'>
                        {renderHtml(window.TERM_AND_CONDITIONS)}
                    </div>
                    : <div className='overflow-auto'>
                        {renderHtml(`<div lang=EN-US link=blue vlink="#954F72" style='word-wrap:break-word'>
	<div style="width: 100%;display: flex;justify-content: center">
		<div style="width: 80%;">
			<div class=WordSection1>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>PRIVACY POLICY AND TERMS OF USE QRMe SERVICE<br>
						<br>
					</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>PRIVACY STATEMENT</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito'>This
						privacy statement describes the processing of personal data of the QRMe service
						and data collected from the use of the service. The subject of processing is
						our customers' data in the service.&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito'>REGISTER HOLDER CONTACT INFORMATION</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>P3 Financial Group Oy (Y-tunnus: 2638819-7)
						hereafter referred as ”QRMe”.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>Tammasaarenkatu 3, 00180, Helsinki</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><u><span style='font-size:13.5pt;
font-family:Nunito;color:#007BFF'>contact@p3.fi</span></u></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>+358 20&nbsp;779 0240<br>
						and behalf of the QRMe partners.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>Partner for the store:</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>Cafe Carusel</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>carusel@carusel.fi</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>WHAT INFORMATION DO WE COLLECT AND FOR WHAT
						PURPOSE?</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>We only collect information from you that is necessary for the
						operation and development of our service.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-family:Nunito;
color:black'>Information we collect directly from you</span></b></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-family:Nunito;
color:black'>&nbsp;</span></b></p>

				<ul type=disc>
					<li class=MsoNormal style='color:black;line-height:18.0pt'><span style='font-family:Nunito'>Of the
							information you use for payment, we only
							store the validity period of the payment card and the last 4 digits of the
							payment card. The information does not include the complete card number,
							security code or other information required for online payment. We do not
							charge the card ourselves. All payments are processed by Nets. Paying in
							that service is safe, as all information about the payment transaction is
							transmitted using an encrypted connection so that no outside party can see
							the payment transaction information.</span></li>
				</ul>

				<p class=MsoNormal style='margin-left:.5in;line-height:18.0pt'><span
						style='font-family:Nunito;color:black'>&nbsp;</span></p>

				<ul type=disc>
					<li class=MsoNormal style='color:black;line-height:18.0pt'><span style='font-family:Nunito'>We store
							your purchase history, i.e. receipts
							of purchase transactions as required by the Accounting Act, and we use the
							information anonymously to profile purchasing behavior. When you withdraw
							your consent to the processing of your data, the receipts become anonymous
							and can no longer be linked to you.</span></li>
				</ul>

				<p class=MsoListParagraph><span style='font-family:Nunito;color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='margin-left:.5in;line-height:18.0pt'><span
						style='font-family:Nunito;color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-family:Nunito;
color:black'>Information we collect from third parties or when you use the service</span></b></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>Third parties may collect or receive information from the QRMe
						service and other applications and use this information to provide analytics
						services and target advertisements.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>You consent to the collection of this information by using the
						service and accepting this privacy policy.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-family:Nunito;
color:black'>WHAT RIGHTS DO YOU HAVE AND HOW CAN YOU USE THEM?</span></b></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-family:Nunito;
color:black'>You have the following rights:</span></b></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-family:Nunito;
color:black'>&nbsp;</span></b></p>

				<ul type=disc>
					<li class=MsoNormal style='color:black;line-height:18.0pt'><span style='font-family:Nunito'>The
							right to get access to personal data: You
							have the right to access the personal data we hold. However, the right to
							access information may have to be restricted due to legislation and the protection
							of the privacy of other persons.</span></li>
					<li class=MsoNormal style='color:black;line-height:18.0pt'><span style='font-family:Nunito'>Right to
							rectification of data: You have the
							right to request the correction of incorrect or incomplete data.</span></li>
					<li class=MsoNormal style='color:black;line-height:18.0pt'><span style='font-family:Nunito'>The
							right to delete data: You have the right to
							request the deletion of your data. Data can be deleted, for example, in
							those cases when you withdraw your consent to the processing of your data,
							and there is no other basis for the processing, or when you object to the
							processing of the data and there is no other basis for continuing the
							processing.</span></li>
					<li class=MsoNormal style='color:black;line-height:18.0pt'><span style='font-family:Nunito'>Right to
							restrict processing: You have the
							right to restrict the processing of your personal data.</span></li>
					<li class=MsoNormal style='color:black;line-height:18.0pt'><span style='font-family:Nunito'>Right to
							object: You have the right to object
							to the processing of your data.</span></li>
					<li class=MsoNormal style='color:black;line-height:18.0pt'><span style='font-family:Nunito'>The
							right to data portability: You have the
							right to receive the information you provide in a machine-readable format.
							The right applies to personal data that has been processed automatically
							on the basis of a contract or consent.</span></li>
					<li class=MsoNormal style='color:black;line-height:18.0pt'><span style='font-family:Nunito'>The
							right to withdraw consent: You have the
							right to withdraw your consent at any time, without affecting the legality
							of the processing carried out before its withdrawal. Withdrawing consent
							may affect our ability to provide services.</span></li>
					<li class=MsoNormal style='color:black;line-height:18.0pt'><span style='font-family:Nunito'>The
							right to file a complaint with a
							supervisory authority: You also have the right to file a complaint with a
							supervisory authority if you suspect that your personal data is being used
							inappropriately or illegally.</span></li>
				</ul>

				<p class=MsoNormal style='margin-left:.5in;line-height:18.0pt'><span
						style='font-family:Nunito;color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-family:Nunito;
color:black'>Exercising rights</span></b></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>To exercise your rights, contact to QRMe customer service email.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-size:13.5pt;
font-family:Nunito;color:black'>&nbsp;</span></b></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-size:13.5pt;
font-family:Nunito;color:black'>WHAT DO WE USE THE INFORMATION FOR AND ON WHAT
							BASIS DO WE PROCESS IT?</span></b></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>QRMe processes personal data to fulfil legal and contractual
						obligations. The legal bases for our processing are:</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>Implementation of the contract: Fulfilling contractual
						obligations, i.e. providing our service, is the main legal basis for our processing
						of personal data. The contract is formed between us when you use the service.
						By using the service, you agree to the processing of data in accordance with
						the privacy statement. We process personal data to provide the service ordered
						from us to the extent necessary.<br>
						Statutory obligation: In addition to contracts, our operations are subject to
						statutory obligations, on the basis of which we process personal data. Examples
						of these are accounting legislation and legislation concerning event and restaurant
						industries.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-size:13.5pt;
font-family:Nunito;color:black'>HOW LONG DO WE KEEP INFORMATION?</span></b></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>Personal data is stored only for the duration of the contractual
						relationship, unless otherwise required by legislation, such as the Accounting
						Act. For example, purchase transactions are stored for the period required by
						the Accounting Act, but the information is anonymized when the contractual
						relationship ends.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>We store customer service data for a maximum of 24 months.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>DATA RECIPIENTS AND DATA TRANSFER TO THIRD
						COUNTRIES</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>The data is processed by QRMe employees, in accordance with the
						applicable personal data law. QRMe reserves the right to partially outsource
						the processing of personal data to a third party, in which case we guarantee
						through contractual arrangements that personal data is processed in accordance
						with the Personal Data Act and otherwise appropriately. Information related to
						purchase transactions is transferred to the system of our payment service
						provider Nets for charging.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-size:13.5pt;
font-family:Nunito;color:black'>&nbsp;</span></b></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-size:13.5pt;
font-family:Nunito;color:black'>TERMS OF USE</span></b></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>By using the QRMe service, you agree to these terms of use.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><b><span style='font-family:Nunito;
color:black'>QRMe </span></b><span style='font-family:Nunito;color:black'>and
						its partners sells products to individuals in Finland. We reserve the right to
						change terms and prices. All prices include VAT.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>AGE LIMIT</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>Ordering alcohol products in QRMe service is only allowed for
						persons who are over 18 years old and meet the requirements of the Alcohol Act
						in other respects.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>ORDERS</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>The user selects the contents of the order in the shopping cart
						and places the order in the QRMe service. The order is confirmed by using the
						purchase function of the QRMe service to pay for the purchase. The user accepts
						these terms and product pricing by placing an order. Orders will be fulfilled
						by the QRMe partners and not by QRMe</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>ORDER CONFIRMATION AND RECEIPT</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>When ordering and paying, customer may choose to have the receipt
						sent to their email address provided by the customer. Receipts list the
						products ordered and the breakdown of prices. The e-mail contains receipt of
						the order.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>ORDER PAYMENT, CANCELLATION AND REFUNDS</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>Payment for the order is made in QRMe service. The order is
						automatically charged to the user of the service as soon as the order is
						placed. After placing the order, the user cannot cancel the order. When placing
						an order, QRMe or the chosen merchant reserves the right to make a payment
						arrangement with the user. If the card payment fails, the user can be sent an
						invoice for the order. If the delivery or part of the delivery fails, either
						due to the product being out of stock or for any other reason beyond the
						control of QRMe’s partner, the partner is responsible for paying the refund for
						those parts to the user. The refund is usually paid within 1-3 weeks.<br>
						QRMe or its partners does not store or process payment card information in its
						own system that could be used to make unauthorized payments. All payments are
						processed by the service payment partner.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>FULLFILLING THE ORDER</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>The order delivery is done based on the instructions provided when
						product is bought.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>ALCOHOL</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>Ordering and paying for alcoholic beverages is possible using the QRMe
						service. QRMe’s partners selling alcohol is licensed to serve alcoholic
						beverages. Each individual decision to sell alcohol is made by the partner and
						their employees. They can refuse to sell alcohol to a user of QRMe service if
						the requirements of the Alcohol Act are not met. The price of alcoholic
						beverages is charged using the payment method chosen by the user of the QRMe
						service. If the user uses the QRMe service in violation of the Alcohol Act (for
						example, as a minor), QRMe’s partner is entitled to collect the total amount of
						the order in question from the user.</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>&nbsp;</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-size:13.5pt;
font-family:Nunito;color:black'>CHANGES TO THE TERMS OF SERVICE</span></p>

				<p class=MsoNormal style='line-height:18.0pt'><span style='font-family:Nunito;
color:black'>Changes may apply to the terms of use of QRMe’s service. The user
						of QRMe service must stop using the service if he/she does not accept the
						amended terms of use.</span></p>
				<p class=MsoNormal>&nbsp;</p>
			</div>

		</div>
	</div>
</div>`)}
                    </div>}
            </Container >
        </div >
    )
}
export default TermsAndConditions