import React from 'react'
import { Button, Skeleton, Typography } from "@mui/material";
import { useStyle } from './product.add.button.style';
import { getTheApplicationTheme, isStandardPlusTheme } from 'components/common/util/util';
import clsx from 'clsx';

const ProductAddButton = (props) => {
    const classes = useStyle()

    return (
        <div>
            {!window.INDEX_NOKIA_ARENA && getTheApplicationTheme().includes('STANDARD') ?
                <div>
                    {isStandardPlusTheme() ?
                        <div onClick={props.onClick} className={clsx(classes.btnSPlus, 'rounded-circle d-flex align-items-center justify-content-center mr-3')}>
                            <img width='13px' height='13px' src={`${process.env.PUBLIC_URL}/assets/icons/plus-icon.svg`} />
                        </div>
                        :
                        <div className='d-flex'>
                            {
                                <Button disabled classes={{ root: classes.btn }} className='rounded-circle d-flex align-items-center'>
                                    -
                                </Button>}
                            <Typography variant='body2' color='dark.main' className="pl-2 pr-2 font-weight-bold d-flex align-items-center">
                                {props.isAddingToCartInProgress() == true ? <Skeleton variant="rectangular" height="15px" width='13px' /> : 0}
                            </Typography>
                            <Button classes={{ root: classes.btn }} className='rounded-circle d-flex align-items-center' onClick={props.onClick}>+</Button>
                        </div>}
                </div>
                : getTheApplicationTheme() === 'PROMINENT' ?
                    <div className='d-flex'>
                        {
                            <Button disabled classes={{ root: classes.btnProminent }} className='rounded-circle d-flex align-items-center'>
                                -
                            </Button>}
                        <Typography variant='body2' color='dark.main' className="pl-4 pr-4 font-weight-bold d-flex align-items-center">
                            {props.isAddingToCartInProgress() == true ? <Skeleton variant="rectangular" height="15px" width='13px' /> : 0}
                        </Typography>
                        <Button classes={{ root: classes.btnProminent }} className='rounded-circle d-flex align-items-center' onClick={props.onClick}>+</Button>
                    </div>
                    : null
            }
        </div >
    )
}
export default ProductAddButton