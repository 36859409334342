import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { useStyle } from "./status.box.v2.style";
import { IsMobileWidth, IsTabletWidth } from "components/common/util/util";
import clsx from "clsx";
import CustomLinearProgress from 'components/common/custom.linear.progress.bar/custom.linear.progress.bar';
import CountDownTimer from "../count.down.timer/count.down.timer";

const StatusBoxV2 = (props) => {
  const { name, label, subLabel, titleBackgroundColor, title, description, message, heading, titleColor, image, status, logo, hideStatus } = props;
  const mobileWidth = IsMobileWidth();
  const tabletWidth = IsTabletWidth();
  const classes = useStyle();

  const getReceivedOrderProgess = () => {
    if (status === "order_pending" || status === "order_confirmed" || status === "order_dispatched" || status === "order_delivered") {
      return 100
    }
    else {
      return 0
    }
  }
  const getPreparingOrderProgress = () => {
    if (status === "order_confirmed" || status === "order_dispatched" || status === "order_delivered") {
      return 100
    }
    else {
      return 0
    }
  }
  const getOnTheWayOrderProgress = () => {
    if (status === "order_dispatched" || status === "order_delivered") {
      return 100
    }
    else {
      return 0
    }
  }
  // const getDeliveredOrderProgress = () => {
  //   if (status === "order_delivered") {
  //     return 100
  //   }
  //   else {
  //     return 0
  //   }
  // }

  return (
    <Grid container justifyContent="center" className={clsx(classes.gridContainer, 'm-1 ')}>
      <Grid item xs={12} sm={12} mb={2} className='position-relative w-100'>
        {label ?
          <div className="d-flex justify-content-center align-items-center flex-column w-100 position-absolute">
            <Typography variant='h6' className={clsx(classes.label, "border-radius-100 bg-white d-flex justify-content-center align-items-center text-center")}>
              {label}
            </Typography>
            {message ?
              <Typography variant="body1" className={clsx("width-max-content m-auto text-center font-weight-bold")} color="color.dark">
                {message}
              </Typography>
              : null}
          </div> : null}
        <Paper className={clsx(classes.paper, label && 'pt-5 mt-5', "d-flex flex-column justify-content-center pb-4")}>
          <div className={clsx(label && 'mt-5', 'w-auto d-flex justify-content-center')}>
            <img src={image}
              width={mobileWidth ? '300px' : '200px'} />
          </div>
          <Typography variant="h6" className="pt-2 pb-2 text-center font-weight-bold" color="color.dark">
            {heading}
          </Typography>
          {
            description ?
              <Typography variant={clsx(!(mobileWidth || tabletWidth) && "body3", (mobileWidth || tabletWidth) && 'body3')} className={clsx(classes.subLabel, "pb-1 text-center")}>
                {description}
              </Typography> : null
          }
          <Typography variant="body1" className={clsx(classes.name, "width-max-content m-auto p-2 pl-4 pr-4 text-center font-weight-bold")} color="color.dark">
            {name}
          </Typography>
        </Paper>
      </Grid >
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {/* <Paper
          elevation={0}
          className="rounded-0"
          sx={{
            backgroundColor: titleBackgroundColor ? titleBackgroundColor : "#fff",
            border: !titleBackgroundColor ? "3px solid #d9d8d8" : "none",
          }}
        >
          {title && (
            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "h6", (mobileWidth || tabletWidth) && 'subtitle')} className='pt-2 pb-2 text-center' color={titleColor ? titleColor : '#fff'}>
              {title}
            </Typography>
          )}
        </Paper> */}
        <Typography variant={clsx(!(mobileWidth || tabletWidth) && "body3", (mobileWidth || tabletWidth) && 'body3')} className={clsx(classes.subLabel, "text-center")}>
          {subLabel}
        </Typography>

        {status && !hideStatus ?
          <Grid container spacing={1} className='w-100 pt-3 d-flex justify-content-between'>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <CustomLinearProgress classNames={classes.linearProgress1} progressValue={getReceivedOrderProgess()} />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <CustomLinearProgress classNames={classes.linearProgress2} progressValue={getPreparingOrderProgress()} />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <CustomLinearProgress classNames={classes.linearProgress3} progressValue={getOnTheWayOrderProgress()} />
            </Grid>
            {/* <div className='w-20'>
              <CustomLinearProgress progressValue={getDeliveredOrderProgress()} />
            </div> */}
          </Grid> : null
        }
      </Grid>
    </Grid >
  );
};

export default StatusBoxV2;
