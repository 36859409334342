import './transport.navigation.panel.popover';
import React from 'react';
import { List, Typography } from '@mui/material';
import { IsMobileWidth, IsTabletWidth, TRANSPORT_ROUTES, TRANSPORT_ROUTES_OTHERS } from 'components/common/util/util';
import { useStyle } from "./transport.navigation.panel.popover.style";
import clsx from 'clsx';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//import { isDemoQRMe } from '../../../util/util';

const TransportNavigationPanelPopover = (props) => {
    const { isNavigationPopoverOpen, location } = props
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()

    const redirect = (url) => {
        props.redirect(url);
    }

    return (
        <div id='top-navigation-panel-popover'>
            <React.Fragment>
                <Popover
                    id='simple-popover'
                    open={isNavigationPopoverOpen}
                    onClose={props.hideIsNavigationPopover}
                    anchorEl={isNavigationPopoverOpen}
                    // anchorOrigin={{
                    //     vertical: 'bottom',
                    //     horizontal: 'left',
                    // }}
                    classes={{
                        paper: classes.drawer
                    }}
                >
                    <div className='d-flex justify-content-end' style={{ padding: "15px 20px" }}>
                        <div onClick={props.hideIsNavigationPopover} style={{ cursor: "pointer" }}>
                            <img src='/assets/icons/close-icon.png' width={'20px'} />
                        </div>
                    </div>
                    {
                        window.location.href.includes("cablah") ?
                            <List className={clsx(!(mobileWidth || tabletWidth) && classes.contentOnTablet, mobileWidth && classes.contentOnMobile)}>
                                {
                                    TRANSPORT_ROUTES && TRANSPORT_ROUTES.map((data, index) => {
                                        return <ListItem className={classes.listItemStyle} key={index} selected={location && location.pathname === data?.path ? true : false} onClick={() => redirect(data.path)} button>
                                            <ListItemIcon>
                                                <div className='cursor-pointer'>
                                                    <img src={data.iconSrc} width={'25px'} style={{ fill: '#fff' }} />
                                                </div>
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography className={clsx(classes.listTextStyle, 'w-100')}>{data.name}</Typography>} />
                                        </ListItem>
                                    })
                                }
                            </List> :
                            <List className={clsx(!(mobileWidth || tabletWidth) && classes.contentOnTablet, mobileWidth && classes.contentOnMobile)}>
                                {
                                    TRANSPORT_ROUTES_OTHERS && TRANSPORT_ROUTES_OTHERS.map((data, index) => {
                                        return <ListItem className={classes.listItemStyle} key={index} selected={location && location.pathname === data?.path ? true : false} onClick={() => redirect(data.path)} button>
                                            <ListItemIcon>
                                                <div className='cursor-pointer'>
                                                    <img src={data.iconSrc} width={'25px'} style={{ fill: '#fff' }} />
                                                </div>
                                            </ListItemIcon>
                                            <ListItemText primary={<Typography className={clsx(classes.listTextStyle, 'w-100')}>{data.name}</Typography>} />
                                        </ListItem>
                                    })
                                }
                            </List>
                    }
                </Popover>
            </React.Fragment>
        </div >
    );
}

export default TransportNavigationPanelPopover