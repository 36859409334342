import './top.navigation.panel.popover';
import React from 'react';
import { List, Typography } from '@mui/material';
import { IsMobileWidth, IsTabletWidth, NAVIGATIONS_ROUTES } from 'components/common/util/util';
import { useStyle } from "./top.navigation.panel.popover.style";
import clsx from 'clsx';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

const TopNavigationPanelPopover = (props) => {
    const { isNavigationPopoverOpen, location } = props
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()

    const { t } = useTranslation('global');
    const navigation = t('messages.navigation', { returnObjects: true });
    const isTypographyStyle = window.INDEX_NOKIA_ARENA === true;

    return (
        <div id='top-navigation-panel-popover'>
            <React.Fragment>
                <Popover
                    id='simple-popover'
                    open={isNavigationPopoverOpen}
                    onClose={props.hideIsNavigationPopover}
                    anchorEl={isNavigationPopoverOpen}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    classes={{
                        paper: mobileWidth && classes.drawer
                    }}
                >
                    <List className={clsx(!(mobileWidth || tabletWidth) && classes.contentOnTablet, mobileWidth && classes.contentOnMobile)}>
                        {
                            NAVIGATIONS_ROUTES && NAVIGATIONS_ROUTES.map((data, index) => {
                                return <ListItem className={classes.listItemStyle} key={index} selected={location && location.pathname === data?.path ? true : false} onClick={() => props.redirect(data.path)} button>
                                    <ListItemIcon>
                                        <div className='cursor-pointer'>
                                            <img src={data.iconSrc} width={'20px'} />
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography className={clsx(classes.listTextStyle, 'w-100')} style={isTypographyStyle
                                        ? {color: "#000" }
                                        : {}
                                    }>

                                        {window.INDEX_NOKIA_ARENA === true ?
                                            (navigation[index]?.title)
                                            :
                                            data.name
                                        }
                                    </Typography>} />
                                </ListItem>
                            })
                        }
                    </List>
                </Popover>
            </React.Fragment>
        </div >
    );
}

export default TopNavigationPanelPopover