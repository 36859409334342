import './custom.circular.progress.bar.scss'
import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

export default function CustomCircularProgressBar(props) {
    const { progressValue, size, label } = props

    return (
        <Box id='custom-circular-progress-bar' sx={{ display: 'inline-flex' }} className='position-relative'>
            <CircularProgress size={size ? size : 60} className='border rounded-circle' color='secondary' variant="determinate" value={progressValue} />
            <Box sx={{ top: 0, left: 0, bottom: 0, right: 0, }} className='d-flex justify-content-center align-items-center position-absolute'>
                {label ?
                    <Box sx={{ lineHeight: 1 }} className='d-block p-0 mt-1'>
                        <Typography variant="h6" className="font-weight-bold p-0 text-center circular-progress" color="secondary">
                            {label ? label : ""}
                        </Typography>
                        <Typography variant="caption" className="font-weight-light p-0 text-center circular-progress" color="secondary">
                            mins
                        </Typography>
                    </Box> : null
                }
            </Box>
        </Box>
    );
}
