import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    appNavbar: {
        backgroundColor: theme.palette.background.paper,
        height: '50px',
        left: '0px',
        top: "0px",
        boxShadow: 'none',
    },
    toolbar: {
        padding: "0px",
        minHeight: '54px !important',
    },
    container: {
        // padding: "4px 12px 4px 12px"
    },
    appBarSpacer: theme.mixins.toolbar,

}))
