import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    addressSelector: {
        minWidth: "140px",
        height: '40px',
        padding: "0px 10px 10px 10px",
        border: `1px solid ${theme.palette.darkBlueColor.main}`,
        borderRadius: "50px",
        // boxShadow: "-1px 0px 6px 3px #e0d8d8bf;",
        backgroundColor: theme.palette.lightBlueColor.main,
        cursor: "pointer"
    },
    textStyle: {
        lineHeight: 0.6,
        fontWeight: 400,
        fontSize: '15px',
    },
    lineHeight: {
        lineHeight: 0.6,
        fontSize: '10px'
    }
}))
