import React from "react";
import { Grid, Typography } from "@mui/material";
import clsx from "clsx";
import { IsMobileWidth, formatAmountV1 } from "components/common/util/util";

const ShoppingCardItemCheckout = (props) => {
  const mobileWidth = IsMobileWidth();

  const { image, name, color, size, price } = props;

  return (
    <Grid container className="mb-4 mt-3" id="shopping-card-item-checkout">
      <Grid item xs={12} sm={3} lg={2}>
        <div
          style={{
            height: 80,
            className: "w-100",
            backgroundImage: image ? `url(${image})` : "",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%, cover",
          }}
        ></div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={8}
        className={clsx(mobileWidth && "text-center")}
      >
        <Typography className="text-ellipsis-clamp-1 h-auto" variant="h6">
          {name ? name : ""}
        </Typography>
        <Typography>color:{color}</Typography>
        <Typography>size: {size}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        lg={2}
        className={clsx(
          !mobileWidth && "text-right",
          mobileWidth && "text-center"
        )}
      >
        <Typography> Rs: {formatAmountV1(price)}</Typography>
      </Grid>
    </Grid>
  );
};

export default ShoppingCardItemCheckout;
