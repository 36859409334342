import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.dark.main,
  },
  textColor2: {
    color: theme.palette.background.paper,
  },
  textGray: {
    color: theme.palette.greyColor.main,
  },
  lightBorder: {
    border: `1.5px solid ${theme.palette.lightBackgroundColor.main}`,
    borderRadius: '8px'
  },
  iconBgColor: {
    backgroundColor: theme.palette.lightBackgroundColor.main,
  },
  btnColor: {
    backgroundColor: theme.palette.dark.main,
    '&:hover': {
      backgroundColor: theme.palette.dark.main,
      border: `1px solid ${theme.palette.dark.main}`
    }
  },
  support: {
    background: "aqua",
  },
  circleStyle: {
    border: `0.5px solid ${theme.palette.lightBackgroundColor.main}`,
  },
  label: {
    color: "#CB1C91",
    "&.Mui-checked": {
      color: "#CB1C91",
    },
  },
  timer: {
    lineHeight: 1.2
  }
}));
