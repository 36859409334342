import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  orderBox: {
    // height: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  // label: {
  //   fontSize: "60px",
  // },
  gridCOntainer: {
    background: '#45d466',
    borderRadius: '0px'
  },
  btnStyle: {
    textTransform: 'none'
  },
  label: {
    width: '120px',
    height: '120px',
  },
  linearProgress1: {
    borderRadius: '17px 0px 0px 17px',
    height: 30
  },
  linearProgress2: {
    height: 30
  },
  linearProgress3: {
    borderRadius: '0px 17px 17px 0px',
    height: 30
  },
  subLabel: {
    color: '#282828',
    fontWeight: 600
  },
  paper: {
    boxShadow: '0px 4px 20px -2px rgba(50, 50, 71, 0.04)',
    borderRadius: '17px'
  },
  name: {
    borderRadius: '5px',
    backgroundColor: '#000',
    color: "#fff"
  }
}));
