import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    radioBtn: {
        color: '#000000',
        '&.Mui-checked':
        {
            color: '#000',
        }
    },
    linkStyle: {
        textDecoration: 'underline'
    },
    actions: {
        background: theme.palette.primary.main
    },
    // btnStyleBack:{
    //     height: '45px'
    // }
}));
