import React, { useEffect, useLayoutEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { readTextFile } from 'view.updater/middlewares/util/readTextFile';
// import LoadingIndicator from 'components/common/loaders/loading.indicator';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { appTheme } from './material.theme/app.theme/app.material.theme';
import CssBaseline from '@mui/material/CssBaseline';
// import { Detector } from "react-detect-offline";
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { cacheClear } from 'components/common/util/cache.clear';
import { initializeIndexedDb } from 'components/common/util/indexed.db.util';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import AppNavbarV2 from 'components/common/app.navbar.v2/app.navbar.v2'
import FooterV1 from 'components/common/footer.v1/footer.v1';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import AppNavbar from 'components/common/app.navbar/app.navbar'
import { initializeFirebase } from './initialize.firebase';
import { useState } from 'react';
import IdleTimer from 'react-idle-timer'
import NewVersionUpdateDialog from 'components/common/new.version.update.dialog/new.version.update.dialog';
import { v4 as uuidv4 } from 'uuid';
//import FirebaseNotificationAlertPopover from 'components/common/firebase.notification.alert.popover/firebase.notification.alert.popover';

export const AppContext = React.createContext();

const Container = (props) => {
    const { translations, locale, language, auth, forceUpdateAppVersion, newVersion } = props;
    const [state, setState] = useState({
        renderApp: false
    })
    const useStyles = makeStyles(theme => ({
        content: {
            flexGrow: 1,
            // height: '100vh',
            // overflow: 'auto',
        },
        appBarSpacer: appTheme.mixins.toolbar,
    }))
    const onCheckOut = () => {
        props.push('/checkout/cart-summary');
    }
    const isNotificationEnabled = () => {
        if ('permissions' in navigator && 'Notification' in window) {
            return Notification.permission === "granted"
        }
        else {
            return true;
        }
    }
    const classes = useStyles();
    const intlProps = {
        defaultLocale: 'en',
        messages: translations,
        key: locale,
        locale: locale || 'en'
    };
    const isEnabled = isNotificationEnabled();
    const intls = Object.keys(translations).length > 0 ? intlProps : { locale: 'en' };
    const [isNotificationPermissionAllowed, setIsNotificationPermissionAllowed] = useState(isEnabled);
    const requestPermission = () => {
        Notification.requestPermission((permission) => {
            //can be default or granted or denied      
            if (permission === "granted") {
                setIsNotificationPermissionAllowed(true)
            }
        })
    }
    useLayoutEffect(() => {
        if (!isNotificationPermissionAllowed && 'permissions' in navigator && 'Notification' in window) {
            requestPermission()
        }
        if ('permissions' in navigator) {
            navigator.permissions.query({ name: 'notifications' }).then((permissionStatus) => {
                //on change of permission status
                permissionStatus.onchange = () => {
                    if (permissionStatus.state === "granted") {
                        //state to change
                        //setIsNotificationPermissionAllowed(true);
                    } else {
                        setIsNotificationPermissionAllowed(false);

                    }
                };
            });
        }
    }, []);

    useEffect(() => {
        initializeIndexedDb().then(() => {
            setState({ ...state, renderApp: true })
            window.localStorage.setItem('appVersion', window.localStorage.getItem('appVersion')) //VERSION_CHANGES
            initializeFirebase();
            cacheClear();
            readTextFile(`${process.env.PUBLIC_URL}/localization/localization.${language}.json`, (localizationContent) => {
                let localization = JSON.parse(localizationContent);
                FingerprintJS.load().then((fp) => {
                    fp.get().then((result) => {
                        let uniqueId = window.localStorage.getItem("uniqueId");
                        if (!uniqueId) {
                            uniqueId = uuidv4();
                            window.localStorage.setItem("uniqueId",uniqueId);
                        }
                        const visitorId = `${result.visitorId}_${uniqueId}_${window.location.host}`;
                        window.localStorage.setItem("visitorId", visitorId);
                        if (window.location.href.includes('transport') === true) {
                            props.doMerchantLogin({
                                login_id: window.MERCHANT_LOGIN_USERNAME,
                                password: window.MERCHANT_LOGIN_USER_PASSWORD
                            })
                        }
                        else {
                            if (window.TRANSPORT_SERVICE === true) {
                                props.doMerchantLogin({
                                    login_id: window.MERCHANT_LOGIN_USERNAME,
                                    password: window.MERCHANT_LOGIN_USER_PASSWORD
                                })
                            }
                            else {
                                props.authenticateAnonymously({
                                    device_uuid: visitorId ? visitorId : 123
                                })
                            }
                        }
                    });
                })
                props.setLocalizationMessages(localization.messages, language);
            });
        })
        // if(props.auth && !props.auth.get('user')){
        //     props.push('/login');
        // }
        // document.ontouchmove = function(event){
        //     event.preventDefault();
        // }
    }, []);

    useEffect(() => {
        readTextFile(`${process.env.PUBLIC_URL}/localization/localization.${language}.json`, (localizationContent) => {
            let localization = JSON.parse(localizationContent);
            props.setLocalizationMessages(localization.messages, language);
        });
    }, [language]);
    const onIdle = () => {
        if (window.location.pathname.includes("/transport/main-page")) {
            window.location.reload(true);
        }
        else if (window.location.pathname.includes("transport/location-pickup")) {
            window.location.reload(true);
        }
    }
    return (
        <IntlProvider {...intls}>
            {
                // Object.keys(translations).length > 0 && translations['home'] !== undefined ?
                <AppContext.Provider value={{
                    executions: props.executions
                }} >
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={appTheme}>
                            <CssBaseline />
                            {/* {auth && auth.size > 0 ?
                                <div className='sticky top-0 z-index-1200'>
                                    <AppNavbar {...props} onCheckOut={onCheckOut} />
                                </div>
                                : null
                                // <div className='sticky top-0 z-index-1200'>
                                //     <AppNavbarV2  {...props} />
                                // </div>
                            } */}
                            {
                                window.TRANSPORT_SERVICE === true ?
                                    <IdleTimer
                                        timeout={600000}
                                        onIdle={onIdle}
                                    /> : null
                            }
                            {
                                forceUpdateAppVersion === true ?
                                    <NewVersionUpdateDialog newVersion={newVersion} open={true} /> : null
                            }
                            {/* <div className={classes.appBarSpacer} /> */}
                            <main className={clsx(classes.content, '')}>
                                {
                                    state.renderApp === true ?
                                        props.children : null
                                }
                            </main>
                            {/* {
                                isNotificationPermissionAllowed === false ?
                                    <FirebaseNotificationAlertPopover isFirebaseNotificationAlertPopoverOpen={true} />
                                    : null
                            } */}
                            {/* <FooterV1  {...props} /> */}
                        </ThemeProvider>
                    </StyledEngineProvider>
                </AppContext.Provider>
                // :< LoadingIndicator />
            }
        </IntlProvider>
    );
}
export default connect(null, { push })(Container);

