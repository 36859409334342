import React, { useState } from 'react'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog'
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import { Paper, Typography, FormControlLabel, Radio, IconButton, RadioGroup } from '@mui/material';
import { IsMobileWidth } from 'components/common/util/util'
import clsx from 'clsx';

const AddProductErrorDialog = (props) => {
    const mobileWidth = IsMobileWidth()
    const { isOpen, selectedProduct, storeDetailInfo } = props;
    const clearCartAndAddNewItm = () => {
        if (storeDetailInfo) {
            props.clearCartAndAddNewItem({ ...selectedProduct, merchantAddressId: storeDetailInfo && storeDetailInfo.get('address') && storeDetailInfo.get('address').get('id') });
            props.hideProductErrorDialog();
        }
    }
    return (
        <ContentDialog
            setHeaderBackgroundAsPrimary={true}
            handleClose={props.hideProductErrorDialog}
            open={isOpen}
            actions={<div className="w-100 d-flex align-items-center justify-content-center flex-wrap">
                <div className={clsx("p-1 text-capitalize", !mobileWidth && 'w-49', mobileWidth && 'w-100')}>
                    <LoaderButton
                        fullWidth
                        type="submit"
                        variant="outlined"
                        onClick={() => props.redirectTo('/checkout/cart-summary')}
                        classNames="text-capitalize"
                        color="primary">
                        Proceed to Cart
                    </LoaderButton>
                </div>
                <div className={clsx("p-1 text-capitalize", !mobileWidth && 'w-49', mobileWidth && 'w-100 mt-2')}>
                    <LoaderButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        classNames="text-capitalize"
                        color="secondary"
                        onClick={clearCartAndAddNewItm}
                    // loaderPosition="top"
                    >
                        Clear Cart and Add New Item
                    </LoaderButton>
                </div>
                {/* <div className={clsx("p-1 text-capitalize", !mobileWidth && 'w-30', mobileWidth && 'w-50')}>
                    <LoaderButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={props.hideProductErrorDialog}
                        classNames="text-capitalize"
                        color="secondary">
                        Cancel
                    </LoaderButton>
                </div> */}
            </div>}
            title={<Typography variant='body1' className="text-white m-auto font-weight-bold w-100 text-center" >
                Confirm</Typography >}>
            <Typography variant='body1' color='dark.main' className={clsx(!mobileWidth && 'pt-2 pb-5 mb-5', mobileWidth && 'pt-1 pb-2', 'w-100')}>
                You've already got items in your cart, please complete your first order or clear
                your cart to add new items.
            </Typography>
        </ContentDialog >
    )
}
export default AddProductErrorDialog