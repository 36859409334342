import './merchant.address.map.scss';
import React, { useState, useEffect } from 'react';
import { GOOGLE_MAPS_API_KEY } from "view.updater/middlewares/util/api.config";
import GoogleMapReact from "google-map-react";
import { Typography } from '@mui/material';

const MerchantAddressMap = (props) => {
    const { storeDetailInfo } = props
    let marker = '';
    const [state, setState] = useState({
        map: '',
        maps: ''
    })
    const getSelectedAddress = (storeDetailInfo) => {
        if (storeDetailInfo && storeDetailInfo.get('address')) {
            return {
                lat: storeDetailInfo && storeDetailInfo.get('address') && storeDetailInfo.get('address').get("lat") ? storeDetailInfo.get('address').get("lat") : 24.8500938933977,
                lng: storeDetailInfo && storeDetailInfo.get('address') && storeDetailInfo.get('address').get("long") ? storeDetailInfo.get('address').get("long") : 67.0082072541118,
                address: storeDetailInfo && storeDetailInfo.get('address') && storeDetailInfo.get('address').get("description") ? storeDetailInfo.get('address').get("description") : '',
            }
        }
        else {
            return {
                lat: 24.8500938933977,
                lng: 67.0082072541118,
                address: ''
            }
        }
    }
    const initGoogleMaps = ({ map, maps }) => {
        if (map === null) {
            setState({ ...state, map: null })
        }
        else {
            setState({ ...state, map: map, maps: maps })
        }
    }

    useEffect(() => {
        if (state.map) {
            if (marker) {
                marker.setMap(null);
            }
            marker = new state.maps.Marker({
                position: { lat: getSelectedAddress(storeDetailInfo)?.lat, lng: getSelectedAddress(storeDetailInfo)?.lng },
                map: state.map,
                icon: {
                    url: `${process.env.PUBLIC_URL}/assets/icons/location-marker.png`,
                    size: new state.maps.Size(71, 71),
                    origin: new state.maps.Point(0, 0),
                    anchor: new state.maps.Point(17, 34),
                    scaledSize: new state.maps.Size(35, 35)
                },
                //animation: state.maps.Animation.BOUNCE, // AVAILABLE ARE BOUNCE,DROP
            })
        }
    }, [state.map])

    useEffect(() => {
        return () => {
            marker = ''
        }
    }, [])
    return (
        <div id='merchant-address-map' className='w-100 position-relative d-flex align-items-end justify-content-start'>
            <div className='d-flex w-100 position-absolute address-style align-items-center'>
                <img
                    className='mr-2 ml-2'
                    src={`${process.env.PUBLIC_URL}/assets/icons/pin-nav-icon.svg`}
                    width="15px"
                />
                <div>
                    <Typography color='dark.main' variant='caption' className='font-weight-bold'>
                        Merchant Address
                    </Typography>
                    <Typography color='dark.main' variant='caption' className='font-weight-bold'>
                        {storeDetailInfo && getSelectedAddress(storeDetailInfo)?.address}
                    </Typography>
                </div>
            </div>
            <div style={{ width: "100%", height: "200px" }} className="">
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: `${GOOGLE_MAPS_API_KEY}&libraries=places`,
                        libraries: ['places']
                    }}
                    center={{ lat: storeDetailInfo && getSelectedAddress(storeDetailInfo)?.lat, lng: storeDetailInfo && getSelectedAddress(storeDetailInfo)?.lng }}
                    defaultZoom={15}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={initGoogleMaps}>
                </GoogleMapReact>
            </div>
        </div>
    )
}

export default MerchantAddressMap