import React, { useEffect, useState, useRef, memo } from "react";
import ContentDialog from "components/common/material.components/content.dialog/content.dialog";
import { IsMobileWidth } from "components/common/util/util";
import { Skeleton } from "@mui/lab";
import { isDemoQRMe, getTheApplicationTheme, getTaxPercentage } from "../util/util";
import TopNavigationPanel from "components/common/top.navigation.panel/top.navigation.panel";
import BankAlfalahIframe from "./bank.alfalah.iframe";

const BankAlfalahPaymentForm = memo((props) => {
    const { open, iframeUrl, email, cartProducts } = props;
    const mobileWidth = IsMobileWidth();
    const onMessageReceiveRef = useRef(null);
    const [isLoadingIframe, setIsLoadingIframe] = useState(true);

    const hideIframePaymentDialog = (e, reason) => {
        if (reason !== "backdropClick") {
            props.resetBankAlfalahPaymentForm();
        }
    };
    useEffect(() => {
        //removing previous event listener
        if (typeof onMessageReceiveRef.current === "function") {
            window.removeEventListener('message', onMessageReceiveRef.current, false)
        }
        onMessageReceiveRef.current = (e) => {
            if (e.data.s && e.data.cn) {
                props.resetCheckOutInvoice();
                props.resetPlaceOrder();
                props.resetCreateInvoice();
                let invoiceItemAttributes = [];
                // const validationResult = isValidFields(state);
                // setstate({ ...state, validationResult: validationResult })
                // if (validationResult.isValidEmail === true) {
                let merchantAddressId = 0;
                if (cartProducts) {
                    for (let cartProd of cartProducts) {
                        //COLOR_SIZE_CHANGES
                        merchantAddressId = cartProd?.get('data')?.get('merchantAddressId')
                        invoiceItemAttributes.push({
                            instruction: "",
                            product_query_id: cartProd?.get('data')?.get('query_id'),
                            quantity: cartProd?.get('data')?.get('quantity'),
                            tax_percent: getTaxPercentage(cartProd),
                            item_description: "",
                            color: cartProd?.get('data')?.get('selectedColor') ? cartProd?.get('data')?.get('selectedColor') : {},
                            size: cartProd?.get('data')?.get('selectedSize') ? cartProd?.get('data')?.get('selectedSize') : {}
                        })
                    }
                    props.createInvoice({
                        invoice: {
                            invoice_items_attributes: invoiceItemAttributes,
                            organization_query_id: cartProducts?.get(0)?.get('data')?.get('storeQueryId'),
                            //address_id: 234
                        }
                    },{
                        email: "",
                        sessionId: e.data.s,
                        cardToken: e.data.cn,
                        merchantAddressId: merchantAddressId,
                        paymentMethod: 'PAYMENT_BY_CARD_BANK_ALFALAH'
                    })
                    props.resetBankAlfalahPaymentForm();
                }
            }
        };
        window.addEventListener('message', onMessageReceiveRef.current, false);
        return () => {
            window.removeEventListener('message', onMessageReceiveRef.current, false);
        };
    }, [])

    const onBankAlfalahFrameLoad = () => {
        //setIsLoadingIframe(false)
    }
    return (
        <div>
            <ContentDialog
                open={open}
                maxWidth={!mobileWidth ? "md" : ""}
                fullScreen={(mobileWidth || getTheApplicationTheme() === 'PROMINENT') ? true : false}
                handleClose={getTheApplicationTheme() !== 'PROMINENT' ? hideIframePaymentDialog : null}
                dividers={false}
                hideContentPadding={true}
                hideTitlePadding={true}
                titleBackgroundColor="#d12194"
                title={getTheApplicationTheme() === 'PROMINENT' ?
                    <div className='w-100'>
                        <TopNavigationPanel
                            hideNavigationButtons={true}
                            name='Payment'
                        />
                    </div> : null}
                logo={mobileWidth ? isDemoQRMe ? `${process.env.PUBLIC_URL}/assets/images/qrme-logo-white-bg.png` : window.TRADE_TYPE_IMAGE_URL : ''}
            >
                <div
                    style={{ minHeight: "450px" }}
                    className="d-flex align-items-center justify-content-center"
                >
                    <div className="w-100 d-flex flex-column position-relative">
                        {/* {isLoadingIframe &&
                            <div className="d-flex w-100 flex-column justify-content-center align-items-center position-absolute">
                                <div className="pt-4">Loading</div>
                                <div className="w-100 d-flex justify-content-center"><Skeleton
                                    variant="rectangular"
                                    height="200px"
                                    width="80%"
                                    sx={{ marginTop: "20px" }}
                                /> </div>
                            </div>
                        } */}
                        <BankAlfalahIframe src={iframeUrl} />
                    </div>
                </div>
            </ContentDialog>
        </div>
    );
});
export default BankAlfalahPaymentForm;
