import React, { useEffect } from 'react';
import { createStateLink, useStateLink } from '@hookstate/core';
import { Typography } from '@mui/material';

const CountDownTimer = (props) => {
    const StateLinkSecondsRemaing = createStateLink(props.secondsRemaining);
    const secondsRemaining = useStateLink(StateLinkSecondsRemaing);
    let timer = undefined;
    const { showSeconds = false, color = "#000" } = props;

    useEffect(() => {
        timer = setInterval(() => tick(), 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    const secondsToString = (seconds) => {
        var numdays = Math.floor(seconds / 86400);
        var numhours = Math.floor((seconds % 86400) / 3600);
        var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
        var numseconds = ((seconds % 86400) % 3600) % 60;
        if (numdays > 0) {
            return <div className='w-100 d-flex justify-content-evenly'>
                <div className='d-flex flex-column align-items-center text-center'>
                    <Typography className='font-weight-bold' variant='h2'>
                        {numdays}
                    </Typography>
                    <Typography className='font-weight-bold' variant='body3'>
                        days
                    </Typography>
                </div>
                <div className='pl-2 d-flex flex-column align-items-center text-center'>
                    <Typography className='font-weight-bold' variant='h2'>
                        {numhours}
                    </Typography>
                    <Typography className='font-weight-bold' variant='body3'>
                        hours
                    </Typography>
                </div>
                <div className='pl-2 d-flex flex-column align-items-center text-center'>
                    <Typography className='font-weight-bold' variant='h2'>
                        {numminutes}
                    </Typography>
                    <Typography className='font-weight-bold' variant='body3'>
                        mins
                    </Typography>
                </div>
                {showSeconds ? <div className='d-flex flex-column align-items-center justify-content-center text-center m-4'>
                    <Typography className='font-weight-bold' variant='h6' style={{ color }}>
                        {numseconds}
                    </Typography>
                    <Typography className='font-weight-bold' variant='body3' style={{ color }}>
                        sec
                    </Typography>
                </div> : null}
                {/* {`${numdays} days ${numhours} hours ${numminutes} mins ${numseconds} secs`} */}
            </div>
        }
        else if (numhours > 0)
            return <div className='w-100 d-flex justify-content-center'>
                <div className='d-flex flex-column align-items-center text-center'>
                    <Typography className='font-weight-bold' variant='h2' style={{ color }}>
                        {numhours}
                    </Typography>
                    <Typography className='font-weight-bold' variant='body3' style={{ color }}>
                        hours
                    </Typography>
                </div>
                <div className='pl-2 d-flex flex-column align-items-center text-center'>
                    <Typography className='font-weight-bold' variant='h2' style={{ color }}>
                        {numminutes}
                    </Typography>
                    <Typography className='font-weight-bold' variant='body3' style={{ color }}>
                        mins
                    </Typography>
                </div>
                {showSeconds ? <div className='d-flex flex-column align-items-center justify-content-center text-center m-4'>
                    <Typography className='font-weight-bold' variant='h6' style={{ color }}>
                        {numseconds}
                    </Typography>
                    <Typography className='font-weight-bold' variant='body3' style={{ color }}>
                        sec
                    </Typography>
                </div> : null}
            </div>
        // `${numhours} hours ${numminutes} mins ${numseconds} secs`;
        else if (numminutes > 0)
            return <div className='w-100 d-flex justify-content-center'>
                <div className='d-flex flex-column align-items-center text-center'>
                    <Typography className='font-weight-bold' variant='h2' style={{ color }}>
                        {numminutes}
                    </Typography>
                    <Typography className='font-weight-bold' variant='body3' style={{ color }}>
                        mins
                    </Typography>
                </div>
                {showSeconds ? <div className='d-flex flex-column align-items-center justify-content-center text-center m-4'>
                    <Typography className='font-weight-bold' variant='h6' style={{ color }}>
                        {numseconds}
                    </Typography>
                    <Typography className='font-weight-bold' variant='body3' style={{ color }}>
                        sec
                    </Typography>
                </div> : null}
            </div>
        // `${numminutes} mins ${numseconds} secs`
        else if (numseconds > 0)
            return <div className='w-100 d-flex flex-column align-items-center text-center'>
                <Typography className='font-weight-bold' variant='h2'>
                    {numseconds}
                </Typography>
                <Typography className='font-weight-bold' variant='body3'>
                    secs
                </Typography>
            </div>
        // `${numseconds} secs`;
        else
            return "";
    }
    const tick = () => {
        if (secondsRemaining.get() <= 0) {
            props.timeUp && props.timeUp();
            clearInterval(timer);
        } else {
            secondsRemaining.set(seconds => seconds - 1);
        }
    }
    const remainingTime = secondsToString(secondsRemaining.get());
    return (
        <div className={props.className}>
            {remainingTime ? remainingTime : null}
        </div>
    )
}
export default CountDownTimer;
{/* <CountDownTimer
secondsRemaining={parseInt(120)}
timeUp={() => { }} /> */}