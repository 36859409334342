//FIREBASE_MESSAGING_CHANGES
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
export const VAPID_KEY = "BHAL_07zWh_rs0K_sFVEqpUfnQcQDFdUsmXzC4BPYGHmAPXBW3LgQ_elqohGGw4VI4zBHQ-c37JlDSLN-7UL-Sg";

const firebaseConfig = {
    apiKey: "AIzaSyCyZIqz6LWz9-H6PpOLm3HY2fAZ6XjAikc",
    authDomain: "p3-apps.firebaseapp.com",
    databaseURL: "https://p3-apps.firebaseio.com",
    projectId: "p3-apps",
    storageBucket: "p3-apps.appspot.com",
    messagingSenderId: "19561852373",
    appId: "1:19561852373:web:895ca21f83a6faf5f8215c",
    measurementId: "G-4918G69EG5"
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app)
if ('permissions' in navigator) {
    navigator.permissions.query({ name: 'notifications' }).then(function (notificationPerm) {
        notificationPerm.onchange = function () {
            if (notificationPerm && notificationPerm.state === "granted") {
                getToken(messaging, {
                    vapidKey: VAPID_KEY
                }).then((currentToken) => {
                    window.localStorage.setItem("FIREBASE_TOKEN", currentToken)
                })
            }
            else {
                console.log("User decided to change his seettings. New permission: " + notificationPerm.state);
            }

        };
    });
}

function notifyMe() {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        //var notification = new Notification("Hi there!");
        return true;
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied' || Notification.permission === "default") {
        Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                //var notification = new Notification("Hi there!");
                return true;
            }
        });
    }
    return false;

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
}
export const requestFirebaseNotificationPermission = () => {
    try {
        // if('serviceWorker' in navigator){
        //     navigator.serviceWorker.register('firebase-messaging-sw.js')
        // no need to register, it must auto register by firebase
        // }
        if (notifyMe() === true) {
            getToken(messaging, {
                vapidKey: VAPID_KEY
            }).then((currentToken) => {
                window.localStorage.setItem("FIREBASE_TOKEN", currentToken)
            })
        }
    } catch (error) {
        console.error(error);
    }
}

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//         debugger
//       console.log("receive push notificication the actual message listner")
//       console.log(payload)
//       //alert('receive push notificication the actual message listner')
//       resolve(payload);
//     });
//   });
export function onMessageListener() {
    // return new Promise((resolve, _reject) => {
    //     onMessage(messaging, (payload) => {
    //         console.log("receive push notificication the actual message listner")
    //         //alert('receive push notificication the actual message listner')
    //         resolve(payload);
    //     });
    // })
}
