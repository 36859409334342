import React from "react";
import "./quantity.exceed.dialog.scss";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

export default function QuantityExceedDialog(props) {
    const { open } = props;
    return (
        <div className="popup">
            <Dialog
                disableBackdropClick={false}
                open={open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ root: "dialog" }}>
                <DialogTitle
                    className="mt-4 font-weight-bold"
                    classes={{ root: "dialogTitle" }}
                    id="alert-dialog-title">
                    Alert
                </DialogTitle>
                <DialogContent classes={{ root: "dialogContent" }}>
                    <DialogContentText dividers id="alert-dialog-description">
                        {props.children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions classes={{ root: "actions" }}>
                    <div className="d-flex w-50 justify-content-center">
                        <div className="w-100">
                            <Button
                                fullWidth
                                onClick={props.handleClose}
                                color="secondary"
                                variant="contained">
                                OK
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
