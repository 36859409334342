import { fromJS } from 'immutable';
import {
    ADD_UPDATE_TRANSPORT_DATA, GET_RIDE_ESTIMATED_PRICE,
    RESET_GET_RIDE_ESTIMATED_PRICE, ASSIGN_RIDER,
    RESET_ASSIGN_RIDER,
    GET_RIDER_STATUS,
    RESET_GET_RIDER_STATUS,
    RESET_TRANSPORT_DATA,
    SET_RIDE_ESTIMATED_PRICE_LOCALLY,
    GET_ALL_BOOKINGS,
    RESET_GET_ALL_BOOKINGS
} from '../actions/transport.actions';
import { getActionName } from './util';
const initialState = fromJS({
    transportData: {
        destinationLat: ""
    },
    estimatedPrices: [],
    bookings: undefined,
    riderDetails: undefined
});

function transportReducer(state = initialState, action) {
    let getEstimatedPriceType = "";
    if (action.type.includes(GET_RIDE_ESTIMATED_PRICE) && action.type.includes("SUCCESS")) {
        getEstimatedPriceType = action.type;
    }
    if (action.type === getEstimatedPriceType) {
        const estimatedPriceIndex = state.get('estimatedPrices').findIndex((esPrice) =>
            esPrice.get('carType') == action.payload.carType);
        if (estimatedPriceIndex === -1) {
            state = state.updateIn(['estimatedPrices'], (estimatedPrices) => {
                return estimatedPrices.push(fromJS({
                    ...action.payload,
                    ...action.response
                }))
            })
        }
        else {
            state = state.updateIn(['estimatedPrices', estimatedPriceIndex], (estimatedPriceDetails) => {
                return fromJS({
                    ...action.payload,
                    ...action.response
                })
            })
        }
        return state;
    }
    switch (action.type) {
        case ADD_UPDATE_TRANSPORT_DATA:
            return state.update('transportData', (transportData) => {
                return transportData.merge(action.payload)
            })
        case SET_RIDE_ESTIMATED_PRICE_LOCALLY:
            let estimatedPriceIndex;
            if (action.payload.meetNGreet === true) {
                estimatedPriceIndex = state.get('estimatedPrices').findIndex((esPrice) =>
                    esPrice.get('carType') == action.payload.carType && esPrice.meetNGreet === true);
            }
            else {
                estimatedPriceIndex = state.get('estimatedPrices').findIndex((esPrice) =>
                    esPrice.get('carType') == action.payload.carType);
            }
            if (estimatedPriceIndex === -1) {
                if (!action.payload.onlyUpdate)
                    state = state.updateIn(['estimatedPrices'], (estimatedPrices) => {
                        return estimatedPrices.push(fromJS({
                            ...action.payload
                        }))
                    })
            }
            else {
                if (action.payload.onlyUpdate === true) {
                    state = state.updateIn(['estimatedPrices', estimatedPriceIndex], (estimatedPriceDetails) => {
                        return estimatedPriceDetails.merge({
                            ...action.payload
                        })
                    })
                }
                else {
                    state = state.updateIn(['estimatedPrices', estimatedPriceIndex], (estimatedPriceDetails) => {
                        return fromJS({
                            ...action.payload
                        })
                    })
                }

            }
            return state;
        case RESET_TRANSPORT_DATA:
            state = state.set('transportData', fromJS({
                destinationLat: ""
            }));
            return state;
        case getActionName(ASSIGN_RIDER):
            return state;
        case GET_ALL_BOOKINGS:
            if (action.useIndexedDb === false) {
                if (action.response && action.response.length > 0) {
                    return state.set('bookings', fromJS(action.response.sort((a, b) => b.data.createdAt - a.data.createdAt)));
                }
                else
                    return state;
            }

        case RESET_GET_ALL_BOOKINGS:
            state = state.set('bookings', undefined);
            return state;
        case getActionName(GET_RIDER_STATUS):
            state = state.set('riderDetails', fromJS({ ...action.payload, ...action.response }));
            return state;
        case RESET_GET_RIDER_STATUS:
            state = state.set('riderDetails', undefined);
            return state;
        case RESET_GET_RIDE_ESTIMATED_PRICE:
            return state.set('estimatedPrices', fromJS([]));
        default:
            return state;
    }
}
export default transportReducer;
