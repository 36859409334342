import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.lightPinkColor.main,
        border: `1px dashed ${theme.palette.dark.main}`,
        clipPath: 'polygon(4% 36%, 0 36%, 0 0, 100% 0, 100% 36%, 96% 36%, 96% 64%, 100% 64%, 100% 100%, 0 100%, 0 64%, 4% 64%)'
    },
    children1: {
        color: theme.palette.secondary.main,
        width: '50px'
    },
    children2: {
        color: theme.palette.secondary.main,
    },
    voucher: {
        backgroundColor: theme.palette.lightPinkColor.main,
        border: `2.5px dashed ${theme.palette.secondary.main}`,
        clipPath: 'polygon(4% 36%, 0 36%, 0 0, 100% 0, 100% 36%, 96% 36%, 96% 64%, 100% 64%, 100% 100%, 0 100%, 0 64%, 4% 64%)',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.darkPinkColor.main
            //border: `2px solid ${theme.palette.secondary.light}`
        }
    },
    selectedVoucher: {
        backgroundColor: theme.palette.darkPinkColor.main,
        border: `2.5px solid ${theme.palette.secondary.dark}`,
        clipPath: 'polygon(4% 36%, 0 36%, 0 0, 100% 0, 100% 36%, 96% 36%, 96% 64%, 100% 64%, 100% 100%, 0 100%, 0 64%, 4% 64%)'
    },
}));