import React, { useContext, useEffect, useState, useRef } from 'react'
import { checkExecutionStatus, scrollToRefElement, scrollToTop } from 'components/common/util/util';
import ShoppingCart from './components/shopping.cart/shopping.cart';
import {
    CHECKOUT_INVOICE, CREATE_INVOICE, PLACE_ORDER,
    DOWNLOAD_INVOICE_RECEIPT, ADD_UPDATE_PRODUCT_TO_CART, VALIDATE_VOUCHER, GET_CART_PRODUCTS, GET_BANK_ALFALAH_PAYMENT_FORM
} from 'view.updater/actions/products.actions';
import { AppContext } from '../main/container';
import NetaXeptPaymentForm from 'components/common/neta.xept.payment.form/neta.xept.payment.form';
import { INITIATE_NETA_XEPT_PAYMENT } from 'view.updater/actions/products.actions';
import BankAlfalahPaymentForm from 'components/common/bank.alfalah.payment.form/bank.alfalah.payment.form';

export default function CheckoutCartSummaryConfirmation(props) {
    const { netsPaymentInitData, bankAlfalahPaymentFormDetails } = props;
    const context = useContext(AppContext);
    const execStatusCheckoutInvoice = checkExecutionStatus(context, CHECKOUT_INVOICE);
    const execStatusCreateInvoice = checkExecutionStatus(context, CREATE_INVOICE);
    const execStatusPlaceOrder = checkExecutionStatus(context, PLACE_ORDER);
    const execStatusDownloadInvoiceReceipt = checkExecutionStatus(context, DOWNLOAD_INVOICE_RECEIPT);
    const execStatusAddUpdateProductToCart = checkExecutionStatus(context, ADD_UPDATE_PRODUCT_TO_CART);
    const execStatusGetCartProducts = checkExecutionStatus(context, GET_CART_PRODUCTS);
    const execStatusValidateVoucherStatus = checkExecutionStatus(context, VALIDATE_VOUCHER);
    const execStatusNetaXeptPayment = checkExecutionStatus(context, INITIATE_NETA_XEPT_PAYMENT);
    const execStatusBankAlfalahGetPaymentForm = checkExecutionStatus(context, GET_BANK_ALFALAH_PAYMENT_FORM);
    const [addProductToCartId, setAddProductToCartId] = useState("");
    const ref = useRef(null)
    useEffect(() => {
        setTimeout(() => {
            // scrollToRefElement(ref)
            scrollToTop()
        }, 100)
        props.getCartProducts();
        props.resetCheckOutInvoice();
        props.resetPlaceOrder();
        props.resetCreateInvoice();
        props.resetInitiateNetaXeptPayment();
        props.resetBankAlfalahPaymentForm();
        return () => {
            props.resetInitiateNetaXeptPayment();
            props.resetBankAlfalahPaymentForm();
            // props.resetCheckOutInvoice();
            // props.resetPlaceOrder();
            // props.resetCreateInvoice();
        }
    }, [])
    return (
        <div ref={ref} className=''>
            <ShoppingCart {...props}
                execStatusAddUpdateProductToCart={execStatusAddUpdateProductToCart}
                execStatusCheckoutInvoice={execStatusCheckoutInvoice}
                execStatusCreateInvoice={execStatusCreateInvoice}
                execStatusGetCartProducts={execStatusGetCartProducts}
                execStatusPlaceOrder={execStatusPlaceOrder}
                addProductToCartId={addProductToCartId}
                execStatusBankAlfalahGetPaymentForm={execStatusBankAlfalahGetPaymentForm}
                execStatusDownloadInvoiceReceipt={execStatusDownloadInvoiceReceipt}
                execStatusValidateVoucherStatus={execStatusValidateVoucherStatus}
                execStatusNetaXeptPayment={execStatusNetaXeptPayment}
                setAddProductToCartId={setAddProductToCartId}
            />
            {
                netsPaymentInitData && netsPaymentInitData.get('transactionId') && netsPaymentInitData.get('merchantId') ?
                    <NetaXeptPaymentForm open={true} netsPaymentInitData={netsPaymentInitData} {...props} /> : null
            }
            {
                bankAlfalahPaymentFormDetails && bankAlfalahPaymentFormDetails.get("url") ?
                    <BankAlfalahPaymentForm
                        {...props}
                        open={true}
                        iframeUrl={bankAlfalahPaymentFormDetails && bankAlfalahPaymentFormDetails.get("url")}
                    /> : null
            }
            {
                execStatusPlaceOrder.status === "ERROR" && execStatusPlaceOrder.errorDetail && execStatusPlaceOrder.errorDetail.get("url") ?
                    <BankAlfalahPaymentForm
                        {...props}
                        open={true}
                        iframeUrl={execStatusPlaceOrder.errorDetail.get("url")}
                    /> : null
            }
        </div>
    )
}
