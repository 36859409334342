import './link.photos.scss';
import React, { useState } from 'react'
import { Draggable, Droppable } from 'react-drag-and-drop';
import CardMedia from '@mui/material/CardMedia';
import BoxGroup from 'components/common/material.components/box.group/box.group';
import { FileUploader } from 'components/common/file.uploader/file.uploader';
import { Avatar } from '@mui/material';
import ImageNavigator from 'components/common/image.navigator/image.navigator';
import { useStyles } from './link.photos.style';
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog';
import clsx from 'clsx';

const LinkPhotos = () => {
    const classes = useStyles();
    const [state, setState] = useState({
        selectedIndexes: [],
        uploadedImages: [],
        selectedProduct: -1,
        selectedImage: '',
        draggableItemIndex: -1,
        showImageZoomDialog: false
    })
    const onDrop = (data, product) => {
        setState({ ...state, selectedProduct: product, selectedImage: data.images, selectedIndexes: [] });
    }
    const onContentClick = (e, item, itemIndex) => {
        const selectedIndexes = state.selectedIndexes;
        if (e.ctrlKey) {
            const selectedItemIndex = selectedIndexes.findIndex((index) => index === itemIndex);
            if (selectedItemIndex > -1) {
                setState({ ...state, selectedIndexes: selectedIndexes.filter((index) => index !== itemIndex) });
            }
            else {
                setState({ ...state, draggableItemIndex: -1, selectedIndexes: selectedIndexes.concat(itemIndex) });
            }
        }
    }
    const onDragStart = (e, item, index) => {
        setState({ ...state, draggableItemIndex: index });
    }
    const onFileUpload = (files) => {
        files && files.map((file, index) => {
            let reader = new FileReader();
            reader.onload = () => {
                state.uploadedImages.push(reader.result);
                setState({ ...state });
            }
            reader.readAsDataURL(file);
        })

    }
    const isSelected = (index) => {
        const selectedIndexes = state.selectedIndexes;
        return selectedIndexes &&
            selectedIndexes.findIndex((selectedIndex) => selectedIndex === index) > -1 ?
            true : false
    }
    const onProductClick = (e, item, itemIndex) => {
        setState({ ...state, showImageZoomDialog: true });
    }
    const getImages = () => {
        const groupContent = [];
        state.uploadedImages.map((image, index) => {
            groupContent.push({
                content: <Draggable key={index} className="draggable"
                    onDragStart={(e) => onDragStart(e, 'banana', index)}
                    type="images" data={image}>

                    <div className="draggable-item" >
                        {
                            isSelected(index) ?
                                <Avatar style={{ width: "25px", height: "25px" }}
                                    className={`font-weight-bolder ${classes.checkIcon}`}>
                                    {state.draggableItemIndex === index ?
                                        `+${state.selectedIndexes.length}` :
                                        <i className="fa fa-check"></i>}
                                </Avatar> :
                                null
                        }
                        <CardMedia
                            component="img"
                            height="100"
                            className="disable-drag image-rounded"
                            image={image}
                            title=""
                        />
                    </div>
                </Draggable>,
                item: image
            })
        })
        return groupContent;
    }
    const handleImageZoomDialogClose = () => {
        setState({ ...state, showImageZoomDialog: false })
    }
    return (
        <div id="link-photos">
            <div className="d-flex">
                <div className="draggable-panel">
                    <FileUploader title="+ Upload Photos" onFileUpload={onFileUpload}
                        accept="image/jpeg, image/png" />
                    <BoxGroup
                        height="100px"
                        width="100px"
                        imageRounded={true}
                        selectedIndexes={state.selectedIndexes}
                        onContentClick={onContentClick}
                        groupContent={getImages()}
                    />
                </div>
                <div className="droppable-panel">
                    <h6 className="text-muted" style={{ padding: '10px' }}>Drag your Images here</h6>
                    <BoxGroup
                        enableDroppableStyle={true}
                        width="200px"
                        height="200px"
                        boxHeight="280px"
                        onContentClick={onProductClick}
                        contentType="IMAGE"
                        groupContent={
                            [
                                {
                                    content: <Droppable
                                        types={['images']} // <= allowed drop types
                                        onDrop={(data) => onDrop(data, 0)}
                                    >
                                        <div className="font-weight-bold p-2 text-center">
                                            Product 1
                                        </div>
                                        <div className="droppable-item">
                                            {state.selectedProduct === 0 ?
                                                <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    image={state.selectedImage}
                                                    title=""
                                                /> : <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    image="/app/assets/images/noimage.png"
                                                    title=""
                                                />}
                                        </div>
                                    </Droppable>,
                                    item: 'banana',
                                    footer: <ImageNavigator>
                                        {Array.from(new Array(10)).map((_, i) =>
                                            <div
                                                key={i}
                                                className={clsx(i === 1 && classes.selectedImage)}
                                                style={{
                                                    height: 50,
                                                    background: 'url(https://placeimg.com/380/200/nature)'
                                                }}
                                            />
                                        )}
                                    </ImageNavigator>
                                },
                                {
                                    content: <Droppable
                                        types={['images']} // <= allowed drop types
                                        onDrop={(data) => onDrop(data, 0)}
                                    >
                                        <div className="font-weight-bold p-2 text-center">
                                            Product 2
                                        </div>
                                        <div className="droppable-item">
                                            {state.selectedProduct === 1 ?
                                                <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    image={state.selectedImage}
                                                    title=""
                                                /> : <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    image="/app/assets/images/noimage.png"
                                                    title=""
                                                />}
                                        </div>
                                    </Droppable>,
                                    item: 'banana',
                                    footer: <ImageNavigator>
                                        {Array.from(new Array(10)).map((_, i) =>
                                            <div
                                                key={i}
                                                className={clsx(i === 1 && classes.selectedImage)}
                                                style={{
                                                    height: 50,
                                                    background: 'url(https://placeimg.com/380/200/nature)'
                                                }}
                                            />
                                        )}
                                    </ImageNavigator>
                                }
                            ]
                        }
                    />
                </div>
            </div>
            <ContentDialog
                handleClose={handleImageZoomDialogClose}
                open={state.showImageZoomDialog}
                title="Product 1" >
                <CardMedia
                    component="img"
                    height="100%"
                    image={state.selectedImage}
                    title=""
                />
            </ContentDialog>
        </div>
    )
}

export default LinkPhotos