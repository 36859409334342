import React, { useState } from 'react'
import { Button, Skeleton, Typography } from "@mui/material";
import QuantityExceedDialog from './quantity.exceeds.dialog';
import { useStyle } from './quantity.button.counter.style';
import { getTheApplicationTheme, isStandardPlusTheme } from 'components/common/util/util';
import clsx from 'clsx';

const QuantityButtonCounter = (props) => {
    const classes = useStyle()
    const { value, size, defaultStyle = true, maxQuantity = 99 } = props;
    const [state, setState] = useState({
        showQuantityExceedsDialog: false
    })
    const handleCloseQuantiyExceedsDialog = (e) => {
        e && e.stopPropagation();
        e && e.preventDefault();
        setState({
            ...state,
            showQuantityExceedsDialog: false
        })
    }
    const openQuantiyExceedsDialog = () => {
        setState({
            ...state,
            showQuantityExceedsDialog: true
        })
    }
    //const [count, setCount] = useState(value);

    const handleIncrement = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.isAddingToCartInProgress() === false) {
            if (value < maxQuantity) {
                props.onQuantityChange(value + 1)
            }
            else {
                openQuantiyExceedsDialog();
            }
        }
    };

    const handleTheIncrement = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(!value){
            props.onClick(e)
        }
        else{
            if (props.isAddingToCartInProgress() === false) {
                if (value < maxQuantity) {
                    props.onQuantityChange(value + 1)
                }
                else {
                    openQuantiyExceedsDialog();
                }
            }
        }
    };


    const handleDecrement = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.isAddingToCartInProgress() === false) {
            if (value >= 1)
                props.onQuantityChange(value - 1)
        }
    };
    return (
        <div className="">
            {/* <ButtonGroup size="medium" color="secondary" variant="outlined" aria-label="outlined button group">
                {
                    <Button color="secondary"
                        variant="contained" onClick={handleDecrement}>
                        -
                    </Button>}
                {<Button color="secondary" variant={props.isAddingToCartInProgress() === true ? "outlined" : "contained"} >
                    {props.isAddingToCartInProgress() === true ?
                        <CircularProgress color="primary" size={10} /> : value}
                </Button>}
                <Button color="secondary" variant='contained' onClick={handleIncrement}>+</Button>
            </ButtonGroup> */}

            {window.INDEX_NOKIA_ARENA === true &&

                <div>
                    <div className='d-flex' style={{background:"#fff",margin:"10px", padding:"7px 10px 7px 10px", borderRadius:"10px"}}>
                        <Button classes={{ root: classes.btnSPlus1 }} className='d-flex align-items-center' onClick={handleDecrement} style={{borderRadius:"50px", height:"0", padding:"13px 10px", width:"auto", background:"#F8F8F8"}}>
                            -
                        </Button>
                        <Typography variant='body1' color='dark.main' className="pt-1 pb-1 pl-3 pr-3 font-weight-bold d-flex align-items-center justify-content-end" style={{lineHeight:"0"}}>
                            {props.isAddingToCartInProgress() == true ? <Skeleton className='pl-2 pr-2' variant="rectangular" /> : value}
                        </Typography>
                        <Button classes={{ root: classes.btnSPlus2 }} className='d-flex align-items-center' onClick={handleTheIncrement} style={{borderRadius:"50px", height:"0", padding:"13px 10px", width:"auto", background:"#F8F8F8"}}>
                            +
                        </Button>
                    </div>
                </div>
            }

            

            

            {!window.INDEX_NOKIA_ARENA && getTheApplicationTheme().includes('STANDARD') ?
                <div>{isStandardPlusTheme() && !defaultStyle ?
                    <div>
                        <Typography variant='body1' color='dark.main' className="pt-3 pb-3 pr-4 font-weight-bold d-flex align-items-center justify-content-end">
                            {props.isAddingToCartInProgress() == true ? <Skeleton variant="rectangular" height="15px" width='13px' /> : value}
                        </Typography>
                        <div className='d-flex'>
                            <Button classes={{ root: classes.btnSPlus1 }} className='d-flex align-items-center' onClick={handleDecrement}>
                                -
                            </Button>
                            <Button classes={{ root: classes.btnSPlus2 }} className='d-flex align-items-center' onClick={handleIncrement}>
                                +
                            </Button>
                        </div>
                    </div>
                    :
                    <div className='d-flex'>
                        {
                            <Button classes={{ root: classes.btn }} className='rounded-circle d-flex align-items-center' onClick={handleDecrement}>
                                -
                            </Button>}
                        <Typography variant='body2' color='dark.main' className="pl-1 pr-1 font-weight-bold d-flex align-items-center">
                            {props.isAddingToCartInProgress() == true ? <Skeleton variant="rectangular" height="15px" width='13px' /> : value}
                        </Typography>
                        <Button classes={{ root: classes.btn }} className='rounded-circle d-flex align-items-center' onClick={handleIncrement}>+</Button>
                    </div>
                }
                </div>
                : getTheApplicationTheme() === 'PROMINENT' ?
                    <div className='d-flex'>
                        {
                            <Button classes={{ root: size === 'sm' ? classes.btnProminentSmall : classes.btnProminent }} className='rounded-circle d-flex align-items-center' onClick={handleDecrement}>
                                -
                            </Button>}
                        <Typography variant='body2' color='dark.main' className={clsx(size === 'sm' ? "pl-2 pr-2" : "pl-4 pr-4", "font-weight-bold d-flex align-items-center")}>
                            {props.isAddingToCartInProgress() == true ? <Skeleton variant="rectangular" height="15px" width='13px' /> : value}
                        </Typography>
                        <Button classes={{ root: size === 'sm' ? classes.btnProminentSmall : classes.btnProminent }} className='rounded-circle d-flex align-items-center' onClick={handleIncrement}>+</Button>
                    </div>
                    : null
            }
            {
                state.showQuantityExceedsDialog === true ?
                    <QuantityExceedDialog
                        open={state.showQuantityExceedsDialog}
                        handleClose={handleCloseQuantiyExceedsDialog}>
                        <div className="pb-3 font-weight-bold text-center">
                            <div>
                                <div className='pt-3'>
                                    Selected Product exceeds the total remaining units
                                </div>
                            </div>
                        </div>
                    </QuantityExceedDialog> : null
            }
        </div>
    )
}
export default QuantityButtonCounter