import React from "react";
import withStyles from "@mui/styles/withStyles";
import Dialog from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { useStyle } from "./content.dialog.style";
import { useEffect } from "react";
import { IsMobileWidth } from "components/common/util/util";
import clsx from "clsx";

const styles = (theme) => ({
  root: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    width: "100%",
  },
  root2: {
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0),
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, setHeaderBackgroundAsPrimary, hideTitlePadding, ...other } =
    props;
  return (
    <MuiDialogTitle disableTypography className={clsx(hideTitlePadding ? classes.root2 : classes.root)} {...other}>
      <Typography variant="h6" className="w-100">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          // className={classes.closeButton}
          onClick={onClose}
          size="medium"
        >
          {setHeaderBackgroundAsPrimary ? (
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/close-icon.svg`}
              width="24px"
              height="24px"
            />
          ) : (
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`}
              width="24px"
              height="24px"
            />
          )}
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function ContentDialog(props) {
  const {
    open,
    title,
    header,
    disableBackdropClick,
    backDropColor,
    transparentDialog,
    fullScreen,
    classNames,
    anyContent,
    id,
    maxWidth,
    dividers,
    hideContentPadding,
    setHeaderBackgroundAsPrimary,
    hideTitlePadding,
    titleBackgroundColor,
    hideActionsPadding,
    contentStyle = {},
    logo
  } = props; // anyContent is optional , use when need to do absolute something
  const classes = useStyle();
  const mobileWidth = IsMobileWidth();

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: hideActionsPadding ? theme.spacing(0) : theme.spacing(1),
    },
  }))(MuiDialogActions);

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: hideContentPadding ? theme.spacing(0) : theme.spacing(2),
    },
  }))(MuiDialogContent);
  useEffect(() => {
    document.getElementById("content-dialog") &&
      document
        .getElementById("content-dialog")
        .addEventListener("touchmove", function (event) {
          event.stopPropagation();
        });
  }, []);
  return (
    <div className="position-relative">
      <Dialog
        BackdropProps={{
          style: { backgroundColor: backDropColor ? backDropColor : "" },
        }}
        maxWidth={maxWidth}
        id={id}
        className={classNames}
        scroll="paper"
        PaperProps={{
          sx: {
            backgroundColor: transparentDialog ? "transparent !important" : "",
            boxShadow: transparentDialog ? "none !important" : "",
          },
        }}
        fullWidth={true}
        fullScreen={fullScreen}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {anyContent}
        {header ? header : title ? (
          <DialogTitle
            classes={{
              root: setHeaderBackgroundAsPrimary ? classes.header : "",
            }}
            id="customized-dialog-title"
            onClose={props.handleClose}
            setHeaderBackgroundAsPrimary={setHeaderBackgroundAsPrimary}
            hideTitlePadding={hideTitlePadding}
          >
            {title}
          </DialogTitle>
        ) : (
          <div
            className="w-100 d-flex justify-content-end"
            style={{
              backgroundColor: titleBackgroundColor
                ? titleBackgroundColor
                : "#fff",
              height: window.TRANSPORT_SERVICE === true ? "5px" : "45px",
            }}
          >
            {logo && mobileWidth ? (
              <div
                className={clsx(
                  !mobileWidth && "d-none",
                  "d-flex justify-content-center w-100 position-relative"
                )}
              >
                <img
                  src={logo}
                  className={clsx(
                    "position-absolute z-index-1200",
                    classes.imageStyle
                  )}
                />
              </div>
            ) : null}
            <Typography onClick={props.handleClose}
              className="mt-1 mr-2 position-absolute cursor-pointer paper-root"
              classes={{ root: classes.iconColor }}>
              <img src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`} width='24px' />
            </Typography>
          </div>
        )}
        <DialogContent dividers={dividers === false ? false : true} sx={contentStyle}>
          <Container
            id="content-dialog"
            maxWidth="xl"
            classes={{
              root: classes.overflowHidden
            }}
            className="pl-0 pr-0 position-relative"
          >
            {props.children}
          </Container>
        </DialogContent>
        {props.actions ? <DialogActions>{props.actions}</DialogActions> : null}
      </Dialog>
    </div>
  );
}
