import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    grid: {
        // maxHeight: '200px !important',
        // overflow: 'auto'
    },
    linkStyle: {
        textDecoration: 'underline',
    },
    btnStyle: {
        textTransform: 'none',
    },
    btnStyleRounded: {
        textTransform: 'none',
        borderRadius: '16px !important'
    },
    radioBtn: {
        color: '#70707087',
        '&.Mui-checked':
        {
            color: '#6F6DC3',
        }
    },
    gridProminent: {
        background: theme.palette.background.paper,
        borderRadius: '20px'
    },
    topNavigation: {
        background: theme.palette.greyColor.main,
        borderRadius: '20px'
    }
}));