import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    Btn: {
        color: theme.palette.darkBlueColor.main,
        border: `2px solid ${theme.palette.lightBlueColor.main}`,
        backgroundColor: theme.palette.background.paper,
        boxShadow: "none",
        fontSize: '14px',
        '&:hover': {
            backgroundColor: theme.palette.lightBlueColor.main,
            boxShadow: "none",
        }
    },
    selectedBtn: {
        backgroundColor: theme.palette.lightBlueColor.main,
        color: theme.palette.darkBlueColor.main,
        textAlign: "center",
        border: `1px solid ${theme.palette.darkBlueColor.main}`,
        boxShadow: "none",
        fontSize: '14px',
    }
}));
