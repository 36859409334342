import React from 'react';
import { Collapse, Typography, IconButton } from '@mui/material';
import { useStyle } from './expandable.view.v3.style';

export default function ExpandableViewV3(props) {
    const { label, collapsed } = props
    const classes = useStyle()

    return (
        <div id="expandable-view">
            <div className="d-block cursor-pointer">
                {label ?
                    <div className='w-100 d-flex align-items-center' onClick={props.onClick}>
                        <div className='d-flex'>
                            <Typography color='dark.main' variant="body2" className='p-0 d-flex text-center'>
                                {label ? label : ""}
                            </Typography>
                        </div>
                        <div>
                            {
                                collapsed ?
                                    <IconButton size='small' classes={{ root: classes.iconStyle }}>
                                        <i className="material-icons">expand_less</i>
                                    </IconButton> :
                                    <IconButton size='small' classes={{ root: classes.iconStyle }}>
                                        <i className="material-icons">expand_more</i>
                                    </IconButton>
                            }
                        </div>
                    </div> : null
                }
                <Collapse in={collapsed}>
                    {props.children}
                </Collapse>
            </div>
        </div >
    )
}