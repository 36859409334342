import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    gridProminent: {
        background: theme.palette.background.paper,
        borderRadius: '20px'
    },
    subLabel: {
        color: '#282828',
        fontWeight: 400
    }
}));