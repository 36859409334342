import React from 'react';
import PopOverColorDropdown from './common/popover.dropdown/popover.dropdown.color'

export default function ProductColor(props) {

  const onColorChange = (color) => {

  }
  return (
    <div>
      <PopOverColorDropdown
        onColorChange = {onColorChange}
        placeholder="Select Color" />
    </div>
  )
}
