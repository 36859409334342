import React, { useState } from 'react'
import { Tooltip, IconButton, Badge, Typography } from "@mui/material";
import { useStyle } from './checkout.basket.v2.style';
import { formatAmountV2, getCurrency } from 'components/common/util/util';
import DeleteDialog from 'components/common/delete.dialog/delete.dialog';
import { IsMobileWidth, IsTabletWidth, getTheApplicationTheme, isStandardPlusTheme } from 'components/common/util/util';
import clsx from 'clsx';
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import { useTranslation} from "react-i18next";

const CheckoutBasketV2 = (props) => {
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const { label, cartProducts, storeDetailInfo } = props// count
    const [state, setstate] = useState({
        isDeleteCartDialogOpen: false
    })
    let totalQuantity = 0;
    const { t} = useTranslation("global");
    for (let cartProd of cartProducts) {

        totalQuantity = totalQuantity + cartProd.get('data')?.get('quantity')
    }
    // const onClearCart = (e) => {
    //     props.deleteAllCartProducts();
    //     e.stopPropagation()
    // }
    const onClose = () => {
        setstate({ ...state, isDeleteCartDialogOpen: false })
    }
    // const showDeleteCartDialog = () => {
    //     setstate({ ...state, isDeleteCartDialogOpen: true })
    // }
    const onDelete = (e) => {
        props.deleteAllCartProducts();
        e.stopPropagation()
        onClose();
    }
    const onCheckOt = () => {
        if (cartProducts?.size > 0) {
            props.onCheckOut()
        }
    }
    const getTooltipTitle = () => {
        if (window.INDEX_NOKIA_ARENA === true && cartProducts?.size > 0 && label) {
            return `${t("messages.totalText")}: ${formatAmountV2(label)} ${getCurrency()}`;
        } else {
            if (cartProducts?.size > 0 && label) {
                return `Total: ${formatAmountV2(label)} ${getCurrency()}`;
            } else {
                return 'Cart is empty!';
            }
        }
    }
    const isCartAmountValid = () => {
        if (storeDetailInfo && cartProducts?.size > 0 && label) {
            if (parseInt(label) < storeDetailInfo.get('min_amount')) {
                return false
            } else {
                return true
            }
        }
        else if (!!storeDetailInfo) {
            return false
        }
        else {
            return true
        }
    }
    const isTypographyStyle = window.INDEX_NOKIA_ARENA === true;

    return (
        <div className='w-100'>
            {getTheApplicationTheme().includes('STANDARD') ?
                <div className='w-100 d-flex align-items-center flex-column' style={isTypographyStyle
                    ? { background: "#F8F8F8",border:"1px solid #D6D6D6", borderRadius: "10px", padding:"10px 10px" }
                    : {}
                }>
                    <Typography className={clsx(classes.title, 'w-100 d-flex justify-content-center pb-1')} color='primary' variant='body1' style={isTypographyStyle
                    ? { color:"#000" }
                    : {}
                }>{getTooltipTitle()}</Typography>
                    <Tooltip title={getTooltipTitle()}
                        placement="left-start" >
                        <LoaderButton
                            showBorderRadius={true}
                            fullWidth
                            endAdornment={!isCartAmountValid() ?
                                <Typography className={clsx(classes.title, 'pl-3')} color='dark.main' variant='body2'>
                                    {`(minimum ${storeDetailInfo && storeDetailInfo.get('min_amount')} ${getCurrency()})`}
                                </Typography> : null}
                            disabled={!isCartAmountValid() ? true : false}
                            type="submit"
                            variant="contained"
                            color="primary"
                            classNames={clsx(!(mobileWidth || tabletWidth) && 'min-w-40', (mobileWidth || tabletWidth) && 'w-100', isStandardPlusTheme() && 'rounded-pill', "mt-1 text-capitalize")}
                            onClick={onCheckOt}>
                            {window.INDEX_NOKIA_ARENA === true ? t("messages.checkoutButton") : 'Proceed to Checkout' }
                        </LoaderButton>
                        {/* <IconButton onClick={onCheckOt} className="mr-2" size="medium">
                    <Badge badgeContent={totalQuantity} color="secondary">
                        <img
                            src={`${ process.env.PUBLIC_URL }/assets/icons/cart.png`}
                            height={clsx(!(mobileWidth || tabletWidth) && '32px', (mobileWidth || tabletWidth) && '24px')}
                            width={clsx(!(mobileWidth || tabletWidth) && '32px', (mobileWidth || tabletWidth) && '24px')}
                            alt='basket_image'
                        />
                    </Badge>
                </IconButton> */}
                    </Tooltip>
                </div>
                : getTheApplicationTheme() === 'PROMINENT' ?
                    <div className='w-100 d-flex align-items-center flex-column'>
                        {/* <Typography className={clsx(classes.title, 'w-100 d-flex justify-content-center pb-1')} color='primary' variant='body1'>{getTooltipTitle()}</Typography> */}
                        <LoaderButton
                            startAdornment={<Typography className={clsx(classes.title, 'pl-3')} color='dark.main' variant='body2'>Order</Typography>}
                            endAdornment={!isCartAmountValid() ?
                                <Typography className={clsx(classes.title, 'pr-3')} color='dark.main' variant='body2'>
                                    {`(minimum ${storeDetailInfo && storeDetailInfo.get('min_amount')} ${getCurrency()})`}
                                </Typography> : null}
                            disabled={!isCartAmountValid() ? true : false}
                            // showRightArrow={true}
                            fullWidth
                            showSwipeButton={getTheApplicationTheme() === 'PROMINENT' ? true : false}
                            variant="outlined"
                            type="submit"
                            classNames={clsx(!(mobileWidth || tabletWidth) && 'w-40', (mobileWidth || tabletWidth) && 'w-100', "mt-1 text-capitalize")}
                            onClick={onCheckOt}>
                            {(cartProducts?.size > 0 && label) ? `${formatAmountV2(label)} ${getCurrency()}` : ''}
                        </LoaderButton>
                        {/* <IconButton onClick={onCheckOt} className="mr-2" size="medium">
                            <Badge badgeContent={totalQuantity} color="secondary">
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/icons/cart.png`}
                                    height={clsx(!(mobileWidth || tabletWidth) && '32px', (mobileWidth || tabletWidth) && '24px')}
                                    width={clsx(!(mobileWidth || tabletWidth) && '32px', (mobileWidth || tabletWidth) && '24px')}
                                    alt='basket_image'
                                />
                            </Badge>
                        </IconButton> */}
                    </div>
                    : null}
            {state.isDeleteCartDialogOpen ?
                <DeleteDialog open={state.isDeleteCartDialogOpen}
                    // alertMessage="Clear cart, are you sure you want to clear cart ?"
                    buttonText='Clear Cart'
                    onDelete={onDelete}
                    title="Are you sure you want to clear cart?"
                    onClose={onClose}>
                    If you clear cart, all items will be deleted from your cart.
                </DeleteDialog> : null}
        </div>
    )
}

export default CheckoutBasketV2