import React, { useState } from 'react'
import { Box, Typography, IconButton, Paper, Grid } from '@mui/material';
import { useStyle } from './top.navigation.panel.style';
import clsx from 'clsx';
import TopNavigationPanelPopover from './components/top.navigation.panel.popover/top.navigation.panel.popover';
import { useLocation } from 'react-router';
import { withRouter } from 'react-router'
import { IsMobileWidth, IsTabletWidth, redirectToHomePage } from 'components/common/util/util';
import TrafficLights from './components/traffic.lights/traffic.lights';

const TopNavigationPanel = (props) => {
    const classes = useStyle()
    const { name, label, status, hideNavigationButtons, hideCloseButton, transparent } = props
    const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
    let location = useLocation();
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const [isNavigationPopoverOpen, setIsNavigationPopoverOpen] = useState(null);

    const showIsNavigationPopover = (event) => {
        setIsNavigationPopoverOpen(event.currentTarget);
    };

    const hideIsNavigationPopover = () => {
        setIsNavigationPopoverOpen(null);
    };

    const redirect = (url) => {
        if (url === '/products') {
            redirectToHomePage(props, tradeTypeId)
        }
        else {
            props.history.push(`${url}?trade_type_id=${tradeTypeId}`)
        }
        hideIsNavigationPopover();
    }

    return (
        <div className={clsx(classes.topNavigation, transparent && classes.transparentColor, (label || status) ? 'pb-1' : 'pb-2')}>
            <Paper elevation={0} className={clsx((label || status) ? classes.borderRadius1 : classes.borderRadius2, transparent && classes.transparentColor, 'z-index position-relative')}>
                <div className={clsx(hideNavigationButtons ? 'justify-content-end' : 'justify-content-between', transparent ? 'pl-0' : 'pl-2', 'w-100 d-flex align-items-center pr-2 pt-1')}>
                    {!hideNavigationButtons ?
                        <div>
                            {isNavigationPopoverOpen ?
                                <IconButton size="medium" onClick={hideIsNavigationPopover} className='cursor-pointer'>
                                    <img
                                        src={!transparent ? `${process.env.PUBLIC_URL}/assets/icons/close-icon-primary.svg` : `${process.env.PUBLIC_URL}/assets/icons/close-icon.svg`}
                                        width="26px"
                                        height="26px"
                                    />
                                </IconButton>
                                :
                                <IconButton size="medium" onClick={showIsNavigationPopover} >
                                    <img
                                        src={!transparent ? `${process.env.PUBLIC_URL}/assets/icons/icon-Hamburger-primary.svg` : `${process.env.PUBLIC_URL}/assets/icons/icon-Hamburger-white.svg`}
                                        width="26px"
                                        height="26px"
                                    />
                                </IconButton>
                            }
                        </div> : null
                    }
                    {!hideCloseButton ?
                        <IconButton onClick={() => redirectToHomePage(props, tradeTypeId)}>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/close-icon-primary.svg`}
                                width="26px"
                                height="26px"
                            />
                        </IconButton> : null
                    }
                </div>
                <div className='w-100 d-flex justify-content-center align-items-center pb-2'>
                    <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'h4', (mobileWidth || tabletWidth) && 'h6')} className={clsx(classes.name, "text-ellipsis-clamp-2 font-weight-light text-capitalize")}>
                        {name ? name : ""}
                    </Typography>
                </div>
            </Paper>
            <div className={clsx('mt-n1')}>
                {label ?
                    <Box className='w-100'>
                        <div className={clsx(classes.label, 'w-100 d-flex justify-content-center align-items-center pl-3 pr-3')}>
                            <Typography variant='body1' className='text-white text-ellipsis-clamp-1 h-auto pt-1'>
                                {label}
                            </Typography>
                        </div>
                    </Box> : null
                }
                {status ?
                    <TrafficLights status={status} />
                    : null
                }
            </div>
            {isNavigationPopoverOpen ?
                <TopNavigationPanelPopover // Currently only for mobile || tablet view
                    isNavigationPopoverOpen={isNavigationPopoverOpen}
                    showIsNavigationPopover={showIsNavigationPopover}
                    hideIsNavigationPopover={hideIsNavigationPopover}
                    redirect={redirect}
                    location={location}
                    {...props}
                /> : null
            }
        </div>
    )
}

export default withRouter(TopNavigationPanel)