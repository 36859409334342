import React, { useContext, useEffect, useState, useRef } from 'react'
import { checkExecutionStatus, scrollToRefElement, scrollToTop } from 'components/common/util/util';
import ShoppingCart from './components/shopping.cart/shopping.cart';
import {
    CHECKOUT_INVOICE, CREATE_INVOICE, PLACE_ORDER,
    DOWNLOAD_INVOICE_RECEIPT, ADD_UPDATE_PRODUCT_TO_CART, VALIDATE_VOUCHER, GET_CART_PRODUCTS
} from 'view.updater/actions/products.actions';
import { AppContext } from '../main/container';
import { GET_ORDER_INVOICE_STATUS } from 'view.updater/actions/products.actions';
import { getQueryStringValue } from 'components/common/util/util';

export default function PaymentSuccess(props) {
    const context = useContext(AppContext);
    const pushNotificationFailed = getQueryStringValue("pushNotificationFailed")
    const merchantQueryId = getQueryStringValue("merchantQueryId")
    const execStatusCheckoutInvoice = checkExecutionStatus(context, CHECKOUT_INVOICE);
    const execStatusCreateInvoice = checkExecutionStatus(context, CREATE_INVOICE);
    const execStatusPlaceOrder = checkExecutionStatus(context, PLACE_ORDER);
    const execStatusDownloadInvoiceReceipt = checkExecutionStatus(context, DOWNLOAD_INVOICE_RECEIPT);
    const execStatusAddUpdateProductToCart = checkExecutionStatus(context, ADD_UPDATE_PRODUCT_TO_CART);
    const execStatusGetCartProducts = checkExecutionStatus(context, GET_CART_PRODUCTS);
    const execStatusValidateVoucherStatus = checkExecutionStatus(context, VALIDATE_VOUCHER);
    const execStatusGetOrderInvoiceStatus = checkExecutionStatus(context, GET_ORDER_INVOICE_STATUS);
    const [addProductToCartId, setAddProductToCartId] = useState("")
    const ref = useRef(null)
    useEffect(() => {
        setTimeout(() => {
            // scrollToRefElement(ref)
            scrollToTop()
        }, 100)
        if(pushNotificationFailed === "true") {
            props.deleteAllCartProducts();
            props.sendOrderNotificationToMerchant({
                module: "sendMerchantNotification",
                subModule: "sendMerchantNotification",
                moduleCode: '9017',
                merchantQueryId: merchantQueryId,
                queryId: merchantQueryId
            })
        }
        props.getCartProducts();
        // props.resetCheckOutInvoice();
        // props.resetPlaceOrder();
        // props.resetCreateInvoice();
        return () => {
            props.resetGetOrderInvoiceStatus();
            props.resetCheckOutInvoice();
            props.resetPlaceOrder();
            props.resetCreateInvoice();
            props.resetSendReceiptEmail();
        }
    }, [])
    return (
        <div ref={ref} className=''>
            <ShoppingCart {...props}
                execStatusAddUpdateProductToCart={execStatusAddUpdateProductToCart}
                execStatusCheckoutInvoice={execStatusCheckoutInvoice}
                execStatusCreateInvoice={execStatusCreateInvoice}
                execStatusGetCartProducts={execStatusGetCartProducts}
                execStatusPlaceOrder={execStatusPlaceOrder}
                addProductToCartId={addProductToCartId}
                execStatusDownloadInvoiceReceipt={execStatusDownloadInvoiceReceipt}
                execStatusValidateVoucherStatus={execStatusValidateVoucherStatus}
                execStatusGetOrderInvoiceStatus={execStatusGetOrderInvoiceStatus}
                setAddProductToCartId={setAddProductToCartId}
            />
        </div>
    )
}
