import Products from './products';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectProductsItems, makeSelectProductCategories, makeSelectIndustryStores, makeSelectActiveIndustry, makeSelectSelectedStoreProductCategory, makeStoreListingSelectedPageIndex } from 'view.updater/selectors/products.selectors';
import { getIndustryStores, resetSearchStores, resetIndustryStores, getCartProducts, resetSelectedStoreProductCategory, setStoreListingSelectedPageIndex } from 'view.updater/actions/products.actions';
import { makeSelectAuth } from 'view.updater/selectors/auth.selectors';

const mapDispatchToProps = (dispatch) => ({
    getIndustryStores: (data) => dispatch(getIndustryStores(data)),
    resetIndustryStores: () => dispatch(resetIndustryStores()),
    getCartProducts: (data) => dispatch(getCartProducts(data)),
    resetSelectedStoreProductCategory: (data) => dispatch(resetSelectedStoreProductCategory(data)),
    setStoreListingSelectedPageIndex: (data) => dispatch(setStoreListingSelectedPageIndex(data)),
    resetSearchStores: () => dispatch(resetSearchStores())
});

const mapStateToProps = createStructuredSelector({
    products: makeSelectProductsItems(),
    auth: makeSelectAuth(),
    industryStores: makeSelectIndustryStores(),
    activeIndustry: makeSelectActiveIndustry(),
    selectedStoreProductCategory: makeSelectSelectedStoreProductCategory(),
    storeListingSelectedPageIndex: makeStoreListingSelectedPageIndex(),
    storeProductCategories: makeSelectProductCategories()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Products);