export const ENV = process.env.REACT_APP_ENV;
//xport const ENV = "DEV";// "PROD"; // DEV , QA , UAT, PROD, LOCAL
const DEV_BASE_URL = 'https://zdev.cloudasset.com';
const DEV_URL = "https://zdev.cloudasset.com/api/p3_mwallet";
const QA_BASE_URL = 'https://zqa.cloudasset.com';
const QA_URL = "https://zqa.cloudasset.com/api/p3_mwallet";
const PROD_BASE_URL = 'https://m3.zapp.world';
const PROD_URL = "https://m3.zapp.world/api/p3_mwallet";
const UAT_BASE_URL = 'https://m3uat.zapp.world';
const UAT_URL = "https://m3uat.zapp.world/api/p3_mwallet";

const NETA_XEPT_API_TOKEN_DEV = "Pb5-!Dy7Jq4)n(B6Tx9_";
const NETA_XEPT_BASE_URL_DEV = "https://test.epayment.nets.eu"
const NETA_XEPT_BASE_URL_PROD = "https://epayment.nets.eu"

const SONERI_INTERNET_BANKING_URL_DEV = "https://internet_banking_qa.cloudasset.com";
const SONERI_INTERNET_BANKING_URL_PROD = "https://internet_banking_qa.cloudasset.com";

const getServerUrl = (ENV) => {
    switch (ENV) {
        case "DEV":
            return DEV_URL;
        case "LOCAL":
            return DEV_URL;
        case "QA":
            return QA_URL;
        case "UAT":
            return UAT_URL;
        case "PROD":
            return PROD_URL;
    }
}
const getSoneriInternetBankingUrl = (ENV) => {
    switch (ENV) {
        case "DEV":
            return SONERI_INTERNET_BANKING_URL_DEV;
        case "LOCAL":
            return SONERI_INTERNET_BANKING_URL_DEV;
        case "QA":
            return SONERI_INTERNET_BANKING_URL_DEV;
        case "UAT":
            return SONERI_INTERNET_BANKING_URL_DEV;
        case "PROD":
            return SONERI_INTERNET_BANKING_URL_PROD;
    }
}
const getNetaXeptApiTokenKey = (ENV) => {
    switch (ENV) {
        case "DEV":
            return NETA_XEPT_API_TOKEN_DEV;
        case "LOCAL":
            return NETA_XEPT_API_TOKEN_DEV;
        case "QA":
            return NETA_XEPT_API_TOKEN_DEV;
        case "UAT":
            return NETA_XEPT_API_TOKEN_DEV;
        case "PROD":
            return NETA_XEPT_API_TOKEN_DEV;
    }
}
const getNetaXeptBaseUrl = (ENV) => {
    switch (ENV) {
        case "DEV":
            return NETA_XEPT_BASE_URL_DEV;
        case "LOCAL":
            return NETA_XEPT_BASE_URL_DEV;
        case "QA":
            return NETA_XEPT_BASE_URL_DEV;
        case "UAT":
            return NETA_XEPT_BASE_URL_DEV;
        case "PROD":
            return NETA_XEPT_BASE_URL_PROD;
    }
}
const getBaseUrl = (ENV) => {
    switch (ENV) {
        case "DEV":
            return DEV_BASE_URL;
        case "QA":
            return QA_BASE_URL;
        case "UAT":
            return UAT_BASE_URL;
        case "PROD":
            return PROD_BASE_URL;
    }
}

const DEV_ANALYTICS_SERVICE_URL = "https://zdev-merchant-analytics.cloudasset.com/api";
const QA_ANALYTICS_SERVICE_URL = "https://zappqa.cloudasset.com";
const PROD_ANALYTICS_SERVICE_URL = "https://p20-service-analytics-node.zapp.world/api" //"http://prod-p20-analytics-node-internet-1438019231.me-south-1.elb.amazonaws.com";
const UAT_ANALYTICS_SERVICE_URL = "http://27.254.158.153:8084";
const LOCAL_ANALYTICS_SERVICE_URL = "http://localhost:3008"

const DEV_MERCHANT_SERVICE_URL = "https://zdev.cloudasset.com/api/p3_plus";
const QA_MERCHANT_SERVICE_URL = "https://zappqa.cloudasset.com/api/p3_plus";
const PROD_MERCHANT_SERVICE_URL = "https://m3.zapp.world/api/p3_plus";
const UAT_MERCHANT_SERVICE_URL = "http://27.254.158.153:8084/api/p3_plus";

const getP20NodeServerUrl = (ENV) => {
    switch (ENV) {
        case "LOCAL":
            return LOCAL_ANALYTICS_SERVICE_URL;
        case "DEV":
            return DEV_ANALYTICS_SERVICE_URL;
        case "QA":
            return QA_ANALYTICS_SERVICE_URL;
        case "UAT":
            return UAT_ANALYTICS_SERVICE_URL;
        case "PROD":
            return PROD_ANALYTICS_SERVICE_URL;
    }
}

const getMerchantServiceUrl = (ENV) => {
    switch (ENV) {
        case "DEV":
            return DEV_MERCHANT_SERVICE_URL;
        case "QA":
            return QA_MERCHANT_SERVICE_URL;
        case "UAT":
            return UAT_MERCHANT_SERVICE_URL;
        case "PROD":
            return PROD_MERCHANT_SERVICE_URL;
    }
}

export const SERVER_P20_URL = getP20NodeServerUrl(ENV);
export const SONERI_INTERNET_BANKING_URL = getSoneriInternetBankingUrl(ENV);
export const MERCHANT_SERVICE_URL = getMerchantServiceUrl(ENV);
export const SERVER_URL = getServerUrl(ENV);
export const SERVER_BASE_URL = getBaseUrl(ENV);
export const SERVER_PORT = "8082";
export const GOOGLE_MAPS_API_KEY = "AIzaSyBBILEr28gGiV7o6k7w0YBoNUXasm9ZRM0"// IT IS OF ZAPP
export const NETA_XEPT_BASE_URL = getNetaXeptBaseUrl(ENV)
export const NETA_XEPT_API_TOKEN_KEY = getNetaXeptApiTokenKey(ENV);
