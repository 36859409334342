import { makeStyles } from '@mui/styles'
export const useStyles = makeStyles((theme) => ({
    rail: {
        height: "100%",
        opacity: 1,
        // border: `0.8px solid ${theme.palette.primary.main}`,
        top: "0px"
    },
    responsiveRoot : {
        height:"unset",
        color:"transparent",
        pointerEvents:"none",
    },
    thumb: {
        background: "linear-gradient(180deg, #0FD26A 0%, #0E8B48 100%)",
        marginLeft: 0,
        marginTop: "0.8px",
        cursor: "grab",
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        borderRadius: "50%",
        height: "-webkit-fill-available",
        width: 40,
        height: 40,
        zIndex: 1,
        height: "100% !important",
        pointerEvents: "all",
        top: "0px"
    },
    root: {
        height: "55px",
        padding:"0px !important",
        display:"flex",
        justifyContent:"center",
        alignItems: "center",
        color: "transparent",
        // pointerEvents: "none"
    },
    responsiveRoot: {
        height: "55px",
        padding:"0px !important",
        display:"flex",
        justifyContent:"center",
        alignItems: "center",
        color: "transparent",
        // pointerEvents: "none"
    }
}))
