import React from 'react';
import PopoverDropdown from './common/popover.dropdown/popover.dropdown'

export default function ProductCategory(props) {
  return (
   <PopoverDropdown
      placeholder="Select Category"
      items={[
        "Category 1",
        "Category 2",
        "Category 3",
        "Category 4",
        "Category 5",
        "Category 6",
        "Category 7",
        "Category 8",
        "Category 9",
        "Category 10"
      ]}
    />
  )
}
