import React from "react";
import { NETA_XEPT_BASE_URL } from "view.updater/middlewares/util/api.config";
import ContentDialog from "components/common/material.components/content.dialog/content.dialog";
import { IsMobileWidth } from "components/common/util/util";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Skeleton } from "@mui/lab";
import { isDemoQRMe, getTheApplicationTheme } from "../util/util";
import TopNavigationPanel from "components/common/top.navigation.panel/top.navigation.panel";

const NetaXeptPaymentForm = (props) => {
    const iframeRef = useRef(null);
    const { netsPaymentInitData, open } = props;
    const [isLoadingIframe, setIsLoadingIframe] = useState(true);
    useEffect(() => {
        if (iframeRef && iframeRef.current) {
            const iframe = document.getElementById('neta-xept-payment-form-nets');
            iframe && iframe.addEventListener('load', () => {
                setIsLoadingIframe(false);
            });
        }
    }, [iframeRef && iframeRef.current]);

    const mobileWidth = IsMobileWidth();
    const hideIframePaymentDialog = (e, reason) => {
        if (reason !== "backdropClick") {
            props.resetInitiateNetaXeptPayment();
        }
    };
    let netXeptBaseUrl = "";
    if (
        netsPaymentInitData &&
        netsPaymentInitData.get("merchantIdDev") == "TEST"
    ) {
        netXeptBaseUrl = "https://test.epayment.nets.eu";
    } else {
        netXeptBaseUrl = NETA_XEPT_BASE_URL;
    }
    return (
        <div>
            <ContentDialog
                open={open}
                maxWidth={!mobileWidth ? "md" : ""}
                fullScreen={(mobileWidth || getTheApplicationTheme() === 'PROMINENT') ? true : false}
                handleClose={getTheApplicationTheme() !== 'PROMINENT' ? hideIframePaymentDialog : null}
                dividers={false}
                hideContentPadding={true}
                hideTitlePadding={true}
                titleBackgroundColor="#f5f5f2"
                title={getTheApplicationTheme() === 'PROMINENT' ?
                    <div className='w-100'>
                        <TopNavigationPanel
                            hideNavigationButtons={true}
                            name='Payment'
                        />
                    </div> : null}
                logo={mobileWidth ? isDemoQRMe ? `${process.env.PUBLIC_URL}/assets/images/qrme-logo-white-bg.png` : window.TRADE_TYPE_IMAGE_URL : ''}
            >
                <div
                    style={{ minHeight: "450px" }}
                    className="d-flex align-items-center justify-content-center"
                >
                    {netsPaymentInitData &&
                        netsPaymentInitData.get("status") &&
                        netsPaymentInitData.get("status") ==
                        "SSE_NETS_PAYMENT_SUCCESSFULL" ? (
                        <div> Payment is successfull </div>
                    ) : (
                        <div className="w-100 d-flex flex-column position-relative">
                            {isLoadingIframe &&
                                <div className="d-flex w-100 flex-column justify-content-center align-items-center position-absolute">
                                    <div className="pt-4">Loading</div>
                                    <div className="w-100 d-flex justify-content-center"><Skeleton
                                        variant="rectangular"
                                        height="200px"
                                        width="80%"
                                        sx={{ marginTop: "20px" }}
                                    /> </div>
                                </div>
                            }
                            <iframe
                                ref={iframeRef}
                                id="neta-xept-payment-form-nets"
                                name="neta-xept-payment-form"
                                src={`${netXeptBaseUrl}/Terminal/default.aspx?merchantId=${netsPaymentInitData.get(
                                    "merchantId"
                                )}&transactionId=${netsPaymentInitData.get("transactionId")}`}
                                style={{
                                    width: "100%",
                                    minHeight: mobileWidth ? "700px" : "450px",
                                }}
                            ></iframe>
                        </div>
                    )}
                </div>
            </ContentDialog>
        </div>
    );
};
export default NetaXeptPaymentForm;
