import { makeStyles } from '@mui/styles';
//import { lightBackgroundColor } from 'components/containers/main/material.theme/app.theme/app.material.theme.base';
export const useStyle = makeStyles(theme => ({
    paper: {
        border: `1px solid ${theme.palette.lightBackgroundColor}`
    },
    container: {
        // maxHeight: '400px !important',
        // overflow:'auto'
    },
    productItemsWrapper: {
        overflowY: "auto !important",
        paddingBottom: "200px"
    },

    imageStyleBox:{
        height: "100px",
        width: "100%",
        objectFit: "cover",
        borderRadius: "10px 10px 0px 0px"
    }
    
}))