
import React from 'react'
import clsx from 'clsx';

const LoadingIndicator = props => {
    const { type, containerClassname, iconClassName, iconColor } = props;
    let styles = {
        bgColor: {
            backgroundColor: 'black'
        }
    }
    if (iconColor)
    styles.bgColor.backgroundColor = iconColor;

    switch (type) {
        case 'line-scale-party':
            return (
                <span className={containerClassname}>
                    <span className={clsx(`loader-inner line-scale-party`,iconClassName)}>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                    </span>
                </span>
            )
        case 'line-spin-fade-loader':
            return (
                <span className={containerClassname}>
                    <span className={clsx(`loader-inner line-spin-fade-loader`,iconClassName)}>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                    </span>
                </span>
            )
        default:
            return (
                <span  className={clsx(containerClassname,'d-flex','justify-content-center','p-3')}>
                    <span className={clsx(`loader-inner line-scale-party`,iconClassName)}>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                        <div style={ styles.bgColor }></div>
                    </span>
                </span>
            )

    }
}
export default LoadingIndicator