import './product.detail.scss';
import React, { useState } from 'react'
import { Draggable, Droppable } from 'react-drag-and-drop';
import CardMedia from '@mui/material/CardMedia';
import BoxGroup from 'components/common/material.components/box.group/box.group';
import ImageNavigator from 'components/common/image.navigator/image.navigator';
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog';
import MaterialExpansionPanel from 'components/common/material.components/expansion.panel/expansion.panel';

const ProductDetail = () => {
    const [state, setState] = useState({
        selectedIndexes: [],
        uploadedImages: [],
        selectedProduct: -1,
        selectedImage: '',
        draggableItemIndex: -1,
        showImageZoomDialog: false
    })
    const onDrop = (data, product) => {
        setState({ ...state, selectedProduct: product, selectedImage: data.images, selectedIndexes: [] });
    }
    const onContentClick = (e, item, itemIndex) => {
        const selectedIndexes = state.selectedIndexes;
        if (e.ctrlKey) {
            const selectedItemIndex = selectedIndexes.findIndex((index) => index === itemIndex);
            if (selectedItemIndex > -1) {
                setState({ ...state, selectedIndexes: selectedIndexes.filter((index) => index !== itemIndex) });
            }
            else {
                setState({ ...state, draggableItemIndex: -1, selectedIndexes: selectedIndexes.concat(itemIndex) });
            }
        }
    }
    const onDragStart = (e, item, index) => {
        setState({ ...state, draggableItemIndex: index });
    }
    const onProductClick = (e, item, itemIndex) => {
        setState({ ...state, showImageZoomDialog: true });
    }
    const getDraggableCategories = () => {
        const groupContent = [];
        groupContent.push({
            content: <div className="draggable h-100"
                onDragStart={(e) => onDragStart(e, 'GROCERY', 6)}
                type="images">
                <div className="draggable-item">
                    <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                        + Add
                    </span>
                </div>
            </div>
        })
        groupContent.push({
            content: <Draggable className="draggable h-100"
                onDragStart={(e) => onDragStart(e, 'FOOD', 5)}
                type="images">
                <div className="draggable-item">
                    <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                        FOOD
                    </span>
                </div>
            </Draggable>
        })
        groupContent.push({
            content: <Draggable className="draggable h-100"
                onDragStart={(e) => onDragStart(e, 'GROCERY', 4)}
                type="images">
                <div className="draggable-item">
                    <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                        GROCERY
                    </span>
                </div>
            </Draggable>
        })

        return groupContent;
    }
    const getCategories = () => {
        const groupContent = [];
        groupContent.push({
            content: <div className="draggable h-100"
                onDragStart={(e) => onDragStart(e, 'GROCERY', 1)}
                type="images">
                <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                    + Add
                    </span>
            </div>
        })
        groupContent.push({
            content: <div className="draggable h-100 selected-box"
                onDragStart={(e) => onDragStart(e, 'FOOD', 2)}
                type="images">
                    <i className="material-icons box-check-icon">
                        done
                        </i>
                <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <div>
                        FOOD
                    </div>
                </span>
            </div>
        })
        groupContent.push({
            content: <div className="draggable h-100"
                onDragStart={(e) => onDragStart(e, 'GROCERY', 3)}
                type="images">
                <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                    GROCERY
                    </span>
            </div>
        })

        return groupContent;
    }

    const handleImageZoomDialogClose = () => {
        setState({ ...state, showImageZoomDialog: false })
    }
    return (
        <div id="product-detail">
            <div className="d-flex">
                <div className="draggable-panel">
                    <MaterialExpansionPanel title="Categories">
                        <BoxGroup
                            height="100px"
                            width="100px"
                            selectedIndexes={state.selectedIndexes}
                            onContentClick={onContentClick}
                            groupContent={getDraggableCategories()}
                        />
                    </MaterialExpansionPanel>
                </div>
                <div className="droppable-panel">
                    <h6 className="text-muted" style={{ padding: '10px' }}>
                        Add More Information to Product by dragging items from left or click on Product
                    </h6>
                    <BoxGroup
                        enableDroppableStyle={true}
                        width="200px"
                        height="200px"
                        boxHeight="280px"
                        onContentClick={onProductClick}
                        contentType="IMAGE"
                        groupContent={
                            [
                                {
                                    content: <Droppable
                                        types={['images']} // <= allowed drop types
                                        onDrop={(data) => onDrop(data, 0)}
                                    >
                                        <div className="font-weight-bold p-2 text-center">
                                            Product 1
                                        </div>
                                        <div className="droppable-item">
                                            {state.selectedProduct === 0 ?
                                                <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    image={state.selectedImage}
                                                    title=""
                                                /> : <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    image="/app/assets/images/noimage.png"
                                                    title=""
                                                />}
                                        </div>
                                    </Droppable>,
                                    item: 'banana',
                                    footer: <ImageNavigator>
                                        <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                                height: 50,
                                                backgroundColor: '#ffffff'
                                            }}
                                        >
                                            Food
                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{
                                                height: 50,
                                                backgroundColor: '#ffffff'
                                            }}
                                        >
                                            National
                                        </div>
                                    </ImageNavigator>
                                },
                                {
                                    content: <Droppable
                                        types={['images']} // <= allowed drop types
                                        onDrop={(data) => onDrop(data, 0)}
                                    >
                                        <div className="font-weight-bold p-2 text-center">
                                            Product 2
                                        </div>
                                        <div className="droppable-item">
                                            {state.selectedProduct === 1 ?
                                                <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    image={state.selectedImage}
                                                    title=""
                                                /> : <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    image="/app/assets/images/noimage.png"
                                                    title=""
                                                />}
                                        </div>
                                    </Droppable>,
                                    item: 'banana',
                                    footer: <ImageNavigator />
                                }
                            ]
                        }
                    />
                </div>
            </div>
            <ContentDialog
                handleClose={handleImageZoomDialogClose}
                open={state.showImageZoomDialog}
                title="Product 1" >
                <div id="product-detail">
                    <MaterialExpansionPanel expanded={true} title="Categories">
                        <BoxGroup
                            height="100px"
                            width="100px"
                            selectedIndexes={state.selectedIndexes}
                            onContentClick={onContentClick}
                            groupContent={getCategories()}
                        />
                    </MaterialExpansionPanel>
                </div>
            </ContentDialog>
        </div>
    )
}

export default ProductDetail