import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appReducer from 'view.updater/reducers/app.reducer';
import localizationReducer from 'view.updater/reducers/localization.reducer';
import authReducer from './auth.reducer';
import productsReducer from './products.reducer';
import orderReducer from './order.reducer';
import userReducer from './user.reducer';
import signUpReducer from './sign.up.reducer';
import forgotPasswordReducer from './forgot.password.reducer';
import transportReducer from './transport.reducer';
import paymentReducer from './payment.reducer';
//import REDUCER_NAMEReducer from './REDUCER_FILENAME.reducer';
//import --EDUCER_NAMEReducer from './--EDUCER_FILENAME.reducer';
export default function createReducer(history,injectedReducers) {
  return combineReducers({
    router: connectRouter(history),
    app: appReducer,
    authorization : authReducer,
    payment: paymentReducer,
    localization :  localizationReducer,
    products: productsReducer,
    transport: transportReducer,
    orders: orderReducer,
    signUp: signUpReducer,
    forgotPassword: forgotPasswordReducer,
    user : userReducer,
    //INDEX_STATE_NAME_REDUCER : REDUCER_NAMEReducer,
    //__INDEX_STATE_NAME_REDUCER : --EDUCER_NAMEReducer,
    ...injectedReducers,
  });
}
//Remove__ and Replace -- with R