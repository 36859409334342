import './order.history.card.scss'
import React from 'react'
import { IconButton, Box, Button, Paper, Typography, Tooltip, Grid } from '@mui/material'
import { useStyle } from './order.history.card.style'
import { formatAmountV1, getTheApplicationTheme, getCurrency } from 'components/common/util/util';
import CustomLinearProgress from 'components/common/custom.linear.progress.bar/custom.linear.progress.bar';
import CustomCircularProgressBar from 'components/common/custom.circular.progress.bar/custom.circular.progress.bar';

const OrderHistoryCard = (props) => {
    const classes = useStyle()
    const { imageText, image, heading, styledHeading, price, description, timerValue, footer, status, subText } = props
    const getReceivedOrderProgess = () => {
        if (status === "order_pending" || status === "order_confirmed" || status === "order_dispatched" || status === "order_delivered") {
            return 100
        }
        else {
            return 0
        }
    }
    const getPreparingOrderProgress = () => {
        if (status === "order_confirmed" || status === "order_dispatched" || status === "order_delivered") {
            return 100
        }
        else {
            return 0
        }
    }
    const getOnTheWayOrderProgress = () => {
        if (status === "order_dispatched" || status === "order_delivered") {
            return 100
        }
        else {
            return 0
        }
    }
    // const getDeliveredOrderProgress = () => {
    //     if (status === "order_delivered") {
    //         return 100
    //     }
    //     else {
    //         return 0
    //     }
    // }
    return (
        <Paper elevation={0} onClick={props.onClick} className='w-100 rounded pb-1' id='order-history-card'>
            <div className="d-flex justify-content-center pl-1 pr-1 pt-1">
                {getTheApplicationTheme() === 'PROMINENT' ?
                    <Box className={`w-100 box position-relative d-flex justify-content-center align-items-center ${classes.merchantBox}`}>
                        <Typography className={`pl-1 text-white text-center d-flex justify-content-center align-items-center h-auto text-ellipsis-clamp-1 ${classes.merchantName}`} variant='h6'>
                            {heading}
                        </Typography>
                    </Box>
                    : <Paper
                        className=""
                        elevation={0}
                        classes={{ root: classes.cardMedia }}
                        style={{
                            backgroundImage: image ? `url(${image})` : "",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center"
                        }}>
                        {imageText ?
                            <div className='h-100 w-100 d-flex justify-content-center align-items-center rounded cancel-order'>
                                <Button disableRipple={true} classes={{ root: classes.btn }} className="pl-3 pr-3" variant='outlined'>
                                    {imageText ? imageText : ''}
                                </Button>
                            </div> : null
                        }
                    </Paper>}
            </div>

            <div>
                <div className='d-flex justify-content-between align-items-center pt-1 pb-1'>
                    {styledHeading ?
                        <Typography classes={{ root: classes.darkColor }} variant="caption" className="text-ellipsis-clamp-1 h-auto font-weight-bold text-capitalize pl-2">
                            {/* <IconButton disableRipple={true} size='small' color='secondary' className="material-icons">
                                radio_button_checked
                            </IconButton> */}
                            {styledHeading ? styledHeading : ""}
                        </Typography>
                        :
                        heading ?
                            <Typography classes={{ root: classes.darkColor }} variant="caption" className="text-ellipsis-clamp-1 text-capitalize pl-2">
                                {heading ? heading : ""}
                            </Typography> : null
                    }
                    {timerValue ?
                        <CustomCircularProgressBar
                            size={55}
                            progressValue={status ? status : ''}
                            label={timerValue}
                        />
                        :
                        price ?
                            <Typography classes={{ root: classes.textColor }} variant="caption" className="text-ellipsis-clamp-2 font-weight-light text-capitalize pr-2">
                                {price ? `${getCurrency()}  ${formatAmountV1(price)}` : ""}
                            </Typography> : null
                    }
                </div>
                {status ?
                    <Grid container spacing={1} className='w-100 pt-1 pl-2 pr-2 d-flex justify-content-between'>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <CustomLinearProgress color='#f8f8f8' progressValue={getReceivedOrderProgess()} />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <CustomLinearProgress color='#f8f8f8' progressValue={getPreparingOrderProgress()} />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                            <CustomLinearProgress color='#f8f8f8' progressValue={getOnTheWayOrderProgress()} />
                        </Grid>
                        {/* <Grid item xs={4} sm={4} md={4} lg={4}>
                            <CustomLinearProgress progressValue={getDeliveredOrderProgress()} />
                         </Grid> */}
                    </Grid> : null
                }
            </div>
            <div>
                {description ?
                    <Typography classes={{ root: classes.darkColor }} variant="body2" className="w-100 text-ellipsis-clamp-2 pt-2 pl-2">
                        {description}
                    </Typography> : null
                }
                <Tooltip title={<div className="tooltip-style width-max-content">{props.children}</div>}>
                    <Typography classes={{ root: classes.children }} variant="caption" className="text-ellipsis-clamp-2 font-weight-light text-capitalize w-100 pt-2 pl-2">
                        {props.children}
                    </Typography>
                </Tooltip>
                {subText ?
                    <Typography classes={{ root: classes.textColor }} variant="caption" className="text-ellipsis-clamp-1 h-auto font-weight-light text-capitalize pt-1 pl-2">
                        {subText}
                    </Typography>
                    : null}
                {footer ?
                    <Typography className='pt-1 pb-1 pl-2 pr-2' variant='caption'>
                        {footer ? footer : ''}
                    </Typography> : null
                }
            </div>
        </Paper>
    )
}

export default OrderHistoryCard