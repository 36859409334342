import SubCategoryProductsList from './sub.category.products.list';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
    getStoreProductCategories, resetStoreProductCategories, getStoreDetailInfo,
    resetStoreDetailInfo, setSelectedStoreProductCategory, resetSelectedStoreProductCategory,
    addUpdateProductToCart, deleteProductFromCart, getCartProducts,clearCartAndAddNewItem,
    getProducts,searchApp,resetProducts
} from 'view.updater/actions/products.actions';
import {
    makeSelectProductCategories, makeSelectStoreDetailInfo,
    makeSelectCartProducts,
    makeSelectSelectedStoreProductCategory, makeSelectSubCategoryProducts
} from 'view.updater/selectors/products.selectors';
import { makeSelectAuth } from 'view.updater/selectors/auth.selectors';

const mapDispatchToProps = (dispatch) => ({
    getStoreProductCategories: (data) => dispatch(getStoreProductCategories(data)),
    resetStoreProductCategories: () => dispatch(resetStoreProductCategories()),
    getStoreDetailInfo: (data) => dispatch(getStoreDetailInfo(data)),
    resetStoreDetailInfo: (data) => dispatch(resetStoreDetailInfo(data)),
    setSelectedStoreProductCategory: (data) => dispatch(setSelectedStoreProductCategory(data)),
    resetSelectedStoreProductCategory: () => dispatch(resetSelectedStoreProductCategory()),
    addUpdateProductToCart: (data) => dispatch(addUpdateProductToCart(data)),
    deleteProductFromCart: (data) => dispatch(deleteProductFromCart(data)),
    getCartProducts: (data) => dispatch(getCartProducts(data)),
    clearCartAndAddNewItem: (data) => dispatch(clearCartAndAddNewItem(data)),
    getProducts: (data) => dispatch(getProducts(data)),
    searchApp: (data) => dispatch(searchApp(data)),
    resetProducts: (reset,data) => dispatch(resetProducts(reset,data))
    
});

const mapStateToProps = createStructuredSelector({
    storeProductCategories: makeSelectProductCategories(),
    storeDetailInfo: makeSelectStoreDetailInfo(),
    selectedStoreProductCategory: makeSelectSelectedStoreProductCategory(),
    subCategoryProducts: makeSelectSubCategoryProducts(),
    cartProducts: makeSelectCartProducts(),
    auth: makeSelectAuth()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SubCategoryProductsList);