import './file.uploader.scss';
import React from 'react';
import clsx from 'clsx';
import Dropzone from 'react-dropzone'

export function FileUploader(props) {
  const { title, accept , classNames } = props;
  const onDrop = (files) => {
    props.onFileUpload(files);
  }
  return <Dropzone accept={accept} onDrop={onDrop}>
    {({ getRootProps, getInputProps }) => (
      <section className={clsx(`container-upload`,classNames)}>
        <div {...getRootProps({ className: 'dropzone p-2' })}>
          <input {...getInputProps()} />
          <div className="text-muted"><b>{title}</b></div>
        </div>
      </section>
    )}
  </Dropzone>
}


