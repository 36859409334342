import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { IsMobileWidth, NAVIGATIONS_ROUTES, getQueryStringValue, redirectToHomePage, isStandardPlusTheme } from 'components/common/util/util';
import clsx from 'clsx';
import { useStyles } from './bottom.navigation.panel.style';
import { useLocation } from 'react-router';
import { withRouter } from 'react-router'
import { IconButton, Typography } from '@mui/material';

const BottomNavigationPanel = (props) => {
    const classes = useStyles()
    const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
    const mobileWidth = IsMobileWidth()
    const [state, setState] = useState({
        selectedNavValue: 0// bottomNavigations && bottomNavigations[0].value
    });
    let location = useLocation();
    const onChange = (event, navigationValue) => {
        setState({ ...state, selectedNavValue: navigationValue });
        const selectedNav = NAVIGATIONS_ROUTES && NAVIGATIONS_ROUTES.find((nav) => nav.defaultValue == navigationValue)
        if (selectedNav) {
            props.getAppVersion();
            if (selectedNav.path === '/products') {
                redirectToHomePage(props, tradeTypeId)
            }
            else {
                props.history.push(`${selectedNav.path}?trade_type_id=${tradeTypeId}`)
            }
        }
    };
    useEffect(() => {
        const selectedNav = NAVIGATIONS_ROUTES && NAVIGATIONS_ROUTES.find((nav) => nav.path == location.pathname)
        if (selectedNav)
            setState({ ...state, selectedNavValue: selectedNav.defaultValue });
    }, [])
    // const onBottomNavigationClick = (data) => {
    //     if (data) {
    //         props.history.push(data.path)
    //     }
    // }
    // useEffect(() => {
    //     if (location) {
    //         if (location.pathname == "/products") {
    //             setState({ ...state, selectedNavValue: 0 });
    //         }
    //         else if (location.pathname === "/order/history") {
    //             setState({ ...state, selectedNavValue: 1 });
    //         }
    //         else if (location.pathname === "/about-us") {
    //             setState({ ...state, selectedNavValue: 2 });
    //         }
    //         else {
    //             setState({ ...state, selectedNavValue: -1 });
    //         }
    //     }
    // }, [location])
    return (
        <Box className='w-100 z-index-1200'>
            <BottomNavigation
                className="h-100"
                showLabels={true}
                value={state.selectedNavValue}
                onChange={onChange}
            >
                {
                    NAVIGATIONS_ROUTES && NAVIGATIONS_ROUTES.map((data, index) => {
                        return <BottomNavigationAction
                            //onClick={() => onBottomNavigationClick(data)}
                            classes={{
                                root: classes.navigationActionButton,
                                selected: classes.selectedNaviationButton
                            }}
                            key={data.value}
                            // disableRipple={true}
                            label={isStandardPlusTheme() ? null : <Typography className={clsx(state.selectedNavValue === index ? classes.selectedLabel : classes.label, 'pb-2')} variant='body3'>{data.name}</Typography>}
                            icon={<div className={clsx(state.selectedNavValue === index ? classes.selectedIcon : '', 'mt-2 mb-2 cursor-pointer')}><img src={data.iconSrc} width={'35px'} /></div>} />
                    })
                }
            </BottomNavigation>
        </Box>
    );
}
export default withRouter(BottomNavigationPanel);