import { makeStyles, withStyles } from "@mui/styles";
import { StepConnector } from "@mui/material";
export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // background:'yellow'
  },
  textColor: {
    color: theme.palette.dark.main,
  },
  labelRoot: {
    flexDirection: "column-reverse",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  stepRoot: {
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    // background: "yellow",
    width: "100%",
  },

  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    display: "flex",
    height: 19,
    alignItems: "center",
    marginTop: 10,
    // background:'pink'
  },
  active: {
    width: 20,
    height: 20,
    backgroundColor: "none",
    borderRadius: "50%",
    fontWeight: "bolder",
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "white",
    border: `3px solid ${theme.palette.secondary.main}`,
    opacity: 0.3,
    // marginTop:10
  },
  activeCircle: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    color: "white",
    fontSize: "12px",
    backgroundColor: theme.palette.secondary.main,
    border: `3px solid ${theme.palette.secondary.main}`,
    // marginTop:10
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18,
  },
}));

export const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 72,
    // left: "calc(-94% + 16px)", //update 94
    // right: "calc(97% + 16px)",

    left: "calc(-47% + 16px)", //update 94
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      backgroundColor: "none !important",
      border: "none !important",
      borderTop: `5px dotted ${theme.palette.secondary.main} !important`,
      borderTopWidth: "5px",
      borderRightWidth: "0px",
      borderBottomWidth: "0px",
      borderLeftWidth: "0px",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "none !important",
      border: "none !important",
      borderTop: `5px solid ${theme.palette.secondary.main} !important`,
      borderTopWidth: "5px",
      borderRightWidth: "0px",
      borderBottomWidth: "0px",
      borderLeftWidth: "0px",
    },
  },
  line: {
    display:'blue'
  },
}))(StepConnector);
