import "./footer.scss";
import React from "react";
import Grid from "@mui/material/Grid";
import { Divider, Typography } from "@mui/material";
import { IsMobileWidth } from "components/common/util/util";
import packageJson from '../../../../../package.json';
import { isB2bUser } from 'components/common/util/util';

import clsx from "clsx";
export const Footer = (props) => {
    const { auth } = props;
    const mobileWidth = IsMobileWidth();
    return (
        <div id="footer">
            <Grid container className="footer">
                <Grid
                    item
                    xs={12}
                    className={clsx(
                        !mobileWidth && "text-left w-100 pl-5",
                        mobileWidth && "text-left w-100 pl-3"
                    )}
                >
                    <div className="mb-4 pt-3">
                        {
                            isB2bUser(auth) ?
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/images/zapp-b2b-logo.png'}
                                    width="110"
                                    height="45" /> :
                                <img
                                    src={process.env.PUBLIC_URL + '/assets/images/zapp-logo.svg'}
                                    width="50"
                                    height="50"
                                />
                        }
                    </div>
                </Grid>
                <Grid
                    item
                    sm={6}
                    md={4}
                    xs={12}
                    lg={6}
                    className={clsx(
                        !mobileWidth && "mb-4 pl-5",
                        mobileWidth && "mb-4 w-100 pl-3 pr-1"
                    )}
                >
                    <div className="d-flex">
                        <div>
                            <span className="material-icons mr-2 icon">place</span>
                        </div>
                        <Typography variant="subtitle2" className="text-left">
                            Zapp Systems Pvt Ltd. 108 Cotton Exchange Building,
                            <br className={clsx(mobileWidth && "d-none")} /> II Chundrigar
                            Road, Karachi.
                        </Typography>
                    </div>
                    <div className="d-flex mt-3">
                        <div>
                            <span className="material-icons mr-2 icon">phone_in_talk</span>
                        </div>
                        <Typography variant="subtitle2">
                            <a href="tel:021-3713-ZAPP" className="icon" >
                                021-3713-ZAPP
                            </a>
                        </Typography>
                    </div>
                    <Typography className="p-2 w-100" color="primary">
                            Version {packageJson.version}
                        </Typography>
                    <div className="d-flex">
                        <a
                            target="_blank"
                            href="https://www.facebook.com/zapp.pk/"
                            className="p-2 social-icons icon">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <a
                            target="_blank"
                            className="p-2 social-icons icon"
                            href="https://mobile.twitter.com/zappworldpk">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a
                            target="_blank"
                            href="https://www.instagram.com/zapp.pk/"
                            className="p-2 social-icons icon">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </div>
                </Grid>
                <Grid
                    container
                    sm={12}
                    md={8}
                    xs={12}
                    lg={6}
                    className={clsx(mobileWidth && "pl-3")}
                >
                    <Grid item sm={4} xs={12} className="text-left">
                        <Typography
                            variant="h6"
                            className={clsx(
                                !mobileWidth && "mb-4 font-weight-bold",
                                mobileWidth && "mb-4 font-weight-bold mt-3"
                            )}
                        >
                            Information
                        </Typography>
                        <Typography variant="subtitle2" className="pb-2">
                            <a
                                className="link"
                                href="https://zapp.pk/merchants.html"
                                target="_blank"
                            >
                                Merchants
                            </a>
                        </Typography>
                        <Typography variant="subtitle2" className="">
                            <a
                                className="link"
                                href="https://zapp.pk/zapp-riders.html"
                                target="_blank"
                            >
                                Zapp Riders
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12} className="text-left">
                        <Typography
                            variant="h6"
                            className={clsx(
                                !mobileWidth && "mb-4 font-weight-bold",
                                mobileWidth && "mb-4 font-weight-bold mt-3"
                            )}
                        >
                            Helpful Links
                        </Typography>
                        <Typography variant="subtitle2" className="pb-2">
                            <a
                                className="link"
                                href="https://zapp.pk/privacy-policy.html"
                                target="_blank"
                            >
                                Privacy Policy
                            </a>
                        </Typography>
                        <Typography variant="subtitle2" className="pb-2">
                            <a
                                className="link"
                                href="https://zapp.pk/cookies-policy.html"
                                target="_blank"
                            >
                                Cookies Policy
                            </a>
                        </Typography>
                        <Typography variant="subtitle2" className="pb-2">
                            <a
                                className="link"
                                href="https://zapp.pk/faqs.html"
                                target="_blank"
                            >
                                FAQ's
                            </a>
                        </Typography>
                        <Typography variant="subtitle2" className="pb-2">
                            <a
                                className="link"
                                href="https://zapp.pk/contact.html"
                                target="_blank"
                            >
                                Contact Details
                            </a>
                        </Typography>

                        <Typography variant="subtitle2">
                            <a
                                className="link"
                                href="https://zapp.pk/terms-&-conditions.html"
                                target="_blank"
                            >
                                Terms & Conditions
                            </a>
                        </Typography>
                    </Grid>
                    <Grid item sm={4} xs={12} className="text-left">
                        <Typography
                            variant="h6"
                            className={clsx(
                                !mobileWidth && "mb-4 font-weight-bold",
                                mobileWidth && "mb-4 font-weight-bold mt-3"
                            )}
                        >
                            Download Zapp App
                        </Typography>
                        <div className="pb-2">
                            <a
                                href="https://apps.apple.com/pk/app/zapp-shop-anytime-anywhere/id1541819812"
                                target="_blank"
                            >
                                <img
                                    src={
                                        process.env.PUBLIC_URL + "/assets/images/app-store-logo.png"
                                    }
                                    alt="app store logo"
                                    width="150px"
                                />
                            </a>
                        </div>
                        <div>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.app.zapp"
                                target="_blank"
                            >
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/play-store-logo.png"
                                    }
                                    alt="playstore logo"
                                    width="150px"
                                />
                            </a>
                        </div>
                       
                    </Grid>
                </Grid>
                <Grid item xs={12} className="pt-3">
                    {/* <Divider variant="middle" /> */}
                    <div className="d-flex justify-content-center align-items-center">
                        {/* <Typography className="p-2 w-50" color="primary">
                            Version {packageJson.version}
                        </Typography> */}
                        <Typography className="p-2 font-weight-bold">
                            © {new Date().getFullYear()} Zapp All rights reserved
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            {/* <Divider variant="middle" />

      <div className="d-flex justify-content-center p-3 font-weight-bold">
        
      </div> */}
        </div>
    );
};
export default Footer;
