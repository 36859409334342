import React, { useState } from 'react'
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog'
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import { Paper, Typography, FormControlLabel, Radio, IconButton, RadioGroup } from '@mui/material';
import { IsMobileWidth } from 'components/common/util/util'
import clsx from 'clsx';

const ReOrderErrorDialog = (props) => {
    const mobileWidth = IsMobileWidth()
    const { isOpen, selectedOrder } = props;
    const clearCartAndAddNewItms = (e) => {
        props.reOrder(selectedOrder,e,true);
        props.hideReOrderErrorDialog(false);
    }
    const redirectTo = (route) => {
        props.history.push(route);
    }
    return (
        <ContentDialog
            handleClose={props.hideReOrderErrorDialog}
            open={isOpen}
            actions={<div className="w-100 d-flex flex-column align-items-center">
                <div className={clsx("p-1 pt-4 text-capitalize", !mobileWidth && 'w-50', mobileWidth && 'w-100')}>
                    <LoaderButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        classNames="text-capitalize"
                        color="primary"
                        onClick={clearCartAndAddNewItms}
                    // loaderPosition="top"
                    >
                        Clear Cart and Add New Items
                    </LoaderButton>
                </div>
                <div className={clsx("p-1 pt-2 text-capitalize", !mobileWidth && 'w-50', mobileWidth && 'w-100')}>
                    <LoaderButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={() => redirectTo('/checkout/cart-summary')}
                        classNames="text-capitalize"
                        color="primary">
                        Proceed to Cart
                    </LoaderButton>
                </div>
                <div className={clsx("p-1 pt-2 pb-4 text-capitalize", !mobileWidth && 'w-50', mobileWidth && 'w-100')}>
                    <LoaderButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={props.hideReOrderErrorDialog}
                        classNames="text-capitalize"
                        color="secondary">
                        Cancel
                    </LoaderButton>
                </div>
            </div>}
            title={<Typography variant='h5'
                className="p-1 font-weight-bold" >
                Your Shopping Cart
            </Typography >}>
            <div className='w-100 p-1 pt-3 pb-3 font-weight-bold'>
                You've already got items in your cart,please complete your first order or clear
                your cart to add new items.
            </div>
        </ContentDialog>
    )
}
export default ReOrderErrorDialog