import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.darkBlueColor.main,
        height: "50px",
        minWidth: "150px",
        // border: `1px solid ${theme.palette.secondary.main}`,
        boxShadow: "none",
    },
    textColor: {
        backgroundColor: theme.palette.darkBlueColor.light,
        color: theme.palette.background.paper,
        fontFamily: 'cursive',
        width: "45px",
    },
    textColor2: {
        color: theme.palette.background.paper,
    },
    btn: {
        color: theme.palette.background.paper,
        fontSize: '12px',
        '&:hover': {
            color: theme.palette.greyColor.main,
            backgroundColor: theme.palette.darkBlueColor.main,
        }
    },
    title: {
        fontWeight: '600 !important',
        display: 'flex',
        alignItems: 'center',
        height: '100%'
    }
}))