import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { useStyle } from "./status.box.style";
import { IsMobileWidth, IsTabletWidth } from "components/common/util/util";
import clsx from "clsx";
import LoaderButton from 'components/common/material.components/loader.button/loader.button';

const StatusBox = (props) => {
  const { label, subLabel, titleBackgroundColor, labelBackgroundColor, title, heading, titleColor } = props;
  const mobileWidth = IsMobileWidth();
  const tabletWidth = IsTabletWidth();
  const classes = useStyle();
  return (
    <Grid container justifyContent="center" className={clsx(classes.gridContainer, 'm-1 pt-2 pb-2')}>
      <Grid item xs={12} sm={12} mb={2}>
        <div className="d-flex justify-content-center font-weight-bold">
          <Typography variant="subtitle" className="pt-2" color="color.dark">
            {heading}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        {/* <Paper
          elevation={0}
          className="rounded-0"
          sx={{
            backgroundColor: titleBackgroundColor ? titleBackgroundColor : "#fff",
            border: !titleBackgroundColor ? "3px solid #d9d8d8" : "none",
          }}
        > */}
        {title && (
          <Typography variant={clsx(!(mobileWidth || tabletWidth) && "h6", (mobileWidth || tabletWidth) && 'subtitle')} className='pt-2 pb-2 text-center' color={titleColor ? titleColor : '#fff'}>
            {title}
          </Typography>
        )}
        {/* </Paper> */}
        {/* <Paper
          className={clsx("rounded-0 pt-5 pb-5")}
          elevation={0}
          sx={{
            backgroundColor: labelBackgroundColor ? labelBackgroundColor : "#fff"
          }}
        > */}
        {label && (
          <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle", (mobileWidth || tabletWidth) && 'subtitle')}
            className="text-center pt-3" style={{ color: labelBackgroundColor ? "#fff" : "#000" }}>
            {label}
          </Typography>
        )}
        {/* </Paper> */}
        {/* <Paper
          className={clsx("rounded-0 pt-4 pb-3")}
          elevation={0}
        > */}
        {subLabel && (
          <Typography variant={clsx(!(mobileWidth || tabletWidth) && "body1", (mobileWidth || tabletWidth) && 'body1')} color="dark.main" className="text-center">
            {subLabel}
          </Typography>
        )}
        {/* <Box className="w-100 mt-5 mb-4 d-flex justify-content-center">
          <LoaderButton
              // fullWidth
              type="submit"
              variant="contained"
              color="primary"
              classNames={clsx("mt-4 mb-4 width-max-content", classes.btnStyle)}
              onClick={props.showQrDialog}
            >
              Show QR code
            </LoaderButton>
        </Box> */}
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
};

export default StatusBox;
