import { makeStyles } from '@mui/styles'
export const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.secondary.main,
  },
  nooutline: {
    outline: 'none',
  },
  noBorder: {
    border: 'none',
  },
  iconColor: {
    color: theme.palette.secondary.main,
  },
  input: {
    height: 0,
    fontSize: 15
  },
  btn: {
    minWidth: 12
  },
  input: {
    // minWidth: '13%',
    textOverflow: "ellipsis",
    height: 8,
    fontSize: 14,
    fontWeight: 300,
    boxShadow: 'none',
    fontFamily: 'Nunito',
  },
  mobileInput: {
    height: 8,
    fontSize: 12,
    fontWeight: 300,
    boxShadow: 'none',
    fontFamily: 'Nunito',
    textOverflow: "ellipsis",
  },
  paperRoot: {
    height: '45px',
    // border: `0px !important`,
    // borderRadius: '10px',
    // position: "relative",
    // boxShadow: 'none',
    // outline: 'none',
    // background: theme.palette.inputControlColor.main,
    // display: 'flex',
    // alignItems: 'center',
  },
}))
