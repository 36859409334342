import React, { useRef, useState } from 'react'
import { Grid, CircularProgress, Typography } from '@mui/material'
// import OrderCard from 'components/common/order.card/order.card'
import { Pagination } from '@mui/material';
import { ORDER_HISTORY_PAGE_SIZE } from 'view.updater/actions/order.actions';
import OrderHistoryCard from 'components/common/order.history.card/order.history.card';
import { SERVER_BASE_URL } from 'view.updater/middlewares/util/api.config';
import OrderHistoryDetailDialog from './order.history.detail.dialog/order.history.detail.dialog';
import { getImageUrl, scrollToRefElement, getOrderStatusDescription, getMessageColor } from 'components/common/util/util';
import { getQueryStringValue } from 'components/common/util/util';
import { useTranslation } from "react-i18next";

export default function ActiveOrders(props) {
    const { execStatus, activeOrders } = props;
    const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
    const [state, setState] = useState({
        selectedPage: 1,
        openOrderHistoryDetailDialog: false,
        selectedOrder: ''
    })
    const { t } = useTranslation("global");
    let actOrders = activeOrders && activeOrders.find((order) => order.get('page') == state.selectedPage);
    const ref = useRef(null)
    const onPageChange = (e, page) => {
        setTimeout(() => {
            scrollToRefElement(ref)
        }, 200)
        props.getActiveOrders({
            page: page
        });
        setState({ ...state, selectedPage: page })
    }
    const getTotalPageCount = () => {
        if (actOrders) {
            const totalOrders = actOrders.get('orders')?.get('pagination')?.get('total_items')
            if (totalOrders) {
                return Math.ceil(totalOrders / ORDER_HISTORY_PAGE_SIZE)
            }
        }
        else return 1
    }
    const openOrderHistoryDetailDialog = (order) => {
        props.history.push(`/checkout/payment-success/${order && order.get('invoice') && order.get('invoice').get('query_id')}?showOrderDetail=true&trade_type_id=${tradeTypeId}`)
        // setState({
        //     ...state, openOrderHistoryDetailDialog: true, selectedOrder: order
        // })
    }
    const closeOrderHistoryDetailDialog = () => {
        setState({ ...state, openOrderHistoryDetailDialog: false, selectedOrder: '' })
    }
    return (
        <div ref={ref}>
            <Grid container spacing={2} className='pt-2'>
                {
                    actOrders && actOrders.get('orders') && actOrders.get('orders').get("invoices") && actOrders.get('orders').get("invoices").size !== 0 ?
                        actOrders.get('orders').get("invoices").map((data, i) =>
                            <Grid spacing={3} item xs={12} sm={6} md={4} lg={4} key={i}>
                                <OrderHistoryCard
                                    onClick={() => openOrderHistoryDetailDialog(data)}
                                    image={getImageUrl(`${data.get("invoice") && data.get("invoice").get("merchant") && data.get("invoice").get("merchant").get("merchant_banner") && data.get("invoice").get("merchant").get("merchant_banner")}`)}
                                    styledHeading={data.get("invoice") && data.get("invoice").get("merchant") && data.get("invoice").get("merchant").get("name") && data.get("invoice").get("merchant").get("name")}
                                    price={data.get("invoice") && data.get("invoice").get("payble_amount")}
                                    status={data.get("invoice") && data.get("invoice").get("status")}
                                    description={data.get("invoice") && getOrderStatusDescription(data.get("invoice").get("status"))}>
                                    {
                                        data?.get("invoice")?.get("invoice_items")?.map((item, index) => {
                                            return <div key={index}>
                                                {`${item?.get('quantity')}* `}{item?.get('product_name') ? item?.get('product_name') : ''}
                                            </div>
                                        })
                                    }
                                </OrderHistoryCard>
                                {/* <OrderCard
                                    image={data.get("invoice") && data.get("invoice").get("invoice_items") && data.get("invoice").get("invoice_items").get(0) && data.get("invoice").get("invoice_items").get(0).get("image_medium_url")}
                                    label={data.get("invoice") && data.get("invoice").get("invoice_items") && data.get("invoice").get("invoice_items").get(0) && data.get("invoice").get("invoice_items").get(0).get("product_name")}
                                    description={data.get("invoice") && data.get("invoice").get("invoice_items") && data.get("invoice").get("invoice_items").get(0) && data.get("invoice").get("invoice_items").get(0).get("product_name")}
                                    noOfItems={data.get("invoice") && data.get("invoice").get("invoice_items").size}
                                    cost={data.get("invoice") && data.get("invoice").get("payble_amount")}
                                /> */}
                            </Grid>
                        ) :
                        execStatus.status === "PENDING" ?
                            <div className='d-flex w-100 flex-column justify-content-center align-items-center h-60-vh'>
                                <Typography variant='body1' className="text-dark pt-2 pb-2">
                                    Loading Orders....
                                </Typography>
                                <CircularProgress />
                            </div> :
                            actOrders && actOrders.get('orders').size === 0 ?
                                <div className={`${getMessageColor()} w-100 font-weight-bold d-flex justify-content-center align-items-center h-60-vh`}>
                                    {window.INDEX_NOKIA_ARENA === true ? t("messages.noOrder"): 'No Orders Found!'}
                                </div> :
                                execStatus.status === "ERROR" ?
                                    <div className="w-100">
                                        <p>{execStatus.status === "ERROR" ?
                                            <div className="d-flex pt-3 text-danger justify-content-center w-100">
                                                {execStatus?.errorDetail?.get('message')}
                                            </div> : null}</p>
                                    </div> : null
                }
            </Grid>
            {
                state.openOrderHistoryDetailDialog == true ?
                    <OrderHistoryDetailDialog
                        isActiveOrder={true}
                        closeOrderHistoryDetailDialog={closeOrderHistoryDetailDialog}
                        openOrderHistoryDetailDialog={state.openOrderHistoryDetailDialog}
                        {...state}
                    /> : null
            }
            {
                getTotalPageCount() > 1 ?
                    <div className='pt-3 w-100 pt-5 d-flex justify-content-center pb-2'>
                        <Pagination
                            page={state.selectedPage}
                            onChange={onPageChange}
                            color="secondary"
                            count={getTotalPageCount()}
                            shape="rounded"
                        />
                    </div> : null
            }
        </div>
    )
}
