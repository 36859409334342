import React from 'react'
import CustomLinearProgress from 'components/common/custom.linear.progress.bar/custom.linear.progress.bar';
import { Grid } from '@mui/material';
import { useStyle } from './traffic.lights.style';
import clsx from 'clsx';

const TrafficLights = (props) => {
    const { status } = props
    const classes = useStyle()
    const getReceivedOrderProgess = () => {
        if (status === "order_pending" || status === "order_confirmed" || status === "order_dispatched" || status === "order_delivered") {
            return 100
        }
        else {
            return 0
        }
    }
    const getPreparingOrderProgress = () => {
        if (status === "order_confirmed" || status === "order_dispatched" || status === "order_delivered") {
            return 100
        }
        else {
            return 0
        }
    }
    const getOnTheWayOrderProgress = () => {
        if (status === "order_dispatched" || status === "order_delivered") {
            return 100
        }
        else {
            return 0
        }
    }

    return (
        <div>
            <Grid container spacing={1} className={clsx(classes.status, 'w-100 d-flex justify-content-between ml-0 mr-0 pt-1')}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <CustomLinearProgress classNames={classes.roundedProgress} progressValue={getReceivedOrderProgess()} />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <CustomLinearProgress classNames={classes.roundedProgress} progressValue={getPreparingOrderProgress()} />
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                    <CustomLinearProgress classNames={classes.roundedProgress} progressValue={getOnTheWayOrderProgress()} />
                </Grid>
            </Grid>
        </div>
    )
}

export default TrafficLights
