import './product.box.scss';
import React, { useState } from 'react'
import { Typography, IconButton, CardContent, CardMedia, Paper, Tooltip, CircularProgress, Button, Grid } from '@mui/material';
import QuantityButtonCounter from 'components/common/quantity.button.counter/quantity.button.counter';
import { useStyle } from './product.box.style';
import AddProductWarningDialog from './add.product.warning.dialog';
import { storeIsInRadius, getSelectedAddressLatLng } from 'components/common/util/util';
import { formatAmountV2, getTheApplicationTheme, isStandardPlusTheme, getCurrency } from 'components/common/util/util';
import ProductAddButton from 'components/common/product.add.button/product.add.button';
import clsx from 'clsx';
import ContentDialog from 'components/common/material.components/content.dialog/content.dialog';
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import ExpandableViewV3 from 'components/common/expandable.view.v3/expandable.view.v3';

const ProductBox = (props) => {
    const [state, setState] = useState({
        showAddProductWarningDialog: false,
        warningMessage: '',
        isOpenImageZoomDialog: false
    })
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const { image, secondaryImage, description, label, auth, cost, oldCost,
        storeQueryId, industryId, categoryId,
        cartProducts, addProductToCartId, product,
        subCategoryId,
        execStatusAddUpdateProductToCart, execStatusGetCartProducts, page, collapsed } = props;
    //cartProducts.get(0).get('id')addProductToCartId
    //product.get("query_id")
    let cartProduct;
    if (product) {
        cartProduct = cartProducts && cartProducts?.find((cartProd) => cartProd.get('id') === product.get('query_id'))
    }
    const checkStoreInRadius = (storeLat, storeLng, serviceAreaRadius) => {
        let location = getSelectedAddressLatLng(auth);
        if (location.lat && location.long && storeLat && storeLng && serviceAreaRadius) {
            return storeIsInRadius(location.lat, location.long, storeLat, storeLng, serviceAreaRadius)
        }
        else {
            return true;
        }
    }
    const onAddPro = (e, quantity) => {
        e.stopPropagation();
        e.preventDefault();
        if (product && product.get('shop_status') !== "open") {
            showAddProductWarningDialog("Merchant is closed, please try again later")
        }
        else {
            if (!checkStoreInRadius(product.get('merchant_coordinates') && product.get('merchant_coordinates').get('lat'), product.get('merchant_coordinates') && product.get('merchant_coordinates').get('long'), product.get('service_area_radius'))) {
                showAddProductWarningDialog("Sorry, the store is not delivering in your area")
            }
            else {
                if (product && product.get('product_sizes') && product.get('product_sizes').size > 0) {
                    showProductDetail();
                }
                else if (product && product.get('product_colors') && product.get('product_colors').size > 0) {
                    showProductDetail();
                }
                else {
                    props.setAddProductToCartId(product.get('query_id'))
                    props.onAdd(quantity)
                }

            }
        }
    }

    let quantity = 0;
    const onQuantityChange = (qty) => {
        props.setAddProductToCartId(product.get('query_id'))
        props.onQtyChange(qty)
    }
    const isAddingToCartInProgress = () => {
        if (execStatusAddUpdateProductToCart.status === "PENDING" &&
            addProductToCartId === product.get('query_id')) {
            return true;
        }
        else if (execStatusGetCartProducts.status === "PENDING" &&
            addProductToCartId === product.get('query_id')) {
            return true
        }
        else
            return false
    }
    const isOutOfStock = () => {
        if (product.get('unlimited') === true) {
            if (product.get('unlimited_out_of_stock') === true) {
                return true
            }
            else {
                return false
            }
        }
        else {
            if (product.get('product_available_quantity') == 0) {
                return true
            }
            return false
        }
    }
    const getDiscountValue = () => {
        if (product && product.get('promotion')) {
            if (product.get('promotion').get('promotion_nature') === "flat") {
                return `${product.get('currency')} ${product.get('promotion').get('promotion_amount')}  off`
            }
            else if (product.get('promotion').get('promotion_nature') === "discount") {
                return `${product.get('promotion').get('promotion_amount')}% off`
            }
            else return ''
        }
    }
    const showAddProductWarningDialog = (message) => {
        setState({ ...state, showAddProductWarningDialog: true, warningMessage: message })
    }
    const handleCloseAddProductWarningDialog = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setState({ ...state, showAddProductWarningDialog: false, warningMessage: '' })
    }
    const showProductDetail = () => {
        props.history.push(`/products/details/${storeQueryId}/${product.get('category_id')}/${product.get('sub_category_id')}/${industryId}/${product.get('query_id')}/${page}`)
    }
    let discountValue = getDiscountValue();

    const showImageZoomDialog = () => {
        setState({ ...state, isOpenImageZoomDialog: true });
    };
    const hideImageZoomDialog = () => {
        setState({ ...state, isOpenImageZoomDialog: false });
    };
    const style = window.INDEX_NOKIA_ARENA ? { background: '#F8F8F8', border: "1px solid #D6D6D6", borderRadius: "17px" } : {};
    const isTypographyStyle = window.INDEX_NOKIA_ARENA === true;

    return (
        <div id="product-box" className={clsx(isOutOfStock() && 'd-none', 'w-100 cursor-pointer')} onClick={props.onClick} style={style}>
            {/* <div onClick={showProductDetail} className="w-100 position-relative border card-style rounded">
                {
                    isOutOfStock() ?
                        <div className="w-100 position-absolute top-0 start-0 stock">
                            <Typography color="white">Out of Stock</Typography>
                        </div> : null
                }
                <CardContent className="d-flex align-items-end product-box w-100 p-0 pl-2 bg-danger">
                    {discountValue ?
                        <div className='w-100 d-flex justify-content-end align-items-start mt-2'>
                            <Typography classes={{ root: classes.tagColor }}
                                variant="caption" className='text-Capitalize font-weight-normal width-max-content pl-2 pr-2 pt-1 pb-0 d-flex justify-content-center align-items-center'>
                                {discountValue}
                            </Typography>
                        </div> : null
                    }
                    <div className='h-100 d-flex justify-content-center'>
                        <img className='img-size' src={image ? image : ''} />
                        {
                            cartProduct && cartProduct?.get('data')?.get('quantity') ?
                                <div className='quantity-button d-flex justify-content-center'>
                                    <QuantityButtonCounter
                                        maxQuantity={product.get('product_available_quantity') > 0 ? product.get('product_available_quantity') : 99}
                                        isAddingToCartInProgress={isAddingToCartInProgress}
                                        onQuantityChange={onQuantityChange}
                                        value={cartProduct?.get('data')?.get('quantity')} />
                                </div> : null
                        }
                    </div>{
                        !cartProduct ?
                            <div className="d-flex align-items-end positive-relative justify-content-end p-1">
                                <div className="">
                                    {
                                        isAddingToCartInProgress() ?
                                            <CircularProgress color="secondary" size={30} /> :
                                            !isOutOfStock() ?
                                                <IconButton
                                                    onClick={(e) => onAddPro(e, quantity)}
                                                    classes={{ root: classes.likeBtnDetail }}
                                                    size='small'
                                                    className="material-icons paper-root border-radius-100 p-1">
                                                    add
                                                </IconButton> : null
                                    }
                                </div>
                            </div> : null
                    }
                    <Tooltip title={<div className='tooltipHeight'>{description}</div>}>
                        <Typography variant="body1" className="text-ellipsis-clamp-2 product-decription font-weight-normal text-capitalize">
                            {description ? description : ""}
                        </Typography>
                    </Tooltip>
                    <div className='d-flex pt-2'>
                        <Tooltip title={label}>
                            <Typography className="font-weight-bold text-ellipsis-clamp-1" variant="body3">{
                                label ? label : ''
                            } </Typography>
                        </Tooltip>
                    </div>
                    <div className='d-flex'>
                        <Typography variant='subtitle2' className="pl-1 font-weight-bold text-capitalize">
                            {product.get('cost_after_discount') ? formatAmountV1(product.get('cost_after_discount')) : product.get('cost') ? formatAmountV1(product.get('cost')) : ''}
                            {
                                product.get('cost_after_discount') ?
                                    <Typography
                                        classes={{
                                            root: classes.oldPriceStyle
                                        }}
                                        variant="caption"
                                        className="p-1 pl-2">{product.get('cost') ? formatAmountV1(product.get('cost')) : ''}
                                    </Typography> : null

                            }
                        </Typography>
                        <Typography variant="subtitle2" color="bannerColor.main">{product.get('currency')} </Typography>
                    </div>
                    <Typography className="w-100 pb-4 cursor-pointer pr-2">
                        <Button classes={{ root: classes.lightBorder }} variant='none' color='primary' className="text-capitalize w-100">
                            View
                        </Button>
                    </Typography>
                </CardContent>
            </div> */}
            {getTheApplicationTheme().includes('STANDARD') ?
                <Grid
                    onClick={props.onClick}
                    style={{ minHeight: isStandardPlusTheme() && '120px', ...style }}
                    classes={{ root: cartProduct && cartProduct?.get('data') && cartProduct?.get('data')?.get('quantity') >= 1 ? (isStandardPlusTheme() ? classes.productBoxSelectedSPlus : classes.productBoxSelected) : classes.productBoxStyle }}
                    //  onClick={showProductDetail}  
                    container className={clsx(isStandardPlusTheme() ? 'pb-0 pr-0 pt-0' : 'pb-2 pr-3 pt-2', 'd-flex align-items-center product-box-style pl-3 position-relative', collapsed && classes.borderStyle)}>
                    <Grid items xs={2.5} sm={2} md={3} lg={3}>
                        <img onClick={showImageZoomDialog} className={clsx(!(mobileWidth || tabletWidth) && 'img-size', (mobileWidth || tabletWidth) && 'responsive-img-size', 'cursor-pointer mr-1', (isStandardPlusTheme() && collapsed) && 'image-style-plus')} src={image ? image : ''} />
                    </Grid>
                    <Grid items xs={isStandardPlusTheme() ? 5.5 : 4} sm={isStandardPlusTheme() ? 7 : 4} md={isStandardPlusTheme() ? 6 : 3} lg={isStandardPlusTheme() ? 6 : 3}>
                        <div className='pl-2 d-flex flex-column pt-3 pb-3'>
                            <Typography color='dark.main' variant='caption' className={` text-ellipsis-clamp-4  ${isTypographyStyle ? 'font-weight-normal' : 'font-weight-bold '}`}
                                style={isTypographyStyle
                                    ? { fontSize: "16px" }
                                    : {}}>{
                                    label ? label : ''
                                } </Typography>
                            <ExpandableViewV3 collapsed={collapsed}>
                                <Typography variant="caption" className="font-weight-normal">
                                    {description ? description : ""}
                                </Typography>
                            </ExpandableViewV3>
                            {isStandardPlusTheme() ?
                                <div className='d-flex justify-content-start pt-1'>
                                    <Typography color='dark.main' variant='caption' className="font-weight-bold text-capitalize text-ellipsis-clamp-2" style={isTypographyStyle
                                        ? { fontSize: "16px" }
                                        : {}}>
                                        {product.get('cost_after_discount') ? formatAmountV2(product.get('cost_after_discount')) : product.get('cost') ? formatAmountV2(product.get('cost')) : ''}
                                        {
                                            product.get('cost_after_discount') ?
                                                <Typography
                                                    classes={{
                                                        root: classes.oldPriceStyle
                                                    }}
                                                    variant="caption"
                                                    className="p-1 pl-2">{product.get('cost') ? formatAmountV2(product.get('cost')) : ''}
                                                </Typography> : null

                                        }
                                    </Typography>
                                    <Typography className='pl-1' variant="caption" color="dark.main"> {getCurrency()} </Typography>
                                </div> : null}
                            {discountValue ?
                                <div className='w-100 d-flex justify-content-start align-items-start mt-2 text-ellipsis-clamp-2'>
                                    <Typography classes={{ root: classes.tagColor }}
                                        variant="caption" className='text-Capitalize font-weight-normal width-max-content pl-2 pr-2 pt-1 pb-0 d-flex justify-content-center align-items-center'>
                                        {discountValue}
                                    </Typography>
                                </div>
                                : null}
                            {/* <Typography className="w-100 pb-4 cursor-pointer pr-2">
                            <Button classes={{ root: classes.lightBorder }} variant='none' color='primary' className="text-capitalize w-100">
                                View
                            </Button>
                        </Typography> */}
                        </div>
                    </Grid>
                    {!isStandardPlusTheme() ?
                        <Grid items xs={2.5} sm={3} md={3} lg={3}>
                            <div className='d-flex justify-content-center'>
                                <Typography color='dark.main' variant='caption' className="pl-1 font-weight-bold text-capitalize text-ellipsis-clamp-2">
                                    {product.get('cost_after_discount') ? formatAmountV2(product.get('cost_after_discount')) : product.get('cost') ? formatAmountV2(product.get('cost')) : ''}
                                    {
                                        product.get('cost_after_discount') ?
                                            <Typography
                                                classes={{
                                                    root: classes.oldPriceStyle
                                                }}
                                                variant="caption"
                                                className="p-1 pl-2">{product.get('cost') ? formatAmountV2(product.get('cost')) : ''}
                                            </Typography> : null

                                    }
                                </Typography>
                                <Typography className='pl-1' variant="caption" color="dark.main"> {getCurrency()} </Typography>
                            </div>
                        </Grid> : null}
                    <Grid items xs={isStandardPlusTheme() ? 4 : 3} sm={3} md={3} lg={3} className={clsx(isStandardPlusTheme() && 'h-100 position-absolute right-0 bottom-0')}>
                        {product && product.get('variant_count') > 0 ?
                            <div className='h-100 w-100 d-flex justify-content-center align-items-center pr-3'>
                                <Button className='text-capitalize' color='primary' onClick={showProductDetail} variant='body1'>
                                    Show more
                                    <img className='ml-2' src={`${process.env.PUBLIC_URL}/assets/icons/expand-more-icon.svg`} width='12px' />
                                </Button>
                            </div>
                            :
                            <div className={clsx(isStandardPlusTheme() && 'h-100 w-100 d-flex justify-content-end align-items-end')}>
                                {
                                    cartProduct && cartProduct?.get('data')?.get('quantity') ?
                                        <div className='d-flex justify-content-center'>
                                            <QuantityButtonCounter
                                                defaultStyle={isStandardPlusTheme() && false}
                                                maxQuantity={product.get('product_available_quantity') > 0 ? product.get('product_available_quantity') : 99}
                                                isAddingToCartInProgress={isAddingToCartInProgress}
                                                onQuantityChange={onQuantityChange}
                                                value={cartProduct && cartProduct?.get('data')?.get('quantity')} />
                                        </div> : window.INDEX_NOKIA_ARENA === true ?
                                            <div className='d-flex justify-content-center'>
                                                <QuantityButtonCounter
                                                    defaultStyle={isStandardPlusTheme() && false}
                                                    maxQuantity={product.get('product_available_quantity') > 0 ? product.get('product_available_quantity') : 99}
                                                    isAddingToCartInProgress={isAddingToCartInProgress}
                                                    onQuantityChange={onQuantityChange}
                                                    value={cartProduct && cartProduct?.get('data')?.get('quantity')} 
                                                    onClick={(e) => onAddPro(e, quantity)}
                                                    />
                                            </div> : null
                                }
                                {
                                    !cartProduct ?
                                        <div className={clsx(isStandardPlusTheme() && 'pb-2', 'd-flex justify-content-center')}>
                                            {
                                                !isOutOfStock() ?
                                                    <ProductAddButton
                                                        isAddingToCartInProgress={isAddingToCartInProgress}
                                                        onClick={(e) => onAddPro(e, quantity)} />
                                                    : null
                                            }
                                        </div>
                                        : null
                                }
                            </div>
                        }
                        {
                            isOutOfStock() ?
                                <div className="w-100 position-absolute top-0 start-0 stock rounded">
                                    <Typography variant='caption' color="white">Out of Stock</Typography>
                                </div>
                                : null
                        }
                    </Grid>
                </Grid>
                : getTheApplicationTheme() === 'PROMINENT' ?
                    <Grid
                        classes={{ root: cartProduct && cartProduct?.get('data') && cartProduct?.get('data')?.get('quantity') >= 1 ? classes.productBoxSelected : classes.productBoxStyle }}
                        //  onClick={showProductDetail}  
                        container className={clsx('d-flex align-items-center product-box-style pl-2 pr-2 pt-2 pb-2')}>
                        <Grid items xs={4} sm={4} md={4} lg={3}>
                            <img onClick={showImageZoomDialog} className={clsx('img-size-prominent cursor-pointer')} src={image ? image : ''} />
                        </Grid>
                        <Grid items xs={8} sm={8} md={8} lg={9} className='pl-4'>
                            <Grid items xs={12} sm={12} md={12} lg={12} className='pt-2 pb-2 w-100'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <Typography color='dark.main' variant='body2' className="font-weight-light text-ellipsis-clamp-2">
                                        {label ? label : ''}
                                    </Typography>
                                    {discountValue ?
                                        <Typography classes={{ root: classes.tagColor }}
                                            variant="body2" className='text-Capitalize font-weight-normal w-25 pl-2 pr-2 d-flex justify-content-center align-items-center'>
                                            {discountValue}
                                        </Typography>
                                        : null
                                    }
                                    {/* <Typography className="w-100 pb-4 cursor-pointer pr-2">
                            <Button classes={{ root: classes.lightBorder }} variant='none' color='primary' className="text-capitalize w-100">
                                View
                            </Butto1 litre bottlen>
                        </Typography> */}
                                </div>
                            </Grid>
                            <Grid items xs={12} sm={12} md={12} lg={12} className='pt-2 pb-2'>
                                <Typography variant="body2" className="text-ellipsis-clamp-3 font-weight-normal">
                                    {description ? description : ""}
                                </Typography>
                                <div className='d-flex justify-content-start'>
                                    <Typography color='dark.main' variant='body2' className="pl-1 font-weight-bold text-capitalize text-ellipsis-clamp-2">
                                        {product.get('cost_after_discount') ? formatAmountV2(product.get('cost_after_discount')) : product.get('cost') ? formatAmountV2(product.get('cost')) : ''}
                                        {
                                            product.get('cost_after_discount') ?
                                                <Typography
                                                    classes={{
                                                        root: classes.oldPriceStyle
                                                    }}
                                                    variant="body2"
                                                    className="p-1 pl-2">{product.get('cost') ? formatAmountV2(product.get('cost')) : ''}
                                                </Typography> : null

                                        }
                                    </Typography>
                                    <Typography className='pl-1' variant="body2" color="dark.main"> {getCurrency()} </Typography>
                                </div>
                            </Grid>
                            <Grid items xs={12} sm={12} md={12} lg={12} className='position-relative pt-2 pb-2'>
                                {product && product.get('variant_count') > 0 ?
                                    <div className='h-100 w-100 d-flex justify-content-center align-items-center pr-3'>
                                        <Button className='text-capitalize' color='primary' onClick={showProductDetail} variant='body1'>Show more</Button>
                                    </div>
                                    :
                                    <div>
                                        {
                                            cartProduct && cartProduct?.get('data')?.get('quantity') ?
                                                <div className='d-flex justify-content-center'>
                                                    <QuantityButtonCounter
                                                        maxQuantity={product.get('product_available_quantity') > 0 ? product.get('product_available_quantity') : 99}
                                                        isAddingToCartInProgress={isAddingToCartInProgress}
                                                        onQuantityChange={onQuantityChange}
                                                        value={cartProduct && cartProduct?.get('data')?.get('quantity')} />
                                                </div> : null
                                        }
                                        {
                                            !cartProduct ?
                                                <div className='d-flex justify-content-center'>
                                                    {
                                                        !isOutOfStock() ?
                                                            <ProductAddButton
                                                                isAddingToCartInProgress={isAddingToCartInProgress}
                                                                onClick={(e) => onAddPro(e, quantity)} />
                                                            : null
                                                    }
                                                </div>
                                                : null
                                        }
                                    </div>
                                }
                                {
                                    isOutOfStock() ?
                                        <div className="w-100 position-absolute top-0 start-0 stock rounded">
                                            <Typography variant='caption' color="white">Out of Stock</Typography>
                                        </div>
                                        : null
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    : null
            }
            {
                state.showAddProductWarningDialog === true ?
                    <AddProductWarningDialog
                        open={state.showAddProductWarningDialog}
                        handleClose={handleCloseAddProductWarningDialog}>
                        <div className="pb-3 font-weight-bold text-center">
                            <div>
                                <div className='pt-3'>
                                    {state.warningMessage}
                                </div>
                            </div>
                        </div>
                    </AddProductWarningDialog> : null
            }
            {
                state.isOpenImageZoomDialog == true ?
                    <ContentDialog
                        handleClose={hideImageZoomDialog}
                        open={state.isOpenImageZoomDialog}
                        dividers={false}
                    // title={productName ? productName : ""}
                    >
                        <div className='w-100 d-flex justify-content-center align-items-center overflow-auto image-zoom-dialog-product-box' style={{ minHeight: '350px' }}>
                            <img className='image-zoom-dialog-product-box' src={secondaryImage ? secondaryImage : ''} width='100%' />
                        </div>
                    </ContentDialog> : null
            }
        </div>
    )
}

export default ProductBox