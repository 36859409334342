import './box.loading.skeleton.scss';
import { Skeleton } from '@mui/lab';
import React from 'react';

function BoxLoadingSkeleton(props) {
    return (
        <div id='box-loading-skeleton'>
            <div className="w-100 border p-1 card-style">
                <Skeleton variant="rectangular" width={'100%'} height={118} />
                <div className='pt-2'>
                    <Skeleton width='80%' />
                    <Skeleton width="60%" />
                    <div className='d-flex justify-content-between'>
                        <Skeleton className="rounded-md py-1" width="20%" />
                        <Skeleton width="30%" />
                    </div>
                </div>
            </div>
            {/* <Skeleton variant="text" width={80} /> */}

        </div>
    )
}

export default BoxLoadingSkeleton;