import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    status: {
        borderRadius: '0px 0px 20px 20px',
        color: theme.palette.background.paper,
        background: theme.palette.primary.main,
        height: '40px'
    },
    roundedProgress: {
        borderRadius: '50px',
        height: 24,
        width: 24,
        background: theme.palette.primary.main,
        border: `1px solid ${theme.palette.background.paper}`
    }
}))
