import React, { memo } from 'react';

const BankAlfalahIframe = memo((props) => {
    const { src } = props;
    const url = decodeURIComponent(src);
    return (
        <div>
            <iframe
                src={url}
                // onLoad={onBankAlfalahFrameLoad}
                style={{
                    width: "100%",
                    minHeight: "700px",
                }}
            ></iframe>
        </div>
    )
})
export default BankAlfalahIframe