import React from "react";
import { Skeleton } from "@mui/lab";
import { Grid, Box, Typography } from "@mui/material";
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';

const OrderCheckoutSkeleton = (props) => {
  const { index } = props
  const tabletWidth = IsTabletWidth()
  const mobileWidth = IsMobileWidth()
  return (
    <Grid container className="mt-2 mb-2 pl-3">
      <Grid item xs={3} md={3}>
        <Skeleton variant="rectangular" width="50px" height="50px" />
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <div className="position-relative">
          {index === 2 ?
            <Typography variant='body1' color='dark.main' className="h-100 font-weight-bold position-absolute w-100 d-flex justify-content-center align-items-center">
              Loading Products....
            </Typography>
            : null
          }
          <Skeleton variant="rectangular" height="40px" />
        </div>
        <Skeleton
          variant="rectangular"
          height="20px"
          width="70%"
          sx={{ marginTop: "10px" }}
        />
      </Grid>
    </Grid>
  );
};

export default OrderCheckoutSkeleton;
