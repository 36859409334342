import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    btn: {
        color: theme.palette.background.paper,
        border: `1px solid ${theme.palette.background.paper}`,
        '&:hover': {
            border: `1px solid ${theme.palette.background.paper}`,
        }
    },
    cardMedia: {
        border: `0.7px solid ${theme.palette.lightBackgroundColor.main}`,
        height: "50px",
        width: "100%", //"150px",
        objectFit: "cover",
        borderRadius: '10px',
        margin: '0 auto'
    },
    darkColor: {
        color: theme.palette.dark.main,
    },
    textColor: {
        color: theme.palette.greyColor.dark,
    },
    reOrderBtn: {
        color: '#6F6DC3',
        border: '2px solid #EDF2FE',
        backgroundColor: theme.palette.background.paper,
        boxShadow: "none",
        '&:hover': {
            backgroundColor: '#EDF2FE',
            boxShadow: "none",
        }
    },
    children: {
        color: theme.palette.greyColor.dark,
        height: '50px',
    },
    merchantBox: {
        height: "50px",
        background: "linear-gradient(180deg, #1145A2 0%, #1558CE 100%)",
        borderRadius: '10px !important',
    },
    merchantName: {
        fontWeight: 100
    }
}))
