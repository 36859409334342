import React, { useState, useEffect } from 'react'
import { Box, Grid, Typography, Paper, Button, IconButton, Container, Divider, Skeleton } from '@mui/material';
import SummaryBox from '../summary.box/summary.box'
import ShoppingCartItem from 'components/common/shopping.cart.item/shopping.cart.item';
import ContentDialog from 'components/common/content.dialog/content.dialog'
import { SERVER_BASE_URL } from "view.updater/middlewares/util/api.config";
import { renderHtml } from 'components/common/util/util';
import DeleteDialog from 'components/common/delete.dialog/delete.dialog';
import { formatAmountV1, PAYMENT_OPTIONS } from 'components/common/util/util';
import PageHeading from 'components/common/page.heading/page.heading';
import { IsMobileWidth, IsTabletWidth, getImageUrl, getOrderPreparationTime, getOrderStatusDescription, scrollToTop } from "components/common/util/util";
import clsx from "clsx";
import { useStyle } from './shopping.cart.style';
import ShoppingCartHeader from './components/shopping.cart.header/shopping.cart.header';
import TapableView from "components/common/tapable.view/tapable.view";
import StatusBox from "components/common/status.box/status.box";
import QrDialog from "../qr.dialog/qr.dialog";
import LoaderButton from 'components/common/material.components/loader.button/loader.button';
import { getOrderStatusDetails, getInvoiceReceipt, getTheApplicationTheme, isStandardPlusTheme } from 'components/common/util/util';
import CountDownTimer from "components/common/count.down.timer/count.down.timer";
import { useParams } from 'react-router';
import SiteContentWrapper from 'components/common/site.content.wrapper/site.content.wrapper';
import StatusBoxV2 from 'components/common/status.box.v2/status.box.v2';
import EmailSubscription from '../../../payment.success/components/email.subscription/email.subscription';
import { getQueryStringValue } from 'components/common/util/util';
import PaymentLoadingSkeleton from 'components/common/skeletons/payment.loading.skeleton/payment.loading.skeleton';
import { isValidForm } from './validator';
import StatusBoxV3 from '../status.box.v3/status.box.v3';

const ShoppingCart = (props) => {
    const { checkOutInvoiceState, execStatusGetOrderInvoiceStatus } = props;
    const { invoiceQueryId } = useParams();
    const [state, setstate] = useState({
        deleteDialogOpen: false,
        selectedProduct: '',
        email: '',
        isTermsAndConditionsAccepted: false,
        showSubcriptionEmail: true,
        isValidEmail: true
        // isQrDialogOpen: false
    })

    const mobileWidth = IsMobileWidth();
    const tabletWidth = IsTabletWidth();
    const classes = useStyle()
    const { cartProducts } = props;

    // useEffect(() => {
    //     // if (!checkOutInvoiceState) {
    //     //     props.history.go(-3)
    //     // }
    // }, [checkOutInvoiceState])

    useEffect(() => {
        scrollToTop()
        props.getOrderInvoiceStatus({
            invoiceQueryId: invoiceQueryId
        }, invoiceQueryId)
        const showOrderDetail = getQueryStringValue("showOrderDetail")
        if (showOrderDetail == "true") {
            setstate({ ...state, showSubcriptionEmail: false })
        }
        // Handle page visibility change:
        // - If the page is hidden, pause the video
        // - If the page is shown, play the video
        document.addEventListener("visibilitychange", refreshInvoiceStatus);
        // document.addEventListener('sleep', logEvent);
        // document.addEventListener('wake', logEvent);
        return () => {
            document.removeEventListener("visibilitychange", refreshInvoiceStatus);
        }
    }, [])
    const refreshInvoiceStatus = () => {
        if (document.hidden) {

        } else {
            console.log('visibility changed')
            scrollToTop()
            props.getOrderInvoiceStatus({
                invoiceQueryId: invoiceQueryId
            }, invoiceQueryId)
        }
    }
    let totalPrice = 0;
    for (let cartProd of cartProducts) {
        if (cartProd && cartProd?.get('data')?.get('promotion') && cartProd?.get('data')?.get('promotion').get('promotion_amount')) {
            totalPrice = totalPrice + (cartProd.get('data')?.get('cost_after_discount') * cartProd.get('data')?.get('quantity'))
        }
        else
            totalPrice = totalPrice + (cartProd.get('data')?.get('cost') * cartProd.get('data')?.get('quantity'))
    }

    const onClose = () => {
        setstate({ ...state, deleteDialogOpen: false, selectedProduct: "" })
    }
    const openDeleteDialog = (product) => {
        setstate({ ...state, deleteDialogOpen: true, selectedProduct: product })
    }
    const onDelete = () => {
        props.setAddProductToCartId(state.selectedProduct?.get("data")?.get('query_id'))
        props.deleteProductFromCart({
            uniqueId: state.selectedProduct && state.selectedProduct?.get("data")?.get('query_id')
        })
        onClose();
    }
    // const goBack = () => {
    //     props.history.goBack();
    //     //props.history.push(route)
    // }
    const redirectTo = (route) => {
        props.history.push(route)
    }
    const onQuantityChange = (product, quantity) => {
        props.resetCheckOutInvoice();
        props.resetPlaceOrder();
        props.resetCreateInvoice();
        if (quantity === 0) {
            setstate({ ...state, deleteDialogOpen: true, selectedProduct: product })
        }
        else {
            props.setAddProductToCartId(product?.get("data").get('query_id'))
            props.addUpdateProductToCart({
                ...product?.get('data')?.toJS(),
                quantity: quantity,
                storeQueryId: product?.get("data").get('storeQueryId'),
                uniqueId: product?.get("data").get('query_id'),
                //merchantAddressId: ""
            })
        }
    }
    const getTotalPrice = (prod) => {
        if (prod?.get('data')?.get('promotion') && prod?.get('data')?.get('promotion').get('promotion_amount')) {
            return formatAmountV1(prod?.get('data')?.get('quantity') * prod?.get('data')?.get('cost_after_discount'))
        }
        else if (prod?.get('data')?.get('quantity') && prod?.get('data')?.get('cost')) {
            return formatAmountV1(prod?.get('data')?.get('quantity') * prod?.get('data')?.get('cost'))
        }
        else
            return ''

    }
    // const showQrDialog = () => {
    //     setstate({ ...state, isQrDialogOpen: true })
    // }
    // const hideQrDialog = () => {
    //     setstate({ ...state, isQrDialogOpen: false })
    // }
    const goBack = () => {
        props.resetCheckOutInvoice();
        props.resetPlaceOrder();
        props.resetCreateInvoice();
        //props.history.go(-3)
        props.history.push('/products')
    }
    const onChange = (name, value) => {
        setstate({ ...state, [name]: value })
    }
    const onSkip = () => {
        const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
        props.history.push(`/checkout/payment-success/${invoiceQueryId}?showOrderDetail=true&trade_type_id=${tradeTypeId}`)
        setstate({ ...state, showSubcriptionEmail: false })
    }
    const emailTheReceipt = () => {
        const validationResult = isValidForm({
            ...state,
        })
        if (validationResult.isValidEmail) {
            props.resetSendReceiptEmail();
            if (state.email && checkOutInvoiceState && checkOutInvoiceState.get("invoice")) {
                props.sendReceiptEmail({
                    toEmail: state.email,
                    isQrMe: true,
                    emailContent: getInvoiceReceipt(checkOutInvoiceState.get("invoice"), state.email),
                    subject: `Order Receipt - ${checkOutInvoiceState.get("invoice").get('merchant') && checkOutInvoiceState.get("invoice").get('merchant').get('shop_name')}`
                })
                const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
                props.history.push(`/checkout/payment-success/${invoiceQueryId}?showOrderDetail=true&trade_type_id=${tradeTypeId}`)
                setstate({ ...state, showSubcriptionEmail: false, ...validationResult })
            }
        }
        else {
            setstate({
                ...state, ...validationResult
            })
        }
    }
    const orderStatusDetail = getOrderStatusDetails(checkOutInvoiceState && checkOutInvoiceState?.get("invoice") && checkOutInvoiceState?.get("invoice")?.get("status"))
    const getOrderCreationTime = (checkOutInvoiceState) => {
        if (checkOutInvoiceState && checkOutInvoiceState.get('invoice') && checkOutInvoiceState.get('invoice').get('created_time') && checkOutInvoiceState.get('invoice').get('created_date'))
            return new Date(`${checkOutInvoiceState.get('invoice').get('created_date')} ${checkOutInvoiceState.get('invoice').get('created_time')} `)
    }
    const prePerationTme = getOrderPreparationTime(checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && checkOutInvoiceState?.get('invoice')?.get('merchant'), getOrderCreationTime(checkOutInvoiceState));
    return (
        <div>
            {state.showSubcriptionEmail ?
                <EmailSubscription
                    emailTheReceipt={emailTheReceipt}
                    onSkip={onSkip}
                    onChange={onChange}
                    {...state}
                    {...props}
                />
                :
                <SiteContentWrapper
                    classNames={clsx(orderStatusDetail.showBackgroundColor && classes.orderDispatched)}
                    {...props}
                    showBottomNavigation={true}
                    status={checkOutInvoiceState && checkOutInvoiceState.get("invoice") && checkOutInvoiceState.get("invoice").get("status")}
                    topNavigationName={orderStatusDetail.topNavigationName}
                // bottomHeight='78px'
                >
                    {/* {state.deleteDialogOpen ?
                    <DeleteDialog open={state.deleteDialogOpen}
                        alertMessage="Are you sure you want to delete?"
                        buttonText='Delete'
                        onDelete={onDelete}
                        title={`Delete ${state.selectedProduct && state.selectedProduct?.get('data')?.get('name')} from cart`}
                        onClose={onClose}>
                        If you choose to delete this item,it will no longer part of your cart.
                    </DeleteDialog>
                    : null} */}
                    {/* <Box className='d-flex justify-content-start width-max-content align-items-center cursor-pointer rounded '> */}
                    {/* <PageHeading redirect={() => props.history.goBack()} name="Shopping Cart" /> */}
                    {/* <IconButton
                    onClick={goBack}
                    classes={{ root: classes.iconColor }}
                    style={{
                        width: "30px",
                        height: "30px",
                        display: "flex"
                    }} size="small" className='material-icons'>
                    <img src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`} width='24px' />
                </IconButton> */}
                    {/* </Box> */}
                    <Grid container className={clsx(classes.grid, 'pb-5 mb-5')}>
                        {
                            checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && checkOutInvoiceState?.get('invoice')?.get('short_order_id') ?
                                <div className='w-100'>
                                    {getTheApplicationTheme().includes('STANDARD') ?
                                        <StatusBoxV2
                                            heading={checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && `${checkOutInvoiceState?.get('invoice')?.get('short_order_id')}`}
                                            name={checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && checkOutInvoiceState?.get('invoice')?.get('merchant') && checkOutInvoiceState?.get('invoice')?.get('merchant').get('name')}
                                            image={getImageUrl(checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && checkOutInvoiceState?.get('invoice')?.get('qr_data_url'))}
                                            // title={orderStatusDetail.title}
                                            // titleColor={orderStatusDetail.titleColor}
                                            // titleBackgroundColor={orderStatusDetail.titleBackgroundColor}
                                            label={!window.INDEX_NOKIA_ARENA && orderStatusDetail.showPreperationTime === true && prePerationTme > 0 ?
                                                <CountDownTimer className='w-100 d-flex justify-content-center align-items-center'
                                                    secondsRemaining={prePerationTme} />
                                                : orderStatusDetail.imageSrc ?
                                                    <div className='w-100 d-flex flex-column text-center align-items-center'>
                                                        {<img src={orderStatusDetail.imageSrc} width='70px' />}
                                                    </div> : null}
                                            message={orderStatusDetail.label ? orderStatusDetail.label : null}
                                            // labelBackgroundColor={orderStatusDetail.labelBackgroundColor}
                                            hideStatus={orderStatusDetail.hideStatus}
                                            subLabel={orderStatusDetail.subLabel}
                                            description={orderStatusDetail.description}
                                            status={checkOutInvoiceState && checkOutInvoiceState.get("invoice") && checkOutInvoiceState.get("invoice").get("status")}
                                        //subLabel={getOrderStatusDescription(checkOutInvoiceState?.get("invoice")?.get("status"))}
                                        /> : getTheApplicationTheme() === 'PROMINENT' ?
                                            <div>
                                                <StatusBoxV3
                                                    description={orderStatusDetail.description}
                                                    {...props}
                                                />
                                            </div>
                                            : null
                                    }
                                </div>
                                : execStatusGetOrderInvoiceStatus.status === "PENDING" ?
                                    <div className='w-100'>
                                        <PaymentLoadingSkeleton text='Loading order details....' />
                                    </div> : null
                        }
                        {/* {
                        cartProducts && cartProducts?.size === 0 ?
                            <div className={clsx(mobileWidth && 'mt-5', "w-100")}>
                                <Typography variant='h6' className="d-flex pt-4 justify-content-center font-weight-bold  h-auto">
                                    Shopping cart is Empty!
                                </Typography>
                                <div className="d-flex justify-content-center pt-5">
                                    <div>
                                        <Button onClick={() => goBack('/products')} variant="outlined" className="text-capitalize mr-2" >Continue Shopping</Button>
                                    </div>
                                    <div>
                                        <Button onClick={() => redirectTo('/order/history')} variant="contained" className="text-capitalize ml-2" >Go to Order History</Button>
                                    </div>
                                </div>
                            </div> : null
                    }
                    <Grid item xs={12} sm={12} lg={12} md={12} className={classes.grid}>
                        {
                        cartProducts 
                    }
                        {cartProducts && cartProducts.size && cartProducts?.size > 0 ?
                            < div >
                                <ShoppingCartHeader {...props} />
                            </div> : null
                        }
                        {
                            cartProducts && cartProducts?.map((prod, index) => {
                                return <div key={index} className="mb-2 mb-2">
                                    <ShoppingCartItem
                                        onItemDelete={() => openDeleteDialog(prod)}
                                        image={`${SERVER_BASE_URL}/${prod?.get('data')?.get('pictures')?.get(0)?.get('image_original_url')}`}
                                        productName={prod?.get('data')?.get('name')}
                                        discountAmount={prod?.get('data')?.get('promotion') && prod?.get('data')?.get('promotion').get('promotion_amount') ? prod?.get('data').get('cost_after_discount') : ''}
                                        price={prod?.get('data')?.get('cost') && formatAmountV1(prod?.get('data')?.get('cost'))}
                                        description={renderHtml(prod?.get('data')?.get('description'))}
                                        quantity={prod?.get('data')?.get('quantity')}
                                        onQuantityChange={(qty) => onQuantityChange(prod, qty)}
                                        {...props}
                                        redirectTo={redirectTo}
                                        product={prod?.get('data')}
                                        totalPrice={`${getTotalPrice(prod)} Є`} />
                                </div>
                            })
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} md={12} className='d-flex justify-content-end'>
                        <div className='w-70 d-flex justify-content-between pt-2 pb-2'>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                                component="div" color='dark.main' className='font-weight-bold' >
                                Total
                            </Typography>
                            <Typography variant={clsx(!(mobileWidth || tabletWidth) && "subtitle1", (mobileWidth || tabletWidth) && 'caption')}
                                component="div" color='dark.main' className='font-weight-bold pr-4' >
                                {`${formatAmountV1(totalPrice)} Є`}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <Divider variant="middle" orientation='horizontal' />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} md={12}>
                        <Typography variant="subtitle16N5" color="color.dark" fontWeight={700}>
                            Payment
                        </Typography>
                        <Box m={2}>
                            {PAYMENT_OPTIONS && PAYMENT_OPTIONS.map((data, index) => {
                                return <TapableView key={index}
                                    name={data.name}
                                    icon={data.icon}
                                />
                            })}
                        </Box>
                    </Grid>
                    {
                    <Grid item xs={12} sm={12} lg={3} md={3.3} >
                        <div className="pt-4 mt-3">
                            <SummaryBox redirecto={redirectTo}
                                totalItems={cartProducts?.size}
                                orderTotalPrice={totalPrice}
                                buttonTitle="Continue Shopping"
                                buttonTitle2="Proceed to Checkout"
                                {...props} />
                        </div>
                    </Grid>
                } */}
                    </Grid>
                    {/* {state.isQrDialogOpen == true ?
                <QrDialog
                    isQrDialogOpen={state.isQrDialogOpen}
                    hideQrDialog={hideQrDialog}
                    showQrDialog={showQrDialog}
                    heading={checkOutInvoiceState && checkOutInvoiceState?.get('invoice') && `#${checkOutInvoiceState?.get('invoice')?.get('short_order_id')}`}>

                    <div className='position-relative w-100 d-flex justify-content-center align-items-center'>
                        <div>
                            <Skeleton variant="rectangular" height='200px' width='200px' />
                        </div>
                        {checkOutInvoiceState && checkOutInvoiceState?.get('invoice') ?
                            <div className='w-auto d-flex justify-content-center bg-white position-absolute z-index'>
                                <img src={getImageUrl(checkOutInvoiceState?.get('invoice')?.get('qr_code_url'))}
                                    width={mobileWidth ? "170px" : '200px'} />
                            </div>
                            : null
                        }
                    </div>
                </QrDialog> : null
            } */}
                </SiteContentWrapper >
            }
        </div>
    )
}
export default ShoppingCart