import React from 'react'
import { IconButton } from '@mui/material';
import  { Search, SearchIconWrapper, StyledInputBase } from './search.box.style';

const SearchBox = () => {
    return (
            <div>
                <Search>
                    <SearchIconWrapper>
                        <IconButton size="medium" className="material-icons">
                            search
                        </IconButton>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"

                        inputProps={{ "aria-label": "search" }}
                    />
                </Search>
            </div>
    )
}

export default SearchBox
