import './product.page.header.scss';
import React, { useState } from 'react'
import { Paper, Typography } from '@mui/material'
import { useStyle } from './product.page.header.style.js'
import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
import clsx from 'clsx';
import { getImageUrl } from 'components/common/util/util';
import FavouriteIconButton from 'components/common/favourite.icon.button/favourite.icon.button';
import MerchantAddressMap from 'components/common/merchant.detail.info.dialog/components/merchant.address.map/merchant.address.map';
//FAVOURITE_CHANGES
const ProductPageHeader = (props) => {
    const { headerImage, tag, heading, description, circularImage, subHeading, footer } = props
    const classes = useStyle()
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()

    return (
        <div id='product-page-header' className={clsx((!mobileWidth || tabletWidth) && 'd-flex', mobileWidth && 'h-100 d-block')}>
            <Paper classes={{ root: classes.paper }} elevation={0} className={clsx((!mobileWidth || tabletWidth) && 'w-30 paper', mobileWidth && 'w-100 paperOnMobile', 'position-relative')}>
                {/* <FavouriteIconButton /> */}
                <div className={clsx(!(mobileWidth || tabletWidth) && 'w-100 d-flex flex-column pt-2 h-100 align-items-start position-absolute', (mobileWidth || tabletWidth) && 'w-100 d-block h-100 pt-2 position-absolute')}>
                    <div className={clsx(!(mobileWidth || tabletWidth) && 'pt-0 pr-2 pl-2', (mobileWidth || tabletWidth) && 'pt-0 pr-2 pl-2 pb-1', 'pt-4')}>
                        <img className={clsx(!(mobileWidth || tabletWidth) && 'circular-img-box', (mobileWidth || tabletWidth) && 'circular-img-box-responsive', 'border-radius-100')}
                            src={getImageUrl(circularImage)}
                            alt="Merchant Logo" />
                    </div>
                    <div className={clsx(!mobileWidth && 'w-100 pl-2 d-flex justify-content-start pb-2', mobileWidth && 'w-100 pl-2 d-flex justify-content-between mt-2', 'position-relative')}>
                        {/* <div> */}
                        {/* <Paper elevation={0} classes={{ root: classes.description }} className='p-2 mt-2'>
                                <div className='d-flex justify-content-between'>
                                    <Typography classes={{ root: classes.darkColor }}
                                        variant={clsx(!mobileWidth && "h6", mobileWidth && 'body1')}
                                        className="text-ellipsis-clamp-1 font-weight-bold text-capitalize h-auto">
                                        {heading ? heading : ""}
                                    </Typography>
                                </div>
                                <Typography classes={{ root: classes.darkColor }}
                                    variant="body2"
                                    className="text-ellipsis-clamp-1 text-capitalize h-auto">
                                    {subHeading ? subHeading : null}
                                </Typography>
                                <div className='w-100'>
                                    {description ? description : null}
                                </div>
                            </Paper> */}
                        <div className='w-100 d-flex justify-content-between position-absolute'>
                            <div className='d-flex align-items-center pt-2 w-100'>
                                {footer ? footer : null}
                            </div>
                        </div>
                        {/* </div> */}
                        <div className={clsx(!(mobileWidth || tabletWidth) && 'mt-2', tabletWidth && 'mt-2', mobileWidth && 'mt-2', 'w-100')}>
                            {/* <div onClick={props.showMerchantDetailInfoDialog} className='cursor-pointer d-flex justify-content-end pr-1'>
                                <img
                                    className='info-icon paper-root rounded-circle'
                                    src={`${process.env.PUBLIC_URL}/assets/icons/info-icon.svg`}
                                    width={30}
                                    height={30}
                                />
                            </div> */}
                            {tag ?
                                <div className='w-100 d-flex justify-content-end align-items-end mt-5'>
                                    <Typography classes={{ root: classes.tagColor }}
                                        variant="caption"
                                        className='text-Capitalize font-weight-normal width-max-content pl-3 pr-3 pt-1 pb-0 mb-2 d-flex justify-content-center align-items-center'>
                                        {tag}
                                    </Typography>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
                <img className='img-size w-100 h-100' src={getImageUrl(headerImage)}
                    alt="Merchant Logo" />
            </Paper>
            <div className={clsx((!mobileWidth || tabletWidth) && 'w-70', mobileWidth && 'w-100', 'position-relative')}>
                <div className='w-100 position-absolute z-index'>
                    <Paper elevation={0} classes={{ root: classes.description }} className='p-2 w-100'>
                        <div className='d-flex justify-content-between'>
                            <Typography classes={{ root: classes.darkColor }}
                                variant={clsx(!mobileWidth && "h6", mobileWidth && 'body1')}
                                className="text-ellipsis-clamp-1 font-weight-bold text-capitalize h-auto">
                                {heading ? heading : ""}
                            </Typography>
                        </div>
                        <Typography classes={{ root: classes.darkColor }}
                            variant="body2"
                            className="text-ellipsis-clamp-1 text-capitalize h-auto">
                            {subHeading ? subHeading : null}
                        </Typography>
                        <div className='w-100'>
                            {description ? description : null}
                        </div>
                    </Paper>
                </div>
                <div onClick={props.showMerchantDetailInfoDialog} className='w-100 cursor-pointer d-flex justify-content-end pr-1 position-absolute z-index pt-4'>
                    <img
                        className='info-icon paper-root rounded-circle'
                        src={`${process.env.PUBLIC_URL}/assets/icons/info-icon.svg`}
                        width={30}
                        height={30}
                    />
                </div>
                <MerchantAddressMap {...props} />
            </div>
        </div >
    )
}

export default ProductPageHeader