export const ADD_UPDATE_TRANSPORT_DATA = "ADD_UPDATE_TRANSPORT_DATA";
export const GET_RIDE_ESTIMATED_PRICE = "GET_RIDE_ESTIMATED_PRICE";
export const RESET_GET_RIDE_ESTIMATED_PRICE = "RESET_GET_RIDE_ESTIMATED_PRICE";
export const ASSIGN_RIDER = "ASSIGN_RIDER";
export const RESET_ASSIGN_RIDER = "RESET_ASSIGN_RIDER";
export const GET_RIDER_STATUS = "GET_RIDER_STATUS";
export const RESET_GET_RIDER_STATUS = "RESET_GET_RIDER_STATUS";
export const CANCEL_RIDER_ASSIGNMENT = "CANCEL_RIDER_ASSIGNMENT";
export const RESET_CANCEL_RIDER_ASSIGNMENT = "RESET_CANCEL_RIDER_ASSIGNMENT";
export const RESET_TRANSPORT_DATA = "RESET_TRANSPORT_DATA";
export const SET_RIDE_ESTIMATED_PRICE_LOCALLY = "SET_RIDE_ESTIMATED_PRICE_LOCALLY";
export const ADD_UPDATE_TRANSPORT_INVOICE = "ADD_UPDATE_TRANSPORT_INVOICE";
export const GET_ALL_BOOKINGS = "GET_ALL_BOOKINGS";
export const RESET_GET_ALL_BOOKINGS = "RESET_GET_ALL_BOOKINGS";

export function addUpdateTransportInvoice(data) {
    return {
        type: ADD_UPDATE_TRANSPORT_INVOICE,
        useIndexedDb: true,
        indexedDbOperationMode: "INSERT_UPDATE",
        payload: { ...data }
    };
}

export function getAllBookings(data) {
    return {
        type: GET_ALL_BOOKINGS,
        useIndexedDb: true,
        indexedDbOperationMode: "GET_ALL",
        payload: { ...data }
    };
}

export function resetGetAllBookings() {
    return {
        type: RESET_GET_ALL_BOOKINGS
    };
}

export function setRideEstimatedPriceLocally(data) {
    let updatedData = data;
    if (data.bookingDateTime) {
        const currentHour = new Date(data.bookingDateTime).getHours();
        if (currentHour >= 0 && currentHour < 7) {
            updatedData = { ...data, price: data.basePrice + 10 };
        }
        else {
            updatedData = { ...data, price: data.basePrice };
        }
    }
    else {
        const currentDate = new Date().toLocaleString("en-US", { timeZone: "Asia/Singapore" });
        const currentHour = new Date(currentDate).getHours();
        if (currentHour >= 0 && currentHour < 7) {
            updatedData = { ...data, price: data.basePrice + 10 };
        }
        else {
            updatedData = { ...data, price: data.basePrice };
        }
    }
    return {
        type: SET_RIDE_ESTIMATED_PRICE_LOCALLY,
        payload: { ...updatedData }
    };
}

export function cancelRiderAssignment(data) {
    return {
        useMerchantService: true,
        url: `zapp_go/cancel_rider_search?id=${data.id}`,
        type: CANCEL_RIDER_ASSIGNMENT,
        //method: 'POST',
        //successMessage: `Rider is successfully assigned`,
        // data,
        payload: { ...data }
    };
}
export function resetCancelRiderAssignment() {
    return {
        type: RESET_CANCEL_RIDER_ASSIGNMENT,
        payload: {
            resetActionNames: ["CANCEL_RIDER_ASSIGNMENT"]
        }
    };
}

export function addUpdateTransportData(data) {
    return {
        type: ADD_UPDATE_TRANSPORT_DATA,
        payload: { ...data }
    };
}

export function resetTransportData() {
    return {
        type: RESET_TRANSPORT_DATA
    };
}

export function getRiderStatus(data) {
    return {
        useMerchantService: true,
        url: `zapp_go/invoice_status?id=${data.id}`,
        type: GET_RIDER_STATUS,
        //method: 'POST',
        //successMessage: `Rider is successfully assigned`,
        // data,
        payload: { ...data }
    };
}
export function resetGetRiderStatus() {
    return {
        type: RESET_GET_RIDER_STATUS,
        payload: {
            resetActionNames: ["GET_RIDER_STATUS"]
        }
    };
}

export function getRideEstimatedPrice(data = {}) {
    return {
        useMerchantService: true,
        url: `zapp_go/cablah_price?pickup_lat_long=${data.pickupLatLng}&dropoff_lat_long=${data.dropOffLatLng}&type=${data.carType}&id=${window.TRANSPORT_MERCHANT_QUERY_ID}&starting_price=${data.startingPrice}`,
        type: `${GET_RIDE_ESTIMATED_PRICE}_${data.carType}`,
        payload: data
    }
}
export function resetRideEstimatedPrice() {
    return {
        type: RESET_GET_RIDE_ESTIMATED_PRICE,
        payload: {
            resetActionNames: ["GET_RIDE_ESTIMATED_PRICE_basic", "GET_RIDE_ESTIMATED_PRICE_premium", "GET_RIDE_ESTIMATED_PRICE_premium_plus"]
        }
    };
}

export function assignRider(data) {
    const timeZone = "Asia/Singapore" // Intl.DateTimeFormat().resolvedOptions().timeZone;
    let theUrl;
    // if (window.TRANSPORT_MERCHANT_QUERY_ID)
    //     theUrl = `zapp_go/cablah_invoice?recipient_name=${data.recipient_name}&recipient_phone_number=${data.recipient_phone_number}&cents=${data.order_amount}&delivery_fee=${data.delivery_fee}&merchant_id=${window.TRANSPORT_MERCHANT_QUERY_ID}&rider_note=${data.rider_note}&invoice_note=${data.invoice_note}&cash_on_delivery=${data.payment_type}&type=${data.type}&stripePaymentClientSecret=${data.stripePaymentClientSecret}&cab_type=${data.cab_type}&time_zone=${timeZone}&cablah_ride_type=${data.cablah_ride_type}&is_dynamic_pickup=${data.is_dynamic_pickup}`;
    // else
    //     theUrl = `zapp_go/cablah_invoice?recipient_name=${data.recipient_name}&recipient_phone_number=${data.recipient_phone_number}&cents=${data.order_amount}&delivery_fee=${data.delivery_fee}&merchant_id=${data.merchant_query_id}&rider_note=${data.rider_note}&invoice_note=${data.invoice_note}&cash_on_delivery=${data.payment_type}&type=${data.type}&stripePaymentClientSecret=${data.stripePaymentClientSecret}&cab_type=${data.cab_type}&time_zone=${timeZone}&cablah_ride_type=${data.cablah_ride_type}&is_dynamic_pickup=${data.is_dynamic_pickup}`;
    if (window.TRANSPORT_MERCHANT_QUERY_ID)
        theUrl = `zapp_go/cablah_invoice`;
    else
        theUrl = `zapp_go/cablah_invoice`;
    return {
        useMerchantService: true,
        url: `${theUrl}`,
        type: ASSIGN_RIDER,
        method: 'POST',
        //successMessage: `Rider is successfully assigned`,
        data: {
            recipient_name: data.recipient_name,
            recipient_phone_number: data.recipient_phone_number,
            cents: data.order_amount,
            delivery_fee: data.delivery_fee,
            merchant_id: window.TRANSPORT_MERCHANT_QUERY_ID ? window.TRANSPORT_MERCHANT_QUERY_ID : data.merchant_query_id,
            rider_note: data.rider_note,
            invoice_note: data.invoice_note,
            cash_on_delivery: data.payment_type,
            type: data.type,
            stripePaymentClientSecret: data.stripePaymentClientSecret,
            cab_type: data.cab_type,
            time_zone: timeZone,
            cablah_ride_type: data.cablah_ride_type,
            is_dynamic_pickup: data.is_dynamic_pickup,
            delivery_address: {
                description: data.delivery_address.description,
                name: data.delivery_address.name,
                building: data.delivery_address.building,
                long: data.delivery_address.long,
                lat: data.delivery_address.lat
            },
            pickup_address: {
                description: data.pickup_address.description,
                name: data.pickup_address.name,
                building: data.pickup_address.building,
                long: data.pickup_address.long,
                lat: data.pickup_address.lat
            },
            advance_booking: data.advance_booking,
            advance_booking_time: data.advance_booking_time,
            scheduled_time_difference: data.scheduled_time_difference
        },
        payload: { ...data }
    };
}
export function resetAssignRider() {
    return {
        type: RESET_ASSIGN_RIDER,
        payload: {
            resetActionNames: ["ASSIGN_RIDER"]
        }
    };
}