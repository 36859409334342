import React from 'react';
import PopoverDropdown from './common/popover.dropdown/popover.dropdown'

export default function ProductSize(props) {
  return (
   <PopoverDropdown
      placeholder="Select Size"
      items={[
        "Size 1",
        "Size 2",
        "Size 3",
        "Size 4",
        "Size 5",
        "Size 6",
        "Size 7",
        "Size 8",
        "Size 9",
        "Size 10"
      ]}
    />
  )
}
