import React, { useEffect, useState, useContext } from 'react'
import { Button, Grid, Typography, IconButton, CircularProgress } from '@mui/material'
import CustomButton from 'components/common/custom.button/custom.button'
import TabButton from 'components/common/tab.button/tab.button'
import OrderCard from 'components/common/order.card/order.card'
import { Box } from '@mui/system'
import { IsMobileWidth, checkExecutionStatus } from 'components/common/util/util'
import clsx from 'clsx'
import { AppContext } from '../../main/container';
import { GET_ACTIVE_ORDERS, GET_PAST_ORDERS, GET_CANCELLED_ORDERS } from 'view.updater/actions/order.actions';
import ActiveOrders from './active.orders'
import PastOrders from './past.orders'
import CancelledOrders from './cancelled.orders'
import PageHeading from 'components/common/page.heading/page.heading';
import { GET_PRODUCTS_BY_ORDER_ID, ADD_UPDATE_PRODUCT_TO_CART } from 'view.updater/actions/products.actions'
import { useStyle } from './history.list.style';
import { useTranslation } from "react-i18next";

export default function HistoryList(props) {
    const context = useContext(AppContext);
    const { activeOrdersCount } = props;
    const classes = useStyle()
    const execStatusActiveOrders = checkExecutionStatus(context, GET_ACTIVE_ORDERS);
    const execStatusPastOrders = checkExecutionStatus(context, GET_PAST_ORDERS);
    const execStatusCancelledOrders = checkExecutionStatus(context, GET_CANCELLED_ORDERS);
    const execStatusGetProductsByOrderId = checkExecutionStatus(context, GET_PRODUCTS_BY_ORDER_ID);
    const execStatusAddUpdateProductToCart = checkExecutionStatus(context, ADD_UPDATE_PRODUCT_TO_CART);
    const { t } = useTranslation("global");

    const mobileWidth = IsMobileWidth()
    const [state, setState] = useState({
        selectedTab: "ACTIVE"
    })
    const setSelectedTab = (tab) => {
        setState({ ...state, selectedTab: tab })
        switch (tab) {
            case "PAST":
                props.getPastOrders({
                    page: 1
                });
                break
            case "CANCELLED":
                props.getCancelledOrders({
                    page: 1
                });
                break
            case "ACTIVE":
                props.getActiveOrders({
                    page: 1
                });
                break
        }
    }
    useEffect(() => {
        props.getActiveOrders({
            page: 1
        });
        props.getPastOrders({
            page: 1
        });
        props.getCancelledOrders({
            page: 1
        });
    }, [])

    return (
        <Grid container className={clsx('mb-4', classes.container)}>
            <Box className='d-flex justify-content-start width-max-content pr-3 align-items-center cursor-pointer pb-1 rounded'>
                {/* <PageHeading name="Order History" /> */}
            </Box>
            <Grid xs={12} sm={12} md={12} className='w-100 pt-2 pb-2'>
                <Grid container spacing={1} className={clsx(classes.tabs, window.TRADE_TYPE_IMAGE_URL ? 'top-50' : 'top-0', 'position-sticky pt-2 pb-2 z-index-1200')}>
                    <Grid item onClick={() => setSelectedTab("ACTIVE")}>
                        <TabButton
                            count={activeOrdersCount ? activeOrdersCount : ''}
                            title={window.INDEX_NOKIA_ARENA === true ? t("messages.active") : 'Active'} selected={state.selectedTab === "ACTIVE"}>
                            {window.INDEX_NOKIA_ARENA === true ? t("messages.active") : 'Active'}
                        </TabButton>
                    </Grid>
                    <Grid item onClick={() => setSelectedTab("PAST")} >
                        <TabButton title={window.INDEX_NOKIA_ARENA === true ? t("messages.past") : 'Past'} selected={state.selectedTab === "PAST"}>
                            {window.INDEX_NOKIA_ARENA === true ? t("messages.past") : 'Past'}
                        </TabButton>
                    </Grid>
                    <Grid item onClick={() => setSelectedTab("CANCELLED")}>
                        <TabButton title={window.INDEX_NOKIA_ARENA === true ? t("messages.cancelled") : 'Cancelled'} selected={state.selectedTab === "CANCELLED"}>
                            {window.INDEX_NOKIA_ARENA === true ? t("messages.cancelled") : 'Cancelled'}
                        </TabButton>
                    </Grid>
                </Grid>
                <div className={clsx(mobileWidth && 'pl-4 pr-4', 'w-100')}>
                    {
                        state.selectedTab === 'ACTIVE' ?
                            <ActiveOrders {...props}
                                execStatusAddUpdateProductToCart={execStatusAddUpdateProductToCart}
                                execStatusGetProductsByOrderId={execStatusGetProductsByOrderId}
                                execStatus={execStatusActiveOrders} /> :
                            state.selectedTab === 'PAST' ?
                                <PastOrders {...props}
                                    execStatusAddUpdateProductToCart={execStatusAddUpdateProductToCart}
                                    execStatus={execStatusPastOrders}
                                    execStatusGetProductsByOrderId={execStatusGetProductsByOrderId} /> :
                                state.selectedTab === "CANCELLED" ?
                                    <CancelledOrders {...props}
                                        execStatusAddUpdateProductToCart={execStatusAddUpdateProductToCart}
                                        execStatusGetProductsByOrderId={execStatusGetProductsByOrderId}
                                        execStatus={execStatusCancelledOrders} />
                                    : null
                    }
                </div>
            </Grid>
        </Grid>
    )
}
