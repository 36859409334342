import { createSelector } from 'reselect';

const selectOrders = (state) => {
    return state.orders;
}
const makeSelectActiveOrders = () => createSelector(
    selectOrders,
    (ordersState) => ordersState.get('activeOrders')
);
const makeSelectPastOrders = () => createSelector(
    selectOrders,
    (ordersState) => ordersState.get('pastOrders')
);
const makeSelectCancelledOrders = () => createSelector(
    selectOrders,
    (ordersState) => ordersState.get('cancelledOrders')
);
const makeSelectActiveOrdersCount = () => createSelector(
    selectOrders,
    (ordersState) => ordersState.get('activeOrdersCount')
);
export {
    selectOrders,
    makeSelectActiveOrders,
    makeSelectPastOrders,
    makeSelectCancelledOrders,
    makeSelectActiveOrdersCount
};
