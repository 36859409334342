import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
  oldPriceStyle: {
    color: theme.palette.greyColor.main,
    textDecoration: "line-through"
  },
  textGray: {
    color: "gray",
  },
  cardStyle: {
    boxShadow: 'none'
  },
  description: {
    color: theme.palette.greyColor.dark
  }
}));
