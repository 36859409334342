import { put, select, call } from 'redux-saga/effects';
import { addUpdateAppLoadersStatus } from 'view.updater/actions/app.actions';
import { push } from 'connected-react-router';
import { LOGIN, LOGOUT, login, updateUserAuthData, authenticateAnonymously, CREATE_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS } from '../actions/auth.actions';
import {
    checkOutInvoice, CREATE_INVOICE, CHECKOUT_INVOICE,
    placeOrder, deleteAllCartProducts, PLACE_ORDER,
    GET_STORE_PRODUCTS_CATEGORIES, setSelectedStoreProductCategory,
    getProducts, sendOrderNotificationToMerchant, GET_INDUSTRY_STORES, getStoreProductCategories, initiateNetaXeptPayment, sendReceiptEmail
} from '../actions/products.actions';
import { selectProducts } from '../selectors/products.selectors';
import processIndexedDbQuery from './process.indexed.db.query';
import { selectAuthorization } from '../selectors/auth.selectors';
import { DO_SIGN_UP, doSignUp } from '../actions/sign.up.actions';
import { getQueryStringValue } from 'components/common/util/util';
import { isNeedToUpdateCache } from '../../components/common/util/cache.clear';
import { GET_APP_VERSION, forceUpdateAppVersion } from '../actions/app.actions';
import { SERVER_P20_URL, SONERI_INTERNET_BANKING_URL } from './util/api.config';
import { ASSIGN_RIDER, addUpdateTransportInvoice } from '../actions/transport.actions';
import { getRideInvoiceReceipt } from '../../components/common/util/util';

const navigateToNewTab = (href) => {
    var a = document.createElement('a');
    a.href = href;
    a.setAttribute('target', '_blank');
    a.click();
}
const getDefaultAddressId = (ath) => {
    if (ath?.get('auth')?.get('user')?.get('home_address')?.get('id')) {
        return ath?.get('auth')?.get('user')?.get('home_address')?.get('id')
    }
    else if (ath?.get('auth')?.get('user')?.get('work_address')?.get('id')) {
        return ath?.get('auth')?.get('user')?.get('work_address')?.get('id')
    }
    else if (ath?.get('auth')?.get('user')?.get('other_addresses') && ath?.get('auth')?.get('user')?.get('other_addresses').size > 0) {
        return ath?.get('auth')?.get('user')?.get('other_addresses').get(0) ? ath?.get('auth')?.get('user')?.get('other_addresses').get(0).get('id') : ""
    }
    else {
        return ""
    }
}
export default function* allAfterMiddleware(action) {
    if (action.useIndexedDb && action.url) {
        const indexedDbQueryResponse = yield call(processIndexedDbQuery, action);
        if (indexedDbQueryResponse) {
            yield put(addUpdateAppLoadersStatus(action.baseType, 'DONE', action.errorId));
            yield put(addUpdateAppLoadersStatus(action.baseType, 'FINNISH', action.errorId));
            yield put({ ...action, useIndexedDb: false, indexedDbQueryResponse })
        }
    }
    else {
        yield put(addUpdateAppLoadersStatus(action.baseType, 'DONE', action.errorId));
        yield put(addUpdateAppLoadersStatus(action.baseType, 'FINNISH', action.errorId));
    }
    switch (action.baseType) {
        case LOGIN: // LOGIN_CHANGES
            window.document.title = "Zapp PK"
            const ath = yield select((selectAuthorization));
            yield put(updateUserAuthData({
                selectedAddressId: getDefaultAddressId(ath)
            }));
            let industryId = ath && ath.get('auth') && ath.get('auth').get('industry_types') && ath.get('auth').get('industry_types')?.get(0) && ath.get('auth').get('industry_types')?.get(0).get('id');
            if (industryId) {
                yield put(push(`/products?industryId=${industryId}`));
            }
            else {
                yield put(push('/products'));
            }
            break;
        case ASSIGN_RIDER:
            if (action.response) {
                if(action.payload.email) {
                    let transactionId = action.payload.stripePaymentClientSecret && action.payload.stripePaymentClientSecret.split("_secret_");
                    yield put(sendReceiptEmail({
                        toEmail: action.payload.email,
                        isQrMe: true,
                        emailContent: getRideInvoiceReceipt({
                            pickupAddressShortDescription: window.localStorage.getItem("pickupAddressShortDescription"),
                            deliveryAddressShortDescription: window.localStorage.getItem("deliveryAddressShortDescription"),
                            totalAmount: action.payload.delivery_fee,
                            fullName: action.payload.recipient_name,
                            durationText: action.payload.durationText,
                            distanceText: action.payload.distanceText,
                            transactionId: transactionId && transactionId[0]
                        }),
                        subject: `Cablah Receipt`
                    }))
                }
                yield put(addUpdateTransportInvoice({
                    uniqueId: action.response && action.response.invoice_id,
                    ...action.response,
                    ...action.payload,
                    pickupAddressShortDescription: window.localStorage.getItem("pickupAddressShortDescription"),
                    deliveryAddressShortDescription: window.localStorage.getItem("deliveryAddressShortDescription"),
                    createdAt: new Date()
                }));
                yield put(push(`/transport/confirm-booking/${action.response.invoice_id}`));
            }
            break;
        case LOGOUT:
            yield put(push('/home'));
            break;
        case CREATE_ADDRESS:
            const authState = yield select((selectAuthorization));
            if (authState) {
                let authData = authState?.get("auth")?.toJS();
                if (authData) {
                    window.localStorage.setItem("auth", JSON.stringify(authData));
                }
            }
            break;
        case UPDATE_ADDRESS:
            const authSt = yield select((selectAuthorization));
            if (authSt) {
                let authData = authSt?.get("auth")?.toJS();
                if (authData) {
                    window.localStorage.setItem("auth", JSON.stringify(authData));
                }
            }
            break;
        case DELETE_ADDRESS:
            const authS = yield select((selectAuthorization));
            if (authS) {
                let authData = authS?.get("auth")?.toJS();
                if (authData) {
                    window.localStorage.setItem("auth", JSON.stringify(authData));
                }
            }
            break;
        case GET_STORE_PRODUCTS_CATEGORIES:
            const storeProductCategories = yield select((selectProducts));
            const selectedStoreProductCategory = storeProductCategories.get('selectedStoreProductCategory');
            if (!selectedStoreProductCategory) {
                const ctegry = storeProductCategories.get('storeProductCategories').find((category) => category.get('storeQueryId') == action.payload.storeQueryId);
                let ctIndex = ctegry?.get('listCategory')?.get('categories').findIndex((cat) => cat.get('id') == action.payload.categoryId);
                if (ctIndex > -1) {
                    if (!action.payload.dontSetSelectedProduct) {
                        if (action?.response?.categories)
                            yield put(setSelectedStoreProductCategory({
                                ...action.response.categories[ctIndex],
                                storeQueryId: action?.payload?.storeQueryId,
                                queryStringName: action?.payload?.name
                            }))
                    }
                }
                else {
                    if (!action.payload.dontSetSelectedProduct) {
                        if (action?.response?.categories)
                            yield put(setSelectedStoreProductCategory({
                                ...action.response.categories[0],
                                storeQueryId: action?.payload?.storeQueryId,
                                queryStringName: action?.payload?.name
                            }))
                    }
                }
            }
            else {
                if (!action.payload.dontSetSelectedProduct) {
                    if (action.payload.name) { // for default query string search
                        yield put(getProducts({
                            storeQueryId: action.payload.storeQueryId,
                            categoryId: selectedStoreProductCategory.get('id'),
                            name: action.payload.name,
                            page: 1
                        }))
                    }
                    else {
                        for (let subCat of selectedStoreProductCategory.get('sub_categories')) {
                            yield put(getProducts({
                                storeQueryId: action.payload.storeQueryId,
                                categoryId: selectedStoreProductCategory.get('id'),
                                subCategoryId: subCat.get('id'),
                                page: 1
                            }))
                        }

                    }
                }
            }
            break;
        case CREATE_INVOICE:
            yield put(checkOutInvoice({
                invoiceId: action?.response?.invoice?.query_id
            }, {
                email: action.payload.email,
                merchantAddressId: action.payload.merchantAddressId,
                paymentMethod: action.payload.paymentMethod,
                sessionId: action.payload.sessionId,
                cardToken: action.payload.cardToken
            }))
            break;
        case GET_INDUSTRY_STORES:
            if (action.response && action.response.companies && action.response.companies.length > 0) {
                // for (var i = 0; i < action.response.companies.length; i++) {
                //     yield put(getStoreProductCategories({
                //         storeQueryId: action.response.companies[i] && action.response.companies[i].id,
                //         dontSetSelectedProduct: true
                //     }))
                // }
            }
            break;
        case CHECKOUT_INVOICE:
            const atho = yield select((selectAuthorization));
            let invoiceItemAttributes = [];
            const cashChannel = atho && atho?.get('auth')?.get('user')?.get('channels')?.find((chn) => chn.get('type') === "Channel::Cash");
            const cardChannel = atho && atho?.get('auth')?.get('user')?.get('channels')?.find((chn) => chn.get('type') === "Channel::Card");
            if (action.response?.invoice?.invoice_items) {
                for (let invItems of action.response?.invoice?.invoice_items) {
                    invoiceItemAttributes.push({
                        id: invItems.id,
                        product_query_id: invItems.product_query_id,
                        quantity: invItems.quantity,
                        size: invItems.size ? invItems.size : {},
                        color: invItems.color ? invItems.color : {},
                        item_description: invItems.item_description,
                        //instruction: state.notesZappMan
                    })
                }
            }
            //MOBILE_PAY_CHANGES, COMMENT AND UNCOMMENT TO ENABLE/DISABLE
            const pyAmout = parseFloat(action.response?.invoice?.payble_amount)
            if (pyAmout && pyAmout > 0 && action?.payload?.paymentMethod === "PAYMENT_BY_SONERI") {
                navigateToNewTab(`${SONERI_INTERNET_BANKING_URL}/login?invoiceQueryId=${action.response.invoice.query_id}&amount=${action.response.invoice.payble_amount}&orderId=${action.response.invoice.short_order_id}&type=PAY_BY_SONERI&redirectUrl=${SERVER_P20_URL}/user/soneri-payment/success/${action.response.invoice.query_id}/html`)
            }
            else if (pyAmout && pyAmout > 0 && action?.payload?.paymentMethod !== "PAYMENT_BY_CARD_BANK_ALFALAH") {
                yield put(initiateNetaXeptPayment({
                    shortOrderId: action.response?.invoice?.short_order_id,
                    amount: action.response?.invoice?.cents,
                    invoiceQueryId: action.response?.invoice.query_id,
                    merchantQueryId: action.response?.invoice?.merchant?.query_id,
                    transactionId: `${action.response?.invoice?.short_order_id}---${pyAmout * 100}`,
                    paymentMethod: action?.payload?.paymentMethod
                }))
            }
            else {
                yield put(placeOrder({
                    invoice: {
                        voucher_id: "",
                        invoice_items_attributes: invoiceItemAttributes,
                        checkout_type: 'pickup',
                        invoice_note: "",
                        invoice_tip: 0,
                        address_id: action.payload.merchantAddressId,
                        recipient_name: action.payload.email,
                        recipient_phone_number: atho?.get('user')?.get('mobile_number')
                    },
                    session_id: action.payload.sessionId,
                    save_card: false,
                    //card_token: action.payload.cardToken,
                    channel_query_id: action.payload.sessionId ? cardChannel && cardChannel?.get('query_id') : cashChannel && cashChannel?.get('query_id')
                }, action?.response?.invoice?.query_id));
            }
            break;
        case PLACE_ORDER:
            yield put(deleteAllCartProducts());
            yield put(sendOrderNotificationToMerchant({
                module: "sendMerchantNotification",
                subModule: "sendMerchantNotification",
                moduleCode: '9017',
                merchantQueryId: action.response && action.response.invoice && action.response.invoice.merchant && action.response.invoice.merchant.query_id,
                queryId: action.response && action.response.invoice && action.response.invoice.merchant && action.response.invoice.merchant.query_id
            }));
            yield put(authenticateAnonymously({
                device_uuid: window.localStorage.getItem("visitorId") ? window.localStorage.getItem("visitorId") : 123
            }))
            const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
            yield put(push(`/checkout/payment-success/${action.response && action.response.invoice && action.response.invoice.query_id}?trade_type_id=${tradeTypeId}`));
            break;
        case DO_SIGN_UP:
            if (action.payload && action.payload.activeStep == 3 && action.payload.pin && action.payload.mobileNumber) {
                yield put(login({
                    login_id: action.payload.mobileNumber?.replace(/\s/g, ''),
                    password: action.payload.pin
                }))
            }
            if (action.payload.activeStep == 1 && action.response && action.response.user && action.response.user.status == "pending") {//if user account is deleted and again signup
                yield put(doSignUp({
                    ...action.data,
                    activeStep: 1,
                    sendOtp: true,
                    userId: action.response.user.query_id
                }));
            }
            break;
        case GET_APP_VERSION:
            const isNeedToUpdate = isNeedToUpdateCache(action.response && action.response.version);
            if (isNeedToUpdate === true) {
                yield put(forceUpdateAppVersion({
                    newVersion: action.response && action.response.version
                }));
            }
            break;
        default:
            return;
    }
}