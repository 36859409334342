import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
    grid: {
        // maxHeight: '60vh !important',
        // overflow: 'auto'
    },
    merchantsWrapper: {
        background: "#FFF",
        borderRadius: "0px 0px 10px 10px",
    },
    linearBackground: {
        background: "linear-gradient(180deg, rgba(0, 35, 104, 0.9) 23.96%, rgba(255, 72, 83, 0.4) 100%)",
        borderRadius: "20px",
        // padding: "20px",
        paddingTop: "50px"
    },
    logoBg: {
        marginTop: "-45px",
        // objectFit:"cover",
        marginBottom: "-10px"
    },
    logo: {
        marginTop: "60px",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        height: "100%"
    },
    productsWrapper: {
        // height: "45vh"
    },
    backgroundImage: {
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        // backgroundBlendMode: "screen, hard-light",
        // mixBlendMode: "normal"
        // height: '100vh',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // objectFit: 'cover',
        backgroundPosition: 'left center',
        borderRadius: '0px',
    },
    bgColorStyle: {
        background: 'linear-gradient(180deg, rgba(0, 35, 104, 0.9) 23.96%, rgba(255, 72, 83, 0.4) 100%)',
        opacity: '86%'
    },
    mobileBackgroundImage: {
        // height: '800px',
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'left center',
        borderRadius: '0px'
    },
    scrollDiv: {
        marginTop: '145px',
        zIndex: 1
    },

    orderDetailInfo: {
        "& h1": {
            fontSize: "24px",
            fontWeight: 600
        },
        "& ol li": {  
            fontSize: "18px",
        },
        "& ol": {  
            paddingLeft: "17px",
        },
        "& strong": {
            fontSize: "24px",
            fontWeight: 600
        },
        "& p": {  
            fontSize: "18px",
        },
    },
    
}));