export const GET_USER_VOUCHERS = "GET_USER_VOUCHERS"
export const RESET_USER_VOUCHERS = "RESET_USER_VOUCHERS"
export const RE_SEND_OTP = "RE_SEND_OTP";
export const RESET_RESEND_OTP = "RESET_RESEND_OTP";

export function reSendOTP(data) {
    if (data.userId)
        return {
            url: `users/${data.userId}/resend_otp`,
            type: RE_SEND_OTP,
            method: 'PUT'
        };
    else {
        return { // for forget password resend otp
            url: "users/forgot_password",
            type: RE_SEND_OTP,
            data,
            method: "POST",
        };
    }
}

export function resetResendOTP() {
    return {
        type: RESET_RESEND_OTP,
        payload: {
            resetActionNames: ["RE_SEND_OTP"]
        }
    }
}

export function getUserVouchers(data) {
    return {
        url: `users/${data.userId}/vouchers?merchant_query_id=${data.merchantQueryId}`,
        type: GET_USER_VOUCHERS,
        payload: { ...data }
    };
}

export function resetUserVouchers() {
    return {
        type: RESET_USER_VOUCHERS,
        payload: {
            resetActionNames: ["GET_USER_VOUCHERS"]
        }
    }
}