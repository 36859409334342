import { makeStyles } from '@mui/styles';
export const useStyle = makeStyles(theme => ({
    drawer: {
        width: '100%'
    },
    contentOnTablet: {
        width: '250px',
        boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.15)',
    },
    contentOnMobile: {
        width: '100%',
        boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    listTextStyle: {
        margin: 0,
        fontFamily: 'Nunito',
        fontWeight: 300,
        fontSize: 14,
        // '&:hover': {
        //     // backgroundColor: theme.palette.primary.light,
        //     color: theme.palette.background.paper
        // },
    },
    // listItemStyle: {
    //     backgroundColor: theme.palette.background.paper,
    //     color: theme.palette.primary.main,
    //     '&:hover': {
    //         backgroundColor: theme.palette.primary.light,
    //         color: theme.palette.background.paper
    //     },
    //     '&:selected': {
    //         backgroundColor: theme.palette.primary.light,
    //         color: theme.palette.background.paper
    //     }
    // }
}))