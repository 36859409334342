import React , { useEffect } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getInjectors from './sagaInjectors';
/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param {string} key A key of the saga
 * @param {function} saga A root saga that will be injected
 * @param {string} [mode] By default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
 * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
 *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
 *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 *
 */
export default ({ key, saga, mode }) => (WrappedComponent) => {
  const InjectSaga = (props) => {
    const injectors = getInjectors(props.store);
    useEffect(()=>{
      const { injectSaga } = injectors;
      injectSaga(key, { saga, mode },props);
      return () => {
        const { ejectSaga } = injectors;
        ejectSaga(key);
      }
    },[]);

    return (<WrappedComponent {...props} />)
  }
  return hoistNonReactStatics(InjectSaga, WrappedComponent);
};
