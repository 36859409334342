import "./industry.list.scss";
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Pagination,
    Navigation,
    Mousewheel,
    Keyboard,
} from "swiper";
import CategoryChip from 'components/common/category.chip/category.chip'
// import { IsMobileWidth, IsTabletWidth } from 'components/common/util/util';
// import { SERVER_BASE_URL } from "view.updater/middlewares/util/api.config";
// import { Typography } from "@mui/material";
import { getQueryStringValue } from 'components/common/util/util';
import { getSelectedAddressLatLng, getImageUrl } from "components/common/util/util";
//import { useStyle } from './industry.list.styles'
export const IndustryList = (props) => {
    SwiperCore.use([Pagination, Navigation, Mousewheel, Keyboard]);
    // const mobileWidth = IsMobileWidth()
    // const tabletWidth = IsTabletWidth()
    const { auth } = props;
    const [state, setState] = useState({
        selectedIndustry: '',
    })
    const onSelectedIndustryChange = (industry) => {
        props.history.push(`/products?industryId=${industry?.get('id')}`)
        setState({ ...state, selectedIndustry: industry })
        props.resetIndustryStores();
        props.updateSelctedStorePage(1);
        let location = getSelectedAddressLatLng(auth);
        props.getIndustryStores({
            industryId: industry?.get('id'),
            selectedIndustry: industry,
            page: 1,
            lat: location.lat,
            long: location.long
        })
    }
    const industryId = getQueryStringValue("industryId");
    useEffect(() => {
        if (industryId) {
            let selectedIndustry = auth && auth.get('industry_types')?.find((ty) => ty.get('id') == industryId);
            setState({ ...state, selectedIndustry: selectedIndustry })
            let location = getSelectedAddressLatLng(auth);
            props.getIndustryStores({
                industryId: selectedIndustry?.get('id'),
                selectedIndustry: selectedIndustry,
                page: 1,
                lat: location.lat,
                long: location.long
            })
        }
        else {
            setState({ ...state, selectedIndustry: auth && auth.get('industry_types') && auth.get('industry_types')?.get(0) })
            let location = getSelectedAddressLatLng(auth);
            props.getIndustryStores({
                industryId: auth.get('industry_types')?.get(0)?.get('id'),
                selectedIndustry: auth && auth.get('industry_types') && auth.get('industry_types')?.get(0),
                page: 1,
                lat: location.lat,
                long: location.long
            })
        }
        return () => {
            props.resetIndustryStores();
        }
    }, [])
    useEffect(() => {
        if (auth && auth.get("selectedAddressId")) {
            if (industryId) {
                let location = getSelectedAddressLatLng(auth);
                let selectedIndustry = auth && auth.get('industry_types')?.find((ty) => ty.get('id') == industryId);
                props.getIndustryStores({
                    industryId: selectedIndustry?.get('id'),
                    selectedIndustry: selectedIndustry,
                    page: 1,
                    lat: location.lat,
                    long: location.long
                })
            }
        }
    }, [auth && auth.get("selectedAddressId")])
    //const classes = useStyle()
    return (
        <div id="industry-list" className="w-100 pt-2 pl-2 pr-2 pb-2">
            <Swiper
                slidesPerView={'auto'}
                mousewheel={true}
                navigation={true}
                keyboard={true}
                freeMode={true}
                className="swiper pl-5 pr-5">
                {
                    auth && auth.get('industry_types') && auth.get('industry_types').map((industry, index) => {
                        return <>
                            <SwiperSlide key={index} onClick={() => onSelectedIndustryChange(industry)} className="swiper-slide pl-2 mr-2 ml-2" >
                                <CategoryChip
                                    selected={state.selectedIndustry && state.selectedIndustry.get('id') === industry.get('id') ? true : false}
                                    image={getImageUrl(industry.get('image'))}
                                    title={industry.get('name')} />
                            </SwiperSlide>
                        </>
                    })
                }
            </Swiper>
            {/* <Typography className="pl-3 pt-3" variant="h6">
                <img src={`${SERVER_BASE_URL}/${activeIndustry && activeIndustry.get('image')}`} width={'80px'} /> {state.selectedIndustry && state.selectedIndustry?.get('name')}
            </Typography> */}
        </div >
    );
};
export default IndustryList;
