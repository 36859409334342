import './nokia.arena.terms.and.conditions.scss';
import React, { useEffect } from 'react'
import { Container, Typography } from '@mui/material'
import clsx from 'clsx';
import { IsMobileWidth, IsTabletWidth, scrollToTop } from 'components/common/util/util';
import { useStyle } from './nokia.arena.terms.and.conditions.style'

const NokiaArenaTermsAndConditions = () => {
    const classes = useStyle()
    const tabletWidth = IsTabletWidth()
    const mobileWidth = IsMobileWidth()
    useEffect(() => {
        document.getElementById('nokia-arena-terms-and-conditions-dialog') &&
            document.getElementById('nokia-arena-terms-and-conditions-dialog').addEventListener('touchmove', function (event) {
                event.stopPropagation();
            });
        scrollToTop()
    }, [])
    return (
        <div id='nokia-arena-terms-and-conditions' className={clsx(!(mobileWidth || tabletWidth) && '', 'pb-5', (tabletWidth || mobileWidth) && 'pt-2 pb-2')}>
            <Container id='nokia-arena-terms-and-conditions-dialog' maxWidth='md' className='content-style-2 h-100'>
                <div className='overflow-auto term-and-condition-style'>
                    <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', tabletWidth && 'body1', mobileWidth && 'body1')}>
                        PRIVACY POLICY AND TERMS OF USE NOKIA ARENA SERVICE
                        <br />
                        PRIVACY STATEMENT
                    </Typography>
                    <div className='pt-3'>
                        <p className={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            This privacy statement describes the processing of personal data of the Nokia Arena service and data collected from the use of the service. The subject of processing is our customers' data in the service.  </p>
                        <p className={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            REGISTER HOLDER CONTACT INFORMATION
                        </p>
                        <Typography variant='body1' color='dark.main'>
                            KOy Tampereen Montoimiareena (Y-tunnus: 2792951-4) hereafter referred as ”Nokia Arena”.
                        </Typography>
                        <Typography variant='body1' color='dark.main'>
                            Kansikatu 3, 33100 Tampere
                        </Typography>
                        <Typography variant='body1' color='dark.main'>
                            <a target='_blank' href="mailto:info@areenatampere.fi">info@areenatampere.fi</a>
                        </Typography>
                        <Typography variant='body1' color='dark.main'>
                            +358 50 511 7668
                            <br />
                            and behalf of the Nokia Arena partners.
                        </Typography>
                        <Typography className='pt-3 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            WHAT INFORMATION DO WE COLLECT AND FOR WHAT PURPOSE?
                        </Typography>
                        <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            We only collect information from you that is necessary for the operation and development of our service.
                        </Typography>

                        <Typography className='pt-2 pb-2 font-weight-bold' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Information we collect directly from you
                        </Typography>
                        <ul>
                            <li>
                                <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                                    Of the information you use for payment, we only store the validity period of the payment card and the last 4 digits of the payment card. The information does not include the complete card number, security code or other information required for online payment. We do not charge the card ourselves. All payments are processed by Nets. Paying in that service is safe, as all information about the payment transaction is transmitted using an encrypted connection so that no outside party can see the payment transaction information.
                                </Typography>
                            </li>
                            <li>
                                <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                                    We store your purchase history, i.e. receipts of purchase transactions as required by the Accounting Act, and we use the information anonymously to profile purchasing behavior. When you withdraw your consent to the processing of your data, the receipts become anonymous and can no longer be linked to you.
                                </Typography>
                            </li>
                        </ul>
                        <Typography className='pt-2 pb-2 font-weight-bold' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Information we collect from third parties or when you use the service
                        </Typography>
                        <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Third parties may collect or receive information from the Nokia Arena service and other applications and use this information to provide analytics services and target advertisements.
                        </Typography>
                        <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            You consent to the collection of this information by using the service and accepting this privacy policy.
                        </Typography>
                        <Typography className='pt-2 font-weight-bold' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'body2')} >
                            WHAT RIGHTS DO YOU HAVE AND HOW CAN YOU USE THEM?
                        </Typography>
                        <Typography className='font-weight-bold' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            You have the following rights:
                        </Typography>
                        <ul>
                            <li>
                                <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                                    The right to get access to personal data: You have the right to access the personal data we hold. However, the right to access information may have to be restricted due to legislation and the protection of the privacy of other persons.
                                </Typography>
                            </li>
                            <li>
                                <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                                    Right to rectification of data: You have the right to request the correction of incorrect or incomplete data.
                                </Typography>
                            </li>
                            <li>
                                <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                                    The right to delete data: You have the right to request the deletion of your data. Data can be deleted, for example, in those cases when you withdraw your consent to the processing of your data, and there is no other basis for the processing, or when you object to the processing of the data and there is no other basis for continuing the processing.
                                </Typography>
                            </li>
                            <li>
                                <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                                    Right to restrict processing: You have the right to restrict the processing of your personal data.
                                </Typography>
                            </li>
                            <li>
                                <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                                    Right to object: You have the right to object to the processing of your data.
                                </Typography>
                            </li>
                            <li>
                                <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                                    The right to data portability: You have the right to receive the information you provide in a machine-readable format. The right applies to personal data that has been processed automatically on the basis of a contract or consent.
                                </Typography>
                            </li>
                            <li>
                                <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                                    The right to withdraw consent: You have the right to withdraw your consent at any time, without affecting the legality of the processing carried out before its withdrawal. Withdrawing consent may affect our ability to provide services.
                                </Typography>
                            </li>
                            <li>
                                <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                                    The right to file a complaint with a supervisory authority: You also have the right to file a complaint with a supervisory authority if you suspect that your personal data is being used inappropriately or illegally.
                                </Typography>
                            </li>
                        </ul>
                        <Typography className='pt-2 pb-2 font-weight-bold' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Exercising rights
                        </Typography>
                        <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            To exercise your rights, contact to Nokia Arena customer service email.
                        </Typography>
                        <Typography className='pt-3 pb-2 font-weight-bold' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            WHAT DO WE USE THE INFORMATION FOR AND ON WHAT BASIS DO WE PROCESS IT?
                        </Typography>
                        <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Nokia Arena processes personal data to fulfil legal and contractual obligations. The legal bases for our processing are:
                        </Typography>
                        <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Nokia Arena processes personal data to fulfil legal and contractual obligations. The legal bases for our processing are:
                        </Typography>
                        <Typography className='pt-3 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Implementation of the contract: Fulfilling contractual obligations, i.e. providing our service, is the main legal basis for our processing of personal data. The contract is formed between us when you use the service. By using the service, you agree to the processing of data in accordance with the privacy statement. We process personal data to provide the service ordered from us to the extent necessary.<br />
                            Statutory obligation: In addition to contracts, our operations are subject to statutory obligations, on the basis of which we process personal data. Examples of these are accounting legislation and legislation concerning event and restaurant industries.
                        </Typography>
                        <Typography className='pt-2 pb-2 font-weight-bold' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            HOW LONG DO WE KEEP INFORMATION?
                        </Typography>
                        <Typography className='pt-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Personal data is stored only for the duration of the contractual relationship, unless otherwise required by legislation, such as the Accounting Act. For example, purchase transactions are stored for the period required by the Accounting Act, but the information is anonymized when the contractual relationship ends.
                        </Typography>
                        <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            We store customer service data for a maximum of 24 months.
                        </Typography>
                        <Typography className='pt-3 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            DATA RECIPIENTS AND DATA TRANSFER TO THIRD COUNTRIES
                        </Typography>
                        <Typography className='pt-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            The data is processed by Nokia Arena employees, in accordance with the applicable personal data law. Nokia Arena reserves the right to partially outsource the processing of personal data to a third party, in which case we guarantee through contractual arrangements that personal data is processed in accordance with the Personal Data Act and otherwise appropriately.
                            Information related to purchase transactions is transferred to the system of our payment service provider Nets for charging.
                        </Typography>
                        <Typography className='pt-5 pb-3 font-weight-bold' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            TERMS OF USE
                        </Typography>
                        <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            By using the Nokia Arena service, you agree to these terms of use.
                        </Typography>
                        <Typography className='pt-2 pb-2' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            <b> KOY Tampereen Monitoimiareena (2792951-4)</b> and its partners sells products to individuals in Finland. We reserve the right to change terms and prices. All prices include VAT.
                        </Typography>
                        <Typography className='pt-3' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            CONTACT
                        </Typography>
                        <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Email: support@areenatampere.fi phone: 00000
                        </Typography>
                        <Typography className='pt-3 pb-3' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            AGE LIMIT
                        </Typography>
                        <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Ordering alcohol products in the Nokia Arena service is only allowed for persons who are over 18 years old and meet the requirements of the Alcohol Act in other respects.
                        </Typography>
                        <Typography className='pt-3 pb-3' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            ORDERS
                        </Typography>
                        <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            The user selects the contents of the order in the shopping cart and places the order in the Nokia Arena service. The order is confirmed by using the purchase function of the Nokia Area service to pay for the purchase. The user accepts these terms and product pricing by placing an order.
                        </Typography>
                        <Typography className='pt-3 pb-3' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            ORDER CONFIRMATION AND RECEIPT
                        </Typography>
                        <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            When ordering and paying, customer may choose to have the receipt sent to their email address provided by the customer. Receipts list the products ordered and the breakdown of prices. The e-mail contains receipt of the order.
                        </Typography>
                        <Typography className='pt-3 pb-3' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            ORDER PAYMENT, CANCELLATION AND REFUNDS
                        </Typography>
                        <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Payment for the order is made in the Nokia Arena service. The order is automatically charged to the user of the service as soon as the order is placed. After placing the order, the user cannot cancel the order. When placing an order, Nokia Arena or the chosen merchant reserves the right to make a payment arrangement with the user. If the card payment fails, the user can be sent an invoice for the order. If the delivery or part of the delivery fails, either due to the product being out of stock or for any other reason beyond the control of Nokia Arena, Nokia Arena or its partner is responsible for paying the refund for those parts to the user. The refund is usually paid within 1-3 weeks.<br />
                            Nokia Arena or its partners does not store or process payment card information in its own system that could be used to make unauthorized payments. All payments are processed by the service payment partner.
                        </Typography>
                        <Typography className='pt-3 pb-3' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            FULLFILLING THE ORDER
                        </Typography>
                        <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            The order delivery is done based on the instructions provided when product is bought.
                        </Typography>
                        <Typography className='pt-3 pb-3' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            ALCOHOL
                        </Typography>
                        <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Ordering and paying for alcoholic beverages is possible using the Nokia Arena service. The Nokia Arena or its partner selling alcohol is licensed to serve alcoholic beverages. Each individual decision to sell alcohol is made by Nokia Arenas partner and their employees. They can refuse to sell alcohol to a user of Nokia Arena service if the requirements of the Alcohol Act are not met. The price of alcoholic beverages is charged using the payment method chosen by the user of the Nokia Arena service. If the user uses the Nokia Arena service in violation of the Alcohol Act (for example, as a minor), Nokia Arena and its partner is entitled to collect the total amount of the order in question from the user.
                        </Typography>
                        <Typography className='pt-3 pb-3' color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body1', (mobileWidth || tabletWidth) && 'body2')} >
                            CHANGES TO THE TERMS OF SERVICE
                        </Typography>
                        <Typography color='dark.main' variant={clsx(!(mobileWidth || tabletWidth) && 'body2', (mobileWidth || tabletWidth) && 'caption')} >
                            Changes may apply to the terms of use of Nokia Arena service. The user of Nokia Arena service must stop using the Nokia Arena service if he/she does not accept the amended terms of use.
                        </Typography>
                    </div>
                </div>
            </Container >
        </div >
    )
}

export default NokiaArenaTermsAndConditions
