import { makeStyles } from "@mui/styles";
export const useStyle = makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.dark.light,
    boxShadow: "none !important",
    cursor: "pointer",
    textAlign: "center",
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Inter',
    minWidth: '70px',
    padding: '8px 16px',
    borderRadius: '5px',
    border: '1px solid #707070',
    "&:hover": {
      backgroundColor: '#E4DDFF',
      color: theme.palette.primary.main,
      // border: "none",
    },
  },
  selectedBtn: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "none !important",
    cursor: "pointer",
    color: theme.palette.background.paper,
    textAlign: "center",
    border: "none",
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Inter',
    minWidth: '70px',
    padding: '8px 16px',
    borderRadius: '5px'
  },
  title: {
    "&:hover": {
      color: theme.palette.background.paper,
    },
  },
  selectedTitle: {
    color: theme.palette.background.paper,
  },
}));
