import './swipe.unlock.scss'
import React, { useEffect, useState, useRef } from 'react';
import { CircularProgress, Typography, Slider } from '@mui/material'
import { useStyles } from './swipe.unlock.style'
import { IsMobileWidth } from 'components/common/util/util'
import clsx from 'clsx';

function SwipeUnlock(props) {
    const { buttonText, loading, reverseThumb, minSwipePercentage, classNames, disabled } = props
    const classes = useStyles()
    const mobileWidth = IsMobileWidth()
    const [sliderValue, setSlideValue] = useState(0);
    const sliderValueRef = useRef(0);

    // useEffect(() => {
    //     if (document && document.getElementsByClassName("MuiSlider-thumb") && document.getElementsByClassName("MuiSlider-thumb").length > 0) {
    //         document.getElementsByClassName("MuiSlider-thumb")[0].tabIndex = 3
    //     }
    // }, []);

    const handleChange = (event, value) => {
        event.preventDefault();
        event.stopPropagation();
        if (value < minSwipePercentage) {
            setSlideValue(value);
            sliderValueRef.current = value;
        }
        else {
            if (!disabled && !loading) {
                setSlideValue(0);
                sliderValueRef.current = 0;
                props.onSwipeComplete(event)
            }
        }
        //if (!disabled && !loading) {

        //}
    };
    const onBtnClick = (event) => {
        if (!disabled && !loading && sliderValueRef?.current == 0) {
            setSlideValue(0);
            sliderValueRef.current = 0;
            props.onSwipeComplete(event)
        }
    }

    const handleChangeCommitted = (event, value) => {
        event.preventDefault();
        event.stopPropagation();
        if (sliderValue < minSwipePercentage) {
            sliderValueRef.current = 0;
            setSlideValue(0);
        }
    };

    return (
        <div className='w-100' id="slide-to-unlock">
            <div onClick={onBtnClick} className={clsx(classNames, !disabled ? 'swipe-button' : 'swipe-button-disabled', "w-100 cursor-pointer d-flex position-relative justify-content-start")}>
                {(<>
                    <Slider
                        className="w-100"
                        onChangeCommitted={handleChangeCommitted}
                        onChange={handleChange}
                        classes={
                            {
                                rail: classes.rail,
                                thumb: !mobileWidth && !disabled ? "breathing-button" : mobileWidth && !disabled ? "responsive-breathing-button" : disabled ? 'd-none' : '',
                                root: !mobileWidth ? classes.root : classes.responsiveRoot
                            }
                        }
                        // disabled={disabled}
                        value={sliderValue}
                        thumbcomponent="div">
                    </Slider>
                    <div className="white-space-no-wrap text-muted text h-100 w-100 d-flex justify-content-center align-items-center slider-body">
                        <Typography
                            classes={{
                                root: 'root',
                            }}
                            variant="body3">{buttonText}</Typography>
                    </div>
                    {
                        loading ?
                            <div className="p-1 pl-4 d-flex justify-content-center align-items-center">
                                <div className='pl-3'>
                                    <CircularProgress size={20} />
                                </div>
                            </div> : null
                    }
                </>)
                }
            </div>
        </div>

    )
}

SwipeUnlock.defaultProps = {
    reverseThumb: true,
    minSwipePercentage: 75
}
export default SwipeUnlock;