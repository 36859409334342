export const DO_SIGN_UP = "DO_SIGN_UP"
export const RESET_DO_SIGN_UP = "RESET_DO_SIGN_UP"
export const RESET_DO_SIGNUP_CALL = "RESET_DO_SIGNUP_CALL";
export const GO_TO_BACK_STEP = "GO_TO_BACK_STEP";

export function doSignUp(data) {
    switch (data.activeStep) {
        case 1: //mobile no validation step
            if (data.sendOtp === true && data.userId) {
                return {
                    url: `users/${data.userId}/resend_otp`,
                    type: DO_SIGN_UP,
                    method: 'PUT',
                    payload: { ...data }
                };
            }
            else {
                let newData = {
                    user: {
                        mobile_number: data.mobileNumber?.replace(/\s/g, '')
                    }
                }
                return {
                    url: `users`,
                    type: DO_SIGN_UP,
                    data: newData,
                    method: 'POST',
                    payload: { ...data }
                };
            }
        case 2: // Verify OTP Step
            let newData = {
                mobile_otp: data.otp
            }
            return {
                url: `users/${data.userId}/verify_mobile_otp`,
                type: DO_SIGN_UP,
                data: newData,
                method: 'POST',
                payload: { ...data }
            };
        case 3: // Setup Pin
            let cAct = {
                user: { email: "", password: data.pin, full_name: data.fullName, dob: "" }
            }
            return {
                url: `users/${data.userId}/update_user`,
                type: DO_SIGN_UP,
                data: cAct,
                method: 'POST',
                payload: { ...data }
            };

        default:
            return {
                type: DO_SIGN_UP,
                data,
                payload: { ...data }
            };
    }
}
export function goToBackStep() {
    return {
        type: GO_TO_BACK_STEP
    }
}

export function resetDoSignUp() {
    return {
        type: RESET_DO_SIGN_UP,
        payload: {
            resetActionNames: ["DO_SIGN_UP"]
        }
    }
}
export function resetDoSignUpCall() {
    return {
        type: RESET_DO_SIGNUP_CALL,
        payload: {
            resetActionNames: ["DO_SIGN_UP"]
        }
    }
}