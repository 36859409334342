import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
    selectedNaviationButton: {
        backgroundColor: "#fafafa",
        outline: "none !important"
        //borderRadius: "10px"
    },
    navigationActionButton: {
        outline: "none !important",
        color: theme.palette.primary.main,
        '&.Mui-selected': {
            backgroundColor: '#fff !important',
            '& .MuiBottomNavigationAction-label': {
                backgroundColor: 'transparent !important',
                fontWeight: '500 !important'
            },
        },

    },
    selectedIcon: {
        borderRadius: '100%',
        backgroundColor: '#fff',
        boxShadow: '0px 3px 12px #c8cfde99',
        height: '60px',
        width: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    label: {
        fontWeight: '400 !important'
    },
    selectedLabel: {
        fontWeight: '600 !important'
    }
}))
