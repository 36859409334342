import OrderHistory from './order.history';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
    makeSelectProductCategories, makeSelectStoreDetailInfo,
    makeSelectSubCategoryProducts, makeSelectSelectedStoreProductCategory,
    makeSelectCartProducts, makeSelectOrderedProducts
} from 'view.updater/selectors/products.selectors';
import { getIndustryStores, resetIndustryStores,
     getCartProducts, getProductsByOrderId, resetGetProductsByOrderId,clearCartAndAddNewItems } from 'view.updater/actions/products.actions';
// import { makeSelectAuth } from 'view.updater/selectors/auth.selectors';
import { getActiveOrders, getPastOrders, getCancelledOrders } from 'view.updater/actions/order.actions';
import { makeSelectActiveOrders, makeSelectCancelledOrders, makeSelectPastOrders, makeSelectActiveOrdersCount } from 'view.updater/selectors/order.selector';

const mapDispatchToProps = (dispatch) => ({
    getIndustryStores: (data) => dispatch(getIndustryStores(data)),
    resetIndustryStores: () => dispatch(resetIndustryStores()),
    getCartProducts: (data) => dispatch(getCartProducts(data)),
    getActiveOrders: (data) => dispatch(getActiveOrders(data)),
    getPastOrders: (data) => dispatch(getPastOrders(data)),
    getCancelledOrders: (data) => dispatch(getCancelledOrders(data)),
    getProductsByOrderId: (data) => dispatch(getProductsByOrderId(data)),
    resetGetProductsByOrderId: () => dispatch(resetGetProductsByOrderId()),
    clearCartAndAddNewItems: (data) => dispatch(clearCartAndAddNewItems(data)),
});

const mapStateToProps = createStructuredSelector({
    storeProductCategories: makeSelectProductCategories(),
    storeDetailInfo: makeSelectStoreDetailInfo(),
    selectedStoreProductCategory: makeSelectSelectedStoreProductCategory(),
    subCategoryProducts: makeSelectSubCategoryProducts(),
    cartProducts: makeSelectCartProducts(),
    activeOrders: makeSelectActiveOrders(),
    pastOrders: makeSelectPastOrders(),
    cancelledOrders: makeSelectCancelledOrders(),
    activeOrdersCount: makeSelectActiveOrdersCount(),
    orderedProducts: makeSelectOrderedProducts()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(OrderHistory);