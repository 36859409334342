import React, { useState } from 'react'
import { Box, Typography, IconButton, Paper, Grid } from '@mui/material';
import { useStyle } from './trasport.navigation.panel.style';
import './trasport.navigation.panel.scss'
import clsx from 'clsx';
import TransportNavigationPanelPopover from './components/transport.navigation.panel.popover/transport.navigation.panel.popover';
import { useLocation } from 'react-router';
import { withRouter } from 'react-router'
import { IsMobileWidth, IsTabletWidth, redirectToHomePage } from 'components/common/util/util';

//

const TransportNavigationPanel = (props) => {
    const classes = useStyle()
    const { name, label, status, hideNavigationButtons, hideCloseButton, transparent } = props
    const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
    let location = useLocation();
    const isMainPage = location.pathname === '/transport/main-page';
    const isLocationPickup = location.pathname === '/transport/location-pickup';
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const [isNavigationPopoverOpen, setIsNavigationPopoverOpen] = useState(null);

    const showIsNavigationPopover = (event) => {
        setIsNavigationPopoverOpen(event.currentTarget);
    };

    const hideIsNavigationPopover = () => {
        setIsNavigationPopoverOpen(null);
    };

    const redirect = (url) => {
        if (url === '/products') {
            redirectToHomePage(props, tradeTypeId)
        }
        else {
            props.history.push(`${url}`)
        }
        hideIsNavigationPopover();
    }

    return (
        <div className={clsx(classes.topNavigation, transparent && classes.transparentColor)}>
            <Paper elevation={0} className='z-index' style={{ backgroundColor: 'transparent', zIndex: '9999999'}}>
                <div className={clsx(hideNavigationButtons ? 'justify-content-end' : 'justify-content-between', transparent ? '' : '', 'w-20 d-flex align-items-center')}>
                    {!hideNavigationButtons ?
                        <div>
                            {isNavigationPopoverOpen ?
                                null
                                // <IconButton size="medium" onClick={hideIsNavigationPopover} className='icon-position-close cursor-pointer z-index position-absolute' style={{backgroundColor:'transparent'}}>
                                //     <img
                                //         src={!transparent ? `${process.env.PUBLIC_URL}/assets/icons/close-icon.png` : `${process.env.PUBLIC_URL}/assets/icons/close-icon.svg`}
                                //         width="16px"
                                //         height="16px"
                                //     />
                                // </IconButton>
                                :
                                <IconButton size="medium" onClick={showIsNavigationPopover} className='icon-position cursor-pointer position-absolute' 
                                style={{
                                    top: isMainPage ? '10%' : isLocationPickup ? '15%' : '1%',
                                    left: isMainPage || isLocationPickup ? '2%' : '2%',
                                  }}
                                >
                                    <img
                                        src={!transparent ? `${process.env.PUBLIC_URL}/assets/icons/hamburger-menu-icon.png` : `${process.env.PUBLIC_URL}/assets/icons/icon-Hamburger-white.svg`}
                                        width="42px"
                                        height="42px"
                                    />
                                </IconButton>
                            }
                        </div> : null
                    }
                    {/* {!hideCloseButton ?
                        <IconButton onClick={() => redirectToHomePage(props, tradeTypeId)}>
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/close-icon-primary.svg`}
                                width="26px"
                                height="26px"
                            />
                        </IconButton> : null
                    } */}
                </div>
                <div className='w-100 d-flex justify-content-center align-items-center pb-2'>
                    <Typography variant={clsx(!(mobileWidth || tabletWidth) && 'h4', (mobileWidth || tabletWidth) && 'h6')} className={clsx(classes.name, "text-ellipsis-clamp-2 font-weight-light text-capitalize")}>
                        {name ? name : ""}
                    </Typography>
                </div>
            </Paper>
            <div className={clsx('mt-n1')}>
                {label ?
                    <Box className='w-100'>
                        <div className={clsx(classes.label, 'w-100 d-flex justify-content-center align-items-center pl-3 pr-3')}>
                            <Typography variant='body1' className='text-white text-ellipsis-clamp-1 h-auto pt-1'>
                                {label}
                            </Typography>
                        </div>
                    </Box> : null
                }
            </div>
            {isNavigationPopoverOpen ?
                <TransportNavigationPanelPopover // Currently only for mobile || tablet view
                    isNavigationPopoverOpen={isNavigationPopoverOpen}
                    showIsNavigationPopover={showIsNavigationPopover}
                    hideIsNavigationPopover={hideIsNavigationPopover}
                    redirect={redirect}
                    location={location}
                    {...props}
                /> : null
            }
        </div>
    )
}

export default withRouter(TransportNavigationPanel)