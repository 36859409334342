import './popover.dropdown.scss';
import React, { useState } from 'react';
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputAdornment from "@mui/material/InputAdornment";

export default function PopoverDropdown(props) {

  const [state, setState] = useState({
    anchorEl: undefined,
    popOverWidth: undefined,
    selectedValue: undefined
  })

  const useStyles = makeStyles(theme => ({
    root: {
      width: state.popOverWidth,
      overflow: "hidden"
    }
  }))

  const classes = useStyles()

  const onDropdownOpen = event => {
    setState({ ...state, popOverWidth: event.currentTarget.offsetWidth, anchorEl: event.currentTarget });
  }

  const onItemSelect = (value) => {
    setState({ ...state, selectedValue: value, anchorEl: undefined })
  }

  const add = (event) => {
    event.preventDefault();
    props.add()
  }

  const onDelete = (event, data) => {
    event.stopPropagation();
    props.onDelete(data)
  }
  const open = Boolean(state.anchorEl);
  const { placeholder, items } = props;
  return (
    <div id="custom-popover-dropdown">
      <Paper
        tabIndex={0}
        className="paper p-3 d-flex justify-content-between align-items-center"
        onClick={onDropdownOpen}>
        <div>{state.selectedValue ? state.selectedValue : placeholder}</div>
        <div className={open ? "fa fa-caret-up" : "fa fa-caret-down"}></div>
      </Paper>

      <Popover
        id="custom-popover-dropdown"
        open={open}
        classes={{
          paper: classes.root
        }}
        anchorEl={state.anchorEl}
        onClose={() => {
          setState({ ...state, anchorEl: undefined })
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="p-3">
          <form>
            <TextField
              label="Add New"
              InputProps={{
                endAdornment:
                  <InputAdornment>
                    <div className="d-flex">
                      <Button
                        type="submit"
                        size="sm"
                        variant="contained"
                        onClick={(event) => add(event)}
                      >
                        <i className="fa fa-plus"></i>
                      </Button>
                    </div>
                  </InputAdornment>
              }}
            />
          </form>
        </div>
        <div className="menu-items">
          {
            items && items.map((data, index) => {
              return <MenuItem key={index} onClick={() => onItemSelect(data)}>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div>{data}</div>
                  <i
                    className="fa fa-trash"
                    onClick={(event) => onDelete(event, data)}
                  ></i>
                </div>
              </MenuItem>
            })
          }
        </div>
      </Popover>
    </div>
  )
}
