// The initial state of the App
import { fromJS } from 'immutable';
import { getActionName } from './util';
import {
    LOGIN, RESET_AUTH, LOGOUT, UPDATE_ADDRESS, DO_MERCHANT_LOGIN, UPDATE_USER_AUTH_DATA, CREATE_ADDRESS,
    RESET_CREATE_ADDRESS, RESET_DELETE_ADDRESS, DELETE_ADDRESS, AUTHENTICATE_ANONYMOUSLY
} from '../actions/auth.actions';

// const address = [
//     {
//       street: "I.I Chundrigar Road",
//       name: "Workplace 2",
//       floor: "181",
//       district: "181 I.I Chundrigar Rd, New Chali, Karachi, Karachi City, Sindh, Pakistan",
//       query_id: "joM70La6vJ1dAlXVEMNGVr5RWOK8qDNm3z9x",
//       status: "active",
//       building: "New Chali",
//       landmark: "Techno Mobile Mall",
//       type: "others",
//       room: "181",
//       id: 2540,
//       long: 67.0147527,
//       description: "181 181 I.I Chundrigar Rd, New Chali, Karachi, Karachi City, Sindh, Pakistan",
//       lat: 24.95321407934218
//     },
//     {
//       street: "I.I Chundrigar Road",
//       name: "Workplace New",
//       floor: "181",
//       district: "181 I.I Chundrigar Rd, New Chali, Karachi, Karachi City, Sindh, Pakistan",
//       query_id: "jrOQ7lLnyRwKmMkVXZWGPxoA4BXe0Zz593v2",
//       status: "inactive",
//       building: "New Chali",
//       landmark: "Techno Mall",
//       type: "others",
//       room: "181",
//       id: 2539,
//       long: 67.0082072541118,
//       description: "181 181 I.I Chundrigar Rd, New Chali, Karachi, Karachi City, Sindh, Pakistan",
//       lat: 24.8500938933977
//     },
//     {
//       street: "I.I Chundrigar Road",
//       name: "Workplace",
//       floor: "181",
//       district: "181 I.I Chundrigar Rd, New Chali, Karachi, Karachi City, Sindh, Pakistan",
//       query_id: "6yvKEwnQjra9p5eYZOyrPBNJWo87x4LDdzqk",
//       status: "inactive",
//       building: "New Chali",
//       landmark: "Techno City Mall",
//       type: "others",
//       room: "181",
//       id: 2538,
//       long: 67.0082072541118,
//       description: "181 181 I.I Chundrigar Rd, New Chali, Karachi, Karachi City, Sindh, Pakistan",
//       lat: 24.8500938933977
//     },
//     {
//       street: "I.I Chundrigar Road",
//       name: "Zapp Office",
//       floor: "1st Floor , Iftikhar Chamber",
//       district: "I.I Chundrigar Rd, Karachi, Karachi City, Sindh, Pakistan",
//       query_id: "ExN1zemRB39oKarVolelYldG0kO5v7pWjny2",
//       status: "inactive",
//       building: "Saddar",
//       landmark: "Techno City Mall",
//       type: "others",
//       room: "1st Floor , Iftikhar Chamber",
//       id: 2506,
//       long: 67.01327126473193,
//       description: "1st Floor , Iftikhar Chamber I.I Chundrigar Rd, Karachi, Karachi City, Sindh, Pakistan",
//       lat: 24.8501361817838
//     }
//   ]

const initialState = fromJS({
    auth: fromJS(JSON.parse(window.localStorage.getItem('auth'))) || {},
    addressUpdated: false,
    addressDeleted: false
});
const getSelectedAddressId = (response, payload) => {
    if (payload?.address?.type === "work") {
        return response?.work_address?.id
    }
    else if (payload?.address?.type === "home") {
        return response?.home_address?.id
    }
    else {
        return response?.other_addresses[0]?.id
    }
}
function authReducer(state = initialState, action) {
    switch (action.type) {
        case getActionName(LOGIN):
            //action.response.user.other_addresses = address;
            window.localStorage.setItem("auth", JSON.stringify(action.response));
            window.localStorage.setItem("loginTime", new Date());
            return state.set('auth', fromJS(action.response));
        case LOGOUT:
            window.localStorage.removeItem("auth");
            window.localStorage.removeItem("loginTime");
            return state.set('auth', '');
        case getActionName(AUTHENTICATE_ANONYMOUSLY):
            //action.response.user.other_addresses = address;
            window.localStorage.setItem("auth", JSON.stringify(action.response));
            window.localStorage.setItem("loginTime", new Date());
            return state.set('auth', fromJS({ ...action.response, industry_types: action.response.industry_type }));
        case getActionName(DO_MERCHANT_LOGIN):
            //action.response.user.other_addresses = address;
            window.localStorage.setItem("auth", JSON.stringify(action.response));
            window.localStorage.setItem("loginTime", new Date());
            return state.set('auth', fromJS({ ...action.response }));
        case RESET_AUTH:
            window.localStorage.removeItem("auth");
            return state.set('auth', '');
        case getActionName(CREATE_ADDRESS):
            state = state.updateIn(['auth', 'user'], (au) => {
                return au.merge(fromJS({ ...action.response }))
            })
            state = state.updateIn(['auth'], (au) => {
                return au.merge(fromJS({ selectedAddressId: getSelectedAddressId(action.response, action.payload) }))
            })
            state = state.set('addressUpdated', true)
            return state;
        case getActionName(UPDATE_ADDRESS):
            state = state.updateIn(['auth', 'user'], (au) => {
                return au.merge(fromJS({ ...action.response }))
            })
            state = state.updateIn(['auth'], (au) => {
                return au.merge(fromJS({ selectedAddressId: getSelectedAddressId(action.response, action.payload) }))
            })
            state = state.set('addressUpdated', true)
            return state;
        case getActionName(DELETE_ADDRESS):
            if (action.payload.addressDetails.type === "others") {
                state = state.updateIn(['auth', 'user', 'other_addresses'], (items) => {
                    return items.filterNot((item) => item.get('query_id') === action.payload.query_id)
                })
            }
            else if (action.payload.addressDetails.type === "home") {
                state = state.updateIn(['auth', 'user', 'home_address'], (au) => {
                    return fromJS({})
                })
            }
            else if (action.payload.addressDetails.type === "work") {
                state = state.updateIn(['auth', 'user', 'work_address'], (au) => {
                    return fromJS({})
                })
            }
            state = state.updateIn(['auth'], (au) => {
                return au.merge(fromJS({ selectedAddressId: action?.payload?.addressDetails?.id == au.get('selectedAddressId') ? '' : au.get('selectedAddressId') }))
            })
            state = state.set('addressDeleted', true)
            return state;
        case UPDATE_USER_AUTH_DATA:
            state = state.updateIn(['auth'], (au) => {
                return au.merge(fromJS({ ...action.payload }))
            })
            const newAuthData = { ...(state?.get("auth")?.toJS()), ...action.payload }
            window.localStorage.setItem("auth", JSON.stringify(newAuthData));
            return state
        case RESET_CREATE_ADDRESS:
            state = state.set('addressUpdated', false)
            return state;
        case RESET_DELETE_ADDRESS:
            state = state.set('addressDeleted', false)
            return state;
        default:
            return state;
    }
}
export default authReducer;
